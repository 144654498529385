import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { salesRepresentativesActions } from "../../../../_store/salesRepresentatives.slice";

export function useSalesRepresentativeSearch() {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const [items, setItems] = useState([]);
  const onSearchChange = async (searchTerm) => {
    const params = { searchQuery: searchTerm, pageNumber: 1, pageSize: 30 };
    const { payload } = await dispatch(salesRepresentativesActions.search(params));
    setItems(payload.items);
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      onSearchChange(null);
    } else {
      isFirstRender.current = false;
    }

  }, []); // eslint-disable-next-line/react-hooks/exhaustive-deps


  return { items, onSearchChange }
}