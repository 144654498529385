// noinspection JSCheckFunctionSignatures,JSVoidFunctionReturnValueUsed

import { useEffect, useState } from "react";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";

export function SortedTh({ children, sortingValue, sorting, itemActions  }) {
  const [isSortingActive, setIsSortingActive] = useState(false);
  const [sortingType, setSortingType] = useState("");
  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (isSortingActive && sortingType === "desc") {
      setIsSortingActive(false);
      dispatch(itemActions.setSorting({ value: null, type: null }));

      return;
    }

    const newSortingType = sortingType === "asc" ? "desc" : "asc";

    setIsSortingActive(true);
    setSortingType(newSortingType);

    dispatch(itemActions.setSorting({ value: sortingValue, type: newSortingType }));
  };

  useEffect(() => {
    if(sorting?.value !== sortingValue && isSortingActive) {
      setIsSortingActive(false);
      setSortingType(null);
      return;
    }

    if(sorting?.value === sortingValue && !isSortingActive) {
      setIsSortingActive(true);
      setSortingType(sorting.type);
    }

  }, [sorting]);

  return <th className="hover-pointer hover-grey" onClick={handleOnClick}>
    {isSortingActive ? sortingType === "asc" ? <ArrowUp/> : <ArrowDown/> : ""} {children}
  </th>;
}