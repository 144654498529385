import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { orderViewsActions } from "../../../_store/orderView.slice";

export function useOrderSearch({ transactionType, isTransfer }) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  const onSearchChange = async(searchTerm) => {
    if (!transactionType) {
      setItems([]);
      return;
    }

    const query = {
      pageNumber: 1,
      pageSize: 10,
      searchTerm,
      transactionType,
      isTransfer
    };

    const { payload } = await dispatch(orderViewsActions.search(query));
    const items = payload.items;
    setItems(items);
  };

  useEffect(() => {
    onSearchChange("");
  }, [transactionType]);

  return { items, onSearchChange };
}