import DropdownButton from "react-bootstrap/DropdownButton";
import { useDispatch } from "react-redux";
import { useToast } from "../../../_helpers/notifications/useToasts";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { bdoActionModalFactory } from "./Actions/bdoActionModalFactory";
import { useGetAction } from "./Actions/UseGetAction";
import { useKpoReportMessageProvider } from "../../../domain/Orders/UpdateKpoReportMessageProvider";

export function OrderKpoCardActions({ orderData, kpoItem, kpoCards, performAll }) {
  const { orderId, orderStatus, transactionType, transportType } = orderData;
  const { getUpdateKpoReportMessage } = useKpoReportMessageProvider();
  const dispatch = useDispatch();
  const { showInfoMessage, showErrorMessage } = useToast();

  const handleAction = async({ action, isFileDownload, kpoItems, refreshTransportDetails }) => {
    const response = await dispatch(action(kpoItems));
    if (isFileDownload) {
      return response;
    }

    if (response.error) {
      showErrorMessage(response.error?.message);
      return;
    }

      const message = getUpdateKpoReportMessage(response);
      const { orderChangeStatusReport } = response?.payload?.report || {};
      const { isSucceeded: isOrderStatusChanged } = orderChangeStatusReport || {};
      if (isOrderStatusChanged) {
        await dispatch(orderViewsActions.refreshStatus({ id: orderId }));
      }

      if(refreshTransportDetails) {
        await dispatch(orderViewsActions.getTransportDetails({ id: orderId }));
      }

      await dispatch(orderViewsActions.getBdoInfoById({ id: orderId }));
      await dispatch(orderViewsActions.refreshHistory({ id: orderId }));
      showInfoMessage(message);
  };

  const bdoActionProviderProps = { kpoItem, orderId, transactionType, orderStatus, kpoCards, transportType, performAll };
  const items = useGetAction(bdoActionProviderProps);
  const availableItems = items.filter(x => performAll ? x.isPerformAllVisible : x.isVisible);
  const ActionsWrapper = performAll ? `div` : DropdownButton;
  const actionsProps = performAll ? { className: "d-flex" } : { title: "", className: "domain-control small", size: "sm", variant: "success" };

  return (availableItems.length > 0 &&
    <ActionsWrapper {...actionsProps}>
      {availableItems.map((x, idx) => {
        const factoryParams = { handleAction, item: x, idx, kpoCards, performAll, orderData, expectedStatus: x.expectedStatus };
        if (x.type === "Prompt") {
          return bdoActionModalFactory.getPromptModal(factoryParams);
        }
        if (x.type === "DirectDropdown") {
          return bdoActionModalFactory.getDirectDropdownItem(factoryParams);
        }
        if (x.type === "RemarksForm") {
          return bdoActionModalFactory.getRemarksModal(factoryParams);
        }
        if (x.type === "ReviseForm") {
          return bdoActionModalFactory.getReviseModal(factoryParams);
        }
        if (x.type === "ReceiveConfirmationForm") {
          return bdoActionModalFactory.getReceiveConfirmationModal(factoryParams);
        }
        if (x.type === "GenerateConfirmation") {
          return bdoActionModalFactory.getGenerateConfirmationModal(factoryParams);
        }
        if (x.type === "RejectForm") {
          return bdoActionModalFactory.getRejectKpoModal(factoryParams);
        }
        if (x.type === "OrderRejectForm") {
          return bdoActionModalFactory.getOrderRejectModal(factoryParams);
        }
        if (x.type === "UpdatePlannedOrApprovedKpoCards") {
          return bdoActionModalFactory.getUpdatePlannedOrApprovedKpoCardsModal(factoryParams);
        }
      })}
    </ActionsWrapper>
  );
}

