import { Receipt, TelephoneForward, TelephoneForwardFill, Truck } from "react-bootstrap-icons";

export const MakeOrdersDashboardItems = [
  {
    name: "Zakup",
    link: "/orders/create-purchase",
    icon: TelephoneForward,
    color: "indigo"
  },
  {
    name: "Sprzedaż",
    link: "/orders/create-sale",
    icon: Receipt,
    color: "teal"
  },
  {
    name: "Usługa transportowa",
    link: "/orders/create-transport",
    icon: Truck,
    color: "orange"
  }
];