import { ValidateBdoAction } from "./BdoActionValidator";
import { FormModal } from "../../../Forms/FormModal";
import { KpoRejectFormProvider } from "./KpoRejectForm";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Card } from "react-bootstrap";
import { WasteMassValidator } from "../../../../domain/Orders/CreatePurchaseOrderValidator";
import { useDefaultKpoItemsValuesForWastesCalculator } from "./RecalculateKpoFromWastes";
import { mapToWastesDetails } from "../../../../_services/Orders/orderActionsMapper";
import { orderViewsActions } from "../../../../_store/orderView.slice";
import { TRANSACTION_TYPE } from "../../../../domain/TransactionType";
import { WastesDetailsFormProvider } from "../../../../useCases/orders/updateWastesDetails/WastesDetailsFormProvider";

export function OrderRejectForm({ kpoCards, orderId, onSubmit, name, promptTitle, performAll }) {
  const dispatch = useDispatch();
  const { orderDetails } = useSelector((x) => x.orderViews);
  const { wastesDetails, transactionType } = orderDetails;
  const { validate } = ValidateBdoAction();
  const { formControls: kpoFormControls, defaultValues: kpoDefaultValues, validationSchema: kpoValidationSchema } = KpoRejectFormProvider({ kpoCards: kpoCards, allowRecalculateWasteMass: true });
  const { formControls: wasteFormControls, defaultValues: wasteDefaultValues, validationSchema: wasteValidationSchema }
    = WastesDetailsFormProvider({ order: orderDetails, transactionType, wastesDetails, requireApplicableKpoItems: true });
  const { getDefaultValues } = useDefaultKpoItemsValuesForWastesCalculator();
  // const { recalculateKpoItems } = RecalculateKpoFromWastes();

  const formControls = <>
    <Card>
      <div className="d-flex">
        <div>
          <Card.Header>Dane towarów</Card.Header>
          <Card.Body>
            {wasteFormControls}
          </Card.Body>
        </div>
        <div>
          <Card.Header>Dane KPO</Card.Header>
          <Card.Body>
            {kpoFormControls}
          </Card.Body>
        </div>
      </div>
    </Card>
  </>;

  // let defaultValues = {
  //   ...wasteDefaultValues,
  //   ...kpoDefaultValues
  // };

  const defaultValues = getDefaultValues({ wasteDefaultValues, kpoDefaultValues });

  const schema = Yup.object().shape({
    wasteMass: Yup.string().nullable()
  });
  const wasteSchema = Yup.object().shape({
    applicableKpoItems: Yup.array().when("validateWasteMass", {
      is: true,
      then: Yup.array().of(WasteMassValidator.wasteSchema.concat(schema))
    }).required("Przynajmniej jedna karta KPO musi być przypisana to towaru").min(1, "Przynajmniej jedna karta KPO musi być przypisana to towaru"),
    wasteMass:
      Yup.string().test("is-total-count-valid", "Suma mas kart KPO przypisanych do towaru musi być równa masie towaru", function(value) {
        const { applicableKpoItems } = this.parent;
        if (applicableKpoItems?.length > 1) {
          const sum = applicableKpoItems.reduce((sum, item) => sum + Number(item?.wasteMass), 0);
          return Number(value) === Number(sum);
        }
        return true;
      })
  });
  const wastesSchema = Yup.object().shape({ wastes: Yup.array().of(wasteSchema) });
  const validationSchema = Yup.object().shape({
    wastesDetails: Yup.object().shape({
      loading: wastesSchema,
      containers: Yup.array().of(wastesSchema)
    })
  }).concat(kpoValidationSchema).concat(wasteValidationSchema);

  const handleSubmit = async(payload) => {
    const kpoItems = payload.kpoItems.filter(x => x.isSelected).map((x) => ({
      kpoId: x.kpoId,
      wasteMass: x.correctedWasteMass,
      remarks: x.remarks
    }));

    validate(kpoItems);
    const response = await onSubmit({ kpoItems, wastesDetails: mapToWastesDetails(payload.wastesDetails, TRANSACTION_TYPE.PURCHASE) });
    if (!response?.error) {
      await dispatch(orderViewsActions.getWastesDetails({ id: orderId }));
    }
  };

  return <FormModal
    title={promptTitle}
    formControls={formControls}
    defaultValues={defaultValues}
    onSubmit={handleSubmit}
    buttonText={name}
    validationSchema={validationSchema}
    isButtonTrigger={performAll}
    skipToast
    sizeModal="xl"
    submitBtnText={name}/>;
}