import { useSelector } from "react-redux";
import { OrderCalendarFilterBox } from "./OrderCalendarFilterBasic";
import { useCalendarView } from "./useCalendarView";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { CalendarDay } from "./CalendarDay";
import { CalendarWeek } from "./CalendarWeek";

export function OrderCalendarView() {
  const { ordersCalendarView, calendarViewDates } = useSelector((x) => x.orderViews);
  const { defaultStatuses, refreshCalendar, currentFilters, setCurrentFilters, handleCalendarChange, refreshFilters } = useCalendarView({ calendarViewDates, orderViewsAction: orderViewsActions.getCalendarView });
  const { weekDates, currentDate } = calendarViewDates;
  const GetItem = (x) => <CalendarDay key={x.id} orderItem={x}/>;

  return <div className="d-flex flex-column justify-content-center">
    <h2>Kalendarz zleceń</h2>
    <OrderCalendarFilterBox
      currentDate={currentDate}
      currentFilters={currentFilters}
      handleCalendarChange={handleCalendarChange}
      handleFilterChange={refreshFilters}
      refreshCalendar={refreshCalendar}
      defaultStatuses={defaultStatuses}
      setCurrentFilters={setCurrentFilters}
    />
    <CalendarWeek weekDates={weekDates} items={ordersCalendarView} getItem={GetItem}/>
  </div>;
}

