import { BadgeDomain } from "_components/Abstractions/BadgeDomain";
import { ContractorBadges, ContractorTypes } from "domain/ContractorType";

export function ContractorTypeLargeBadge({ role }) {
  return (
    <>
      <BadgeDomain bg={ContractorBadges[role]}>
        <h5>{ContractorTypes[role]}</h5>
      </BadgeDomain>
    </>
  );
}
