import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { OrderWorkFlowAction } from "./OrderWorkFlowAction";

export function ReopenOrder({ orderId, orderNumber }) {
  const successMessage = "Zlecenie zostało otworzone ponownie";
  const buttonText = "Otwórz zlecenie";
  const title = `Czy ponownie otworzyć zlecenie\n ${orderNumber}?`;

  const props = {
    orderId: orderId,
    orderAction: ordersActions.reopen,
    orderViewAction: orderViewsActions.reopen,
    successMessage,
    buttonText,
    title,
    useFormModal: false
  };

  return <OrderWorkFlowAction {...props} />;
}