export const UserRoles = {
  User: "User",
  SuperUser: "Super User",
  Admin: "Admin",
  Automate: "Automat"
};

export const USER_ROLES = {
  USER: "User",
  SUPER_USER: "SuperUser",
  ADMIN: "Admin",
  AUTOMATE: "Automat"
}

export const USER_DISPLAY = {
  User: {
    name: "User",
    color: "secondary",
  },
  Admin: {
    name: "Admin",
    color: "dark",
  },
  "Super User": {
    name: "SuperAdmin",
    color: "info",
  },
  "Automate": {
    name: "Automat",
    color: "info",
  }
};
//   {
//     value: "User",
//     display: "User",
//   },
//   {
//     value: "SuperUser",
//     display: "Super User",
//   },
// ];
