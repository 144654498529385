import { TableDomain } from "_components/Abstractions/TableDomain";

export function CompanyBdoTable({ items, onSelect, multiSelect, onSelectedItemsChanged, selectedItems }) {
  const keyColumn = "companyId";
  const columns = [
    {
      name: "registrationNumber",
      headerName: "Numer rejestrowy",
    },
    {
      name: "name",
      headerName: "Nazwa lub Imię i Nazwisko",
    },
    {
      name: "nip",
      headerName: "NIP",
    },
    {
      name: "addressRaw",
      headerName: "Adres",
    },
  ];

  const props = {
    keyColumn,
    columns,
    items,
    onSelect,
    multiSelect,
    onSelectedItemsChanged,
    selectedItems
  };

  return (
    <>
      <TableDomain {...props}></TableDomain>
    </>
  );
}
