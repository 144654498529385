import { useShippingCalendarExtras } from "./useShippingCalendarExtras";
import { Calendar } from "react-big-calendar";
import moment from "moment";
import { useEffect, useState } from "react";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

const DragAndDropCalendar = withDragAndDrop(Calendar);
export const CalendarShippingItem = ({ date, onSelect, selectable, items, localizer, selected, selectedId, resources }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // if (!items || events.length !== 0) {
    //   return;
    // }

    const categoryEvents = getEvents(items, selectedId, selected, date);
    setEvents(categoryEvents);
  }, [items]);

  const defaultDate = new Date(date);
  // const { scrollToTime } = useMemo(
  //   () => {
  //     let scrollToTime = null;
  //     let [hour, minute] = [6, 0];
  //
  //     if (selected?.selectedStartTime && selected?.selectedStartTime !== "") {
  //       [hour, minute] = selected?.selectedStartTime.split(":");
  //     }
  //     else if(events.length > 0) {
  //       const earliestItem = events?.reduce((minItem, currentItem) => {
  //         return new Date(currentItem.start) < new Date(minItem.start) ? currentItem : minItem;
  //       });
  //       [hour, minute] = earliestItem?.plannedShippingTimeStart.split(":");
  //     }
  //
  //     const date = new Date();
  //     date.setHours((parseInt(hour, 10)));
  //     date.setMinutes(parseInt(minute, 10) - 30);
  //     scrollToTime = date;
  //
  //     return { scrollToTime: scrollToTime };
  //   }, [date]
  // );

  const { props } = useShippingCalendarExtras({ events, moment, onSelect, setEvents });

  return (
    <DragAndDropCalendar
      localizer={localizer}
      events={events}
      date={defaultDate}
      scrollToTime={new Date()}
      resourceIdAccessor="squarePlaceType"
      resources={resources}
      resourceTitleAccessor="squarePlaceTitle"
      onNavigate={_ => () => { }} // it disables inside changing
      selectable={selectable}

      {...props}
    >
    </DragAndDropCalendar>);
};

const getEvents = (items, selectedId, selected, date) => {
  const getDate = (date, time) => {
    return new Date(date + "T" + time);
  };

  const getTitle = (item) => {
    return `${item.orderNumber} - ${item.addressName}`;
  };

  let events = items?.map(x => ({
    ...x,
    title: x.id === selectedId ? "" : getTitle(x),
    start: getDate(x.filterShippingDate, x.plannedShippingTimeStart),
    end: getDate(x.filterShippingDate, x.plannedShippingTimeEnd),
    isSelected: x.id === selectedId,
    resourceId: x.squarePlaceType
  })) ?? [];

  if (selected) {
    const { selectedStartTime, selectedEndTime, squarePlaceType } = selected || {};
    const event = {
      title: "",
      start: selectedStartTime !== "" ? getDate(date, selectedStartTime) : null,
      end: selectedStartTime !== "" ? getDate(date, selectedEndTime) : null,
      isSelected: true,
      resourceId: squarePlaceType
    };
    if (selectedId) {
      events = events.filter(x => x.id !== selectedId);
    }
    if (event.start && event.end && squarePlaceType) {
      events.push(event);
    }

  }

  return events;
};