import { TableDomain } from "_components/Abstractions/TableDomain";

export function EupBdoTable({ items, onSelect, onUnselect, multiSelect }) {
  const keyColumn = "eupId";
  const columns = [
    {
      name: "registrationNumber",
      headerName: "Numer MPD",
    },
    {
      name: "name",
      headerName: "Nazwa",
    },
    {
      name: "addressRaw",
      headerName: "Adres",
    },
  ];

  const props = {
    keyColumn,
    columns,
    items,
    onSelect,
    onUnselect,
    multiSelect,
  };

  return (
    <>
      <TableDomain {...props}></TableDomain>
    </>
  );
}
