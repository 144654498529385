import { Card } from "react-bootstrap";
import { UpdateOrderWastesDetails } from "../../../useCases/orders/updateWastesDetails/UpdateOrderWastesDetails";
import { BadgeDomain } from "../../Abstractions/BadgeDomain";
import { OrderLineItem } from "./OrderDetailsView";
import React from "react";
import { YesNoBadge } from "../../Abstractions/YesNoBadge";
import { WasteDifferenceReason } from "../../../domain/WasteDifferenceReason";
import { UpdateOrderSeriesWastesDetails } from "../../../useCases/orders/updateWastesDetails/UpdateOrderSeriesWastesDetails";

export function WastesOrderDetailsView({ item, isOrderSeries = false }) {
  const { id, transactionType, wastesDetails } = item;
  const { isWasteTransport } = wastesDetails || {};
  const isPurchase = transactionType === "Purchase";
  const isSale = transactionType === "Sale";
  const isTransport = transactionType === "Transport";

  return <Card className="h-100">
    <Card.Header className="d-flex align-items-center justify-content-between">
      Dane towarów
      {!isOrderSeries ?
        <UpdateOrderWastesDetails order={item} wastesDetails={wastesDetails} transactionType={transactionType} orderId={id}/> :
        <UpdateOrderSeriesWastesDetails series={item} wastesDetails={wastesDetails} transactionType={transactionType}/>}
    </Card.Header>
    <Card.Body>
      <OrderLineItem name="Integracja z BDO"><YesNoBadge value={item?.wastesDetails?.isBdoIntegrated}/></OrderLineItem>
      {(isPurchase || isWasteTransport) &&
        <OrderLineItem name="Numer dokumentu PZ">{wastesDetails?.receivedNoteNumber ?? "-"}</OrderLineItem>}
      {(isSale || isWasteTransport) && <>
        <OrderLineItem name="Numer dokumentu WZ">{wastesDetails?.deliveryNoteNumber ?? "-"}</OrderLineItem>
        <OrderLineItem name="Numer dokumentu sprzedaży">{wastesDetails?.saleDocumentNumber ?? "-"}</OrderLineItem>
      </>}
      {isTransport && <>
        <OrderLineItem name="Transport z TRK"><YesNoBadge value={item?.wastesDetails?.isWasteTransport}/></OrderLineItem>
      </>}
      <div>{wastesDetails?.wasteExchanges?.map((w, idx) => {
        return item.transactionType === "Purchase" ? (<Card key={idx} className="mb-1">
          <Card.Header>
            {w.isContainer ? `Kontener - ${w.containerName?.toUpperCase()}` : "Załadunek"}
          </Card.Header>
          <Card.Body>
            {w.wastes.map(x => <Card.Text className="upper" key={x.wasteId}><BadgeDomain>{x.code}</BadgeDomain> {x.internalName} {x.wasteMass ? `: ${x.wasteMass?.toFixed(4)} [Mg]` : ""}</Card.Text>)}
          </Card.Body>
        </Card>) : w.wastes.map(x => {
          const incomeType = x.extendedData?.isRevenue ? "PW" : "RW";
          return <div className=" upper" key={x.wasteId}>
            <Card.Text>
              <BadgeDomain>{x.code}</BadgeDomain> {x.internalName}: <b>{x.wasteMass?.toFixed(4)} [Mg]</b>
            </Card.Text>
            {x.extendedData && x.extendedData?.deductions !== 0 && <div>
              Początkowa masa: <b>{x.extendedData?.initWasteMass?.toFixed(4)}</b> <br/>
              <>Potrącenia: <b>{x.extendedData?.deductions?.toFixed(4)} [Mg]</b> <br/>
                {" "}Masa {incomeType}: <b>{x.extendedData?.revenueWasteMass?.toFixed(4)} [MG]</b></>
              <br/>
              {x.extendedData?.deductions !== 0 && <>
              <>Dokument {incomeType}: <b>{x.extendedData?.revenueDocumentNumber}</b></>
              <br/>
              <>Przyczyny: <b>{x.extendedData?.wasteDifferenceReasons?.map(x => WasteDifferenceReason[x]).join(", ")}</b></>
              {x.extendedData?.wasteDifferenceOtherReason && <><b> ({x.extendedData?.wasteDifferenceOtherReason})</b></>}
              </>}
              <div className="mt-2"/>
            </div>}
          </div>;
        });
      })}</div>
    </Card.Body>
  </Card>;

}