import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";
import { getDictionaryQuery } from "_store";

// create slice

const name = "users";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, reducers, initialState, extraReducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    users: {},
    usersSorting: { value: "login", type: "asc" },
    usersPaging: null,
  };
}

function createReducers() {
  return { setSorting };

  function setSorting(state, action) {
    state.usersSorting = action.payload;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/accounts`;

  return {
    getAll: getAll(),
    getUser: getUser(),
    register: register(),
    deactivate: deactivate(),
    activate: activate(),
    update: update(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async (payload) =>
        await fetchWrapper.get(getDictionaryQuery(baseUrl, payload))
    );
  }

  function getUser() {
    return createAsyncThunk(
      `${name}/getUser`,
      async (userId) => await fetchWrapper.get(`${baseUrl}/${userId}`)
    );
  }

  function register() {
    return createAsyncThunk(`${name}/register`, async (payload) => {
      return await fetchWrapper.post(`${baseUrl}/register`, payload);
    });
  }

  function deactivate() {
    return createAsyncThunk(`${name}/deactivate`, async ({ userId }) => {
      return await fetchWrapper.patch(`${baseUrl}/${userId}/deactivate`);
    });
  }

  function activate() {
    return createAsyncThunk(`${name}/activate`, async ({ userId }) => {
      return await fetchWrapper.patch(`${baseUrl}/${userId}/activate`);
    });
  }

  function update() {
    return createAsyncThunk(`${name}/update`, async ({ userId, payload }) => {
      return await fetchWrapper.patch(`${baseUrl}/${userId}`, payload);
    });
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...getUser(),
    ...deactivate(),
    ...activate(),
    ...update(),
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.users = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.users = action.payload.items;
        state.usersPaging = action.payload.paging;
      },
      [rejected]: (state, action) => {
        state.users = { error: action.error };
      },
    };
  }

  function getUser() {
    var { fulfilled } = extraActions.getUser;
    return {
      [fulfilled]: (state, action) => {
        const user = action.payload;
        const existingUser = state.users.find((x) => x.id === user.id);
        if (!existingUser) {
          state.users.push(user);
        } else {
          Object.assign(existingUser, action.payload);
        }
      },
    };
  }

  function deactivate() {
    var { fulfilled } = extraActions.deactivate;
    return {
      [fulfilled]: (state, action) => {
        const { userId } = action.meta.arg;
        const userToUpdate = state.users.find((user) => user.id === userId);
        userToUpdate.isActive = false;
      },
    };
  }

  function activate() {
    var { fulfilled } = extraActions.activate;
    return {
      [fulfilled]: (state, action) => {
        const { userId } = action.meta.arg;
        const userToUpdate = state.users.find((user) => user.id === userId);
        userToUpdate.isActive = true;
      },
    };
  }

  function update() {
    var { fulfilled } = extraActions.update;
    return {
      [fulfilled]: (state, action) => {
        const { userId, payload } = action.meta.arg;
        const updatedUser = state.users.find((user) => user.id === userId);
        Object.assign(updatedUser, payload);
      },
    };
  }
}
