import { BadgeDomain } from "_components/Abstractions/BadgeDomain";
import { AddressTypes, AddressTypesBadges } from "domain/AddressType";

export function AddressTypeBadge({ type }) {
  return (
    <>
      <BadgeDomain key={type} bg={AddressTypesBadges[type]}>
        {AddressTypes[type]}
      </BadgeDomain>
    </>
  );
}
