import { Badge } from "react-bootstrap";

export function BadgeDomain({ bg, children, onClick, className, size }) {
  const props = {
    bg,
    onClick,
  };

  return (
    <>
      <Badge style={{fontSize: size }} className={`me-1 ${onClick ? "hover-pointer" : className}`} {...props}>
        {children}
      </Badge>
    </>
  );
}
