import s from "../style.module.css";
import { Weekdays } from "../../../domain/Weekdays";

export function CalendarWeek({ weekDates, items, getItem }) {
  return <div className="d-flex align-items-start justify-content-center gap-4 p-3">
    {weekDates?.map(weekDate =>
      <div key={weekDate.date} className={s.container}>
        <h5>{weekDate.dayOfWeek}</h5>
        <h6>{weekDate.date}</h6>
        {items?.loading && <div className="spinner-border"></div>}
        {weekDate.dayOfWeek === Weekdays.Monday && items["overdue"]?.map(x => getItem(x))}
        {items[weekDate.date]?.map(x => getItem(x))}
      </div>)}
  </div>;
}