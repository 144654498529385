import { useSelector } from "react-redux";
import { userActions } from "_store";
import { UserTable } from "_components/Accounts/UserTable";
import { CreateAccount } from "useCases/accounts/CreateAccount";
import { DictionaryPage } from "_components/Abstractions/DictionaryPage";

export function GetUsers({ activeEventKey, pageEventKey }) {
  const { users, usersPaging, usersSorting } = useSelector((x) => x.users);
  const table = <UserTable users={users} sorting={usersSorting} itemActions={userActions} />;
  const createPage = <CreateAccount />;

  return (
    <DictionaryPage
      items={users}
      paging={usersPaging}
      itemsTable={table}
      itemActions={userActions}
      createItem={createPage}
      activeEventKey={activeEventKey}
      pageEventKey={pageEventKey}
      includeSearcher
      searcherPlaceholder="Szukaj po loginie, nazwie..."
      sorting={usersSorting}
    />
  );
}
