import { useDispatch } from "react-redux";
import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { stringToDateTimeForm } from "../../../domain/Configuration";
import { mapToTransportDetails } from "../../../_services/Orders/orderMapper";
import { useKpoReportMessageProvider } from "../../../domain/Orders/UpdateKpoReportMessageProvider";
import { useToast } from "../../../_helpers/notifications/useToasts";
import { kpoActions } from "../../../_store/kpo.slice";
import { UpdateOrderTransportForm } from "./UpdateOrderTransportDetailsForm";

export function UpdateOrderTransport({ orderId, transactionType, transportDetails, isMainDetailsUpdateAvailable, hasKpoCards }) {
  const dispatch = useDispatch();
  const { getUpdateKpoReportMessage } = useKpoReportMessageProvider();
  const { showErrorMessage, showInfoMessage } = useToast();
  const defaultPlannedLoadingDate = transportDetails?.plannedLoadingDate ? stringToDateTimeForm(transportDetails?.plannedLoadingDate) : null;

  async function onSubmit(payload) {
    const updatedDate = stringToDateTimeForm(payload?.transportDetails?.plannedLoadingDate);
    const isLoadingDateChange = defaultPlannedLoadingDate !== updatedDate;
    const requestPayload = { id: orderId, transactionType: transactionType, transportDetails: mapToTransportDetails(payload) };
    const response = await dispatch(ordersActions.updateTransportDetails({ orderId, payload: requestPayload }));
    if(response?.error) {
      return response;
    }

    if(transactionType === "Sale" && isLoadingDateChange && hasKpoCards) {
      const details =  {  plannedTransportTime: payload?.transportDetails?.plannedLoadingDate };
      const updateKpoResponse = await dispatch(kpoActions.updateKpoData({ orderId, payload: { orderId, transactionType, details }}));
      const message = getUpdateKpoReportMessage(updateKpoResponse);

      if(updateKpoResponse.error){
        showErrorMessage(message);
      } else {
        showInfoMessage(message);
        dispatch(orderViewsActions.getBdoInfoById({ id: orderId }));
      }
    }

    await dispatch(orderViewsActions.getTransportDetails({ id: orderId }));
    await dispatch(orderViewsActions.refreshHistory({ id: orderId }));

    return response;
  }

  return <UpdateOrderTransportForm
    orderId={orderId}
    transportDetails={transportDetails}
    onSubmit={onSubmit}
    transactionType={transactionType}
    isMainDetailsUpdateAvailable={isMainDetailsUpdateAvailable}/>;
}