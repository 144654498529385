import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { UpdateContainer } from "useCases/containers/UpdateContainer";
import { RefreshContainerUsed } from "../../useCases/containers/RefreshContainerUsed";
import { GetContainerState } from "../../useCases/containers/GetContainerState";
import { ContainersTableSummary } from "./ContainersTableSummary";

export function ContainerTable({ containers, isActivePage, summary }) {
  const [activeContainerId, setActiveContainerId] = useState();
  const [activeAction, setActiveAction] = useState();
  useEffect(() => {
    if(!isActivePage){
      setActiveAction(null);
    }
  }, [isActivePage]);

  return (
    <Table hover>
      <thead>
      <tr>
        <th>#</th>
        <th>Grupa</th>
        <th>Podtyp</th>
        <th>Ilość bazowa</th>
        <th>Ilość zużytych</th>
        <th>Pozostało</th>
        <th>Akcje</th>
      </tr>
      </thead>
      <tbody>
      {containers.loading && (
        <>
          <tr className="spinner-border"></tr>
        </>
      )}
      {containers.length > 0 &&
        containers.map((container) => (
          <tr
            key={container.id}>
            <td>{container.index}</td>
            <td>{container.groupContainerType}</td>
            <td>{container.name}</td>
            <td>{container.initializeCount}</td>
            <td>{container.usedCount}</td>
            <td>{container.remainingCount}</td>
            <td>
              <UpdateContainer
                container={container}
                onShow={() => setActiveAction("updateContanier")}/>
              <RefreshContainerUsed
                container={container}
                onShow={() => setActiveAction("refreshUsedContainer")}/>
              <GetContainerState
                container={container}
                activeContainerId={activeContainerId}
                onShow={() => {
                  setActiveContainerId(container.id)
                  setActiveAction("getContainerState");
                }}
                isFormActive={activeAction === "getContainerState"}
              />
            </td>
          </tr>
        ))}
      <ContainersTableSummary headerColSpan={3} summary={summary}/>
      </tbody>
    </Table>
  );
}
