import { Card } from "react-bootstrap";

export function CardView({ children, className, headerText, headerTypo, headerContent }) {
  return <Card className={className}>
    <Card.Header as={headerTypo}>
      {headerText}
    </Card.Header>
    {headerContent}
    <Card.Body>
      {children}
    </Card.Body></Card>;
}