import { useDispatch } from "react-redux";
import { FormModal } from "../../../../Forms/FormModal";
import { FormControl } from "../../../../Forms/FormControl";
import { Dropdown } from "../../../../Abstractions/Dropdown";
import { DocumentType } from "../../../../../domain/DocumentType";
import * as Yup from "yup";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { orderFileDocumentsActions } from "../../../../../_store/orderFileDocuments.slice";
import { orderViewsActions } from "../../../../../_store/orderView.slice";
import { PencilSquare } from "react-bootstrap-icons";
import { DomainSwitch } from "../../../../Abstractions/DomainSwitch";

export function AttachFileToOrder({ orderId, file, isUpdate }) {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    file: Yup.mixed().nullable().when("isFileChanging", {
      is: true,
      then: Yup.mixed().required("Plik jest wymagany")
      .test("length", "Plik jest wymagany", value => value?.length === 1)
      .test("size", "Maksymalna wielkość pliku to 10MB", value =>  value?.[0]?.size <= 10 * 1024 * 1024)
    }),
    documentName: Yup.string().required("Nazwa dokumentu jest wymagana"),
    documentType: Yup.string().required("Typ dokumentu jest wymagany").typeError("Typ dokumentu jest wymagany")
  });

  async function onSubmit(data) {
    const { file, isFileChanging, ...requestData } = data;
    let formData;

    if (isFileChanging) {
      formData = new FormData();
      formData.append("file", file[0]);
    }
    const payload = { ...requestData, orderId, file: formData };

    const response = await dispatch(orderFileDocumentsActions.attachFile(payload));
    if (!response?.error) {
      dispatch(orderViewsActions.getFileDocuments({ orderId }));
    }

    return response;
  }

  const buttonText = isUpdate ? "Edytuj dokument" : "Załącz dokument";
  const successMessage = isUpdate ? "Zaktualizowano dokument zlecenia" : "Załączono dokument do zlecenia";
  const defaultValues = {
    fileId: file?.id,
    documentName: file?.documentName,
    documentType: file?.documentType,
    isFileChanging: !isUpdate
  } ;

  return <FormModal
    formControls={<AttachFileToOrderForm isUpdate={isUpdate}/>}
    defaultValues={defaultValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    buttonText={isUpdate ? <PencilSquare /> : buttonText}
    successMessage={successMessage}
    title={buttonText}
    btnSize={isUpdate ? "x-small" : "smaller"}
    triggerBtnVariant={isUpdate ? "success" : null}
    clearTriggerBtnDefaultStyles
  />;
}

export function AttachFileToOrderForm({ isUpdate }) {
  const { watch, setValue } = useFormContext();
  const file = watch("file");
  const isFileChanging = watch("isFileChanging");

  useEffect(() => {
    const onFileUpload = async() => {
      let fileName = file?.[0]?.name?.split(".")?.[0];
      if (fileName) {
        setValue("documentName", fileName);
      }
    };

    onFileUpload();
  }, [file]);

  return <>
    {isUpdate && <DomainSwitch name="isFileChanging" label="Zmiana pliku"/>}
    {isFileChanging &&
      <FormControl name="file" label="Plik" inputType="file" additionalAttributes={{ accept: ".pdf, .docx" }}/>}
    <FormControl name="documentName" label="Nazwa"/>
    <Dropdown name="documentType" items={DocumentType} label="Typ dokumentu"/>
  </>;
}
