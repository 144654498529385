import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";
import { getDictionaryQuery } from "_store";
import { StringExtensions } from "../_helpers/extensions";

// create slice

const name = "notifications";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const notificationsActions = { ...slice.actions, ...extraActions };
export const notificationsReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    notifications: {
      items: [],
      paging: {},
    },
  };
}

function createReducers() {
  return { setSorting };

  function setSorting(state, action) {
    state.notifications.sorting = action.payload;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/notifications`;

  return {
    getAll: getAll(),
    update: update(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async(payload) => {
        const queryString = StringExtensions.createQueryStringFromObject(payload);
        return await fetchWrapper.get(`${baseUrl}${queryString}`);
      });
  }

  function update() {
    return createAsyncThunk(
      `${name}/update`,
      async ({ id, payload }) => {
        return await fetchWrapper.patch(`${baseUrl}/${id}`, payload);
      }
    );
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...update(),
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.notifications.items = { loading: true };
      },
      [fulfilled]: (state, action) => {
        const { items, ...paging } = action.payload;
        state.notifications.items = items;
        state.notifications.paging = paging;
      },
      [rejected]: (state, action) => {
        state.notifications.items = { error: action.error };
      },
    };
  }

  function update() {
    var { fulfilled } = extraActions.update;
    return {
      [fulfilled]: (state, action) => {
        const { id } = action.meta.arg;
        const updatedNotification = state.notifications?.items?.find((x) => x.id === id);
        Object.assign(updatedNotification, { isRead: true });
        state.notifications.paging.totalUnread = state.notifications.paging.totalUnread - 1;
      },
    };
  }
}
