import { PromptModal } from "_components/Abstractions/PromptModal";
import { useToast } from "_helpers/notifications/useToasts";
import { useDispatch, useSelector } from "react-redux";
import { containersActions } from "../../_store/containers.slice";

export function RefreshContainerUsed({ container, items, refreshAll = false, onShow }) {
  const { containersPaging } = useSelector((x) => x.containers);

  const buttonText = refreshAll ? "Odśwież zużycie podtypów kontenerów na stronie" : "Odśwież zużycie";
  const promptTitle = refreshAll ? "Czy odświeżyć zużycie podtypów kontenerów na stronie?" : `Czy odświeżyć zużycie podtypu kontenera ${container.name}?`;

  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useToast();

  async function onSubmit(promptResult) {
    if (promptResult !== "Yes") {
      return;
    }

    let containerIds = [];
    if (refreshAll) {
      containerIds = items.map(x => x.id);
    } else {
      containerIds.push(container.id);
    }
    const payload = { containerIds };
    const response = await dispatch(containersActions.refreshUsed({ payload }));

    if (!response.error) {
      const successMessage = refreshAll ? "Odświeżono zużycie kontenerów na stronie" : "Odświeżono zużycie kontenera";
      const pageNumber = containersPaging?.pageNumber ?? 1;
      dispatch(containersActions.getAll({ pageNumber }));

      showSuccessMessage(successMessage);
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText={buttonText}
        title={promptTitle}
        type="YesNo"
        onSubmit={onSubmit}
        onShowModal={onShow}
      />
    </>
  );
}
