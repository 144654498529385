import { SelectSearcher } from "../../Abstractions/MultiSearcher/SelectSearcher";
import { useOrderSearch } from "./OrderSearch";
import { MultiSelectSearcher } from "../../Abstractions/MultiSearcher/MultiSearcher";

export function OrderSelect({ name, transactionType, isTransfer, label }) {
  const { items, onSearchChange } = useOrderSearch({ transactionType, isTransfer });

  return (<SelectSearcher
    name={name}
    label={label}
    items={items}
    onSearchChange={onSearchChange}
    optionsObject={{ key: "id", value: "displayName" }}
    placeholder="Szukaj"
    required={true}
    saveWholeObject
  />);
}

export function OrderMultiSelect({ name, transactionType, isTransfer, label }) {
  const { items, onSearchChange } = useOrderSearch({ transactionType, isTransfer });

  return (<MultiSelectSearcher
    name={name}
    placeholder={label}
    items={items}
    onSearchChange={onSearchChange}
    optionsObject={{ key: "id", value: "displayName" }}
    // optionSave={x => x.id}
    required={true}
    saveWholeObject
  />);
}