import { GenerateOrderBaseReport } from "./GenerateOrderBaseReport";
import { reportsActions } from "../../_store/reports.slice";
import { FormControl } from "../../_components/Forms/FormControl";
import { OrderReportContainerTable } from "./OrderReportContainerTable";
import * as Yup from "yup";

export function GenerateOrderContainerReport() {
  const customFormControls = <>
    <FormControl name="days" label="Liczba dni bez wymiany kontenera" inputType="number"/>
  </>;

  const validationSchema = Yup.object().shape({
    days: Yup.number()
    .integer("Liczba dni musi być liczbą całkowitą")
    .positive("Liczba dni musi być większa od 0")
    .required("Liczba dni jest wymagana")
    .typeError("Liczba dni jest wymagana"),
  });

  const renderData = (data) => {
    if (!data || data.loading) {
      return;
    }

    return <div className="mt-2">
      <OrderReportContainerTable data={data}/>
    </div>;
  };

  return <GenerateOrderBaseReport
    reportTitle="Raport Zlecenia - Kontenery"
    reportType="orderContainer"
    reportAction={reportsActions.generateOrderContainerReport}
    renderData={renderData}
    renderDefaultControls={false}
    customControls={customFormControls}
    additionalValidationSchema={validationSchema}
  />;
}