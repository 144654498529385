import { FilterSelectSearcher } from "../../../../_components/Abstractions/MultiSearcher/FilterSelectSearcher";
import { useSalesRepresentativeSearch } from "./SalesRepresentativeSearcher";

export function SalesRepresentativeFilterSelect({ name }) {
  const { items, onSearchChange } = useSalesRepresentativeSearch();

  return (
    <>
      <FilterSelectSearcher
        name={name}
        label="PH"
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "id", value: "name" }}
        placeholder="Szukaj"
        saveWholeObject={false}
      />
    </>
  );
}