import { Fragment } from "react";

export function LoadingSpinner({ isLoading, as, center }) {
  const SpinnerComponent = as || "tr";
  const SpinnerWrapper = as ? `div` : Fragment;
  const WrapperProps = as ? {
    className: center ? "d-flex  justify-content-center align-items-center h-100" : ""
  } : null;

  return isLoading && (<SpinnerWrapper {...WrapperProps}>
    <SpinnerComponent className="spinner-border"/></SpinnerWrapper>);
}