import * as Yup from "yup";
import { FormControl } from "_components/Forms/FormControl";
import { FormModal } from "_components/Forms/FormModal";

export function GroupContainerForm({ onSubmit, defaultValues, successMessage, buttonText, onShow }) {
  const validationSchema = Yup.object().shape({
    type: Yup.string()
    .required("Typ jest wymagany")
    .max(100, "Typ nie może mieć więcej niż 100 znaków"),
  });

  const formControls = (
    <>
      <FormControl label="Typ" name="type" autoFocus />
    </>
  );

  return (
    <>
      <FormModal
        formControls={formControls}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        buttonText={buttonText}
        successMessage={successMessage}
        defaultValues={defaultValues}
        title={buttonText}
        onShowModal={onShow}
        btnSize="small"
      />
    </>
  );
}
