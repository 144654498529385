export const TRANSACTION_TYPE = {
    PURCHASE: "Purchase",
    SALE: "Sale",
    TRANSPORT: "Transport"
}

export const TransactionType = {
    Purchase: "Zakup",
    Sale: "Sprzedaż",
    Transport: "Usługa transportowa"
}

export const TransactionTypeToClientType = {
    Purchase: "Sender",
    Sale: "Receiver",
    Transport: "Carrier"
}

export const TransactionTypeToMainType = {
    Purchase: "Sender",
    Sale: "Receiver",
    Transport: "Sender"
}

export const TransactionTypeToOriginatorType = {
    Purchase: "Receiver",
    Sale: "Sender",
    Transport: "Carrier"
}

export const OriginatorType = {
    Sender: "Przekazujący",
    Carrier: "Transportujący",
    Receiver: "Przejmujący"
}