import { TableDomain } from "_components/Abstractions/TableDomain";

export function ContainerGroupStateTable({ items }) {
  const keyColumn = "id";
  const columns = [
    {
      name: "contractorName",
      headerName: "Kontrahent"
    },
    {
      name: "addressName",
      headerName: "Nazwa adresu"
    },
    {
      name: "usedCount",
      headerName: "Ilość zużytych"
    }
  ];

  const props = {
    keyColumn,
    columns,
    items,
    selectable: false
  };
  return (
    <>
      <TableDomain {...props}></TableDomain>
    </>
  );
}
