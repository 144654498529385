import { GenerateOrderBaseReport } from "./GenerateOrderBaseReport";
import { reportsActions } from "../../_store/reports.slice";
import { DriverSelect } from "../../_components/Drivers/DriverSelect";
import { OrderReportGroup } from "./OrderReportGroup";
import * as Yup from "yup";

export function GenerateOrderDriversReport() {
  const customFormControls = <>
    <DriverSelect name="driver" allowSearchWithoutType required/>
  </>;

  const validationSchema = Yup.object().shape({
    driver: Yup.object().nullable().required("Kierowca jest wymagany")
  });

  const renderData = (data) => <>
    <div className="mt-2">
      {data?.length >= 0 && <h6>Ilość zleceń w sumie: {data.length}</h6>}
      <hr/>
    </div>
    <OrderReportGroup entries={data}/>
  </>;

  return <GenerateOrderBaseReport
    reportTitle="Raport Zlecenia - Kierowca"
    reportType="orderDriver"
    reportAction={reportsActions.generateOrderDriversReport}
    renderData={renderData}
    customControls={customFormControls}
    additionalValidationSchema={validationSchema}
  />;
}