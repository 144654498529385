import { Card } from "react-bootstrap";
import s from "../style.module.css";
import { TransactionTypeBadge } from "../Components/TransactionTypeBadge";
import { OrderStatusBadge } from "../Components/OrderStatusBadge";
import { VehicleTypeBadge } from "../../Vehicles/VehicleTypeBadge";
import { ButtonOverlay } from "../../Abstractions/ButtonOverlay";
import { BadgeDomain } from "../../Abstractions/BadgeDomain";
import { TransportTypeBadge } from "../Components/TransportTypeBadge";
import { CardLinkBody } from "../../Abstractions/CardLinkBody";
import { WasteExchangesView } from "../../Orders/WasteDetails/WasteExchangesView";
import { CalendarCheck, CalendarCheckFill, Telephone } from "react-bootstrap-icons";
import { OrderKpoCardItemsView } from "./OrderKpoCardItemsView";
import { TRANSACTION_TYPE } from "../../../domain/TransactionType";
import { PICKUP_TYPES } from "../../../domain/VehicleType";
import { OrderSeriesBadge } from "../Components/OrderSeriesBadge";
import { ORDER_STATUS } from "../../../domain/Orders/OrderStatus";

export const CalendarDay = ({ orderItem }) => {
  const {
    id, pickupType, transportType, transportContractorName, includedVehicleRegNumber,
    isTransfer, orderNumber, transactionType, addressName, contactPersonInfo, remarks, orderDate, driverName,
    vehicleRegNumber, status, contractorRemarks, addressRemarks, driverPhone, delayState, kpoCardItems,
    externalOrderNumber, filterShippingDate, isSeries, isCourseCompletedByDriver, isKpoConfirmationGeneratedByDriver
  } = orderItem;
  let additionalRemarks = null;
  const isSale = transactionType === "Sale";

  if (contractorRemarks) {
    additionalRemarks = `Kontrahent: ${contractorRemarks}`;
  }
  if (additionalRemarks) {
    const additionalAddress = `Adres: ${addressRemarks}`;
    additionalRemarks = additionalRemarks ? `${additionalRemarks} / ${additionalAddress}` : additionalRemarks;
  }
  let vehicleInfo = "";
  if (vehicleRegNumber || includedVehicleRegNumber) {
    vehicleInfo = `${vehicleRegNumber ?? "-"} / ${includedVehicleRegNumber ?? "-"}`;
  } else {
    vehicleInfo = "-";
  }
  const isShippingDateRequired =
    transactionType !== TRANSACTION_TYPE.TRANSPORT &&
    pickupType === PICKUP_TYPES.TIR;


  const state = isKpoConfirmationGeneratedByDriver && (status === ORDER_STATUS.CONFIRMED || status === ORDER_STATUS.COURSE_COMPLETED) ?
    "driver" : delayState;
  const orderStyle = `order-${state.toLowerCase()}-delay`;
  const orderStatus = `order-${status.toLowerCase()}`;

  return <>
    <Card className={`${s.box} ${s[orderStyle]} ${s[orderStatus]}`}>
      <CardLinkBody link={`/orders/${id}`}>
        <b className={s.header}>{addressName}</b>
        <div className="d-flex justify-content-between align-items-start mt-2">
          <TransactionTypeBadge className="mb-2" transactionType={transactionType} size="xx-small"/>
          <OrderStatusBadge className="mb-2" status={status} size="xx-small"/>
          <VehicleTypeBadge type={pickupType} size="xx-small"/>
        </div>
        <div className="d-flex justify-content-between upper">
          <b className={s.small}>{orderNumber}</b>
          <p className={s.small}>{driverName ?? "Brak kierowcy"}</p>
          <p className={s.small}>{vehicleInfo}</p>
        </div>
        <div className="d-flex justify-content-between align-items-start">
          <p className={s.orderDate}>
            {orderDate} {isShippingDateRequired ?
            (filterShippingDate ? <CalendarCheckFill color="darkGreen" className="mb-1"/> : <CalendarCheck className="mb-1"/> ) : ""}</p>
          {additionalRemarks && <ButtonOverlay size="xx-small" tooltip={<p className={s.small}>{additionalRemarks ?? "-"}</p>}>Uwagi</ButtonOverlay>}
          <p className={s.small}>{transportContractorName}</p>
          {<TransportTypeBadge size="xx-small" transportType={transportType}/>}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          {!orderItem?.isLoading && !orderItem?.isContainersExchange && <p className={s.small}>{externalOrderNumber}</p>}
          <div className="d-flex gap-1 align-items-center">
            {isTransfer && <BadgeDomain className="xx-small">Przerzut</BadgeDomain>}
            {!isSale && contactPersonInfo && <ButtonOverlay variant="success" size="xx-small" tooltip={<>Kontakt do klienta: {contactPersonInfo}</>}><Telephone/></ButtonOverlay>}
            {isSale && driverPhone && <ButtonOverlay size="xx-small" tooltip={<>Telefon do kierowcy: {driverPhone}</>}><Telephone/></ButtonOverlay>}
            <OrderKpoCardItemsView items={kpoCardItems}/>
          </div>
        </div>
        <WasteExchangesView isLoading={orderItem?.isLoading} isContainersExchange={orderItem?.isContainersExchange} wastesExchanges={orderItem?.wasteExchanges}/>
        {isSeries && <OrderSeriesBadge size="xx-small"/>}
        <p className={s.remarks}>{remarks}</p>
      </CardLinkBody>
    </Card>
  </>;
};

