import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { SelectTable } from "../../_components/Abstractions/SelectTable";
import { ContainerGroupStateTable } from "../../_components/Containers/ContainerGroupStateTable";
import { containersActions } from "../../_store/containers.slice";

export function GetContainerState({ container, activeContainerId, show, isFormActive, onShow }) {
  const dispatch = useDispatch();
  const { id, name, groupContainerType, initializeCount, remainingCount, usedCount } = container;
  const [items, setItems] = useState();

  useEffect(() => {
    if(activeContainerId !== id || !isFormActive){
      return;
    }

    const getState = async() => {

      setItems({ loading: true });
      const response = await dispatch(containersActions.getState({ id }));
      if (!response?.error) {
        setItems(response.payload);
      }
    }

    getState();

  }, [isFormActive, show, activeContainerId]);

  const table = <ContainerGroupStateTable items={items}/>;

  return <>
    <SelectTable
      table={table}
      label="Zużycie"
      show={show}
      btnVariant="outline-primary"
      skipSearchBar
      modalSize="lg"
      onShowModal={onShow}
      modalTitle={`Kontener: ${groupContainerType} ${name} Zużycie: ${usedCount}, Liczba bazowa: ${initializeCount}, Pozostało: ${remainingCount}`}
    />
  </>;
}