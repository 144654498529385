import { Link } from "react-router-dom";

// Parent <tr> must have position-relative style
export function RowLink({ to }) {
  return (
    <td >
      <Link to={to} style={{
        position: "absolute",
        zIndex: 0,
        top: 0,
        left: 0,
        // backgroundColor: "rgba(0, 0, 0, 0)",
        width: "80%",
        height: "100%",
      }}/>
    </td>
  );
}