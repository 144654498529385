import { SelectTable } from "_components/Abstractions/SelectTable";
import { useEffect, useRef, useState } from "react";
import { bdoActions } from "_store/bdo.slice";
import { EupBdoTable } from "./EupBdoTable";
import { useToast } from "_helpers/notifications/useToasts";
import useFetch from "../../../_helpers/hooks/useFetch";

export function EupBdoSelect({ companyId, onSelect, multiSelect, overrideLabel }) {
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);
  const { showErrorMessage } = useToast();
  const isFirstRender = useRef(true);

  const fetch = useFetch();

  const onSearchChange = async (searchTerm) => {
    if (!companyId) {
      showErrorMessage(
        "Żeby wyświetlić adresy MPD, trzeba najpierw podpiąć klienta do podmiotu BDO..."
      );
      setShow(false);
      return;
    }

    const params = { companyId, searchTerm };
    await fetch(bdoActions.searchEup(params), setItems, false);
  };

  const onSelectItem = (item) => {
    if (multiSelect) {
      return;
    }
    onSelect(item);
    setShow(false);
  };

  const onUnselect = (item) => {};

  const table = (
    <EupBdoTable
      items={items}
      onSelect={onSelectItem}
      onUnselect={onUnselect}
      multiSelect={multiSelect}
    />
  );

  useEffect(() => {
    if (companyId && !isFirstRender.current) {
      onSearchChange("");
    } else {
      setItems([]);
      isFirstRender.current = false;
    }
  }, [companyId]);

  return (
    <>
      <SelectTable
        table={table}
        label={overrideLabel ?? "Powiąż adres dostawy z MPD (BDO)"}
        onSearchChange={onSearchChange}
        show={show}
        setShow={setShow}
      />
    </>
  );
}
