import { Table } from "react-bootstrap";
import { StatusBadge } from "../../_components/Abstractions/StatusBadge";
import { UpdateService } from "./UpdateService";
import { ChangeServiceState } from "./ChangeServiceState";
import { SortedTh } from "../../_components/Abstractions/SortedTh";

export function ServicesTable({ items, sorting, itemActions }) {
  const table = (
    <>
      <Table hover>
        <thead>
        <tr>
          <SortedTh sortingValue="name" sorting={sorting} itemActions={itemActions}>Nazwa</SortedTh>
          <th>Status</th>
          <th>Akcje</th>
        </tr>
        </thead>
        <tbody>
        {items.loading && (
          <>
            <tr className="spinner-border"></tr>
          </>
        )}
        {items.length > 0 &&
          items.map((item, index) => (
            <tr key={index}>
              <td style={{ width: "70%" }}>{item.name}</td>
              <td >
                <StatusBadge isActive={item.isActive}/>
              </td>
              <td>
                <UpdateService item={item}/>
                <ChangeServiceState item={item}/>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );

  return <>{table}</>;
}