import { Link, useNavigate, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { TransactionTypeBadge } from "../Components/TransactionTypeBadge";
import { HorizontalView } from "../../Abstractions/HorizontalView";
import { OrderTransportDetailsView } from "./OrderTransportDetailsView";
import { OrderWorkflowService } from "./Actions/OrderWorkflowService";
import { LoadingSpinner } from "../../Abstractions/LoadingSpinner";
import { TransportTypeBadge } from "../Components/TransportTypeBadge";
import { WastesOrderDetailsView } from "./WastesOrderDetailsView";
import { BackButton } from "../../Abstractions/BackButton";
import { useGetOrderSeriesDetails } from "../useGetOrderSeriesDetails";
import { TableDomain } from "../../Abstractions/TableDomain";
import { stringToDate } from "../../../domain/Configuration";
import { OrderStatusBadge } from "../Components/OrderStatusBadge";
import { frequencyDescriptionProvider } from "../../../useCases/orders/createOrderSeries/frequencyDescriptionProvider";
import { DeleteOrderSeries } from "../../../useCases/orders/workflowActions/DeleteOrderSeries";
import { BadgeDomain } from "../../Abstractions/BadgeDomain";
import { UpdateOrderSeriesMainDetails } from "../../../useCases/orders/updateMainDetails/UpdateOrderSeriesMainDetails";

export function OrderSeriesDetailsView() {
  const { id } = useParams();
  const { item } = useGetOrderSeriesDetails({ id });
  const navigate = useNavigate();
  const { seriesDetails, mainDetails: contractorDetails, transportDetails, transactionType, wastesDetails } = item || {};
  const { isBdoIntegrated } = wastesDetails || {};
  const seriesData = {
    frequencyType: seriesDetails?.seriesType,
    frequencyInterval: seriesDetails?.frequencyInterval,
    weeklyData: seriesDetails?.weeklyDetails,
    dateStart: seriesDetails?.dateFrom ,
    dateEnd: seriesDetails?.dateTo  };

  const frequencyDescription = frequencyDescriptionProvider.getFrequencyDescription(seriesData);

  const mainDetailsView = <Card className="h-100">
    <Card.Header className="d-flex align-items-center justify-content-between">Główne dane <UpdateOrderSeriesMainDetails series={item}/>
    </Card.Header>
    <Card.Body>
      {transactionType !== "Transport" && <>
        <OrderLineItem name="Opis częstotliwości">{frequencyDescription}</OrderLineItem>
        <OrderLineItem name="Kontrahent"><Link to={`/contractors/${contractorDetails?.contractorId ?? ""}`}>{contractorDetails?.contractorName}</Link></OrderLineItem>
        <OrderLineItem name="Uwagi kontrahenta">{contractorDetails?.contractorRemarks}</OrderLineItem>
        <OrderLineItem name="Nazwa adresu">{contractorDetails?.addressName}</OrderLineItem>
        <OrderLineItem name="Adres">{contractorDetails?.addressRaw}</OrderLineItem>
        <OrderLineItem name="Uwagi adresu kontrahenta" skipIfEmpty>{contractorDetails?.addressRemarks}</OrderLineItem>
      </>}
      {transactionType === "Transport" && <>
        <OrderLineItem name="Przekazujący">{contractorDetails?.contractorName}</OrderLineItem>
        <OrderLineItem name="Uwagi przekazującego">{contractorDetails?.contractorRemarks}</OrderLineItem>
        <OrderLineItem name="Nazwa adresu przekazującego">{contractorDetails?.addressName}</OrderLineItem>
        <OrderLineItem name="Adres przekazującego">{contractorDetails?.addressRaw}</OrderLineItem>
        <OrderLineItem name="Uwagi adresu przekazującego" skipIfEmpty>{contractorDetails?.addressRemarks}</OrderLineItem>

        <OrderLineItem name="Przejmujący">{contractorDetails?.additionalContractorName}</OrderLineItem>
        <OrderLineItem name="Nazwa adresu przejmującego">{contractorDetails?.additionalAddressName}</OrderLineItem>
        <OrderLineItem name="Adres przejmującego">{contractorDetails?.additionalAddressRaw}</OrderLineItem>
        <OrderLineItem name="Uwagi adresu przejmującego" skipIfEmpty>{contractorDetails?.additionalAddressRemarks}</OrderLineItem>
      </>}
      <OrderLineItem name="Osoba kontaktowa">{contractorDetails?.contactPersonInfo ?? "-"}</OrderLineItem>
      <OrderLineItem name="Przedstawiciel handlowy" skipIfEmpty>{contractorDetails?.salesRepresentativeInfo}</OrderLineItem>
      <OrderLineItem name="Pośrednik">{contractorDetails?.intermediaryName ?? "-"}</OrderLineItem>
      {transactionType === "Sale" && <OrderLineItem name="Numer zamówienia przejmującego">{contractorDetails?.externalOrderNumber ?? "-"}</OrderLineItem>}
      <OrderLineItem name="Uwagi do zlecenia">{contractorDetails?.remarks ?? "-"}</OrderLineItem>
    </Card.Body>
  </Card>;

  const invalidSeriesDateBadge = <BadgeDomain bg="danger">Niepoprawna data</BadgeDomain>
  const columns = [
    { name: "orderNumber", headerName: "Numer zlecenia" },
    { name: "orderStatus", headerName: "Status", map: x => <OrderStatusBadge status={x.orderStatus} /> },
    { name: "plannedLoadingDate", headerName: "Planowana data załadunku", map: (x) => <>{stringToDate(x.plannedLoadingDate)} {x.isValidOrderSeriesDate ? "" : invalidSeriesDateBadge}</> },
    { name: "realLoadingDate", headerName: "Rzeczywista data załadunku", map: (x) => stringToDate(x.realLoadingDate) },
  ]

  const ordersDetails = <Card className="mt-2">
    <Card.Header>Dane zleceń</Card.Header>
    <Card.Body>
      <TableDomain keyColumn="id" items={item?.orders} columns={columns} onSelect={(order) => navigate(`/orders/${order.id}`)} />
    </Card.Body>
  </Card>

  return <div className="m-3">
    <h2>
      <BackButton className="me-3" size="medium" btnSize="lg">Powrót</BackButton>
      Szczegóły zlecenia cyklicznego: {item?.seriesDetails?.name?.toUpperCase()}
    </h2>
    <Card>
      <Card.Body style={{ fontSize: "small" }}>
        <div className="d-flex w-100">
        </div>
        <Card.Header className="d-flex align-items-center justify-content-between mb-1">
          <div className="d-flex gap-4">
            <Card.Title>Typ transakcji: <TransactionTypeBadge transactionType={item?.transactionType}/></Card.Title>
            <Card.Title>Typ transportu: <TransportTypeBadge transportType={transportDetails?.transportType}/></Card.Title>
            <Card.Title>Ilość zleceń: <b>{item?.orders?.length}</b></Card.Title>
            <LoadingSpinner isLoading={item?.status?.isLoading}/>
          </div>
          <DeleteOrderSeries item={item}/>
        </Card.Header>

        {item?.loading && <div className="spinner-border"/>}
        {!item?.loading && <HorizontalView>
          {mainDetailsView}
          <OrderTransportDetailsView item={item} isOrderSeries/>
          <WastesOrderDetailsView item={item} isOrderSeries/>
        </HorizontalView>}
        {ordersDetails}
      </Card.Body>
    </Card>
  </div>;
}

export const OrderLineItem = ({ name, children }) => <Card.Text>{name}: <b className="upper">{children}</b></Card.Text>;