import { SelectSearcher } from "_components/Abstractions/MultiSearcher/SelectSearcher";
import { wastesActions } from "_store/wastes.slice";
import { useState } from "react";
import { useDispatch } from "react-redux";

export function WasteBdoSelect({ name }) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  const onSearchChange = async (searchTerm) => {
    const { payload } = await dispatch(wastesActions.searchBdo({ searchTerm }));
    const items = payload.items;
    setItems(items);
  };

  // useEffect(() => {
  //   onSearchChange(null);
  // }, []); // eslint-disable-next-line/react-hooks/exhaustive-deps

  return (
    <>
      <SelectSearcher
        name={name}
        label="Towar BDO"
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "id", value: "codeDescription" }}
        placeholder="Wyszukaj towar BDO po kodzie/nazwie..."
        saveWholeObject
      />
    </>
  );
}
