import { Form, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";

export const BdoNumberControl = ({ name, label, addressRegistrationNumber, onSearchChange, allowMultipleEupIds }) => {
  const documentType = "/KPO";

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const handleSearch = (event) => {
    if (event.target.value === searchTerm) {
      return;
    }

    setSearchTerm(event.target.value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const newSearchTimeout = setTimeout(() => {
      handleSearchChange(event.target.value);
    }, 300);

    setSearchTimeout(newSearchTimeout);
  };

  const handleSearchChange = (e) => {
    const cardNumber = e + documentType + "/" + addressRegistrationNumber;
    onSearchChange({ name, value: cardNumber });
  };

  useEffect(() => {
    setSearchTerm("");
  }, [allowMultipleEupIds]);

  return (
    <InputGroup className="mb-3">
      <InputGroup.Text>{label}</InputGroup.Text>
      <Form.Control onChange={handleSearch} placeholder="Wpisz"/>
      <InputGroup.Text>{documentType}</InputGroup.Text>
      <InputGroup.Text>/{addressRegistrationNumber}</InputGroup.Text>
    </InputGroup>
  );
};

