import { useSelector } from "react-redux";
import { useCalendarView } from "../OrderCalendar/useCalendarView";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { OrderCalendarFilterBox } from "../OrderCalendar/OrderCalendarFilterBasic";
import { Card } from "react-bootstrap";
import { BadgeDomain } from "../../Abstractions/BadgeDomain";

import { CalendarDay } from "../OrderCalendar/CalendarDay";

export function OrderDriverView() {
  const { driverView, driverViewDates } = useSelector((x) => x.orderViews);
  const { refreshCalendar, currentFilters, handleCalendarChange, refreshFilters, setCurrentFilters } = useCalendarView({ calendarViewDates: driverViewDates, orderViewsAction: orderViewsActions.getDriverView });

  const { weekDates, currentDate } = driverViewDates;
  return <div className="d-flex flex-column justify-content-center">
    <h2>Widok kierowców ({weekDates?.[0].date} - {weekDates?.[weekDates.length - 1].date})</h2>
    <OrderCalendarFilterBox
      currentDate={currentDate}
      currentFilters={currentFilters}
      handleCalendarChange={handleCalendarChange}
      handleFilterChange={refreshFilters}
      refreshCalendar={refreshCalendar}
      setCurrentFilters={setCurrentFilters}
    />
    <DriverCalendarItems driverView={driverView}/>
  </div>;
}

export function DriverCalendarItems({ driverView }) {
  return <div className="d-flex flex-column align-items-start justify-content-start gap-2 p-3">
    {driverView?.loading && <div className="spinner-border"></div>}
    {Object.keys(driverView)?.map((driverId) => {
      const { driverName, items } = driverView[driverId];
      return <Card key={driverId} className="w-100">
        <Card.Header as="h5" className="d-flex align-items-center flex-row gap-2 upper dark-grey white-text">
          <BadgeDomain size="small">{items?.length}</BadgeDomain>
          {driverName}
          {/*{pickupType && <VehicleTypeBadge size="small" type={pickupType}/>}*/}
          {/*{<TransportTypeBadge size="small" type={vehicleItems[driverId]?.transportType}/>}*/}
        </Card.Header>
        <div className={`d-flex flex-row gap-1 flex-wrap`}>
          {items?.map(x => <CalendarDay key={x.id} orderItem={x}/>)}
        </div>
      </Card>;
    })}
  </div>;
}
