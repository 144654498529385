import { BaseWastesForm } from "./BaseWastesForm";
import { Card } from "react-bootstrap";
import { useContext } from "react";
import { OrderContext } from "../MainForms/OrderContext";

export function LoadingWastesForm({ fillWasteMass }) {
  const { wastesDetails, requireApplicableKpoItems, addressId } = useContext(OrderContext) || {};
  const { isLoading } = wastesDetails || {};
  return (
    <>
      {isLoading && (
        <Card>
          <Card.Header>Dane załadunku</Card.Header>
          <Card.Body>
            <BaseWastesForm
              addressId={addressId}
              wasteFieldName="wastesDetails.loading"
              requireApplicableKpoItems={requireApplicableKpoItems}
              fillWasteMass={fillWasteMass} />
          </Card.Body>
        </Card>
      )}
    </>);
}
