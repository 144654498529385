import { AttachFileToOrder } from "./Actions/DocumentFiles/AttachFileToOrder";
import { Card } from "react-bootstrap";
import { BadgeDomain } from "../../Abstractions/BadgeDomain";
import { DocumentType } from "../../../domain/DocumentType";
import { LoadingSpinner } from "../../Abstractions/LoadingSpinner";
import { DeleteDocumentFileFromOrder } from "./Actions/DocumentFiles/DeleteDocumentFileFromOrder";
import { DownloadOrderDocumentFile } from "./Actions/DocumentFiles/DownloadOrderDocumentFile";

export function OrderFilesView({ item }) {
  const { documents, id: orderId } = item;
  const isLoading = documents?.loading;

  return <Card className="mt-1">
    <Card.Header className="d-flex align-items-center gap-2">Pliki <AttachFileToOrder orderId={item.id}/></Card.Header>
    <Card.Body className="d-flex gap-2">
      <LoadingSpinner isLoading={isLoading} as="div" center/>
      {documents?.map?.(file => <Card key={file.id}>
        <Card.Body className="domain-control d-flex align-items-center gap-2">
          <div>{file.documentName}</div>
          <BadgeDomain bg="success">{DocumentType[file.documentType]}</BadgeDomain>
          <div className="d-flex gap-1">
            <DownloadOrderDocumentFile orderId={orderId} file={file} />
            <AttachFileToOrder orderId={orderId} file={file} isUpdate />
            <DeleteDocumentFileFromOrder orderId={orderId} file={file} />
          </div>
        </Card.Body>
      </Card>)}
    </Card.Body>
  </Card>;
}