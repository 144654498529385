import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "_helpers";
import { downloadReport } from "../_helpers/fileDownloader";
// create slice

const name = "orderFileDocuments";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const orderFileDocumentsActions = { ...slice.actions, ...extraActions };
export const orderFileDocumentsReducer = slice.reducer;

// implementation

function createInitialState() {
  return {};
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/orders`;

  return {
    attachFile: attachFile(),
    deleteFile: deleteFile(),
    downloadFile: downloadFile(),
  };
  function attachFile() {
    return createAsyncThunk(`${name}/attachFile`, async (payload) => {
      const { orderId, file, fileId, documentName, documentType } = payload;
      const params = `?id=${fileId ?? ""}&documentName=${documentName}&documentType=${documentType}`;

      return await fetchWrapper.put(`${baseUrl}/${orderId}/files${params}`, file);
    });
  }

  function deleteFile() {
    return createAsyncThunk(`${name}/deleteFile`, async (payload) => {
      const { orderId, fileId } = payload;
      return await fetchWrapper.delete(`${baseUrl}/${orderId}/files/${fileId}`);
    });
  }

  function downloadFile() {
    return createAsyncThunk(`${name}/downloadFile`, async (payload) => {
      const { orderId, fileId } = payload;
      return await fetchWrapper.post(`${baseUrl}/${orderId}/files/${fileId}`);
    });
  }
}

function createExtraReducers() {
  return {
    ...downloadFile()
  };

  function downloadFile() {
    var { fulfilled } = extraActions.downloadFile;
    return {
      [fulfilled]: (state, action) => {
        const { payload } = action;
        downloadReport(payload);
      },
    };
  }
}
