import { PromptModal } from "_components/Abstractions/PromptModal";
import { useToast } from "_helpers/notifications/useToasts";
import { useDispatch } from "react-redux";
import { FormModal } from "../../../_components/Forms/FormModal";
import { orderViewsActions } from "../../../_store/orderView.slice";

export function OrderWorkFlowAction({
  orderId,
  orderAction,
  orderViewAction,
  successMessage,
  buttonText,
  title,
  formControls,
  validationSchema,
  extraViewAction,
  extraAction = null,
  useFormModal = false,
  skipRefreshViews = false
}) {
  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useToast();

  async function onSubmit(payload) {
    const response = await dispatch(orderAction({ orderId, payload }));

    if (response.error) {
      showErrorMessage(response.error.message);
      return;
    }

    if (orderViewAction) {
      await dispatch(orderViewAction());
    }

    if (extraViewAction) {
      await dispatch(extraViewAction({ id: orderId }));
    }

    if (!skipRefreshViews) {
      await dispatch(orderViewsActions.getBdoInfoById({ id: orderId }));
      await dispatch(orderViewsActions.refreshHistory({ id: orderId }));
    }

    extraAction?.();

    showSuccessMessage(successMessage);
  }

  if (useFormModal) {
    return <>
      <FormModal
        buttonText={buttonText}
        formControls={formControls}
        title={title}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        keepModalAfterError={useFormModal}
      />
    </>;
  }

  return (
    <>
      <PromptModal
        buttonText={buttonText}
        title={title}
        type="YesNo"
        onSubmit={onSubmit}
      />
    </>
  );
}
