import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { authActions } from "_store";
import { FormControl } from "_components/Forms/FormControl";
import { FormModal } from "_components/Forms/FormModal";
import { HiOutlineKey } from "react-icons/hi";

export function ChangePassword({ userId }) {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Obecne hasło jest wymagane"),
    newPassword: Yup.string()
      .required("Nowe hasło jest wymagane")
      .min(8, "Nowe hasło musi mieć przynajmniej 8 znaków")
      .notOneOf(
        [Yup.ref("oldPassword"), null],
        "Nowe hasło musi się różnić od starego"
      ),
    confirmNewPassword: Yup.string()
      .required("Potwierdzenie nowego hasła jest wymagane")
      .oneOf([Yup.ref("newPassword"), null], "Nowe hasła muszą się zgadzać"),
  });

  async function onSubmit(payload) {
    return await dispatch(authActions.changePassword({ userId, payload }));
  }

  const formControls = (
    <>
      <FormControl
        label="Obecne hasło"
        name="oldPassword"
        inputType="password"
        className="login-input"
        autoFocus
      />
      <FormControl label="Nowe hasło" name="newPassword" inputType="password" className="login-input" />
      <FormControl label="Potwierdź nowe hasło" name="confirmNewPassword" inputType="password" className="login-input"/>
    </>
  );

  const buttonText = <HiOutlineKey size={12}></HiOutlineKey>;
  const title = `Zmień hasło`;
  const successMessage = "Zmieniono hasło";

  return (
    <>
      <FormModal
        formControls={formControls}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        successMessage={successMessage}
        buttonText={buttonText}
        title={title}
        triggerBtnVariant="success"
        btnSize="xx-small"
      />
    </>
  );
}
