import { Col, Row } from "react-bootstrap";
import { Children } from "react";

export function HorizontalView({ children, columnsSize, ...otherProps }) {
  return (
    <>
      <Row {...otherProps}>
        {Children.map(children, (child, idx) => (
          <Col xs={columnsSize?.[idx]}>{child}</Col>
        ))}
      </Row>
    </>
  );
}
