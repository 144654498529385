import { useSelector } from "react-redux";
import { DictionaryPage } from "../../_components/Abstractions/DictionaryPage";
import { HolidaysTable } from "./HolidaysTable";
import { holidaysActions } from "../../_store/holidays.slice";

export function GetHolidays({ activeEventKey, pageEventKey }) {
  const { holidays } = useSelector((x) => x.holidays);
  const table = <HolidaysTable items={holidays}/>;
  const paging = {
    pageNumber: 1,
    totalCount: holidays?.length ?? 0,
    totalPages: 1,
    pageSize: 50,
  }
  return (
    <DictionaryPage
      items={holidays}
      paging={paging}
      // createItem={createPage}
      itemsTable={table}
      itemActions={holidaysActions}
      activeEventKey={activeEventKey}
      pageEventKey={pageEventKey}
      hidePagination
    />
  );
}