import { ContractorSelect } from "../../_components/Contractors/ContractorSelect";
import { OrderReportGroup } from "./OrderReportGroup";
import { GenerateOrderBaseReport } from "./GenerateOrderBaseReport";
import { reportsActions } from "../../_store/reports.slice";
import * as Yup from "yup";
import { AddressSelect } from "../../_components/Addresses/AddressSelect";
import { HorizontalView } from "../../_components/Abstractions/HorizontalView";
import { useFormContext } from "react-hook-form";
import { useContractorExtendedData } from "../../_components/Contractors/ContractorExtentedData";

export function GenerateOrderContractorsReport() {
  const validationSchema = Yup.object().shape({
    contractor: Yup.object().nullable().required("Klient jest wymagany"),
  });

  const renderData = (data) => <div className="mt-2">
    {data?.length >= 0 && <h6>Ilość zleceń w sumie: {data.length}</h6>}
    <hr/>
    <OrderReportGroup entries={data}/>
  </div>

  return <GenerateOrderBaseReport
    reportTitle="Raport Zlecenia - Klient"
    reportType="orderContractor"
    reportAction={reportsActions.generateOrderContractorReport}
    renderData={renderData}
    customControls={<GenerateOrderContractorsReportForm/>}
    additionalValidationSchema={validationSchema}
  />;
}

export function GenerateOrderContractorsReportForm() {
  const { watch } = useFormContext();
  const contractor = watch("contractor");
  const contractorId = contractor?.id;
  useContractorExtendedData({ contractorId, contractorField: "contractor", isUpdate: true });

  return <HorizontalView>
    <ContractorSelect name="contractor"/>
    <AddressSelect name="address" label="Adres" readonly={!contractor} required={false} addressesField="contractor.addresses"/>
  </HorizontalView>;
}