import { Pagination } from "react-bootstrap";

export function DomainPagination({ paging, onPageChange, itemsCount, minimalPagination = false }) {
  const pageNumber = paging?.pageNumber;
  const totalPages = paging?.totalPages;
  const totalCount = paging?.totalCount;
  const pageSize = paging?.pageSize;
  const pageSizeOptions = [20, 50, 100];

  const handleFirstPage = () => onPageChange(1, pageSize);
  const handlePreviousPage = () => onPageChange(pageNumber - 1, pageSize);
  const handleNextPage = () => onPageChange(pageNumber + 1, pageSize);
  const handleLastPage = () => onPageChange(totalPages, pageSize);

  const handlePageChange = (pageNumberToChange, pageSizeToChange) => {
    if (pageNumber === pageNumberToChange && pageSize === pageSizeToChange) {
      return;
    }
    onPageChange(pageNumberToChange, pageSizeToChange);
  };

  const isPaginationValid = paging
    ? Object.values(paging).every((x) => x)
    : false;

  if(!paging){
    return;
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        {!minimalPagination && isPaginationValid && (
          <>
            <strong>{`Strona ${pageNumber} z ${totalPages}`}</strong>
            <strong>{`Pokazywanie ${itemsCount} z ${totalCount} rezultatów`}</strong>
          </>
        )}
      </div>
      {totalPages > 1 && <Pagination className="pagination justify-content-center">
        <Pagination.First
          onClick={handleFirstPage}
          disabled={pageNumber === 1 || !pageNumber}
        />
        <Pagination.Prev
          onClick={handlePreviousPage}
          disabled={pageNumber === 1 || !pageNumber}
        >
          {!minimalPagination && "Poprzednia"}
        </Pagination.Prev>
        {[...Array(totalPages)].map((val, x) => {
          const number = x + 1;
          return (
            <Pagination.Item
              key={x}
              active={number === pageNumber}
              onClick={() => handlePageChange(number, pageSize)}
            >
              {number}
            </Pagination.Item>
          );
        })}
        <Pagination.Next
          onClick={handleNextPage}
          disabled={pageNumber === totalPages}
        >
          {!minimalPagination && "Następna"}
        </Pagination.Next>
        <Pagination.Last
          onClick={handleLastPage}
          disabled={pageNumber === totalPages}
        />
      </Pagination>}
      <div className={totalPages > 1 ? "" : "mt-1"}>
        <strong>Rozmiar strony</strong>
        <Pagination className="pagination justify-content-start">
          {pageSizeOptions.map((x) => (
            <Pagination.Item
              key={x}
              active={pageSize === x}
              onClick={() => handlePageChange(1, x)}
            >
              {x}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
    </>
  );
}
