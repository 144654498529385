import { AddressTypeBadge } from "./AddressTypeBadge";

export function AddressTypeBadgeList({ address }) {
  const badges = [];

  if (address.isCentral) {
    badges.push(<AddressTypeBadge key="isCentral" type="isCentral" />);
  }

  if (address.isDefault) {
    badges.push(<AddressTypeBadge key="isDefault" type="isDefault" />);
  }

  if (address.isPickup) {
    badges.push(<AddressTypeBadge key="isPickup" type="isPickup" />);
  }

  if (address.isBdoLinked) {
    badges.push(<AddressTypeBadge key="isBdoLinked" type="isBdoLinked" />);
  }

  return <div className="mb-2">{badges}</div>;
}
