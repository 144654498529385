import { GenerateOrderBaseReport } from "./GenerateOrderBaseReport";
import { reportsActions } from "../../_store/reports.slice";
import { DomainSwitch } from "../../_components/Abstractions/DomainSwitch";

export function GenerateSubContainerUsageReport() {
  const customFormControls = <>
    <DomainSwitch name="isDetailed" label="Szczegółowy raport"/>
  </>;

  const renderData = () => {};

  return <GenerateOrderBaseReport
    reportTitle="Zużycie kontenerów (podtypy)"
    reportType="subcontainersUsage"
    reportAction={reportsActions.generateSubContainersUsageReport}
    renderData={renderData}
    customControls={customFormControls}
    renderDefaultControls={false}
    isPrintable={false}
  />;
}

