import { useDispatch } from "react-redux";
import { groupContainersActions } from "../../_store/groupContainers.slice";
import { GroupContainerForm } from "./GroupContainerForm";

export function UpdateGroupContainer({ groupContainer, onShow }) {
  const dispatch = useDispatch();
  const { id } = groupContainer;

  async function onSubmit(payload) {
    return dispatch(groupContainersActions.updateGroup({ id, payload }));
  }

  const buttonText = "Edytuj grupę";
  const successMessage = "Zaktualizowano grupę kontenerów";

  return <GroupContainerForm onSubmit={onSubmit} defaultValues={groupContainer} successMessage={successMessage} buttonText={buttonText} onShow={onShow}/>;
}
