import { useSelector } from "react-redux";
import { DictionaryPage } from "../../_components/Abstractions/DictionaryPage";
import { ServicesTable } from "./ServicesTable";
import { servicesActions } from "../../_store/services.slice";
import { CreateService } from "./CreateService";

export function GetServices({ activeEventKey, pageEventKey }) {
  const { services, servicesPaging, servicesSorting } = useSelector((x) => x.services);
  const table = <ServicesTable items={services} sorting={servicesSorting} itemActions={servicesActions}/>;
  const createPage = <CreateService/>;

  return (
    <DictionaryPage
      items={services}
      paging={servicesPaging}
      itemsTable={table}
      itemActions={servicesActions}
      createItem={createPage}
      activeEventKey={activeEventKey}
      pageEventKey={pageEventKey}
      includeSearcher
      sorting={servicesSorting}
    />
  );
}