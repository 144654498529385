import { OrderShippingCalendarView } from "./OrderShippingCalendarView";
import { useToast } from "../../../_helpers/notifications/useToasts";
import { SelectTable } from "../../Abstractions/SelectTable";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export function OrderShippingTimeSelect({ date, selectedId }) {
  const { showErrorMessage } = useToast();
  const [show, setShow] = useState(false);
  const { getValues, setValue } = useFormContext();
  const plannedShippingTimeStart = getValues("transportDetails.plannedShippingTimeStart");
  const plannedShippingTimeEnd = getValues("transportDetails.plannedShippingTimeEnd");
  const squarePlaceType = getValues("transportDetails.squarePlaceType");
  const transportType = getValues("transportDetails.transportType");
  const internalIncludedVehicle = getValues("transportDetails.includedVehicleId");
  const externalIncludedVehicleRegNumber = getValues("transportDetails.includedVehicleRegNumber");
  const externalIncludedVehicleType = getValues("transportDetails.externalIncludedVehicleType");
  const includedVehicleName = transportType === "Internal" ? internalIncludedVehicle?.name : externalIncludedVehicleRegNumber;
  const includedVehicleType = transportType === "Internal" ? internalIncludedVehicle?.semiTrailerType : externalIncludedVehicleType;
  const includedVehicle = { name: includedVehicleName, type: includedVehicleType };

  const defaultValues = {
    selectedStartTime: plannedShippingTimeStart,
    selectedEndTime: plannedShippingTimeEnd,
    squarePlaceType: squarePlaceType,
  }

  const onSubmit = (result) => {
    if (result.selectedStartTime && result.selectedEndTime && result.squarePlaceType) {
      setValue("transportDetails.plannedShippingTimeStart", result.selectedStartTime);
      setValue("transportDetails.plannedShippingTimeEnd", result.selectedEndTime);
      setValue("transportDetails.squarePlaceType", result.squarePlaceType);
      setShow(false);
      return;
    }

    showErrorMessage("Nie wybrano czasu awizacji...");
  };
  const control = <OrderShippingCalendarView
    date={date}
    includedVehicleName
    includedVehicle={includedVehicle}
    selectable onSubmit={onSubmit} defaultValues={defaultValues} selectedId={selectedId}
  />;

  return <SelectTable
    table={control}
    disabled={!date}
    show={show}
    setShow={setShow}
    modalTitle="Wybierz czas awizacji"
    label="Wybór czasu awizacji"
    disabledReason="Nie wybrano daty awizacji"
    skipSearchBar
    submitBtnText="Zatwierdź czas awizacji"/>;
}