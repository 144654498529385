import { DictionaryPage } from "_components/Abstractions/DictionaryPage";
import { ContainerTable } from "_components/Containers/ContainerTable";
import { containersActions } from "_store/containers.slice";
import { useSelector } from "react-redux";
import { CreateContainer } from "./CreateContainer";
import { RefreshContainerUsed } from "./RefreshContainerUsed";

export function GetContainers({ activeEventKey, pageEventKey }) {
  const { containers, containersPaging, containersSummary } = useSelector((x) => x.containers);
  const isActivePage = activeEventKey === pageEventKey;
  const table = <ContainerTable containers={containers} isActivePage={isActivePage} summary={containersSummary}/>;
  const createPage = <CreateContainer/>;
  const tableUseCases = <>
    <RefreshContainerUsed items={containers} refreshAll={true}/>
  </>;

  return (
    <DictionaryPage
      items={containers}
      paging={containersPaging}
      itemsTable={table}
      itemActions={containersActions}
      tableUseCases={tableUseCases}
      createItem={createPage}
      activeEventKey={activeEventKey}
      pageEventKey={pageEventKey}
      includeSearcher
      excludeStatus
    />
  );
}