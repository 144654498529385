 import * as Yup from "yup";
import { FormControl } from "../../../Forms/FormControl";
import { FormModal } from "../../../Forms/FormModal";
import { ListGroup } from "react-bootstrap";
import { BaseKpoForm } from "./KpoReceiveConfirmation";
import { ValidateBdoAction } from "./BdoActionValidator";

export function KpoRemarksForm({ kpoCards, onSubmit, name, promptTitle, performAll, expectedStatus, submitBtnText }) {
  const kpoItemSchema = Yup.object().shape({
    remarks: Yup.string().when("isSelected", {
      is: true,
      then: Yup.string().required("Uwagi są wymagane"),
    })
  });

  const validationSchema = Yup.object().shape({
    kpoItems: Yup.array().of(kpoItemSchema)
  });

  const { validate } = ValidateBdoAction();
  const filteredKpoCards = kpoCards.filter(x => expectedStatus?.includes(x.status));
  const values = filteredKpoCards.map(x => { return { kpoId: x.kpoId, cardNumber: x.cardNumber, isSelected: true }; });
  const defaultValues = { kpoItems: values };
  const formControls =
    <div>
      {values.length === 0 && <ListGroup.Item>Brak kart KPO do wykonania akcji</ListGroup.Item>}
      {filteredKpoCards.map((x, idx) => <div key={idx}>
        <BaseKpoForm kpoItem={x}
                     selectable={kpoCards?.length > 1}
                     formControls={
                       <>
                         <FormControl name={`kpoItems[${idx}].remarks`} label="Uwagi"/>
                       </>}
                     parentName={`kpoItems[${idx}]`}/>
      </div>)}
    </div>;

  const handleSubmit = async (payload) => {
    const kpoItems = payload.kpoItems.filter(x => x.isSelected).map(x => {
      return { kpoId: x.kpoId, remarks: x.remarks };
    });
    validate(kpoItems);
    await onSubmit({ kpoItems });
  };

  return <FormModal
    title={promptTitle}
    formControls={formControls}
    defaultValues={defaultValues}
    onSubmit={handleSubmit}
    buttonText={name}
    validationSchema={validationSchema}
    isButtonTrigger={performAll}
    skipToast
    sizeModal="md"
    submitBtnText={submitBtnText}/>;
}