import { useFormContext } from "react-hook-form";
import { DomainSwitch } from "../../../Abstractions/DomainSwitch";
import { useEffect } from "react";
import { TextArea } from "../../../Forms/TextArea";

export function KpoRemarksControl({ parentName, intermediary, includeIntermediary }) {
  const getFieldName = (fieldName) => `${parentName}.${fieldName}`;
  const { watch, setValue } = useFormContext();
  const remarksFieldName = getFieldName("remarks");
  const overrideRemarksFieldName = getFieldName("overrideRemarks");
  const correctedWasteMassFieldName = getFieldName("correctedWasteMass");
  const overrideRemarks = watch(overrideRemarksFieldName);
  const correctedWasteMass = watch(correctedWasteMassFieldName);
  useEffect(() => {
    if (!overrideRemarks) {
      const defaultRemarks = GetDefaultRemarks(correctedWasteMass,  intermediary, includeIntermediary, true);
      setValue(remarksFieldName, defaultRemarks);
    }
  }, [overrideRemarks]);

  return <>
    <TextArea name={remarksFieldName} label="Uwagi" required={false} readonly={!overrideRemarks} height="25px"/>
    <DomainSwitch name={overrideRemarksFieldName} label="Zmiana uwag"/>
  </>;
}

export const GetDefaultRemarks = (wasteMass, intermediary, includeIntermediary, skipToFixedConversion = false) => {
  const wasteMassDescription = skipToFixedConversion ? wasteMass : wasteMass.toFixed(4);
  const intermediaryDescription = includeIntermediary && intermediary ? ` ${intermediary} SPRZEDAWCA/POŚREDNIK` : "";

  return `MASA WG WAGI ODBIORCY - ${wasteMassDescription ?? "0.0000"} Mg${intermediaryDescription}`;
};