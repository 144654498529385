import { useDispatch, useSelector } from "react-redux";
import { salesRepresentativesActions } from "../../_store/salesRepresentatives.slice";
import { SalesRepresentativesForm } from "../../_components/Wastes/SalesRepresentativesForm";

export function CreateSalesRepresentative() {
  const dispatch = useDispatch();
  const { salesRepresentatives: { paging } } = useSelector((x) => x.salesRepresentatives);

  async function onSubmit(payload) {
    const response = await dispatch(salesRepresentativesActions.create(payload));
    if (!response?.error) {
      const pageNumber = paging?.pageNumber ?? 1;
      dispatch(salesRepresentativesActions.getAll({ pageNumber }));
    }

    return response;
  }

  return <SalesRepresentativesForm onSubmit={onSubmit}/>;
}