import { useDispatch } from "react-redux";
import { mapToWastesDetails } from "../../../_services/Orders/orderActionsMapper";
import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { UpdateOrderWastesDetailsForm } from "./UpdateOrderWastesDetailsForm";

export function UpdateOrderSeriesWastesDetails({ series, transactionType }) {
  const dispatch = useDispatch();
  const { id: seriesId, wastesDetails } = series || {};

  const onSubmit = async(payload) => {
    const requestPayload = {
      id: seriesId,
      wastesDetails: mapToWastesDetails(payload.wastesDetails, transactionType, false)
    };
    const response = await dispatch(ordersActions.updateSeriesWastesDetails({ seriesId, payload: requestPayload }));
    if (!response?.error) {
      dispatch(orderViewsActions.getSeriesById({ id: seriesId }));
    }

    return response;
  }

  return <UpdateOrderWastesDetailsForm
    order={series}
    transactionType={transactionType}
    wastesDetails={wastesDetails}
    onSubmit={onSubmit}
    isSeries
  />;
}

