import { BadgeDomain } from "_components/Abstractions/BadgeDomain";
import { StatusBadge } from "_components/Abstractions/StatusBadge";
import {
  HOOK_FEATURES,
  HookTypes,
  HookTypesColors,
  SemiTrailerType,
  SemiTrailerTypeColor
} from "domain/VehicleType";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ChangeVehicleState } from "useCases/vehicles/ChangeVehicleState";
import { GetExtendedData } from "useCases/vehicles/GetExtendedData";
import { UpdateVehicle } from "useCases/vehicles/UpdateVehicle";
import { VehicleTypeBadge } from "./VehicleTypeBadge";

import { SemiTrailerTypeBadge } from "../OrderViews/Components/SemiTrailerTypeBadge";
import { SortedTh } from "../Abstractions/SortedTh";

export function VehiclesTable({ vehicles, isActive, sorting, itemActions }) {
  const [activeItem, setActiveItem] = useState();
  const [activeAction, setActiveAction] = useState();
  const columnsNames = {
    "displayName": "Nazwa",
    "name": "Marka",
    "model": "Model",
    "registrationNumber": "Nr. rejestracyjny",
    "vehicleType": "Typ pojazdu"
  }

  useEffect(() => {
    if (isActive) {
      setActiveItem();
      setActiveAction();
    }
  }, [isActive]);

  return (
    <Table hover>
      <thead>
      <tr>
        {Object.entries(columnsNames).map(([value, name]) =>
          <SortedTh key={value} sorting={sorting} itemActions={itemActions} sortingValue={value}>{name}</SortedTh>)}
        <th>Cechy pojazdu</th>
        <th>Status</th>
        <th>Akcje</th>
      </tr>
      </thead>
      <tbody>
      {vehicles.loading && (
        <>
          <tr className="spinner-border"></tr>
        </>
      )}
      {vehicles.length > 0 &&
        vehicles.map((item) => (
          <tr key={item.id} onClick={() => setActiveItem(item.id)}>
            <td>{item.displayName}</td>
            <td>{item.name}</td>
            <td>{item.model}</td>
            <td>{item.registrationNumber}</td>
            <td>
              <VehicleTypeBadge type={item.vehicleType}/>
            </td>
            <td>
              {item.hasAdjustableHook && (
                <BadgeDomain
                  className="me-1"
                  bg={HOOK_FEATURES["AdjustableHook"].color}
                  text={HOOK_FEATURES["AdjustableHook"].text ?? "light"}
                >
                  {HOOK_FEATURES["AdjustableHook"].name}
                </BadgeDomain>
              )}
              {item.vehicleType === "Hook" && !item.hasAdjustableHook && (
                <BadgeDomain
                  className="me-1"
                  bg={HOOK_FEATURES["NonAdjustableHook"].color}
                >
                  {HOOK_FEATURES["NonAdjustableHook"].name}
                </BadgeDomain>
              )}
              {item.hookType && (
                <BadgeDomain bg={HookTypesColors[item.hookType]}>
                  {HookTypes[item.hookType]}
                </BadgeDomain>
              )}
              {item.semiTrailerType && <SemiTrailerTypeBadge type={item.semiTrailerType}/>}
            </td>
            <td>
              <StatusBadge isActive={item.isActive}/>
            </td>
            <td>
              <UpdateVehicle
                vehicle={item}
                onShow={() => setActiveAction("updateVehicle")}
              />
              <GetExtendedData
                vehicle={item}
                activeVehicleId={activeItem}
                onShow={() => setActiveAction("getExtendedData")}
                isFormActive={activeAction === "getExtendedData"}
              />
              <ChangeVehicleState
                vehicle={item}
                onShow={() => setActiveAction("changeVehicleState")}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
