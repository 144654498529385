import * as Yup from "yup";
import { FormControl } from "_components/Forms/FormControl";
import { FormModal } from "_components/Forms/FormModal";
import { Dropdown } from "_components/Abstractions/Dropdown";
import { HookTypes, SemiTrailerType, VEHICLES_TYPES } from "domain/VehicleType";
import { RadioButtonGroup } from "_components/Abstractions/RadioButtonGroup";
import { ReadonlyControl } from "../Forms/ReadonlyControl";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { HorizontalView } from "../Abstractions/HorizontalView";
import moment from "moment";

export function VehicleForm({
  onSubmit,
  defaultValues,
  isUpdate = false,
  onShow = null
}) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Marka jest wymagana").max(30, "Marka może mieć maksymalnie 30 znaków"),
    model: Yup.string().required("Model jest wymagany").max(30, "Model może mieć maksymalnie 30 znaków"),
    registrationNumber: Yup.string().required("Nr. rejestracyjny jest wymagany").max(10, "Nr. rejestracyjny może mieć maksymalnie 10 znaków"),
    insuranceNumber: Yup.string().nullable().required("Nr. polisy jest wymagany").max(50, "Nr. polisy może mieć maksymalnie 50 znaków"),
    insuranceCompany: Yup.string().nullable().required("Ubezpieczyciel jest wymagany").max(100, "Ubezpieczyciel może mieć maksymalnie 100 znaków"),
    insuranceDate: Yup.string().nullable()
    .required("Data ważności badania technicznego jest wymagana")
    .typeError("Data ważności badania technicznego jest wymagana")
    .test("is-future", "Data ważności badania technicznego musi być przyszła", value => moment(value).isAfter(moment())),
    inspectionDate: Yup.string().nullable().required("Data ważności polisy OC jest wymagana")
    .typeError("Data ważności polisy OC jest wymagana")
    .test("is-future", "Data ważności polisy OC musi być przyszła", value => moment(value).isAfter(moment())),
    udtInspectionDate: Yup.string().nullable().when("vehicleType", {
      is: x => x === "Hook",
      then: Yup.string().nullable()
      .required("Data UDT jest wymagana")
      .typeError("Data UDT jest wymagana")
      .test("is-future", "Data UDT musi być przyszła", value => moment(value).isAfter(moment())),
    }),
    tachographDate: Yup.string().nullable().when("vehicleType", {
      is: x => x === "Hook" || x === "Tir",
      then: Yup.string().nullable().required("Data tachografu jest wymagana")
      .typeError("Data tachografu jest wymagana")
      .test("is-future", "Data tachografu musi być przyszła", value => moment(value).isAfter(moment())),
    }),
    vehicleType: Yup.string().required("Typ pojazdu jest wymagany").typeError("Typ pojazdu jest wymagany").oneOf(Object.keys(VEHICLES_TYPES), "Typ pojazdu jest wymagany"),
    hasAdjustableHook: Yup.boolean().nullable().when("vehicleType", {
      is: "Hook",
      then: Yup.boolean().typeError(
        "Informacja o regulacji haku jest wymagana"
      )
    }),
    hookType: Yup.string().nullable().when("vehicleType", {
      is: "Hook",
      then: Yup.string().typeError("Informacja o rozmiarze hakowca jest wymagana")
    }),
    semiTrailerType: Yup.string().nullable().when("vehicleType", {
      is: "SemiTrailer",
      then: Yup.string().typeError("Informacja o typie naczepy jest wymagana").required("Informacja o typie naczepy jest wymagana")
    })
  });

  const formControls = (
    <div className="domain-control">
      <FormControl label="Marka" name="name" autoFocus/>
      <FormControl label="Model" name="model"/>
      <FormControl label="Nr. rejestracyjny" name="registrationNumber"/>
      <VehicleDisplayName/>
      <Dropdown
        label="Wybierz typ pojazdu"
        name="vehicleType"
        items={VEHICLES_TYPES}
      />
      <RadioButtonGroup
        label="Typ hakowca"
        name="hookType"
        items={Object.entries(HookTypes).map(([key, value]) => ({
          name: value,
          value: key
        }))}
        conditionField="vehicleType"
        when={(field) => field === "Hook"}
        defaultChecked={defaultValues?.hookType}
      />
      <RadioButtonGroup
        label="Hak"
        name="hasAdjustableHook"
        items={[
          { name: "Regulowany", value: true },
          { name: "Nieregulowany", value: false }
        ]}
        conditionField="vehicleType"
        when={(field) => field === "Hook"}
        defaultChecked={defaultValues?.hasAdjustableHook}
      />
      <VehicleFeatures/>
      <VehicleDates/>
    </div>
  );

  const buttonText = isUpdate ? "Edytuj" : "Dodaj pojazd";
  const successMessage = isUpdate ? "Zaktualizowano pojazd" : "Dodano pojazd";
  const title = buttonText;
  const handleOnSubmit = async(payload) => {
    return onSubmit({ details: payload });
  };

  return (
    <>
      <FormModal
        formControls={formControls}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        buttonText={buttonText}
        successMessage={successMessage}
        title={title}
        defaultValues={defaultValues}
        onShowModal={onShow}
        btnSize="small"
      />
    </>
  );
}

const VehicleDisplayName = () => {
  const { watch } = useFormContext();
  const [displayName, setDisplayName] = useState("");
  const name = watch("name");
  const regNumber = watch("registrationNumber");

  useEffect(() => {
    setDisplayName(`${name} ${regNumber}`);
  }, [name, regNumber]);

  return <ReadonlyControl label="Nazwa">{displayName}</ReadonlyControl>;
};

const VehicleFeatures = () => {
  const { watch } = useFormContext();
  const vehicleType = watch("vehicleType");
  return <>
    {vehicleType === "SemiTrailer" &&
      <Dropdown label="Typ naczepy" name="semiTrailerType" items={SemiTrailerType}/>}
  </>;
};

const VehicleDates = () => {
  const { watch } = useFormContext();
  const vehicleType = watch("vehicleType");
  const isHook = vehicleType === "Hook";
  const isPoweredVehicle = isHook || vehicleType === "Tir";

  return <>
    <hr/>
    <HorizontalView>
      <FormControl label="Data ważności badania technicznego" name="inspectionDate" inputType="date"/>
      <FormControl label="Data ważności polisy OC" name="insuranceDate" inputType="date"/>
    </HorizontalView>
    <HorizontalView>
      <FormControl label="Nr. polisy" name="insuranceNumber"/>
      <FormControl label="Towarzystwo ubezpieczeniowe" name="insuranceCompany"/>
    </HorizontalView>
    {isPoweredVehicle && <HorizontalView>
      <FormControl label="Data Tachografu" name="tachographDate" inputType="date"/>
      {isHook && <FormControl label="Data UDT" name="udtInspectionDate" inputType="date"/>}
    </HorizontalView>}
  </>;
};