import { SelectSearcher } from "_components/Abstractions/MultiSearcher/SelectSearcher";
import { useContractorSearch } from "./searchContractor";

export function ContractorSelect({ name, contractorType, label, saveWholeObject = true, required, readonly }) {
  const { items, onSearchChange } = useContractorSearch({ contractorType });

  return (
    <>
      <SelectSearcher
        name={name}
        label={label ?? "Klient"}
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "id", value: "name" }}
        placeholder="Wyszukaj klienta po nazwie..."
        saveWholeObject={saveWholeObject}
        required={required}
        readonly={readonly}
      />
    </>
  );
}

