import { BadgeDomain } from "_components/Abstractions/BadgeDomain";
import { ContractorBadges, ContractorTypes } from "domain/ContractorType";

export function ContractorType({ types }) {
  return (
    <>
      {types?.map((x) => (
        <BadgeDomain key={x} bg={ContractorBadges[x]}>
          {ContractorTypes[x]}
        </BadgeDomain>
      ))}
    </>
  );
}
