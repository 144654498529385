import { SelectTable } from "_components/Abstractions/SelectTable";
import { useEffect, useState } from "react";
import { KeoBdoTable } from "./KeoBdoTable";
import { KeoBdoSearcher } from "./KeoBdoSearcher";
import { bdoActions } from "../../../_store/bdo.slice";
import { useFormContext } from "react-hook-form";
import useFetch from "../../../_helpers/hooks/useFetch";

export function KeoBdoSelect({ name }) {
  const { setValue } = useFormContext();
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);
  const fetch = useFetch();


  const onSelect = (item) => {
    setValue(name, item, { shouldValidate: true, shouldTouch: true, shouldDirty: true });
    setShow(false);
  };

  const onSearchChange = async(filters) => {
    let data = { pageNumber: 1, pageSize: 50, ...filters };
    await fetch(bdoActions.searchKeoCards({ payload: data }), setItems);
  };

  useEffect(() => {
    onSearchChange(null);
  }, []);

  const searcherData = { onSearchChange };
  const table = (<KeoBdoTable items={items} onSelect={onSelect}/>);
  const searcher = <KeoBdoSearcher {...searcherData}/>;

  return (
    <>
      <SelectTable
        table={table}
        label="Wyszukiwarka kart KEO"
        onSearchChange={onSearchChange}
        show={show}
        setShow={setShow}
        searcher={searcher}
      />
    </>
  );
}
