import { useEffect, useRef } from "react";
import { useContainersSearch } from "./ContainersSearch";
import { MultiSelectSearcher } from "../Abstractions/MultiSearcher/MultiSearcher";

export function ContainersSearchMultiSelect({ name, readonly, groupContainerId, skipInitLoading = false }) {
  const { onSearchChange, items } = useContainersSearch({ groupContainerId });
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (readonly) {
      return;
    }

    if (isFirstRender.current && skipInitLoading) {
      isFirstRender.current = false;
      return;
    }

    onSearchChange(null);
  }, [readonly, groupContainerId]); // eslint-disable-next-line/react-hooks/exhaustive-deps

  return (
    <>
      <MultiSelectSearcher
        name={name}
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "containerId", value: "containerName" }}
        placeholder="Wyszukaj podtypy kontenerów..."
        readonly={readonly}
        appendDefaultData={{ usedCount: null }}
        resetOnReadonlyChange={false}
        skipFieldsTrack={false}
        resetOnChange={groupContainerId}
      />
    </>
  );
}
