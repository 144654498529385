import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { OrderWorkFlowAction } from "./OrderWorkFlowAction";

export function ConfirmOrder({ orderId, orderNumber }) {
  const successMessage = "Kurs zlecenia został rozpoczęty.";
  const buttonText = "Rozpocznij kurs";
  const title = `Czy rozpocząć kurs zlecenia ${orderNumber}?`;

  const props = {
    orderId: orderId,
    orderAction: ordersActions.confirmOrder,
    orderViewAction: orderViewsActions.confirm,
    successMessage,
    buttonText,
    title,
    extraViewAction: orderViewsActions.getTransportDetails
  };

  return <OrderWorkFlowAction {...props} />
}
