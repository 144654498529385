import { GenerateOrderBaseReport } from "./GenerateOrderBaseReport";
import { reportsActions } from "../../_store/reports.slice";
import { OrderReportUserTable } from "./OrderReportUserEntry";
import { UserSelect } from "../../_components/Users/UserSelect";

export function GenerateOrderUserReport() {
  const customFormControls = <>
    <UserSelect name="user"/>
  </>;

  const renderData = (data) => {
    if (!data || data.loading) {
      return;
    }
    return <OrderReportUserTable data={data}/>;
  };

  return <GenerateOrderBaseReport
    reportTitle="Raport Zlecenia - Użytkownik"
    reportType="orderUser"
    reportAction={reportsActions.generateOrderUserReport}
    renderData={renderData}
    customControls={customFormControls}
  />;
}

