export const WASTE_EXCHANGE_TYPE = {
  "CONTAINER_EXCHANGE": "ContainerExchange",
  "LOADING": "Loading"
}

export const WasteExchangeType = {
  "ContainerExchange": "Wymiana kontenera",
  "Loading": "Załadunek"
}

export const WasteExchangeBadges = {
  "ContainerExchange": "teal",
  "Loading": "fiolet"
}