import { useEffect, useState } from "react";
import { Form, ListGroup } from "react-bootstrap";
import { BadgeDomain } from "../BadgeDomain";
import { useFieldArray, useFormContext } from "react-hook-form";
import s from "./style.module.css";
import { DomainButton } from "../DomainButton";

export function MultiSelectSearcher({
  name,
  items,
  optionsObject,
  optionSave,
  onSearchChange,
  placeholder,
  editHelper,
  appendDefaultData,
  resetOnReadonlyChange = false,
  readonly = false,
  required = true,
  resetOnChange = null,
  skipFieldsTrack = true,
  bg = null,
  selectedHorizontalView = false
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [resetTrigger, setResetTrigger] = useState(null);
  const { replace, fields, remove } = useFieldArray({ name: name });
  const { getFieldState, getValues, clearErrors } = useFormContext();
  const fieldState = getFieldState(name);
  const { key, value } = optionsObject;
  const formattedLabel = required ? `${placeholder} *` : placeholder;

  useEffect(() => {
    let defaultValues = getValues(name);

    if (!defaultValues || defaultValues?.length === 0) {
      return;
    }

    if (!optionSave) {
      setSelectedOptions(defaultValues);
      return;
    }

    if (editHelper) {
      setSelectedOptions(editHelper);
    }
  }, []);

  useEffect(() => {
    if (selectedOptions.length === 0 && fields.length === 0) {
      return;
    }

    let newOptions = selectedOptions;
    if (optionSave) {
      newOptions = selectedOptions.map((x) => optionSave(x));
    }

    if (appendDefaultData) {
      if (selectedOptions.length > fields.length) {
        newOptions = selectedOptions.map((x) => { return { ...appendDefaultData, ...x }; });
      }
    }

    clearErrors(name);
    replace(newOptions);

  }, [selectedOptions]);

  useEffect(() => {
    if (fields.length === 0 || selectedOptions.length === fields.length) {
      return;
    }

    if (!skipFieldsTrack) {
      setSelectedOptions(fields);
    }
  }, [fields]);

  const handleSearch = (event) => {
    if (event.target.value === searchTerm) {
      return;
    }

    setSearchTerm(event.target.value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const newSearchTimeout = setTimeout(() => {
      onSearchChange(event.target.value);
    }, 300);

    setSearchTimeout(newSearchTimeout);
  };

  useEffect(() => {
    if (readonly && resetOnReadonlyChange) {
      setSelectedOptions([]);
    }
  }, [readonly]);

  useEffect(() => {
      setResetTrigger(resetOnChange);
    if (resetOnChange && resetTrigger && resetTrigger !== resetOnChange) {
      setSelectedOptions([]);
    }
  }, [resetOnChange]);

  const handleSelectOption = (option) => {
    if (!selectedOptions.some((x) => x[key] === option[key])) {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleRemoveOption = (option, idx) => {
    remove(idx);
    const updatedOptions = selectedOptions.filter((item) => item !== option);
    setSelectedOptions(updatedOptions);
  };

  const searcher = (
    <div>
      <Form.Control
        className="domain-control"
        value={searchTerm}
        onChange={handleSearch}
        placeholder={formattedLabel}
        disabled={readonly}
        isInvalid={fieldState?.error}
      />
      <Form.Control.Feedback type="invalid">
        {fieldState?.error?.message}
      </Form.Control.Feedback>
    </div>
  );

  const selectedSection = (
    <div className={`${s.selectedOptions} ${selectedHorizontalView ? "flex-column align-items-start" : "flex-wrap"}`}>
      {selectedOptions.map((option, idx) => (
        <BadgeDomain
          bg={bg ?? "primary"}
          key={option[key]}
          onClick={() => handleRemoveOption(option, idx)}
        >
          {option[value]}
        </BadgeDomain>
      ))}
    </div>
  );

  const optionsSection = (
    <>
      <div className={s.options}>
        <ListGroup>
          {items?.length > 0 &&
            items.map((option) => (
              <ListGroup.Item key={option[optionsObject.key]} onMouseDown={() => handleSelectOption(option)} className={s.option}>
                {option[optionsObject.value]}
              </ListGroup.Item>
            ))}
          {(items?.length === 0 && searchTerm?.length !== 0 &&
            <ListGroup.Item>Nie znaleziono...</ListGroup.Item>
          )}
          {(items?.length === 0 && searchTerm?.length === 0 &&
            <ListGroup.Item>Zacznij wyszukiwać...</ListGroup.Item>
          )}
        </ListGroup>
      </div>
      {/*<div className="d-flex mt-1 flex-column-reverse">*/}
      {/*</div>*/}
    </>
  );

  const handleOptionsShow = () => {
    const isEmptySelect = searchTerm === "" && items.length === 0;
    if (isEmptySelect) {
      onSearchChange(" ");
    }

    setShowOptions(!readonly);
  };

  return (
    <>
      {selectedSection}
      <div
        className={`${s.mainContainer}`}
        onFocus={handleOptionsShow}
        onBlur={() => setShowOptions(false)}
        tabIndex={0}
      >
        {searcher}
        {showOptions && optionsSection}
      </div>
    </>
  );
}
