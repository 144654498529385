import { Card } from "react-bootstrap";
import { ButtonOverlay } from "../../Abstractions/ButtonOverlay";
import { BiLink } from "react-icons/bi";
import { dateToString } from "../../../domain/Configuration";
import { KpoBdoStatusBadge } from "../../Bdo/Kpo/KpoBdoStatusBadge";
import { BadgeDomain } from "../../Abstractions/BadgeDomain";
import { KPO_STATUS } from "../../../domain/KpoCardStatus";

export const OrderKpoCardItemsView = ({ items }) => {
  if (!items || items?.length === 0) {
    return;
  }
  const anyCorrectedItems = items.some(x => x.revisionNumber > 0 && x.status === KPO_STATUS.CONFIRMATION_GENERATED);
  const toolTip = <Card className="x-small">
    <Card.Header as="p" className="px-2">Podpięte karty KPO</Card.Header>
    <Card.Body className="py-0 px-2 x-small">
      {items.map((x, idx) => <div className={x.isRealTransportTimeEligible ? "" : "error"} key={x.id}>
        {idx + 1}. {x.cardNumber ?? "-"} ({dateToString(x.realTransportTime)}) <KpoBdoStatusBadge status={x.status}/>
        {x.revisionNumber > 0 && x.status === KPO_STATUS.CONFIRMATION_GENERATED && <BadgeDomain bg="orange">Korekta {x.revisionNumber}</BadgeDomain>}
      </div>)}
    </Card.Body>
  </Card>;

  return <ButtonOverlay size="xx-small" variant={anyCorrectedItems ? "orange" : "success"} overrideTooltip tooltip={toolTip} placement="bottom"><BiLink/> {items.length} </ButtonOverlay>;
};