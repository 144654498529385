import * as Yup from "yup";
import { DRIVER_TYPES } from "../../domain/DriverType";
import { FormModal } from "../../_components/Forms/FormModal";
import { FormControl } from "../../_components/Forms/FormControl";
import { Dropdown } from "../../_components/Abstractions/Dropdown";
import { HorizontalView } from "../../_components/Abstractions/HorizontalView";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

export function DriverForm({ title, onSubmit, defaultValues, onShow, buttonText, successMessage, additionalFormControls, additionalValidationSchema }) {
  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required("Nazwa jest wymagany").max(20, "Nazwa może mieć maksymalnie 20 znaków"),
    login: Yup.string().required("Login jest wymagany").min(2, "Login musi mieć przynajmniej 2 znaki").max(30, "Login może mieć maksymalnie 30 znaków"),
    name: Yup.string().required("Imię jest wymagane").min(3, "Imię musi mieć przynajmniej 3 znaki").max(20, "Imię może mieć maksymalnie 20 znaków"),
    lastName: Yup.string().required("Nazwisko jest wymagane").min(3, "Nazwisko musi mieć przynajmniej 3 znaki").max(20, "Nazwisko może mieć maksymalnie 20 znaków"),
    driverType: Yup.string().nullable().required("Typ kierowcy jest wymagany").oneOf(Object.keys(DRIVER_TYPES), "Typ kierowcy jest wymagany"),
    phone: Yup.string().nullable().required("Telefon jest wymagany").max(30, "Telefon może mieć maksymalnie 30 znaków")
  }).concat(additionalValidationSchema);

  const formControls = (
    <div className="x-small">
      <FormControl label="Nazwa" name="displayName" autoFocus/>
      <HorizontalView>
        <FormControl label="Imię" name="name"/>
        <FormControl label="Nazwisko" name="lastName"/>
      </HorizontalView>
      <DriverLogin/>
      {additionalFormControls}
      <FormControl label="Telefon" name="phone"/>
      <Dropdown label="Typ kierowcy" name="driverType" items={DRIVER_TYPES}/>
    </div>
  );

  return (
    <>
      <FormModal
        formControls={formControls}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        buttonText={buttonText}
        successMessage={successMessage}
        title={title}
        defaultValues={defaultValues}
        onShowModal={onShow}
      />
    </>
  );
}

const DriverLogin = () => {
  // const { watch, setValue } = useFormContext();
  // const name = watch("name");
  // const lastName = watch("lastName");
  //
  // useEffect(() => {
  //   if (!name || !lastName) {
  //     return;
  //   }
  //
  //   const login = `${name[0]}${lastName}`;
  //   setValue("login", login);
  //
  // }, [name, lastName]);

  return <FormControl label="Login" name="login" className="login-input"/>;
};