import { useDispatch, useSelector } from "react-redux";
import { vehiclesActions } from "_store/vehicles.slice";
import { VehicleForm } from "_components/Vehicles/VehicleForm";

export function CreateVehicle() {
  const dispatch = useDispatch();
  const { vehiclesPaging } = useSelector((x) => x.vehicles);

  async function onSubmit(data) {
    let { hasCurtain, hasMovingFloor, ...payload } = data;
    const features = [];
    if (hasCurtain) {
      features.push("Curtains");
    }
    if (hasMovingFloor) {
      features.push("MovingFloor");
    }
    payload = { ...payload, features, isActive: true };
    const response = await dispatch(vehiclesActions.create(payload));
    if (!response?.error) {
      const pageNumber = vehiclesPaging?.pageNumber ?? 1;
      dispatch(vehiclesActions.getAll({ pageNumber }));
    }

    return response;
  }

  return <VehicleForm onSubmit={onSubmit}></VehicleForm>;
}
