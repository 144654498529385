import { Form } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CheckListBoxItem } from "./CheckListBoxItem";
import { HorizontalView } from "_components/Abstractions/HorizontalView";
import { ControlLabel } from "../Abstractions/ControlLabel";

export function CheckListBox({ label, name, items, required = true }) {
  const { getFieldState, watch } = useFormContext();
  const fieldState = getFieldState(name);
  const { replace } = useFieldArray({
    name: name,
  });
  const watchFields = watch(name);
  const formattedLabel = <ControlLabel label={label} required={required}/>

  const onSelect = (id) => {
    const updatedFields = watchFields || [];
    updatedFields.push(id);
    replace(updatedFields);
  };

  const onUnselect = (id) => {
    const updatedFields = (watchFields || []).filter((item) => item !== id);
    replace(updatedFields);
  };

  return (
    <>
      <div
        className={`form-control domain-control mb-3 ${fieldState?.error ? "is-invalid" : ""}`}
      >
        <Form.Label className="" style={{ color: "gray", fontSize: "smaller"}}>{formattedLabel}</Form.Label>
        <div className="d-flex gap-3">
          {Object.entries(items).map(([key, value]) => (
            <CheckListBoxItem
              key={key}
              id={key}
              onSelect={onSelect}
              onUnselect={onUnselect}
              isInvalid={fieldState?.error}
              checked={watchFields?.includes(key) || false}
            >
              {value}
            </CheckListBoxItem>
          ))}
        </div>
        <Form.Control.Feedback type="invalid">
          {fieldState?.error?.message}
        </Form.Control.Feedback>
        <div className="text-danger domain-control">{fieldState?.error?.message}</div>
      </div>
    </>
  );
}
