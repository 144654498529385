import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper, history } from "_helpers";
import { defaultPageNumber, defaultPageSize, getDictionaryQuery } from "_store";
import { StringExtensions } from "../_helpers/extensions";

// create slice

const name = "contractors";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const contractorsActions = { ...slice.actions, ...extraActions };
export const contractorsReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    contractors: {},
    contractorsSorting: { value: "name", type: "asc" },
    contractorData: null,
    contractorsPaging: null,
    contractorWastes: null
  };
}

function createReducers() {
  return { setSorting };

  function setSorting(state, action) {
    state.contractorsSorting = action.payload;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/contractors`;
  const ratePlanBaseUrl = `${process.env.REACT_APP_API_URL}/rate-plan`;

  return {
    getAll: getAll(),
    getExtendedData: getExtendedData(),
    search: search(),
    searchAddresses: searchAddresses(),
    getById: getById(),
    create: create(),
    update: update(),
    changeStatus: changeStatus(),
    getIntermediaries: getIntermediaries(),
    getWastes: getWastes(),
    upsertRatePlan: upsertRatePlan(),
    getRatePlans: getRatePlans(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async (payload) => await fetchWrapper.get(getDictionaryQuery(baseUrl, payload))
    );
  }

  function getExtendedData() {
    return createAsyncThunk(
      `${name}/getExtendedData`,
      async ({ contractorId }) =>
        await fetchWrapper.get(
          `${baseUrl}/${contractorId}/extended-data`
        )
    );
  }

  function search() {
    return createAsyncThunk(
      `${name}/search`,
      async ({ query }) => {
        const { pageNumber, pageSize, filter } = query;
        const payload = { pageNumber, pageSize, ...filter };
        const queryString = StringExtensions.createQueryStringFromObject(payload);
        return await fetchWrapper.get(`${baseUrl}/search${queryString}`);
      }
    );
  }

  function searchAddresses() {
    return createAsyncThunk(
      `${name}/searchAddresses`,
      async ({ query }) =>
        await fetchWrapper.post(`${baseUrl}/addresses/search`, query)
    );
  }

  function getById() {
    return createAsyncThunk(
      `${name}/getById`,
      async ({ id }) =>
        await fetchWrapper.get(
          `${baseUrl}/${id}`
        )
    );
  }

  function create() {
    return createAsyncThunk(`${name}/create`, async (payload) => {
      return await fetchWrapper.post(`${baseUrl}`, { details: payload });
    });
  }

  function changeStatus() {
    return createAsyncThunk(
      `${name}/changeStatus`,
      async ({ contractorId: id, isActive }) => {
        const payload = {
          id,
          isActive,
        };
        return await fetchWrapper.patch(
          `${baseUrl}/${id}/change-status`,
          payload
        );
      }
    );
  }

  function update() {
    return createAsyncThunk(`${name}/update`, async ({ id, payload }) => {
      return await fetchWrapper.patch(`${baseUrl}/${id}`, {
        id,
        ...payload,
      });
    });
  }

  function getIntermediaries() {
    return createAsyncThunk(
      `${name}/getIntermediaries`,
      async ({ searchQuery, pageNumber, pageSize }) =>
        await fetchWrapper.get(
          `${baseUrl}/intermediaries?pageNumber=${pageNumber ?? defaultPageNumber}&pageSize=${
            pageSize ?? defaultPageSize
          }&searchQuery=${searchQuery ?? ""}`
        )
    );
  }

  function getWastes() {
    return createAsyncThunk(`${name}/getContractorWastes`, async ({ id }) => {
      return await fetchWrapper.get(`${baseUrl}/${id}/wastes`);
    });
  }

  function upsertRatePlan() {
    return createAsyncThunk(`${name}/upsertRatePlan`, async ({ payload }) => {
      return await fetchWrapper.patch(ratePlanBaseUrl, payload);
    });
  }

  function getRatePlans() {
    return createAsyncThunk(`${name}/getRatePlans`, async ({ id }) => {
      return await fetchWrapper.get(`${ratePlanBaseUrl}/${id}`);
    });
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...getById(),
    ...changeStatus(),
    // ...update(),
    ...getWastes(),
    ...getRatePlans()
  };

  function getById() {
    var { pending, fulfilled, rejected } = extraActions.getById;
    return {
      [pending]: (state) => {
        state.contractorData = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.contractorData = action.payload;
      },
      [rejected]: (state, action) => {
        state.contractorData = { error: action.error };
      },
    };
  }

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.contractors = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.contractors = action.payload.items;
        state.contractorsPaging = action.payload.paging;
      },
      [rejected]: (state, action) => {
        state.contractors = { error: action.error };
      },
    };
  }

  function changeStatus() {
    var { fulfilled } = extraActions.changeStatus;
    return {
      [fulfilled]: (state, action) => {
        const { contractorId, isActive } = action.meta.arg;
        if(contractorId === state.contractorData.id) {
          state.contractorData.isActive = isActive;
          return;
        }

        const item = state.contractors.find((x) => x.id === contractorId);
        item.isActive = isActive;
      },
    };
  }

  function getWastes() {
    var { pending, fulfilled, rejected } = extraActions.getWastes;
    return {
      [pending]: (state) => {
        state.contractorWastes = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.contractorWastes = action.payload;
      },
      [rejected]: (state, action) => {
        state.contractorWastes = { error: action.error };
      },
    };
  }

  function getRatePlans() {
    var { fulfilled } = extraActions.getRatePlans;
    return {
      [fulfilled]: (state, action) => {
        state.contractorData.ratePlans = action.payload;
      }
    };
  }
}
