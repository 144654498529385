import { PromptModal } from "_components/Abstractions/PromptModal";
import { useToast } from "_helpers/notifications/useToasts";
import { useDispatch } from "react-redux";
import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { ArrowRepeat } from "react-bootstrap-icons";

export function RefreshKpoCard({ orderId, kpoId, cardNumber, transactionType }) {
  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useToast();
  let title = `Czy odświeżyć kartę KPO`;
  if (cardNumber !== "") {
    title += " " + cardNumber;
  }

  title += " ?";

  async function onSubmit(promptResult) {
    if (promptResult !== "Yes") {
      return;
    }

    const response = await dispatch(ordersActions.refreshKpoCard({ orderId, kpoId, transactionType }));

    if (!response.error) {
      dispatch(orderViewsActions.getBdoInfoById({ id: orderId }));

      showSuccessMessage("Odświeżono kartę KPO");
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText={<ArrowRepeat/>}
        btnVariant="fiolet"
        title={title}
        type="YesNo"
        onSubmit={onSubmit}
        btnSize="sm"
      />
    </>
  );
}
