import { BadgeDomain } from "../../Abstractions/BadgeDomain";
import { WASTE_EXCHANGE_TYPE, WasteExchangeBadges, WasteExchangeType } from "../../../domain/WasteExchangeType";

export function WasteExchangesView({ isLoading, isContainersExchange, wastesExchanges, horizontalView = false }) {
  const isSale = !isLoading && !isContainersExchange;

  if (!wastesExchanges?.some(x => x.wastes.length !== 0)) {
    return <></>;
  }

  return wastesExchanges?.map((x, idx) => <div key={idx} className={`small upper ${horizontalView ? "d-flex gap-1" : ""}`}>
    {!isSale && <div><WasteExchangeBadge isContainer={x.isContainer}/>{x.isContainer && x.containerName}</div>}
    {x.wastes.map((w, idx) => <BadgeDomain key={idx} size="xx-small">{w.displayDescription}</BadgeDomain>)}
    {!horizontalView && <hr className="py-0 my-1"/>}
  </div>);
}

const WasteExchangeBadge = ({ isContainer }) => {
  const type = isContainer ? WASTE_EXCHANGE_TYPE.CONTAINER_EXCHANGE : WASTE_EXCHANGE_TYPE.LOADING;

  return <BadgeDomain size="xx-small" bg={WasteExchangeBadges[type]}>
      {WasteExchangeType[type]}
    </BadgeDomain>
}