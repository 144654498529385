import { FormControl } from "../../_components/Forms/FormControl";
import { HorizontalView } from "../../_components/Abstractions/HorizontalView";
import { Container } from "react-bootstrap";
import { FormDomain } from "../../_components/Forms/FormDomain";
import { useDispatch, useSelector } from "react-redux";
import { dateToString } from "../../domain/Configuration";
import { SubmitButton } from "../../_components/SubmitButton";
import { useEffect, useRef } from "react";
import { reportsActions } from "../../_store/reports.slice";
import { ReportsDashboard } from "./ReportsDashboard";
import * as Yup from "yup";
import { useReactToPrint } from "react-to-print";
import { DomainButton } from "../../_components/Abstractions/DomainButton";
import { Printer } from "react-bootstrap-icons";
import s from "./style.module.css"

export function GenerateOrderBaseReport({ reportTitle, reportType, reportAction, customControls, renderData, additionalValidationSchema, overrideDefaultValues, isPrintable = true, renderDefaultControls = true }) {
  const dispatch = useDispatch();
  const { reports } = useSelector(x => x.reports);
  const report = reports[reportType];
  const formRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => formRef.current
  });

  const formControls = <>
    <h3>{reportTitle}</h3>
    {renderDefaultControls ? <HorizontalView columnsSize={[3, 3, 6]}>
      <FormControl name="dateFrom" label="Dzień od" inputType="date"/>
      <FormControl name="dateTo" label="Dzień do" inputType="date"/>
      {customControls}
    </HorizontalView> : customControls}
    <div className={`${s.printHide} d-flex gap-2 mb-2`}>
      <SubmitButton>Generuj raport</SubmitButton>
      {isPrintable && <DomainButton variant="success" onClick={handlePrint}>Drukuj <Printer/></DomainButton>}
    </div>
    {renderData(report)}
  </>;
  const today = dateToString(new Date().toJSON());
  const validationSchema = Yup.object().shape({
    dateFrom: Yup.string().required("Data od jest wymagana"),
    dateTo: Yup.string().required("Data od jest wymagana")
  }).concat(additionalValidationSchema);
  const defaultValues = { dateFrom: today, dateTo: today, ...overrideDefaultValues };

  useEffect(() => {
    dispatch(reportsActions.clear({ reportType }));
  }, []);

  async function onSubmit(payload) {
    return await dispatch(reportAction({ payload }));
  }

  const formData = {
    validationSchema,
    defaultValues,
    formControls,
    isModal: false,
    successMessage: "Wygenerowano raport",
    addSubmitFooter: false,
    customSubmitBtn: true,
    submitBtnName: "Generuj raport",
    onSubmit
  };

  return <Container>
    <ReportsDashboard isDirect={false}/>
    <div ref={formRef} className={s.pageBreak}>
      <FormDomain {...formData}/>
    </div>
  </Container>;
}