import * as Yup from "yup";
import { FormControl } from "../../../Forms/FormControl";
import { FormModal } from "../../../Forms/FormModal";
import { KPO_STATUS } from "../../../../domain/KpoCardStatus";
import { ListGroup } from "react-bootstrap";
import { WasteMassValidator } from "../../../../domain/Orders/CreatePurchaseOrderValidator";
import { KpoCardNumberItem } from "./KpoCardNumberList";
import { GetDefaultRemarks, KpoRemarksControl } from "./KpoRemarksControl";
import { useFormContext } from "react-hook-form";
import { ValidateBdoAction } from "./BdoActionValidator";
import { NumericControl } from "../../../Forms/NumericControl";

export function KpoReceiveConfirmation({ kpoCards, onSubmit, name, promptTitle, performAll, intermediaryName, intermediaryBdoRegistrationNumber }) {
  const kpoItemSchema = Yup.object().shape({
    cardNumber: Yup.string().required("Numer karty jest wymagany"),
    correctedWasteMass: WasteMassValidator.validator
  });
  const { validate } = ValidateBdoAction();
  const validationSchema = Yup.object().shape({ kpoItems: Yup.array().of(kpoItemSchema) });
  const filteredKpoCards = kpoCards; //.filter(x => x.status === KPO_STATUS.CONFIRMATION_GENERATED);
  const intermediary = intermediaryName ? `${intermediaryBdoRegistrationNumber} ${intermediaryName}` : null;
  const values = filteredKpoCards.map(x => {
    return (
      {
        kpoId: x.kpoId,
        cardNumber: x.cardNumber,
        correctedWasteMass: x.wasteMass.toFixed(4),
        remarks: GetDefaultRemarks(x.wasteMass, intermediary, true),
        isSelected: true
      });
  });

  const defaultValues = { kpoItems: values, intermediary };
  const formControls =
    <div>
      {values.length === 0 && <ListGroup.Item>Brak kart KPO do wygenerowania potwierdzenia</ListGroup.Item>}
      {intermediary && <FormControl name="intermediary" label="Pośrednik" readonly/>}
      {filteredKpoCards.map((x, idx) =>
        <BaseKpoForm key={idx} kpoItem={x} formControls={
        <>
          <NumericControl decimalPlaces={4} name={`kpoItems[${idx}].correctedWasteMass`} label="Masa przejętych odpadów [Mg]" readonly/>
          <KpoRemarksControl
            parentName={`kpoItems[${idx}]`}
            intermediary={intermediary}
            includeIntermediary/>
        </>}
        parentName={`kpoItems[${idx}]`}
        />)}
    </div>;

  const handleOnSubmit = async(payload) => {
    const kpoItems = payload.kpoItems.filter(x => x.isSelected).map(x => ({ kpoId: x.kpoId, correctedWasteMass: x.correctedWasteMass, remarks: x.remarks }));
    validate(kpoItems);

    await onSubmit({ kpoItems });
  };

  return <FormModal
    title={promptTitle}
    formControls={formControls}
    defaultValues={defaultValues}
    onSubmit={handleOnSubmit}
    buttonText={name}
    validationSchema={validationSchema}
    isButtonTrigger={performAll}
    skipToast
    sizeModal="md"
    submitBtnText={name}/>;
}

export function BaseKpoForm({ parentName, kpoItem, formControls, selectable }) {
  const { watch } = useFormContext();
  const isSelected = watch(`${parentName}.isSelected`);

  return <>
    <ListGroup className="mb-2">
      <KpoCardNumberItem parentName={parentName} kpoItem={kpoItem} selectable={selectable} />
    </ListGroup>
    {isSelected && formControls}
  </>
}