import * as Yup from "yup";
import moment from "moment";
import { Dropdown } from "../../../_components/Abstractions/Dropdown";
import { FormControl } from "../../../_components/Forms/FormControl";
import { HorizontalView } from "../../../_components/Abstractions/HorizontalView";
import { IntegerControl } from "../../../_components/Forms/IntegerControl";
import { useFormContext } from "react-hook-form";
import { CardView } from "../../../_components/Abstractions/CardView";
import { CheckListBox } from "../../../_components/CheckListBox/CheckListBox";
import { BusinessWeekdays } from "../../../domain/Weekdays";
import { BaseOrderForm } from "../../../_components/Orders/MainForms/BaseOrderForm";
import { ordersActions } from "../../../_store/order.slice";
import { mapToCreateSeriesRequest } from "../../../_services/Orders/orderMapper";
import { frequencyDescriptionProvider } from "./frequencyDescriptionProvider";
import { useNavigate } from "react-router-dom";

export function CreateOrderSeries() {
  const navigate = useNavigate();
  const defaultValues = {
    frequencyInterval: "1",
    frequencyType: ORDER_SERIES_TYPES.DAILY,
    isOrderSeriesDefinition: true
  }

  const validationSchema = Yup.object().shape({
    dateStart: Yup.string()
    .typeError("Data początkowa jest wymagana")
    .test("plannedLoadingDateForSeries", "Data początkowa powinna być taka sama jak data pierwszego załadunku", function(value) {
      const { transportDetails: { plannedLoadingDate } } = this.parent;
      if (!value || !plannedLoadingDate) {
        return true;
      }
      const seriesDateStart = moment(value).startOf("day");
      const loadingDate = moment(plannedLoadingDate).startOf("day");
      return seriesDateStart.isSame(loadingDate);
    }),
    dateEnd: Yup.string()
    .nullable()
    .test("DateEndForSeries", "Data końcowa nie może być wcześniejsza od daty początkowej", function(value) {
      const { dateStart } = this.parent;
      if (!value || !dateStart) {
        return true;
      }
      const seriesDateStart = moment(dateStart).startOf("day");
      const seriesDateEnd = moment(value).startOf("day");
      return seriesDateStart.isSameOrBefore(seriesDateEnd);
    })
  });

  const additionalFormWrapper = <>
    <OrderSeriesForm/>
  </>

  return <>
    <BaseOrderForm
      transactionTypeParam="Purchase"
      validationSchema={validationSchema}
      submitAction={ordersActions.createSeries}
      requestMapper={mapToCreateSeriesRequest}
      setInternalTransportType={true}
      defaultValues={defaultValues}
      redirect={({ orderId: orderSeriesId }) => navigate(`/orders/series/${orderSeriesId}`)}
      // redirect={({ orderId: orderSeriesId }) => {}}
      additionalFormWrapper={additionalFormWrapper}
      isOrderSeries
    />
  </>
}

export const ORDER_SERIES_TYPES = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly"
};

export const OrderSeriesTypes = {
  "Daily": "Codzienny",
  "Weekly": "Tygodniowo",
  "Monthly": "Miesięcznie"
};

export function OrderSeriesForm() {
  const { watch } = useFormContext();
  const frequencyType = watch("frequencyType");
  const isWeeklySeriesType = frequencyType === ORDER_SERIES_TYPES.WEEKLY;

  return <CardView headerTypo="h4" headerText="Dane zlecenia cyklicznego" className="mb-2">
    <FormControl name="name" label="Nazwa cyklicznego zlecenia"/>
    <HorizontalView>
      <Dropdown name="frequencyType" label="Typ częstotliwości" items={OrderSeriesTypes}/>
      <IntegerControl name="frequencyInterval" label="Częstotliwość"/>
      <FormControl name="dateStart" label="Data początkowa" inputType="date"/>
      <FormControl name="dateEnd" label="Data końcowa" inputType="date" required={false}/>
    </HorizontalView>
    {isWeeklySeriesType && <CheckListBox name="weeklyData.weekDays" label="Dni tygodnia" items={BusinessWeekdays} />}
    <OrderSeriesInfo />
  </CardView>
}

export function OrderSeriesInfo() {
  const { watch } = useFormContext();
  const frequencyType = watch("frequencyType");
  const frequencyInterval = watch("frequencyInterval");
  const weeklyData = watch("weeklyData");
  const dateStart = watch("dateStart");
  const dateEnd = watch("dateEnd");
  const seriesData = { frequencyType, frequencyInterval, weeklyData, dateStart, dateEnd };
  const frequencyDescription = frequencyDescriptionProvider.getFrequencyDescription(seriesData);

  return <>
    <CardView headerText="Opis częstotliwości">{frequencyDescription}</CardView>
  </>;
}