import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { orderViewsActions } from "../../_store/orderView.slice";

export function useGetOrderSeriesDetails({ id }) {
  const dispatch = useDispatch();
  const { orderSeriesDetails: item } = useSelector(x => x.orderViews);
  const getOrderById = useCallback(async({ id, token }) => {
    dispatch(orderViewsActions.getSeriesById({ id, token }));
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const token = abortController.signal;

    getOrderById({ id, token });
    return () => {
      if (process.env.REACT_APP_ENVIROMENT === "Development") { return; }
      abortController.abort();
    };
  }, [id, getOrderById]);

  return { item, getOrderById };
}