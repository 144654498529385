import { BadgeDomain } from "_components/Abstractions/BadgeDomain";
import { StatusBadge } from "_components/Abstractions/StatusBadge";
import { ContractorType } from "_components/Contractors/ContractorType";
import { VehicleTypeBadge } from "_components/Vehicles/VehicleTypeBadge";
import { OriginatorTypes } from "domain/ContractorType";
import { Card, ListGroup } from "react-bootstrap";
import { CardView } from "../../../../_components/Abstractions/CardView";

export function ContractorMainDetails({ contractorData }) {
  return (
    <CardView headerText="Szczegóły kontrahenta" headerTypo="h4">
        <Card.Title className="upper">{contractorData.name}</Card.Title>
        <Card.Title>
          {contractorData.isBdoLinked ? (
            <BadgeDomain>Połączony z BDO</BadgeDomain>
          ) : (
            ""
          )}
        </Card.Title>
        <ListGroup variant="flush" className="upper">
          <ListGroup.Item>
            <div className="d-flex gap-4">
              <div>
                Nazwa z programu magazynowego: <strong> {contractorData.externalName}</strong>
              </div>
              <div>
                NIP: <strong>{contractorData.taxId}</strong>
              </div>
              <div>
                Adres centrali: <strong>{contractorData.centralAddressRaw}</strong>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="d-flex gap-2">
              <div>
                Rodzaje operacji: <ContractorType types={contractorData.contractorTypes}/>
              </div>
              <div>
                Sposób transportu: {contractorData.pickupType?.map((t) => (
                <VehicleTypeBadge key={t} type={t}/>
              ))}
              </div>
              <div>
                Status: <StatusBadge isActive={contractorData?.isActive}/>
              </div>
              <div>
                Typ klienta: <strong>{OriginatorTypes[contractorData?.originatorType]}</strong>
              </div>
              {contractorData?.salesRepresentative && <div>
                PH: <strong>{contractorData?.salesRepresentative?.name}</strong>
                {contractorData?.salesRepresentative?.isDefault ? " (domyślnie)" : " (domyślnie bez)"}
              </div>}
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            Uwagi: <strong>{contractorData.remarks}</strong>
          </ListGroup.Item>
        </ListGroup>
    </CardView>);
}
