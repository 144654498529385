import { useSelector } from "react-redux";
import { useCalendarView } from "../OrderCalendar/useCalendarView";
import { OrderCalendarFilterBox } from "../OrderCalendar/OrderCalendarFilterBasic";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { Card } from "react-bootstrap";
import { BadgeDomain } from "../../Abstractions/BadgeDomain";
import { VehicleTypeBadge } from "../../Vehicles/VehicleTypeBadge";
import { TransportTypeBadge } from "../Components/TransportTypeBadge";
import { CalendarDay } from "../OrderCalendar/CalendarDay";

export function OrderVehicleView() {
  const { vehicleView, vehicleViewDates } = useSelector((x) => x.orderViews);
  const { refreshCalendar, currentFilters, handleCalendarChange, refreshFilters, setCurrentFilters } = useCalendarView({ calendarViewDates: vehicleViewDates, orderViewsAction: orderViewsActions.getVehicleView });

  const { weekDates, currentDate } = vehicleViewDates;
  return <div className="d-flex flex-column justify-content-center">
    <h2>Widok samochodowy ({weekDates?.[0].date} - {weekDates?.[weekDates.length - 1].date})</h2>
    <OrderCalendarFilterBox
      currentDate={currentDate}
      currentFilters={currentFilters}
      handleCalendarChange={handleCalendarChange}
      handleFilterChange={refreshFilters}
      refreshCalendar={refreshCalendar}
      setCurrentFilters={setCurrentFilters}
    />
    <VehicleCalendarItems vehicleItems={vehicleView}/>
  </div>;
}

export function VehicleCalendarItems({ vehicleItems }) {
  return <div className="d-flex flex-column align-items-start justify-content-start gap-2 p-3">
    {vehicleItems?.loading && <div className="spinner-border"></div>}
    {Object.keys(vehicleItems)?.map((vehicleId) => {
      const { displayName, pickupType, items } = vehicleItems[vehicleId];
      return <Card key={vehicleId} className="w-100">
        <Card.Header as={"h5"} className="d-flex align-items-center flex-row gap-2 upper dark-grey white-text">
          <BadgeDomain size="small">{items?.length}</BadgeDomain>
          {displayName}
          {pickupType && <VehicleTypeBadge size="small" type={pickupType}/>}
          {<TransportTypeBadge size="small" type={vehicleItems[vehicleId]?.transportType}/>}
        </Card.Header>
        <div className={`d-flex flex-row gap-1 flex-wrap`}>
          {items?.map(x => <CalendarDay key={x.id} orderItem={x}/>)}
        </div>
      </Card>;
    })}
  </div>;
}