import { PromptModal } from "_components/Abstractions/PromptModal";
import { useToast } from "_helpers/notifications/useToasts";
import { useDispatch } from "react-redux";
import { orderFileDocumentsActions } from "../../../../../_store/orderFileDocuments.slice";
import { BiUnlink } from "react-icons/bi";
import { orderViewsActions } from "../../../../../_store/orderView.slice";

export function DeleteDocumentFileFromOrder({ orderId, file }) {
  const { id: fileId, documentName } = file;
  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useToast();

  async function onSubmit() {
    const response = await dispatch(orderFileDocumentsActions.deleteFile({ orderId, fileId }));

    if (!response.error) {
      await dispatch(orderViewsActions.getFileDocuments({ orderId }));

      showSuccessMessage("Usunięto dokument ze zlecenia");
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText={<BiUnlink/>}
        btnSize="sm"
        btnVariant="danger"
        title={`Czy usunąć dokument ${documentName} ze zlecenia?`}
        type="YesNo"
        onSubmit={onSubmit}
      />
    </>
  );
}

