import * as Yup from "yup";
import { FormControl } from "../../../Forms/FormControl";
import { FormModal } from "../../../Forms/FormModal";
import { KpoCardNumberList } from "./KpoCardNumberList";
import { stringToDateTimeForm } from "../../../../domain/Configuration";
import { KPO_STATUS } from "../../../../domain/KpoCardStatus";
import { RealTransportDateForm } from "./RealTransportDateForm";
import { ValidateBdoAction } from "./BdoActionValidator";

export function KpoGenerateConfirmation({ kpoCards, onSubmit, name, promptTitle, performAll, vehiclesBdoFormatted, submitBtnText }) {
  const { validate } = ValidateBdoAction();
  const today = new Date();
  let minimumDate = new Date();
  today.setMinutes(today.getMinutes() + 2);
  minimumDate.setMinutes(minimumDate.getMinutes() - 1);
  const kpoItemSchema = Yup.object().shape({
    vehicleRegNumber: Yup.string().required("Numer rejestracyjny pojazdów ze zlecenia jest wymagany").typeError("Numer rejestracyjny pojazdów ze zlecenia jest wymagany"),
    realTransportTime: Yup.date().nullable().required("Rzeczywisty czas rozpoczęcia transportu jest wymagany").min(minimumDate, "Data załadunku nie może być wcześniejsza niż dzisiejsza")
  });

  const filteredKpoCards = kpoCards.filter(x => x.status === KPO_STATUS.APPROVED || x.status === KPO_STATUS.REJECTED);
  const baseDefaultValues = { vehicleRegNumber: vehiclesBdoFormatted, realTransportTime: stringToDateTimeForm(today) };
  const defaultValues =  { ...baseDefaultValues, isSelected: true, kpoItems: filteredKpoCards.map(x => ({ ...x, isSelected: true })) };
  const formControls = <div>
    <KpoCardNumberList kpoCards={filteredKpoCards} performAll={performAll} selectable={filteredKpoCards.length > 1} />
    <FormControl name="vehicleRegNumber" label="Numer rejestracyjny pojazdów" readonly={true} />
    <RealTransportDateForm />
  </div>;

  const handleSubmit = async(payload) => {
    const kpoItems = payload.kpoItems.filter(x => x.isSelected).map((x) => ({
      kpoId: x.kpoId,
      vehicleRegNumber: payload.vehicleRegNumber,
      realTransportTime: payload.realTransportTime
    }));
    validate(kpoItems);

    return await onSubmit(kpoItems);
  };

  return <FormModal
    title={promptTitle}
    formControls={formControls}
    defaultValues={defaultValues}
    onSubmit={handleSubmit}
    buttonText={name}
    validationSchema={kpoItemSchema}
    isButtonTrigger={performAll}
    sizeModal="md"
    skipToast
    submitBtnText={submitBtnText}/>;
}