import { BadgeDomain } from "../../Abstractions/BadgeDomain";
import { TransportType, TransportTypeColor } from "../../../domain/TransportType";

export function TransportTypeBadge({ transportType, size }) {
  return (
    <>
      <BadgeDomain bg={TransportTypeColor[transportType]} size={size}>
        {TransportType[transportType]}
      </BadgeDomain>
    </>
  );
}