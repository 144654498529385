import { Button } from "react-bootstrap";

export function DomainButton({
  children,
  className,
  variant,
  onClick,
  disabled,
  type,
  btnSize,
  size = "small"
}) {
  return (
    <Button
      className={className}
      variant={variant ?? "outline-primary"}
      onClick={onClick}
      size={btnSize}
      disabled={disabled ?? false}
      type={type ?? "button"}
      style={{fontSize: size }}
    >
      {children}
    </Button>
  );
}
