import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

export function CheckListBoxItem({
  id,
  children,
  onSelect,
  onUnselect,
  checked = false,
  isInvalid = false,
}) {
  const [isSelected, setIsSelected] = useState(checked);

  const onClick = () => {
    setIsSelected(!isSelected);
    if (isSelected) {
      onUnselect(id);
    } else {
      onSelect(id);
    }
  };

  useEffect(() => {
    setIsSelected(checked);
  }, [checked]);

  return (
    <>
      <Form.Check
        isInvalid={isInvalid}
        type="checkbox"
        className="domain-control"
        onChange={onClick}
        label={children}
        id={id}
        checked={isSelected}/>
    </>
  );
}
