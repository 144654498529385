import { Dropdown } from "_components/Abstractions/Dropdown";
import { MultiSelectSearcher } from "_components/Abstractions/MultiSearcher/MultiSearcher";
import { contractorsActions } from "_store/contractors.slice";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";

export function IntermediariesForm({ name }) {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const { watch, getValues } = useFormContext();
  const intermediaries = watch("intermediaries");
  const defaultIntermediaryId = getValues("defaultIntermediaryId");
  const intermediariesDictionary = intermediaries
    ? Object.fromEntries(intermediaries?.map((x) => [x.intermediaryId, x.name]))
    : [];
  const dropdownNullDefaultValue = "Brak pośrednika";
  const [items, setItems] = useState([]);
  const onSearchChange = async (searchQuery) => {
    const params = { searchQuery, pageNumber: 1, pageSize: 10 };
    const { payload } = await dispatch(
      contractorsActions.getIntermediaries(params)
    );
    setItems(payload?.items);
  };
  useEffect(() => {
    if (!isFirstRender.current) {
      onSearchChange(null);
    } else {
      isFirstRender.current = false;
    }
  }, []); // eslint-disable-next-line/react-hooks/exhaustive-deps

  return (
    <div className="d-flex flex-column gap-1">
      <MultiSelectSearcher
        name={name}
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "intermediaryId", value: "name" }}
        placeholder="Wybierz pośredników"
        required={false}
      />
      <Dropdown
        label="Domyślny pośrednik"
        name="defaultIntermediaryId"
        items={intermediariesDictionary}
        defaultValue={dropdownNullDefaultValue}
        selectedValue={defaultIntermediaryId}
      />
    </div>
  );
}
