import { Link } from "react-router-dom";
import React from "react";

export function CardLinkBody({ link, className, children }) {
  return <Link
    to={link}
    className={className}
    style={{ textDecoration: "none", color: "black" }}>
    {children}
  </Link>;
}