import { useFieldArray, useFormContext } from "react-hook-form";
import { DomainButton } from "_components/Abstractions/DomainButton";
import { Card } from "react-bootstrap";
import { UsedContainerForm } from "./UsedContainerForm";

export function UsedContainersForm({ prefix }) {
  const baseFieldName = "usedContainers";
  const fieldName = prefix ? `${prefix}.${baseFieldName}` : baseFieldName;

  const { getFieldState } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: fieldName,
  });

  const fieldState = getFieldState(fieldName);

  const addItem = () => {
    append({
      totalCount: null,
      subContainers: []
    });
  };

  const removeItem = (index) => {
    remove(index);
  };

  const controls = (
    <div className={`form-control domain-control ${fieldState?.error ? "is-invalid" : ""}`}>
      {fields.map((address, index) => (
        <div key={address.id}>
          <Card.Header>{`${index + 1}. Kontener `}</Card.Header>
          <Card.Body>
            <DomainButton onClick={() => removeItem(index)} className="mb-2">
              Usuń kontener
            </DomainButton>
            <div className="domain-control error">{fieldState?.error?.message}</div>
            <UsedContainerForm name={`${fieldName}[${index}]`}/>
          </Card.Body>
        </div>
      ))}
      <Card.Footer>

        <DomainButton className="mt-1" onClick={addItem}>Dodaj kontener</DomainButton>
      </Card.Footer>
    </div>
  );

  return <>{controls}</>;
}
