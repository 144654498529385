import { useToast } from "../../../../_helpers/notifications/useToasts";

export const ValidateBdoAction = () => {
  const { showErrorMessage } = useToast();
  const validate = (kpoItems) => {
    if (kpoItems.length === 0) {
      const errorMessage = "Przynajmniej jedna karta musi zostać wybrana";
      showErrorMessage(errorMessage);
      throw new Error(errorMessage);
    }
  };

  return { validate };
};