import { SelectSearcher } from "_components/Abstractions/MultiSearcher/SelectSearcher";
import { useContractorAddressSearch } from "./AddressSearch";

export function AddressSelect({ label, name, addressesField, readonly, required = true }) {
  const { items, onSearchChange } = useContractorAddressSearch({ addressesField });

  return (
    <>
      <SelectSearcher
        name={name}
        label={label}
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "id", value: "name" }}
        placeholder="Wyszukaj adres po nazwie..."
        required={required}
        saveWholeObject
        readonly={readonly}
      />
    </>
  );
}
