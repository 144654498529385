import { useDispatch } from "react-redux";
import { useToast } from "../../../_helpers/notifications/useToasts";
import { PromptModal } from "../../../_components/Abstractions/PromptModal";
import { ordersActions } from "../../../_store/order.slice";
import { useNavigate } from "react-router-dom";

export function DeleteOrderSeries({ item }) {
  const { id: orderSeriesId, seriesDetails } = item;
  const { name } = seriesDetails || {};
  const buttonText = "Usuń zlecenie cykliczne"
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showSuccessMessage, showErrorMessage } = useToast();

  async function onSubmit(promptResult) {
    const response = await dispatch(
      ordersActions.deleteSeries({ orderSeriesId })
    );

    if (!response.error) {
      navigate("/orders/series-view");
      showSuccessMessage("Usunięto cykliczne zlecenie razem z zaplanowanymi zleceniami");
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText={buttonText}
        title={`Czy usunąć zlecenie cykliczne (${name?.toUpperCase() ?? "-"})?`}
        type="YesNo"
        onSubmit={onSubmit}
      />
    </>
  );
}
