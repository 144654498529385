import { useDispatch, useSelector } from "react-redux";
import { userActions } from "_store";
import { UserForm } from "_components/Accounts/UserForm";

export { CreateAccount };

function CreateAccount() {
  const dispatch = useDispatch();
  const { usersPaging } = useSelector((x) => x.users);

  async function onSubmit(payload) {
    const response = await dispatch(userActions.register(payload));
    if (!response?.error) {
      const pageNumber = usersPaging?.pageNumber ?? 1;
      dispatch(userActions.getAll({ pageNumber }));
    }

    return response;
  }

  return <UserForm onSubmit={onSubmit} />;
}
