import { useFieldArray, useFormContext } from "react-hook-form";
import { DomainButton } from "_components/Abstractions/DomainButton";
import { Card } from "react-bootstrap";
import { AddressForm } from "./AddressForm";

export function AddressesForm({ contractorTypes }) {
  const { formState } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    name: "addresses",
  });
  const { errors } = formState;

  const addAddress = () => {
    append({
      name: "",
      postalCode: "",
      city: "",
      streetName: "",
      streetNumber: "",
      apartment: "",
      remarks: "",
      isDefault: false,
      isCentral: false,
      isPickup: true,
    });
  };

  const removeAddress = (index) => {
    update(index, {});
    remove(index);
  };

  return (
    <>
      {errors["addresses"]?.message}
      {fields.map((address, index) => (
        <div key={address.id}>
          <Card.Header as="h5">{`${index + 1}. Adres dostawy `}</Card.Header>
          <Card.Body>
            <DomainButton onClick={() => removeAddress(index)} className="mb-2">
              Usuń adres
            </DomainButton>
            <AddressForm addressField={`addresses[${index}]`} contractorTypes={contractorTypes} />
          </Card.Body>
        </div>
      ))}
      <Card.Footer>
        <DomainButton onClick={addAddress}>Dodaj adres dostawy</DomainButton>
        {/* <DomainButton onClick={() => console.log(getValues())}>
          Form
        </DomainButton> */}
      </Card.Footer>
    </>
  );
}
