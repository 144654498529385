import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";
import { defaultPageNumber, defaultPageSize, getDictionaryQuery } from "_store";

// create slice

const name = "wastes";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const wastesActions = { ...slice.actions, ...extraActions };
export const wastesReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    wastes: {},
    wastesSorting: { value: "internalName", type: "asc" },
    wastesPaging: null,
  };
}

function createReducers() {
  return { setSorting };

  function setSorting(state, action) {
    state.wastesSorting = action.payload;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/wastes`;

  return {
    getAll: getAll(),
    search: search(),
    searchBdo: searchBdo(),
    create: create(),
    update: update(),
    remove: remove(),
    searchGroupBdo: searchGroupBdo()
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async(payload) =>
        await fetchWrapper.get(getDictionaryQuery(baseUrl, payload)));
  }

  function search() {
    return createAsyncThunk(
      `${name}/search`,
      async (payload) => await fetchWrapper.post(`${baseUrl}/search`, payload));
  }

  function searchBdo() {
    return createAsyncThunk(
      `${name}/searchBdo`,
      async ({ pageNumber, pageSize, searchTerm }) =>
        await fetchWrapper.get(
          `${baseUrl}/bdo?pageNumber=${pageNumber ?? defaultPageNumber}&pageSize=${
            pageSize ?? defaultPageSize
          }&searchQuery=${searchTerm ?? ""}`
        )
    );
  }

  function searchGroupBdo() {
    return createAsyncThunk(
      `${name}/searchGroupBdo`,
      async ({ searchTerm }) =>
        await fetchWrapper.get(`${baseUrl}/bdo/group?&searchQuery=${searchTerm ?? ""}`)
    );
  }

  function create() {
    return createAsyncThunk(`${name}/create`, async (payload) => {
      return await fetchWrapper.post(`${baseUrl}`, payload);
    });
  }

  function update() {
    return createAsyncThunk(
      `${name}/update`,
      async ({ wasteId, payload }) => {
        return await fetchWrapper.patch(`${baseUrl}/${wasteId}`, payload);
      }
    );
  }
  
  function remove() {
    return createAsyncThunk(
      `${name}/remove`,
      async ({ wasteId }) => {
        return await fetchWrapper.delete(`${baseUrl}/${wasteId}`);
      }
    );
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...update(),
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.wastes = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.wastes = action.payload.items;
        state.wastesPaging = action.payload.paging;
      },
      [rejected]: (state, action) => {
        state.wastesResponse = { error: action.error };
      },
    };
  }

  function update() {
    var { fulfilled } = extraActions.update;
    return {
      [fulfilled]: (state, action) => {
        const { wasteId, payload } = action.meta.arg;
        const waste = state.wastes.find((x) => x.wasteId === wasteId);
        Object.assign(waste, payload);
      },
    };
  }
}
