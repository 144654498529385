import React, { createContext, useContext, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

// Tworzymy kontekst dla hooka do zarządzania tostami
const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const mapHeader = {
    primary: "Operacja się powiodła...",
    danger: "Wystąpił błąd...",
    info: "Raport zmian BDO...",
  };

  const showSuccessMessage = (message) => showToastMessage(message, "primary", 3000);
  const showErrorMessage = (message) => showToastMessage(message, "danger", 5000);
  const showInfoMessage = (message) => showToastMessage(message, "info", 10000);

  const showToastMessage = (message, type = "success", delay = 3000) => {
    const newToast = {
      id: Date.now(),
      message,
      type,
      delay,
      header: mapHeader[type],
    };

    setToasts((prevToasts) => [...prevToasts.filter((x => x.message !== message)), newToast]);
  };

  const closeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider
      value={{ showSuccessMessage, showErrorMessage, showInfoMessage, closeToast }}
    >
      {children}
      <ToastContainer position="top-end" className="p-3" style={{ fontSize: "xx-small", position: "fixed", zIndex: 2000 }}>
        {toasts.map((toast) => (
          <Toast
            animation
            key={toast.id}
            onClose={() => closeToast(toast.id)}
            delay={toast.delay}
            autohide
            bg={toast.type}
          >
            <Toast.Header>
              <strong className="me-auto small">{toast.header}</strong>
            </Toast.Header>
            <Toast.Body style={{ whiteSpace: "pre-line" }} className="text-white small">
              {toast.message}
            </Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
