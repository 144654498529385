import { OrderStatusBadge } from "../Components/OrderStatusBadge";
import { stringToDate } from "../../../domain/Configuration";
import { Card } from "react-bootstrap";
import { TableDomain } from "../../Abstractions/TableDomain";
import { ORDER_STATUS } from "../../../domain/Orders/OrderStatus";
import { KpoBdoStatusBadge } from "../../Bdo/Kpo/KpoBdoStatusBadge";

export function OrderHistoryView({ history, cancellationReason }) {
  const getChangeDescription = (logItem) => {
    if (logItem?.logType === "StatusUpdated") {
      const change = logItem.changes[0];
      let remarks = "";
      if (change.newValue === ORDER_STATUS.CANCELLED) {
        remarks = "Powód: " + cancellationReason;
      }

      return <>Zmiana z { <OrderStatusBadge status={change.oldValue} />} na {<OrderStatusBadge status={change.newValue} />} {remarks}</>
    } else if (logItem?.logType === "KpoCardStatusChanged") {
      return logItem?.changes?.map((x, idx) => {
          return <div key={idx}>Zmiana statusu <b>{x.propertyName}</b> z <b><KpoBdoStatusBadge status={x.oldValue}/></b> na <b><b><KpoBdoStatusBadge status={x.newValue}/></b></b></div>;
      });
    } else if (logItem?.logType === "KpoCardAttachedChanged") {
      return logItem?.changes?.map((x, idx) => {
        return <div key={idx}>{x.newValue} <b>{x.propertyName}</b></div>;
      });
    } else {
      return logItem?.changes?.map((x, idx) => {
        const isCustomChange = x.propertyName.startsWith("Usunięto") ||
          x.propertyName.startsWith("Dodano") ||
          x.propertyName.startsWith("Zmieniono");

        if(isCustomChange) {
          if(!x.newValue){
            return <div key={idx}>{x.propertyName} <b>{x.oldValue ?? "Brak"}</b></div>;
          }
          if (x.oldValue) {
            return <div key={idx}>{x.propertyName} z <b>{x.oldValue ?? "Brak"}</b> na <b>{x.newValue ?? "Brak"}</b></div>;
          }
          return <div key={idx}>{x.propertyName} <b>{x.newValue ?? "Brak"}</b></div>;
        }

        if (x.oldValue) {
          return <div key={idx}>Zmiana pola <b>{x.propertyName}</b> z <b>{x.oldValue ?? "Brak"}</b> na <b>{x.newValue ?? "Brak"}</b></div>;
        }
        return <div key={idx}>Ustawiono pole <b>{x.propertyName}</b> na <b>{x.newValue ?? "Brak"}</b></div>;
      });
    }
  }

  const columns = [
    { name: "createdAt", headerName: "Data", map: (x) => stringToDate(x.createdAt) },
    { name: "userLogin", headerName: "Użytkownik" },
    { name: "description", headerName: "Zdarzenie" },
    { name: "changes", headerName: "Opis", map: (x) =>  getChangeDescription(x) }
  ];
  const isSelectedRow = (x) => x.userLogin === "automat"; // TODO change it to userRole

  return <Card className="mt-1">
    <Card.Header>Historia zlecenia</Card.Header>
    {history && <TableDomain keyColumn="id" bordered columns={columns} items={history} selectable={false} isSelectedRow={isSelectedRow}/>}
  </Card>
}