import { ordersActions } from "../../../_store/order.slice";
import { OrderWorkFlowAction } from "./OrderWorkFlowAction";
import { orderViewsActions } from "../../../_store/orderView.slice";

export function RecoverOrder({ orderId, orderNumber }) {
  const successMessage = "Zlecenie zostało przywrócone";
  const buttonText = "Przywróć zlecenie";
  const title = `Czy przywrócić zlecenie ${orderNumber}?`;

  const props = {
    orderId: orderId,
    orderAction: ordersActions.recoverOrder,
    orderViewAction: orderViewsActions.recover,
    successMessage,
    buttonText,
    title,
    skipRefreshViews: false
  };

  return <OrderWorkFlowAction {...props} />;
}