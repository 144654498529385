import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { history } from "_helpers";
import { Configuration } from "pages/configuration/Configuration";
import { Login } from "login";
import { CreateAccount } from "useCases/accounts/CreateAccount";
import { NavMenu, PrivateRoute } from "_components";
import { ContractorDetails } from "useCases/contractors/ContractorDetails";
import { CreateContractor } from "useCases/contractors/CreateContractor";
import { UpdateContractor } from "useCases/contractors/UpdateContractor";
import { PurchaseOrderForm } from "_components/Orders/MainForms/PurchaseOrderForm";
import { TransportOrderForm } from "_components/Orders/MainForms/TransportOrderForm";
import { SaleOrderForm } from "_components/Orders/MainForms/SaleOrderForm";
import { KeoGenerateReport } from "./pages/keo/KeoGenerateReport";
import { KpoBdoSearcherPage } from "./pages/bdo/KpoBdoSearcherPage";
import React from "react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { GetOrders } from "./useCases/orders/getOrders/GetOrders";
import { OrderListView } from "./useCases/orders/getOrderListView/OrderListView";
import { OrderCalendarView } from "./_components/OrderViews/OrderCalendar/OrderCalendarView";
import { OrderDetailsView } from "./_components/OrderViews/Details/OrderDetailsView";
import { OrderVehicleView } from "./_components/OrderViews/ListViews/OrderVehicleView";
import { OrderDriverView } from "./_components/OrderViews/ListViews/OrderDriverView";
import { OrderShippingCalendarView } from "./_components/OrderViews/ShippingCalendar/OrderShippingCalendarView";
import { MainDashboard } from "./_components/Dashboard/MainDashboard";
import { GenerateOrderStatusReport } from "./useCases/reports/GenerateOrderStatusReport";
import { ReportsDashboard } from "./useCases/reports/ReportsDashboard";
import { GenerateOrderDriversReport } from "./useCases/reports/GenerateOrderDriversReport";
import { GenerateOrderContractorsReport } from "./useCases/reports/GenerateOrderContractorsReport";
import { GenerateOrderUserReport } from "./useCases/reports/GenerateOrderUserReport";
import { GenerateOrderContainerReport } from "./useCases/reports/GenerateOrderContainerReport";
import { GenerateSubContainerUsageReport } from "./useCases/reports/GenerateSubContainerUsageReport";
import { GenerateGroupContainerUsageReport } from "./useCases/reports/GenerateGroupContainerUsageReport";
import { UpdateUsedContainersState } from "./useCases/containers/UpdateUsedContainersState";
import { GenerateVehiclesInformationReport } from "./useCases/reports/GenerateVehiclesInformationReport";
import { CreateOrderSeries } from "./useCases/orders/createOrderSeries/CreateOrderSeries";
import { OrderSeriesListView } from "./useCases/orders/getOrderListView/OrderSeriesListView";
import { OrderSeriesDetailsView } from "./_components/OrderViews/Details/OrderSeriesDetailsView";
import { OrdersDeletedListView } from "./useCases/orders/getOrderListView/OrdersDeletedListView";

export { App };

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <div className="app-container bg-light">
      <SpeedInsights/>
      <NavMenu />
      <div className="container-fluid pt-4 pb-4 mt-5">
        <Routes>
          <Route
            path="/configuration"
            element={
              <PrivateRoute>
                <Configuration />
              </PrivateRoute>
            }
          />
          <Route
            path="/keo-reports"
            element={
              <PrivateRoute>
                <KeoGenerateReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/kpo-searcher"
            element={
              <PrivateRoute>
                <KpoBdoSearcherPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-user"
            element={
              <PrivateRoute>
                <CreateAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="/contractors/create"
            element={
              <PrivateRoute>
                <CreateContractor />
              </PrivateRoute>
            }
          />
          <Route path="/orders/:id" element={<PrivateRoute><OrderDetailsView /></PrivateRoute>} />
          <Route path="/orders/series/:id" element={<PrivateRoute><OrderSeriesDetailsView /></PrivateRoute>} />
          <Route path="/orders" element={<PrivateRoute><GetOrders /></PrivateRoute>} />
          <Route path="/orders/create-series" element={<PrivateRoute><CreateOrderSeries /></PrivateRoute>}/>
          <Route path="/orders/create-purchase" element={<PrivateRoute><PurchaseOrderForm /></PrivateRoute>}/>
          <Route path="/orders/create-sale" element={<PrivateRoute><SaleOrderForm /></PrivateRoute>}/>
          <Route path="/orders/create-transport" element={<PrivateRoute><TransportOrderForm /></PrivateRoute>}/>
          <Route path="/orders/deleted" element={<PrivateRoute><OrdersDeletedListView /></PrivateRoute>}/>
          <Route
            path="/contractors/:id/update"
            element={
              <PrivateRoute>
                <UpdateContractor />
              </PrivateRoute>
            }
          />
          <Route
            path="/contractors/:id"
            element={
              <PrivateRoute>
                <ContractorDetails />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          // Order Views
          <Route
            path="/orders/list-view"
            element={
              <PrivateRoute>
                <OrderListView />
              </PrivateRoute>
            }
          />
          <Route
            path="/orders/calendar-view"
            element={
              <PrivateRoute>
                <OrderCalendarView />
              </PrivateRoute>
            }
          />
          <Route
            path="/orders/vehicle-view"
            element={
              <PrivateRoute>
                <OrderVehicleView />
              </PrivateRoute>
            }
          />
          <Route
            path="/orders/driver-view"
            element={
              <PrivateRoute>
                <OrderDriverView />
              </PrivateRoute>
            }
          />
          <Route path="/orders/driver-view" element={<PrivateRoute><OrderDriverView /></PrivateRoute>}/>
          <Route path="/orders/shipping-calendar-view" element={<PrivateRoute><OrderShippingCalendarView/></PrivateRoute>} />
          <Route path="/orders/series-view" element={<PrivateRoute><OrderSeriesListView /></PrivateRoute>}/>
          <Route path="/dashboard" element={<PrivateRoute><MainDashboard/></PrivateRoute>} />
          <Route path="/reports" element={<PrivateRoute><ReportsDashboard/></PrivateRoute>} />
          <Route path="/reports/order-status" element={<PrivateRoute><GenerateOrderStatusReport/></PrivateRoute>} />
          <Route path="/reports/order-driver" element={<PrivateRoute><GenerateOrderDriversReport/></PrivateRoute>} />
          <Route path="/reports/order-user" element={<PrivateRoute><GenerateOrderUserReport/></PrivateRoute>} />
          <Route path="/reports/order-contractor" element={<PrivateRoute><GenerateOrderContractorsReport/></PrivateRoute>} />
          <Route path="/reports/order-containers" element={<PrivateRoute><GenerateOrderContainerReport/></PrivateRoute>} />
          <Route path="/reports/containers-usage" element={<PrivateRoute><GenerateSubContainerUsageReport/></PrivateRoute>} />
          <Route path="/reports/group-containers-usage" element={<PrivateRoute><GenerateGroupContainerUsageReport/></PrivateRoute>} />
          <Route path="/reports/vehicles-information" element={<PrivateRoute><GenerateVehiclesInformationReport/></PrivateRoute>} />
          <Route path="/containers/used-state" element={<PrivateRoute><UpdateUsedContainersState/></PrivateRoute>} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </div>
    </div>
  );
}

const ProtectedRoute = ({ path, element }) => <Route path={path} element={<PrivateRoute>{element}</PrivateRoute>} />;
