import { DomainButton } from "_components/Abstractions/DomainButton";
import { HorizontalView } from "_components/Abstractions/HorizontalView";
import { CompanyBdoSelect } from "_components/Bdo/Company/CompanyBdoSelect";
import { FormControl } from "_components/Forms/FormControl";
import { TextArea } from "_components/Forms/TextArea";
import { useFormContext } from "react-hook-form";
import { AccordionDomain } from "../../../../_components/Abstractions/AccordionDomain";
import { BdoAdditionalCompaniesForm } from "./BdoAdditionalCompaniesForm";

export function BdoCompanyForm() {
  const { setValue, watch, getValues, getFieldState } = useFormContext();
  const companyId = watch("bdoDetails.companyId");

  const setValueOptions = {
    shouldValidate: true,
    shouldDirty: true,
    shouldTouch: true
  };
  const onCompanySelect = (company) => {
    const {
      name,
      nip,
      registrationNumber,
      companyId,
      addressRaw,
      addressDetails
    } = company;

    const currentAddressCentral = getValues("centralAddress");
    const addressCentral = {
      ...currentAddressCentral,
      ...addressDetails,
      name: "Siedziba"
    };

    setValue("name", name, setValueOptions);
    setValue("taxId", nip, setValueOptions);
    setValue("isBdoLinked", true, setValueOptions);
    setValue("centralAddress", addressCentral, setValueOptions);
    setValue(
      "bdoDetails",
      {
        registrationNumber: registrationNumber,
        companyId: companyId,
        companyName: name,
        address: addressRaw,
        taxId: nip
      },
      setValueOptions
    );
  };

  const RemoveCompanyLink = () => {
    setValue("bdoDetails", null);
    setValue("isBdoLinked", false);
  };

  return (
    <>
      <div>
        <CompanyBdoSelect onSelect={onCompanySelect}/>
        {companyId && (
          <DomainButton
            className="ms-2"
            variant="secondary"
            onClick={RemoveCompanyLink}
          >
            Usuń powiązanie z podmiotem BDO
          </DomainButton>
        )}
        {companyId && (
          <div className="mt-2">
            <FormControl name="bdoDetails.companyName" label="Nazwa BDO" readonly/>
            <AccordionDomain header="Szczegóły podmiotu BDO">
              <HorizontalView>
                <FormControl name="bdoDetails.taxId" label="NIP" readonly/>
                <FormControl name="bdoDetails.registrationNumber" label="Numer rejestrowy" readonly/>
                <FormControl name="bdoDetails.companyId" label="Numer ref." readonly/>
              </HorizontalView>
              <TextArea name="bdoDetails.address" label="Adres centrali" height="100px" readonly/>
              {/*<ContractorReceiverDetailsForm contractorTypes={contractorTypes}/>*/}
              <BdoAdditionalCompaniesForm />
            </AccordionDomain>
          </div>
        )}
      </div>
    </>
  );
}

