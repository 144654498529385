import { OrderBdoDetails } from "../../../_components/Orders/OrderBdoDetails";
import { OrderProvider } from "../../../_components/Orders/MainForms/OrderContext";
import { FormModal } from "../../../_components/Forms/FormModal";
import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { useDispatch } from "react-redux";

export function UpdateBdoDetails({ order }) {
  const dispatch = useDispatch();
  const { id: orderId, transactionType, contractorDetails, transportDetails } = order;
  const currentKpoCards = order.orderBdoInfo?.kpoCards;
  const { taxId, contractorBdoRegistrationNumber, addressBdoRegistrationNumber, hasAdditionalBdoCompanies,
    additionalCompanyBdoDataItems, bdoCompanyName, bdoCompanyId } = contractorDetails;
  const defaultValues = {
    contractor: {
      taxId: taxId,
      registrationNumber: contractorBdoRegistrationNumber,
      hasAdditionalBdoCompanies,
      additionalCompanyBdoDataItems,
      bdoCompanyName,
      bdoCompanyId
    },
    orderAddress: { bdoDetails: { registrationNumber: addressBdoRegistrationNumber } },
    wastesDetails: { isBdoIntegrated: true },
    transportDetails: { plannedLoadingDate: transportDetails?.plannedLoadingDate, transportType: transportDetails?.transportType },
    bdoDetails: { kpoCards: currentKpoCards }
  };

  const formControls = <OrderProvider transactionType={transactionType} isUpdate>
    <OrderBdoDetails isUpdate transactionType={transactionType}/>
  </OrderProvider>;

  const onSubmit = async(payload) => {
    const { bdoDetails: { kpoCards } } = payload;

    const kpoItemsToUnlink = currentKpoCards?.filter(x => !kpoCards?.some(c => c.kpoId === x.kpoId)).map(x => x.kpoId) ?? [];
    const kpoItemsToLink = kpoCards?.filter(x => !kpoItemsToUnlink?.some(c => c.kpoId === x.kpoId)).map(x => ({
      kpoId: x.kpoId,
      status: x.status
    })) ?? [];
    const requestPayload = { orderId, kpoItemsToLink, kpoItemsToUnlink };

    const response = await dispatch(ordersActions.updateBdoDetails({ orderId, payload: requestPayload }));
    if (!response?.error) {
      dispatch(orderViewsActions.getBdoInfoById({ id: orderId }));
      dispatch(orderViewsActions.refreshHistory({ id: orderId }));
    }

    return response;
  };

  return <>
    <FormModal
      formControls={formControls}
      // validationSchema={validationSchema}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      sizeModal="lg"
      buttonText="Dodaj / usuń karty"
      successMessage="Zaktualizowano przypisane karty KPO"
      title="Aktualizacja przypisanych kart KPO"
      submitBtnText="Przypisz karty"
    />
  </>;
}