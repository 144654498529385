import { OffcanvasDomain } from "../../Abstractions/OffcanvasDomain";
import { Card, ProgressBar } from "react-bootstrap";
import { HorizontalView } from "../../Abstractions/HorizontalView";
import { ReadonlyControl } from "../../Forms/ReadonlyControl";
import { useKpoCardPrintingDetails } from "./KpoBdoCardPrintingDetails";
import { stringToDateExact } from "../../../domain/Configuration";
import { EyeFill } from "react-bootstrap-icons";

const KpoCardInfo = ({ kpoId, additionalInfo }) => {

  const { kpoCardPrintingDetails, isLoading } = useKpoCardPrintingDetails({ kpoId });
  if (isLoading || !kpoCardPrintingDetails) {
    return <>
      <ProgressBar/>
    </>;
  }

  const { auditInfo, senderInfo, carrierInfo, receiverInfo } = kpoCardPrintingDetails || {};
  const getDateTime = (date, time) => {
    if (!date || !time) {
      return "-";
    }
    return `${date} ${time}`;
  };

  const MainCardDetails =
    <>
      <HorizontalView columnsSize={[3, 5, 4]}>
        <ReadonlyControl size="sm" label="Rok kalendarzowy">{auditInfo?.calendarYear}</ReadonlyControl>
        <ReadonlyControl size="sm" label="Numer karty KPO">{kpoCardPrintingDetails.cardNumber}</ReadonlyControl>
        <ReadonlyControl size="sm" label="Status karty">{kpoCardPrintingDetails.cardStatus}</ReadonlyControl>
      </HorizontalView>
    </>;

  const SenderCard =
    <>
      <Card.Header>Dane przekazującego odpady</Card.Header>
      <Card.Body>
        <HorizontalView>
          <ReadonlyControl size="sm" label="Numer rejestrowy">{senderInfo.identificationNumber}</ReadonlyControl>
          <ReadonlyControl size="sm" label="NIP">{senderInfo.nip}</ReadonlyControl>
        </HorizontalView>
        <ReadonlyControl size="sm" label="Nazwa">{senderInfo.name}</ReadonlyControl>
        <ReadonlyControl size="sm" label="Adres siedziby przekazującego odpady">{senderInfo.address}</ReadonlyControl>

        <HorizontalView columnsSize={[4, 8]}>
          <ReadonlyControl size="sm" label="Numer MPD">{senderInfo.eupNumber}</ReadonlyControl>
          <ReadonlyControl size="sm" label="Nazwa miejsca prowadzenia działalności">{senderInfo.eupName}</ReadonlyControl>
        </HorizontalView>
        <ReadonlyControl size="sm" label="Adres miejsca prowadzenia działalności">{senderInfo.eupAddress}</ReadonlyControl>
      </Card.Body>
    </>;

  const CarrierCard =
    <>
      <Card.Header>Dane transportującego odpady</Card.Header>
      <Card.Body>
        <HorizontalView>
          <ReadonlyControl size="sm" label="Numer rejestrowy">{carrierInfo.identificationNumber}</ReadonlyControl>
          <ReadonlyControl size="sm" label="NIP">{carrierInfo.nip}</ReadonlyControl>
        </HorizontalView>
        <ReadonlyControl size="sm" label="Nazwa">{carrierInfo.name}</ReadonlyControl>
        <ReadonlyControl size="sm" label="Adres siedziby transportującego odpady">{carrierInfo.address}</ReadonlyControl>
      </Card.Body>
    </>;

  const ReceiverCard =
    <>
      <Card.Header>Dane przejmującego odpady</Card.Header>
      <Card.Body>
        <HorizontalView>
          <ReadonlyControl size="sm" label="Numer rejestrowy">{receiverInfo.identificationNumber}</ReadonlyControl>
          <ReadonlyControl size="sm" label="NIP">{receiverInfo.nip}</ReadonlyControl>
        </HorizontalView>
        <ReadonlyControl size="sm" label="Nazwa">{receiverInfo.name}</ReadonlyControl>
        <ReadonlyControl size="sm" label="Adres siedziby przekazującego odpady">{receiverInfo.address}</ReadonlyControl>
        <HorizontalView columnsSize={[4, 8]}>
          <ReadonlyControl size="sm" label="Numer MPD">{receiverInfo.eupNumber}</ReadonlyControl>
          <ReadonlyControl size="sm" label="Nazwa miejsca prowadzenia działalności">{receiverInfo.eupName}</ReadonlyControl>
        </HorizontalView>
        <ReadonlyControl size="sm" label="Adres miejsca prowadzenia działalności">{receiverInfo.eupAddress}</ReadonlyControl>
      </Card.Body>
    </>;

  const WastesCard = <>
    <Card.Header>Informacje dotyczące odpadów</Card.Header>
    <Card.Body>
      <ReadonlyControl size="sm" label="Kod i rodzaj odpadów">{kpoCardPrintingDetails.wasteCodeAndDescription}</ReadonlyControl>
      <HorizontalView>
        <ReadonlyControl size="sm" label="Masa odpadów w tonach">{kpoCardPrintingDetails.wasteMass}</ReadonlyControl>
        <ReadonlyControl size="sm" label="Skorygowana masa odpadów w tonach">{kpoCardPrintingDetails.correctedWasteMass}</ReadonlyControl>
      </HorizontalView>
      <ReadonlyControl size="sm" label="Numer rejestracyjny pojazdów">{kpoCardPrintingDetails.vehicleRegNumber}</ReadonlyControl>
      <ReadonlyControl size="sm" label="Data i godzina planowanego rozpoczęcia transportu">{getDateTime(auditInfo.plannedTransportDate, auditInfo.plannedTransportTime)}</ReadonlyControl>
      <ReadonlyControl size="sm" label="Faktyczna data i godzina rozpoczęcia transportu">{getDateTime(auditInfo.realTransportDate, auditInfo.realTransportTime)}</ReadonlyControl>
      <ReadonlyControl size="sm" label="Dodatkowe informacje">{kpoCardPrintingDetails.additionalInfo ?? "-"}</ReadonlyControl>
      <ReadonlyControl size="sm" label="Uwagi">{kpoCardPrintingDetails.remarks ?? "-"}</ReadonlyControl>
    </Card.Body>
  </>;

  const AdditionalCardInfo = <>
    <Card.Header>Historia karty</Card.Header>
    <Card.Body>
      <ReadonlyControl size="sm" label="Numer korekty">{additionalInfo?.revisionNumber ?? "-"}</ReadonlyControl>

      <HorizontalView>
        <ReadonlyControl size="sm" label="Osoba zatwierdzająca kartę">{auditInfo.approvedByUser ?? "-"}</ReadonlyControl>
        <ReadonlyControl size="sm" label="Data i godzina zatwierdzenia">{getDateTime(auditInfo.approvalDate, auditInfo.approvalTime)}</ReadonlyControl>
      </HorizontalView>
      {additionalInfo?.revisionNumber > 0 &&
        <HorizontalView>
          <ReadonlyControl size="sm" label="Osoba odrzucająca kartę">{additionalInfo?.rejectedByUser ?? "-"}</ReadonlyControl>
          <ReadonlyControl size="sm" label="Data i godzina odrzucenia">{additionalInfo.cardRejectionTime ? stringToDateExact(additionalInfo.cardRejectionTime, "yyyy-MM-dd HH:mm") : "-"}</ReadonlyControl>
        </HorizontalView>}
      <HorizontalView>
        <ReadonlyControl size="sm" label="Osoba potwierdzające przejęcie">{auditInfo.receiveConfirmedByUser ?? "-"}</ReadonlyControl>
        <ReadonlyControl size="sm" label="Data i godzina przejęcia odpadów">{getDateTime(auditInfo.receiveConfirmationDate, auditInfo.receiveConfirmationTime)}</ReadonlyControl>
      </HorizontalView>
      <HorizontalView>
        <ReadonlyControl size="sm" label="Osoba potwierdzająca transport">{auditInfo.transportConfirmedByUser ?? "-"}</ReadonlyControl>
        <ReadonlyControl size="sm" label="Data i godzina potwierdzenia transportu">{getDateTime(auditInfo.transportConfirmationDate, auditInfo.transportConfirmationTime)}</ReadonlyControl>
      </HorizontalView>
    </Card.Body>
  </>;

  return (<Card>
    <Card.Body>
      {MainCardDetails}
      {SenderCard}
      {CarrierCard}
      {ReceiverCard}
      {WastesCard}
      {AdditionalCardInfo}
    </Card.Body>
  </Card>);
};

export function KpoBdoCardDetailsOffcanvas({ item }) {
  const { kpoId } = item;
  const title = "Karta przekazania odpadów";
  return (
    <>
      <OffcanvasDomain buttonContent=<EyeFill/> title={title} body={<KpoCardInfo kpoId={kpoId} additionalInfo={item}/>}/>
    </>);
}