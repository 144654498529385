import { BaseOrderForm } from "./BaseOrderForm";
import { ordersActions } from "../../../_store/order.slice";
import { mapToCreateSaleRequest } from "../../../_services/Orders/orderMapper";
import * as Yup from "yup";
import { WasteMassValidator } from "../../../domain/Orders/CreatePurchaseOrderValidator";
import { useSearchParams } from "react-router-dom";

export function SaleOrderForm() {
  const wasteSchema = Yup.object().shape({
    wasteMass: WasteMassValidator.validator
  });

  const saleValidationSchema = Yup.object().shape({
    transportDetails: Yup.object().when("wastesDetails.generateKpoCards", {
      is: true,
      then: Yup.object().shape({
        transportContractor: Yup.object().nullable().when("transportType", {
            is: "External",
            then: Yup.object().typeError("Przewoźnik jest wymagany dla zlecenia BDO, aby wygenerować karty")
          }
        )
      })
    }),
    wastesDetails: Yup.object().shape({
      generateKpoCards: Yup.boolean(),
      saleWastes: Yup.object().shape({
        wastes: Yup.array().of(wasteSchema)
      })
    })
  });

  const [searchParams] = useSearchParams();
  const transferPurchaseId = searchParams.get("transferPurchaseId");
  const defaultValues = {
    transportDetails: {
      isTransfer: !!transferPurchaseId,
      isFullyTransfer: transferPurchaseId ? true : null,
      // transferPurchaseId: transferPurchaseId,
      transportType: "Internal"
    },
    wastesDetails: {
      isBdoIntegrated: true,
      generateKpoCards: false
    }
  }


  return (
    <BaseOrderForm
      transactionTypeParam="Sale"
      validationSchema={saleValidationSchema}
      submitAction={ordersActions.createSale}
      requestMapper={mapToCreateSaleRequest}
      overrideAddressLabel="Adres rozładunku"
      defaultValues={defaultValues}
    />
  );
}
