import { ordersActions } from "../../../_store/order.slice";
import { OrderWorkFlowAction } from "./OrderWorkFlowAction";
import { useNavigate } from "react-router-dom";

export function DeleteOrder({ orderId, orderNumber }) {
  const successMessage = "Zlecenie zostało usunięte";
  const buttonText = "Usuń zlecenie";
  const title = `Czy usunąć zlecenie ${orderNumber}?`;
  const navigate = useNavigate();

  const props = {
    orderId: orderId,
    orderAction: ordersActions.deleteOrder,
    successMessage,
    buttonText,
    title,
    skipRefreshViews: true,
    extraAction: () => navigate(-1)
  };

  return <OrderWorkFlowAction {...props} />;
}