import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { ControlLabel } from "../Abstractions/ControlLabel";

export function IntegerControl({
  label,
  name,
  autoFocus = false,
  readonly = false,
  required = true,
}) {
  const { control, getFieldState } = useFormContext();
  let fieldState = getFieldState(name);
  const formattedLabel = <ControlLabel label={label} required={required} />;

  const handleOnKeyDown = (event) => {
    if (
      event.keyCode === 38 ||
      event.keyCode === 40 ||
      event.key === '-' ||
      event.key === 'e' ||
      event.key === 'E' ||
      event.key === '.'
    ) {
      event.preventDefault();
    }
  };

  const handleOnChange = (value) => {
    return value.replace(/\D/g, '');
  };

  return (
    <>
      <Form.Group className="mb-3">
        <FloatingLabel label={formattedLabel}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <Form.Control
                  {...field}
                  onChange={(e) => field.onChange(handleOnChange(e.target.value))}
                  // onBlur={(e) => field.onChange(handleOnBlur(e.target.value))}
                  type="number"
                  onKeyDown={handleOnKeyDown}
                  onWheel={(e) => e.target.blur()}
                  autoFocus={autoFocus}
                  value={field.value ?? ""}
                  placeholder={label}
                  autoComplete="off"
                  isInvalid={fieldState?.error}
                  disabled={readonly}
                  className="form-input form"
                  required={required}
                />
              );
            }}
          />
          <Form.Control.Feedback type="invalid">
            {fieldState?.error?.message}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    </>
  );
}
