import { useFormContext } from "react-hook-form";
import { WastesBdoSelectGroupTable } from "./WastesBdoSelectGroupTable";
import { ContractorReceiverDetailsView } from "./ContractorReceiverDetailsView";
import React, { useState } from "react";

export const ContractorReceiverDetailsForm = ({ contractorTypes, addressField }) => {
  const { watch, setValue, getFieldState, clearErrors } = useFormContext();
  const getFieldName = (fieldName) => `${addressField}.wasteProcessingDecisionData.${fieldName}`;
  const allowedWasteCodesBdo = watch(getFieldName("allowedWasteCodesBdo"));
  const allowedWasteCodesBdoCount = watch(getFieldName("allowedWasteCodesBdoCount"));
  const allowedWasteCodesBdoCountFieldState = getFieldState(getFieldName("allowedWasteCodesBdoCount"));
  const [forceAccordionOpen, setForceAccordionOpen]  = useState(false);

  if (!contractorTypes?.includes("Receiver")) {
    return;
  }

  const onWastesBdoSelectSubmit = (selectedItems, codesCount) => {
    setValue(getFieldName("allowedWasteCodesBdo"), selectedItems);
    setValue(getFieldName("allowedWasteCodesBdoCount"), codesCount);

    if (codesCount > 0) {
      clearErrors(getFieldName("allowedWasteCodesBdoCount"));
      setForceAccordionOpen(true);
    } else {
      setForceAccordionOpen(false);
    }
  };

  const formActions = <div className="d-flex justify-content-start mb-2">
    <WastesBdoSelectGroupTable onSubmit={onWastesBdoSelectSubmit} defaultValues={allowedWasteCodesBdo}/>
  </div>;

  return <>
    <ContractorReceiverDetailsView
      addressField={addressField}
      allowedWasteCodesBdo={allowedWasteCodesBdo}
      allowedWasteCodesBdoCount={allowedWasteCodesBdoCount}
      isReadonly={false}
      formActions={formActions}
      errors={allowedWasteCodesBdoCountFieldState?.error?.message}
      forceAccordionOpen={forceAccordionOpen}></ContractorReceiverDetailsView>

  </>;
};