import moment from "moment"

export function getWeekDateRange(date = null, offset = 0) {
  var dateFrom = date ? new Date(date) : new Date();
  var dayOfWeek = dateFrom.getDay();

  var startDate = new Date(dateFrom);
  startDate.setDate(dateFrom.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1) + offset * 7);

  var endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  return {
    startDate: startDate,
    endDate: endDate
  };
}

export function getDatesForWeek(skipSunday = false, date = null, offset = 0) {
  var dates = [];
  var { startDate, endDate } = getWeekDateRange(date, offset)

  const weekLength = skipSunday ? 6 : 7;

  for (var i = 0; i < weekLength; i++) {
    var currentDate = new Date(startDate);

    currentDate.setDate(startDate.getDate() + i);
    var dateISO = currentDate.toISOString().split('T')[0];
    var dayOfWeekPolish = getPolishDayOfWeek(currentDate);
    dates.push({ date: dateISO, dayOfWeek: dayOfWeekPolish });
  }

  const dateFrom = startDate.toISOString().split('T')[0];
  const dateTo = endDate.toISOString().split('T')[0];

  return { dateFrom, dateTo, weekDates: dates, currentDate: startDate.toJSON() };
}

export function getPolishDayOfWeek(date) {
  var polishDaysOfWeek = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];
  var dayOfWeek = date.getDay();

  return polishDaysOfWeek[dayOfWeek];
}


export function areDatesMatching(dateTime1, dateTime2) {
  const date1 = moment(dateTime1).startOf("day");
  const date2 = moment(dateTime2).startOf("day");

  return date1.isSame(date2, "day");
}