import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { ORDER_STATUS } from "../../../domain/Orders/OrderStatus";
import { mapToMainDetails } from "../../../_services/Orders/orderMapper";
import { kpoActions } from "../../../_store/kpo.slice";
import { UpdateOrderMainDetailsForm } from "./UpdateOrderMainDetailsForm";
import { useDispatch } from "react-redux";
import { useKpoReportMessageProvider } from "../../../domain/Orders/UpdateKpoReportMessageProvider";
import { useToast } from "../../../_helpers/notifications/useToasts";

export function UpdateOrderMainDetails({ order }) {
  const dispatch = useDispatch();
  const { showErrorMessage, showInfoMessage } = useToast();
  const { getUpdateKpoReportMessage } = useKpoReportMessageProvider();
  const { id: orderId, transactionType, contractorDetails, status, wastesDetails, orderBdoInfo } = order || {};
  const hasKpoCards = orderBdoInfo?.kpoCards?.length > 0;

  if (status === ORDER_STATUS.CLOSED) {
    return;
  }

  const onSubmit = async(payload) => {
    const requestPayload = mapToMainDetails(payload);
    const response = await dispatch(ordersActions.updateMainDetails({ orderId, payload: { orderId, details: requestPayload } }));
    if (response?.error) {
      return response;
    }

    const isExternalOrderNumberChange = payload?.externalOrderNumberInit !== payload?.externalOrderNumber;
    if (transactionType === "Sale" && isExternalOrderNumberChange && hasKpoCards) {
      const details = { plannedTransportTime: payload?.transportDetails?.plannedLoadingDate, additionalInfo: payload?.externalOrderNumber?.toUpperCase() };
      const updateKpoResponse = await dispatch(kpoActions.updateKpoData({ orderId, payload: { orderId, transactionType, details } }));
      const message = getUpdateKpoReportMessage(updateKpoResponse);

      if (updateKpoResponse.error) {
        showErrorMessage(message);
      } else {
        showInfoMessage(message);
        await dispatch(orderViewsActions.getBdoInfoById({ id: orderId }));
      }
    }

    await dispatch(orderViewsActions.getMainDetails({ id: orderId }));
    await dispatch(orderViewsActions.refreshHistory({ id: orderId }));

    return response;
  };

  return <UpdateOrderMainDetailsForm
    mainDetails={contractorDetails}
    status={status}
    transactionType={transactionType}
    wastesDetails={wastesDetails}
    onSubmit={onSubmit}
  />;
}