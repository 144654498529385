import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { groupContainersActions } from "../../_store/groupContainers.slice";
import { SelectTable } from "../../_components/Abstractions/SelectTable";
import { ContainerGroupStateTable } from "../../_components/Containers/ContainerGroupStateTable";

export function GetGroupContainerState({ container, activeGroupContainerId, show, isFormActive, onShow }) {
  const dispatch = useDispatch();
    const { id, type, totalInitializeCount, totalRemainingCount, totalUsedCount } = container;
  const [items, setItems] = useState();

  useEffect(() => {
    if(activeGroupContainerId !== id || !isFormActive) {
      return;
    }

    const getState = async() => {
      setItems({ loading: true });
      const response = await dispatch(groupContainersActions.getState({ id }));
      if (!response?.error) {
        setItems(response.payload);
      }
    }

    getState();

  }, [isFormActive, show, activeGroupContainerId]);

  const table = <ContainerGroupStateTable items={items}/>;

  return <>
    <SelectTable
      table={table}
      label="Zużycie"
      show={show}
      btnVariant="outline-primary"
      skipSearchBar
      modalSize="lg"
      onShowModal={onShow}
      modalTitle={`Grupa kontenera: ${type} Zużycie: ${totalUsedCount}, Liczba bazowa: ${totalInitializeCount}, Pozostało: ${totalRemainingCount}`}
    />
  </>;
}