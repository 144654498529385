import { Card, Col, Row } from "react-bootstrap";
import { AddressesForm } from "./AddressesForm";
import { IntermediariesForm } from "./IntermediariesForm";
import { AddressForm } from "./AddressForm";
import { BdoCompanyForm } from "./BdoCompanyForm";
import { FormDomain } from "_components/Forms/FormDomain";
import { ContractorMainDetailsForm } from "./ContractorMainDetailsForm";
import ContractorValidator from "../../../../domain/Contractors/ContractorValidator";
import { dateToString } from "../../../../domain/Configuration";

export function ContractorForm({ defaultValues, onSubmit, isUpdate = false }) {
  const validationSchema = ContractorValidator.validationSchema;
  const clientCard = (
    <>
      <Card className="h-100 b-2">
        <Card.Header as="h4">Dane klienta</Card.Header>
        <Card.Body>
          <ContractorMainDetailsForm/>
        </Card.Body>
      </Card>
    </>
  );

  const bdoCard = (
    <>
      <Card className="h-100">
        <Card.Header as="h4">Dane BDO</Card.Header>
        <Card.Body>
          <BdoCompanyForm/>
        </Card.Body>
      </Card>
    </>
  );

  const centralAddressCard = (
    <>
      <Card>
        <Card.Header as="h4">Adres centrali</Card.Header>
        <Card.Body>
          <AddressForm addressField="centralAddress" isCentral/>
        </Card.Body>
      </Card>
    </>
  );

  const addressesCard = (
    <>
      <Card className="mb-1">
        <Card.Header as="h4">Adresy dostawy</Card.Header>
        <Card.Body>
          <AddressesForm/>
        </Card.Body>
      </Card>
    </>
  );

  const intermediariesCard = (
    <>
      <Card className="mb-1">
        <Card.Header as="h4">Pośrednicy</Card.Header>
        <Card.Body>
          <IntermediariesForm name="intermediaries"/>
        </Card.Body>
      </Card>
    </>
  );

  const formControls = (
    <>
      <>{bdoCard}</>
      <>{clientCard}</>
      <Row>
        <Col>{centralAddressCard}</Col>
      </Row>
      {intermediariesCard}
      <Col>{addressesCard}</Col>
    </>
  );

  const successMessage = isUpdate
    ? "Zaktualizowano dane klienta"
    : "Dodano klienta";

  const onSubmitForm = async(data) => {
    let addresses = [data.centralAddress, ...data.addresses];
    const { defaultIntermediaryId } = data;
    const intermediaries = data.intermediaries?.map((x) => {
      return {
        intermediaryId: x.intermediaryId,
        name: x.name,
        isDefault: defaultIntermediaryId === x.intermediaryId
      };
    });

    addresses = addresses?.map((a) => {
      const { wasteProcessingDecisionData } = a;
      const allowedWasteCodesBdo = Object.values(wasteProcessingDecisionData?.allowedWasteCodesBdo ?? {}).flatMap(entry =>
        entry.selectedItems?.map(item => ({
          ...item,
          parentId: entry.parentId
        }))
      );

      return ({
        ...a,
        usedContainers: a.usedContainers?.map(c => ({
          ...c,
          // wastesIds: c.wastes.map((x) => x.wasteId),
          wastes: c.wastes?.map((x) => ({ id: x.wasteId, count: x.count }))
        })),
        wasteProcessingDecisionData: data.contractorTypes?.includes("Receiver") ? {
          decisionDate:  wasteProcessingDecisionData?.decisionDate ? dateToString(wasteProcessingDecisionData.decisionDate) : null,
          decisionNumber: wasteProcessingDecisionData?.decisionNumber,
          allowedWasteCodesBdo: allowedWasteCodesBdo
        } : null
      });
    });

    const applyBdoDetails = !!data.bdoDetails?.companyId;
    const payload = {
      ...data,
      salesRepresentativeId: data.salesRepresentative?.id,
      isSalesRepresentativeDefault: data.isSalesRepresentativeDefault,
      addresses: addresses,
      intermediaries,
      defaultIntermediaryId: null,
      centralAddress: null,
      bdoDetails: applyBdoDetails ? {
        ...data.bdoDetails,
        additionalCompaniesBdo: data.bdoDetails?.additionalCompaniesBdo?.map(company => ({
          id: company.id,
          companyId: company.companyId,
          companyName: company.name,
          taxId: company.nip,
          registrationNumber: company.registrationNumber,
          addressCentral: company.addressRaw
        }))
      } : null
    };

    return await onSubmit(payload);
  };

  return (
    <>
      <FormDomain
        formControls={formControls}
        validationSchema={validationSchema}
        onSubmit={onSubmitForm}
        successMessage={successMessage}
        defaultValues={defaultValues}
      />
    </>
  );
}
