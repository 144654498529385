import { FormModal } from "../../../_components/Forms/FormModal";
import { WastesDetailsFormProvider } from "./WastesDetailsFormProvider";

export function UpdateOrderWastesDetailsForm({ order, onSubmit, transactionType, wastesDetails, isSeries }) {
  const { formControls, defaultValues, validationSchema, includeExtendedSaleWastesData } = WastesDetailsFormProvider({ order, transactionType, wastesDetails, isSeries });

  return <>
    <FormModal
      formControls={formControls}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
      onSubmit={(payload) => onSubmit(payload, includeExtendedSaleWastesData)}
      buttonText="Edytuj dane towarów"
      successMessage="Zaktualizowano dane towarów"
      title="Zmień dane towarów"
      btnSize="x-small"
      sizeModal="lg"
    />
  </>;
}

