import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { DomainButton } from "./DomainButton";
import Dropdown from "react-bootstrap/Dropdown";
import { LoadingSpinner } from "./LoadingSpinner";

export function PromptModal({ title, buttonText, onSubmit,
  onShowModal,
  btnVariant,
  btnSize,
  body,
  modalSize,
  isButtonTrigger = true })
{
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShow = () => {
    setShow(true);
    if (onShowModal) {
      onShowModal();
    }
  };
  const handleClose = () => setShow(false);
  const handleSubmit = async(state) => {
    setLoading(true);
    await onSubmit(state);
    setLoading(false);
    handleClose();
  };

  const triggerButton =
    <DomainButton
      variant={btnVariant}
      btnSize={btnSize}
      className="me-1"
      onClick={handleShow}>{buttonText}
    </DomainButton>;

  const triggerAction = <Dropdown.Item className="domain-control" onClick={handleShow}>{buttonText}</Dropdown.Item>;

  return (
    <>
      {isButtonTrigger ? triggerButton : triggerAction}
      <Modal backdrop="static" keyboard={false} size={modalSize} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {body && <Modal.Body>{body}</Modal.Body>}
        <Modal.Footer>
          <LoadingSpinner isLoading={loading} as={`div`}/>
          <DomainButton variant="secondary" onClick={handleClose} disabled={loading}>Nie</DomainButton>
          <DomainButton variant="primary" onClick={() => handleSubmit("Yes")} disabled={loading}>Tak</DomainButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}
