import { useDispatch, useSelector } from "react-redux";
import { AssignVehicles } from "useCases/vehicles/AssignVehicles";
import { groupContainersActions } from "../../_store/groupContainers.slice";

export function AssignVehiclesToGroupContainer({
  groupContainer,
  activeGroupContainerId,
  onShow,
  isFormActive,
}) {
  const dispatch = useDispatch();
  const { id: groupContainerId, type } = groupContainer;
  const { groupContainerVehicles } = useSelector((x) => x.groupContainers);
  const getVehiclesForContainer = () => {
    return dispatch(groupContainersActions.getVehiclesForGroupContainer({ groupContainerId }));
  };

  const assignVehicles = async (payload) =>
    await dispatch(groupContainersActions.assignVehicles({ groupContainerId, payload }));

  const buttonText = "Przypisz pojazdy";
  const successMessage = "Przypisano pojazdy do grupy kontenera";
  const title = `Przypisz pojazdy dla grupy kontenera '${type?.toUpperCase()}'`;
  const displayInfo = { buttonText, successMessage, title };

  return (
    <AssignVehicles
      entityId={groupContainerId}
      activeEntityId={activeGroupContainerId}
      entityVehicles={groupContainerVehicles}
      getVehiclesForEntity={getVehiclesForContainer}
      assignVehicles={assignVehicles}
      displayInfo={displayInfo}
      onShow={onShow}
      isFormActive={isFormActive}
    />
  );
}
