import { DomainSwitch } from "_components/Abstractions/DomainSwitch";
import { LoadingWastesForm } from "./WasteDetails/LoadingWastesForm";
import { ContainersExchangeForm } from "./WasteDetails/ContainersExchangeForm";
import { SaleWastesDetailsForm, TransportWastesDetailsForm } from "./WasteDetails/SaleWastesDetailsForm";
import { FormControl } from "_components/Forms/FormControl";
import { useContext, useEffect } from "react";
import { OrderContext } from "./MainForms/OrderContext";
import { useFormContext } from "react-hook-form";
import { HorizontalView } from "../Abstractions/HorizontalView";

export function WastesOrderDetails({ transactionType, includeExtendedSaleWastesData, isUpdate = false }) {
  const isPurchase = transactionType === "Purchase";
  const isSale = transactionType === "Sale";
  const isTransport = transactionType === "Transport";
  const { isBdoIntegrated, isWasteTransport, isSeries } = useContext(OrderContext);
  const fillWasteMass = isUpdate && !isSeries;

  const { setValue } = useFormContext();

  useEffect(() => {
    if(!isBdoIntegrated && isSale) {
      setValue("wastesDetails.generateKpoCards", false);
    }
  }, [isBdoIntegrated, isSale]);

  return (
    <div className="mb-2">
      <div className="d-flex gap-4">
        <DomainSwitch label="Zlecenie BDO" name="wastesDetails.isBdoIntegrated" />
        {isSale && isBdoIntegrated && !isUpdate && <DomainSwitch label="Generuj karty KPO" name="wastesDetails.generateKpoCards" />}
        {isTransport && <DomainSwitch label="Transport z TRK" name="wastesDetails.isWasteTransport" />}
        {isPurchase && (
          <>
            <DomainSwitch label="Załadunek" name="wastesDetails.isLoading" />
            <DomainSwitch label="Wymiana kontenerów" name="wastesDetails.isContainerExchange"/>
          </>
        )}
      </div>
      {(isPurchase || isWasteTransport) && (<FormControl name="wastesDetails.receivedNoteNumber" label="Numer PZ" required={false}/>)}
      {(isSale || isWasteTransport) && <HorizontalView>
        <FormControl name="wastesDetails.deliveryNoteNumber" label="Numer dokumentu WZ" required={false}/>
        <FormControl name="wastesDetails.saleDocumentNumber" label="Numer dokumentu sprzedaży" required={false}/>
      </HorizontalView>}
      <LoadingWastesForm fillWasteMass={fillWasteMass} />
      <ContainersExchangeForm fillWasteMass={fillWasteMass} />
      {isSale && <SaleWastesDetailsForm fillWasteMass={fillWasteMass} includeExtendedSaleWastesData={includeExtendedSaleWastesData }/>}
      {isTransport && <TransportWastesDetailsForm fillWasteMass={fillWasteMass} />}
    </div>
  );
}
