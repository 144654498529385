import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./_store";
import { App } from "./App";

// Bootstrap CSS
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.css";
import "./_root.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { ToastProvider } from "_helpers/notifications/useToasts";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
      <Provider store={store}>
        <ToastProvider>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </ToastProvider>
      </Provider>
  </React.StrictMode>
);
