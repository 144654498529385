import { Form } from "react-bootstrap";
import { useFilterFormContext } from "../Forms/FilterContext";
import { useEffect, useState } from "react";

export function FilterSwitch({ name, label, readonly }) {
  const { onFilterChange, currentFilters, reset } = useFilterFormContext();
  const [selectedValue, setSelectedValue] = useState((Boolean(currentFilters[name] ?? false)));
  const handleChange = (e) => {
    onFilterChange({ name, value: e.target.checked });
    setSelectedValue(e.target.checked);
  };

  useEffect(() => {
    if(reset > 0) {
      setSelectedValue(false);
    }
  }, [reset]);

  return (
    <>
      <Form.Group className="mb-3 domain-control">
        <Form.Check
          id={name}
          type="switch"
          className="hover-pointer switch"
          label={label}
          disabled={readonly}
          checked={selectedValue}
          onChange={handleChange}
        ></Form.Check>
      </Form.Group>
    </>
  );
}