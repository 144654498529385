import { useDispatch } from "react-redux";
import { vehiclesActions } from "_store/vehicles.slice";
import { VehicleForm } from "_components/Vehicles/VehicleForm";
import { dateToString } from "../../domain/Configuration";

export function UpdateVehicle({ vehicle, onShow }) {
  const dispatch = useDispatch();

  const { id } = vehicle;

  function onSubmit(payload) {
    return dispatch(vehiclesActions.update({ id, payload }));
  }

  const { isActive, inspectionDate, insuranceDate, udtInspectionDate, tachographDate, ...vehicleProps } = vehicle;
  const defaultValues = {
    ...vehicleProps,
    inspectionDate: inspectionDate && dateToString(inspectionDate),
    insuranceDate: insuranceDate && dateToString(insuranceDate),
    udtInspectionDate: udtInspectionDate && dateToString(udtInspectionDate),
    tachographDate: tachographDate && dateToString(tachographDate),
  };

  return (
    <>
      <VehicleForm
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        isUpdate
        onShow={onShow}
      />
    </>
  );
}
