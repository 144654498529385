import { Table } from "react-bootstrap";

export function HolidaysTable({ items }) {
  const table = (
    <>
      <Table hover>
        <thead>
        <tr>
          <th style={{ width: "10%" }}>Data</th>
          <th style={{ width: "70%" }}>Nazwa</th>
        </tr>
        </thead>
        <tbody>
        {items.loading && (
          <>
            <tr className="spinner-border"></tr>
          </>
        )}
        {items.length > 0 &&
          items.map((item, index) => (
            <tr key={index}>
              <td>{item.date}</td>
              <td>{item.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );

  return <>{table}</>;
}