import { WasteBdoSelect } from "../../../_components/Wastes/WasteBdoSelect";
import { NumericControl } from "../../../_components/Forms/NumericControl";
import { HorizontalView } from "../../../_components/Abstractions/HorizontalView";
import { useFieldArray, useFormContext } from "react-hook-form";
import { DomainButton } from "../../../_components/Abstractions/DomainButton";
import { BiMinus } from "react-icons/bi";
import { FormControl } from "../../../_components/Forms/FormControl";
import { Hr } from "../../../_components/Abstractions/Hr/Hr";
import { Dropdown } from "../../../_components/Abstractions/Dropdown";
import { TRANSPORT_TYPES, TransportType } from "../../../domain/TransportType";

export function GenerateOrderKpoCardsForm({ handleDownloadWastesBdo, transportType }) {
  const { append, fields, remove, replace } = useFieldArray({ name: "kpoCards" });
  const { getFieldState } = useFormContext();
  const getFieldName = (idx, name) => `kpoCards[${idx}].${name}`;
  const kpoCardsState = getFieldState("kpoCards");
  const onHandleDownloadWastesBdo = async() => {
    const kpoCards = await handleDownloadWastesBdo();
    replace(kpoCards);
  };

  return <div className="d-flex flex-column align-items-stretch justify-content-end">
    <HorizontalView>
      <FormControl name="bdoCompanyName" label="Kontrahent - Przejmujący BDO" readonly/>
      <FormControl name="addressBdoRegistrationNumber" label="Nr. adresu BDO" readonly/>
    </HorizontalView>
    <HorizontalView>
      <FormControl name="plannedTransportTime" label="Planowana data rozpoczęcia transportu" inputType="datetime-local"/>
      <Dropdown name="transportType" items={TransportType} label="Rodzaj transportu" readonly/>
    </HorizontalView>
    <HorizontalView>
      <FormControl name="additionalInfo" label="Dodatkowe informacje" readonly required={false}/>
      {transportType === TRANSPORT_TYPES.EXTERNAL && <FormControl name="carrierCompanyName" label="Przewoźnik - transportujący BDO" readonly/>}
    </HorizontalView>
    <DomainButton className="mb-2 align-self-end" onClick={onHandleDownloadWastesBdo}>Pobierz towary BDO z danych towarów</DomainButton>
    <Hr>Dane towarów</Hr>
    {fields.map((field, index) => <HorizontalView key={field.id} columnsSize={[7, 4, 1]}>
      <WasteBdoSelect name={getFieldName(index, "wasteBdo")}/>
      <NumericControl decimalPlaces={4} name={getFieldName(index, "wasteMass")} label="Masa odpadów [Mg]"/>
      <DomainButton className="mt-3" onClick={() => remove(index)}><BiMinus/></DomainButton>
    </HorizontalView>)}
    <div className="error small text-center">{kpoCardsState.error?.message}</div>
    <DomainButton onClick={() => append({})} className="align-self-end mb-2">Dodaj następny towar</DomainButton>
  </div>;
}