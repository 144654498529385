import { useState } from "react";
import { FormDomain } from "./FormDomain";
import { ModalDomain } from "_components/Abstractions/ModalDomain";

export function FormModal({
  formControls,
  validationSchema,
  onSubmit,
  successMessage,
  buttonText,
  title,
  defaultValues = null,
  onShowModal = null,
  onCloseModal = null,
  loading = null,
  sizeModal = null,
  btnSize,
  isButtonTrigger,
  skipToast,
  submitBtnText,
  isReadonly,
  triggerBtnVariant,
  clearTriggerBtnDefaultStyles,
  keepModalAfterError = false
}) {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleModal = (state) => {
    if (onCloseModal && !state) {
      onCloseModal();
    }
    if (onShowModal && state) {
      onShowModal();
    }
    setShowModal(state);
  };

  const handleSubmit = async (payload) => {
    setIsLoading(true);
    const response = await onSubmit(payload);
    setIsLoading(false);

    if (!response?.error && !keepModalAfterError) {
      handleModal(false);
    }

    return response;
  };

  const form = (
    <FormDomain
      formControls={formControls}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      successMessage={successMessage}
      isModal={true}
      handleModal={handleModal}
      defaultValues={defaultValues}
      skipToast={skipToast}
      submitBtnName={submitBtnText}
    />
  );
  return (
    <>
      <ModalDomain
        buttonText={buttonText}
        title={title}
        body={form}
        show={showModal}
        handleShow={() => handleModal(true)}
        handleClose={() => handleModal(false)}
        loading={loading ?? isLoading}
        size={sizeModal}
        btnSize={btnSize}
        isReadonly={isReadonly}
        isButtonTrigger={isButtonTrigger}
        skipBodyWrapper={(!formControls?.length > 0)}
        triggerBtnVariant={triggerBtnVariant}
        clearTriggerBtnDefaultStyles={clearTriggerBtnDefaultStyles}
      />
    </>
  );
}
