import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { OrderWorkFlowAction } from "./OrderWorkFlowAction";

export function RejectOrder({ orderId, orderNumber }) {
  const successMessage = "Zlecenie zostało odrzucone";
  const buttonText = "Odrzuć zlecenie";
  const title = `Czy odrzucić zlecenie ${orderNumber}?`;

  const props = {
    orderId: orderId,
    orderAction: ordersActions.reject,
    orderViewAction: orderViewsActions.reject,
    successMessage,
    buttonText,
    title
  };

  return <OrderWorkFlowAction {...props} />;
}