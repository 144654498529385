export const OrderStatus = {
  Created: "Kurs zaplanowany",
  Confirmed: "Kurs rozpoczęty",
  CourseCompleted: "Kurs zrealizowany",
  ReceiveConfirmed: "Zrealizowane przejęcie",
  // Rejected: "Odrzucone",
  TransportConfirmed: "Zrealizowany transport ",
  Closed: "Zamknięte",
  Cancelled: "Anulowane",
}

export const ORDER_STATUS = {
  CREATED: "Created",
  CONFIRMED: "Confirmed",
  COURSE_COMPLETED: "CourseCompleted",
  RECEIVE_CONFIRMED: "ReceiveConfirmed",
  REJECTED: "Rejected",
  TRANSPORT_CONFIRMED: "TransportConfirmed",
  CLOSED: "Closed",
  CANCELLED: "Cancelled",
}

export const OrderStatusColor = {
  Created: "secondary",
  Confirmed: "indigo",
  CourseCompleted: "teal",
  ReceiveConfirmed: "cyan",
  Rejected: "danger",
  TransportConfirmed: "fiolet",
  Closed: "dark",
  Cancelled: "orange",
}