import * as Yup from "yup";
import { FormControl } from "../../../Forms/FormControl";
import { FormModal } from "../../../Forms/FormModal";
import { KPO_STATUS } from "../../../../domain/KpoCardStatus";
import { ValidateBdoAction } from "./BdoActionValidator";
import { BaseKpoForm } from "./KpoReceiveConfirmation";
import { NumericControl } from "../../../Forms/NumericControl";
import { HorizontalView } from "../../../Abstractions/HorizontalView";
import { WasteBdoSelect } from "../../../Wastes/WasteBdoSelect";
import { stringToDateTimeForm } from "../../../../domain/Configuration";
import { WasteMassValidator } from "../../../../domain/Orders/CreatePurchaseOrderValidator";

export function UpdatePlannedOrApprovedKpoCards({ kpoCards, onSubmit, name, promptTitle, performAll, vehiclesBdoFormatted, plannedTransportTime, submitBtnText }) {
  const { validate } = ValidateBdoAction();
  const today = new Date();
  let minimumDate = new Date();
  today.setMinutes(today.getMinutes() + 2);
  minimumDate.setMinutes(minimumDate.getMinutes() - minimumDate.getMinutes());
  minimumDate.setHours(minimumDate.getHours() - minimumDate.getHours());
  const kpoItemSchema = Yup.object().shape({
    wasteCodeBdo: Yup.object().typeError("Towar jest wymagany"),
    wasteMass: WasteMassValidator.validator
  });
  const validationSchema = Yup.object().shape({
    vehicleRegNumber: Yup.string().nullable()
    .required("Numer rejestracyjny pojazdów jest wymagany")
    .typeError("Numer rejestracyjny pojazdów jest wymagany"),
    plannedTransportTime: Yup.date().nullable()
    .required("Planowany czas rozpoczęcia transportu jest wymagany")
    .min(minimumDate, "Planowany czas transportu nie może być wcześniejszy niż dzisiejsza data"),
    kpoItems: Yup.array().of(kpoItemSchema)
  });
  const filteredKpoCards = kpoCards.filter(x => x.status === KPO_STATUS.PLANNED || x.status === KPO_STATUS.APPROVED);
  const baseDefaultValues = {
    vehicleRegNumber: vehiclesBdoFormatted,
    plannedTransportTime: plannedTransportTime ? stringToDateTimeForm(plannedTransportTime) : null
  };
  const defaultValues =  {
    ...baseDefaultValues,
    vehicleRegNumber: vehiclesBdoFormatted,
    kpoItems: filteredKpoCards.map(x => ({
      ...x,
      wasteMass: x.wasteMass.toFixed(4),
      wasteCodeBdo: { id: x.wasteCodeId, codeDescription: x.codeDescription },
      isSelected: true
    }))
  };
  const formControls = <div>
    <HorizontalView>
      <FormControl name="vehicleRegNumber" label="Numer rejestracyjny pojazdów" required={false}/>
      <FormControl name="plannedTransportTime" label="Planowany czas transportu" inputType="datetime-local"/>
    </HorizontalView>
    {filteredKpoCards.map((x, idx) => <div key={idx}>
      <BaseKpoForm
        kpoItem={x}
        selectable={kpoCards?.length > 1}
        formControls={
          <>
            <HorizontalView>
              <WasteBdoSelect name={`kpoItems[${idx}].wasteCodeBdo`} label="Kod odpadu"/>
              <NumericControl name={`kpoItems[${idx}].wasteMass`} label="Masa odpadów [Mg]" decimalPlaces={4}/>
            </HorizontalView>
            <FormControl name={`kpoItems[${idx}].additionalInfo`} label="Dodatkowe informacje" required={false} />
          </>}
        parentName={`kpoItems[${idx}]`}/>
    </div>)}
  </div>;

  const handleSubmit = async(payload) => {
    const kpoItems = payload.kpoItems.filter(x => x.isSelected).map((x) =>
      ({
        kpoId: x.kpoId,
        vehicleRegNumber: payload.vehicleRegNumber,
        plannedTransportTime: payload.plannedTransportTime,
        wasteCodeId: x.wasteCodeBdo.id,
        wasteMass: x.wasteMass,
        additionalInfo: x.additionalInfo
      }));
    validate(kpoItems);

    return await onSubmit(kpoItems);
  };

  return <FormModal
    title={promptTitle}
    formControls={formControls}
    defaultValues={defaultValues}
    onSubmit={handleSubmit}
    buttonText={name}
    validationSchema={validationSchema}
    isButtonTrigger={performAll}
    sizeModal="md"
    skipToast
    submitBtnText={submitBtnText}/>;
}