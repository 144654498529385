import { FloatingLabel, Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { ControlLabel } from "../Abstractions/ControlLabel";

export function NumericControl({
  label,
  name,
  decimalPlaces = 2,
  autoFocus = false,
  readonly = false,
  required = true,
  allowNegatives = false
}) {
  const { control, getFieldState, setValue, getValues } = useFormContext();
  let fieldState = getFieldState(name);
  const formattedLabel = <ControlLabel label={label} required={required}/>;
  const handleOnKeyDown = (event) => {
    const isNegative = !allowNegatives && event.key === "-";

    if (event.keyCode === 38 || isNegative || event.keyCode === 40 || event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
  };

  const handleOnChange = (value) => {
    const newValue = value.replace(/[^\d.,]/g, "");
    const parts = newValue.split(/[.,]/);
    return parts.length > 1
      ? `${parts[0]}.${parts[1].slice(0, decimalPlaces)}`
      : parts[0];
  };

  const handleOnBlur = (value) => {
    let updatedValue = value.toString();
    const decimalIndex = updatedValue.indexOf(".");

    if (decimalIndex !== -1) {
      const decimalPart = updatedValue.slice(decimalIndex + 1);
      const integerPart = updatedValue.slice(0, decimalIndex);
      const missingZeros = decimalPlaces - decimalPart.length;

      updatedValue += "0".repeat(missingZeros);
      if (integerPart === "") {
        updatedValue = "0" + updatedValue;
      }
    } else if (value === "" || decimalIndex === 0) {
      updatedValue += "0." + "0".repeat(decimalPlaces);
    } else {
      updatedValue += "." + "0".repeat(decimalPlaces);
    }

    return updatedValue;
  };

  return (
    <>
      <Form.Group className="mb-3">
        <FloatingLabel label={formattedLabel}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <Form.Control
                  {...field}
                  onChange={(e) => field.onChange(handleOnChange(e.target.value))}
                  onBlur={(e) => field.onChange(handleOnBlur(e.target.value))}
                  type="number"
                  onKeyDown={handleOnKeyDown}
                  onWheel={(e) => e.target.blur()}
                  autoFocus={autoFocus}
                  value={field.value ?? ""}
                  placeholder={label}
                  autoComplete="off"
                  isInvalid={fieldState?.error}
                  disabled={readonly}
                  className="form-input form"
                  required={required}
                />
              );
            }}
          />
          <Form.Control.Feedback type="invalid">
            {fieldState?.error?.message}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    </>
  );
}
