import { Card } from "react-bootstrap";
import { KpoBdoStatusBadge } from "./KpoBdoStatusBadge";
import { HorizontalView } from "../../Abstractions/HorizontalView";
import { stringToDate } from "../../../domain/Configuration";
import { DomainButton } from "../../Abstractions/DomainButton";
import { KpoBdoCardDetailsOverlay } from "./KpoBdoCardDetailsOverlay";
import { KpoBdoCardDetailsOffcanvas } from "./KpoBdoCardDetailsOffcanvas";
import { BadgeDomain } from "../../Abstractions/BadgeDomain";

const KpoBdoCard = ({ kpoCard, onUnselect, originatorType, plannedLoadingDate }) => {
  const { cardNumber, status, plannedTransportTime, realTransportTime,
    wasteCode, vehicleRegNumber, wasteMass, isPlannedTransportTimeEligible, isRealTransportTimeEligible } = kpoCard;

  return (
    <Card>
      {/*{senderName && originatorType !== "Sender" && <Card.Header>{senderName}</Card.Header>}*/}
      {/*{receiverName && originatorType === "Sender" && <Card.Header>{receiverName}</Card.Header>}*/}
      <Card.Body>
        <div className="d-flex align-content-start align-items-center justify-content-between">
          <KpoBdoStatusBadge status={status} size="x-small"/>
          <div className="d-flex gap-2">
            <KpoBdoCardDetailsOverlay item={kpoCard}/>
            <KpoBdoCardDetailsOffcanvas item={kpoCard}/>
            <DomainButton onClick={() => onUnselect(kpoCard)}>Odłącz kartę</DomainButton>
          </div>
        </div>
        <Card.Title className="text-muted small">
          {cardNumber}
        </Card.Title>
        <Card.Subtitle className="my-2 small">Kod odpadu: <BadgeDomain size="x-small">{wasteCode}</BadgeDomain> </Card.Subtitle>
        <HorizontalView>
          <Card.Text className="x-small">Numer rejestracyjny<br/> <strong className="upper">{vehicleRegNumber}</strong></Card.Text>
          <Card.Text className="x-small">Masa [Mg]<br/> <strong>{wasteMass ? Number(wasteMass).toFixed(4) : "..."}</strong></Card.Text>
          <Card.Text className="x-small">Planowana data transportu<br/> <strong className={isPlannedTransportTimeEligible ? "" : "error"}>{stringToDate(plannedTransportTime)}</strong></Card.Text>
          <Card.Text className="x-small">Rzeczywista data transportu<br/> <strong className={isRealTransportTimeEligible ? "" : "error"}>{stringToDate(realTransportTime)}</strong></Card.Text>
        </HorizontalView>
      </Card.Body>
    </Card>
  );
};

export default KpoBdoCard;
