export const TRANSPORT_TYPES = {
    INTERNAL: "Internal",
    EXTERNAL: "External"
}

export const TransportType = {
    [TRANSPORT_TYPES.INTERNAL]: "Eko-Partner",
    [TRANSPORT_TYPES.EXTERNAL]: "Obcy"
}

export const TransportTypeColor = {
    Internal: "teal",
    External: "orange"
}