import { BaseOrderForm } from "./BaseOrderForm";
import { ordersActions } from "../../../_store/order.slice";
import { mapToCreateTransportRequest } from "../../../_services/Orders/orderMapper";
import * as Yup from "yup";

export function TransportOrderForm() {
  const defaultValues = {
    transportDetails : {
      transportType: "Internal",
      isTransportInvoiceNeeded: true,
    },
  };

  const validationSchema = Yup.object().shape({

  });


  return (
    <BaseOrderForm
      transactionTypeParam="Transport"
      validationSchema={validationSchema}
      submitAction={ordersActions.createTransportService}
      requestMapper={mapToCreateTransportRequest}
      skipIntermediary={true}
      setInternalTransportType={true}
      overrideAddressLabel="Adres załadunku"
      defaultValues={defaultValues}
    />
  );
}
