import { TableDomain } from "_components/Abstractions/TableDomain";

export function KeoBdoTable({ items, onSelect }) {
  const keyColumn = "keoId";

  const columns = [
    {
      name: "year",
      headerName: "Rok"
    },
    {
      name: "cardNumber",
      headerName: "Numer karty"
    },
    {
      name: "wasteCode",
      headerName: "Kod odpadu"
    },
    {
      name: "createdByUser",
      headerName: "Utworzone przez użytkownika"
    }
  ];

  const props = { keyColumn, columns, items, onSelect };

  return (
    <>
      <TableDomain {...props}></TableDomain>
    </>
  );
}