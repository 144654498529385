import { DomainPagination } from "../../Abstractions/DomainPagination";
import { TableDomain } from "../../Abstractions/TableDomain";
import { dateToString } from "../../../domain/Configuration";
import { OrderSeriesTypes } from "../../../useCases/orders/createOrderSeries/CreateOrderSeries";
import { useNavigate } from "react-router-dom";

export function OrderSeriesListViewTable({ items, paging, handlePageChange }) {
  const navigate = useNavigate();

  const Pagination = paging && (
    <DomainPagination
      onPageChange={handlePageChange}
      paging={paging}
      itemsCount={items.length}
    />);

  const columns = [
    // { name: "createdAt", headerName: "Data", map: (x) => stringToDate(x.createdAt) },
    { name: "name", headerName: "Nazwa" },
    { name: "seriesType", headerName: "Typ częstotliwości", map: (x) => OrderSeriesTypes[x.seriesType] },
    { name: "frequencyInterval", headerName: "Częstotliwość" },
    { name: "dateFrom", headerName: "Data od", map: (x) => dateToString(x.dateFrom) },
    { name: "dateTo", headerName: "Data do", map: (x) => dateToString(x.dateTo) },
    // { name: "description", headerName: "Zdarzenie" },
    // { name: "changes", headerName: "Opis", map: (x) =>  getChangeDescription(x) }
  ];

  return <div className="d-flex flex-column">
    {/*{items?.map(item => <OrderListViewItem key={item.id} orderItem={item}/>)}*/}
    <TableDomain onSelect={(item) => navigate(`/orders/series/${item.id}`)} keyColumn="id" columns={columns} items={items} selectable={true}/>
    {Pagination}
  </div>;
}