import { Dropdown } from "_components/Abstractions/Dropdown";
import { HorizontalView } from "_components/Abstractions/HorizontalView";
import { CheckListBox } from "_components/CheckListBox/CheckListBox";
import { FormControl } from "_components/Forms/FormControl";
import { TextArea } from "_components/Forms/TextArea";
import { ContractorTypes, OriginatorTypes } from "domain/ContractorType";
import { PickupTypes } from "domain/VehicleType";
import { useFormContext } from "react-hook-form";
import { SalesRepresentativeSelect } from "./SalesRepresentativeSelect";
import { DomainSwitch } from "../../../../_components/Abstractions/DomainSwitch";

export function ContractorMainDetailsForm() {
  const { watch } = useFormContext();
  const isBdoLinked = watch("isBdoLinked");
  const originatorType = watch("originatorType");
  const isSalesRepresentative = originatorType === "SalesRepresentative";

  return (
    <>
      <HorizontalView columnsSize={[5, 2, 5]}>
        <FormControl label="Nazwa wewnętrzna" name="name" autoFocus/>
        <FormControl label="NIP" name="taxId" readonly={isBdoLinked} required={isBdoLinked === true}/>
        <FormControl label="Nazwa z programu magazynowego" name="externalName" required={false}/>
      </HorizontalView>
      <HorizontalView>
        <Dropdown
          label="Typ klienta"
          name="originatorType"
          items={OriginatorTypes}/>
        {isSalesRepresentative && <SalesRepresentativeSelect name="salesRepresentative"/>}
      </HorizontalView>
      {isSalesRepresentative && <DomainSwitch className="mb-2" name="isSalesRepresentativeDefault" label="PH domyślnie podpięty do zlecenia"/>}
      <HorizontalView>
        <CheckListBox
          label="Rodzaje operacji"
          items={ContractorTypes}
          name="contractorTypes"
        />
        <CheckListBox
          label="Sposób transportu"
          name="pickupType"
          items={PickupTypes}
        />
      </HorizontalView>
      <TextArea label="Uwagi do klienta" name="remarks"/>
    </>
  );
}

