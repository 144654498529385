import { Table } from "react-bootstrap";
import { UpdateSalesRepresentative } from "../../useCases/salesRepresentatives/UpdateSalesRepresentative";
import { ChangeSalesRepresentativeState } from "../../useCases/salesRepresentatives/ChangeSalesRepresentativeState";
import { StatusBadge } from "../Abstractions/StatusBadge";
import { SortedTh } from "../Abstractions/SortedTh";

export function SalesRepresentativesTable({ items, sorting, itemActions }) {
  const columnsNames = {
    "name": "Nazwa",
    "phone": "Telefon",
  }

  const table = (
    <>
      <Table hover>
        <thead>
        <tr>
          {Object.entries(columnsNames).map(([value, name]) =>
            <SortedTh key={value} sorting={sorting} itemActions={itemActions} sortingValue={value}>{name}</SortedTh>)}
          <th>Status</th>
          <th>Akcje</th>
        </tr>
        </thead>
        <tbody>
        {items.loading && (
          <>
            <tr className="spinner-border"></tr>
          </>
        )}
        {items.length > 0 &&
          items.map((item, index) => (
            <tr key={index}>
              <td style={{ width: "30%" }}>{item.name}</td>
              <td style={{ width: "20%" }}>{item.phone}</td>
              <td >
                <StatusBadge isActive={item.isActive}/>
              </td>
              <td>
                <UpdateSalesRepresentative item={item}/>
                <ChangeSalesRepresentativeState item={item}/>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );

  return <>{table}</>;
}