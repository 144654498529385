import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "_store";
import {
  Col,
  Nav,
  NavDropdown,
  Navbar
} from "react-bootstrap";

import { StatusBadge } from "./StatusBadge";
import { VersionControl } from "../Maintenance/VersionControl";
import { ButtonOverlay } from "./ButtonOverlay";
import { DomainButton } from "./DomainButton";
import { ChangePassword } from "../../useCases/accounts/ChangePassword";
import { NotificationIcon } from "../Notifications/NotificationIcon";

export function NavMenu() {
  const navigate = useNavigate();
  const authUser = useSelector((x) => x.auth.user);
  const details = useSelector((x) => x.auth.details);
  const dispatch = useDispatch();
  const logout = () => dispatch(authActions.logout());

  // only show nav when logged in
  if (!authUser) return null;
  const createOrderDropdown = (
    <NavDropdown
      title="Dodaj zlecenie"
      id="navbarScrollingDropdown"
      data-bs-theme="light"
    >
      <NavDropdown.Item className="domain-control" onClick={() => navigate("/orders/create-purchase")}>
        Zakup
      </NavDropdown.Item>
      <NavDropdown.Item className="domain-control" onClick={() => navigate("/orders/create-sale")}>
        Sprzedaż
      </NavDropdown.Item>
      <NavDropdown.Item className="domain-control" onClick={() => navigate("/orders/create-transport")}>
        Usługa transportowa
      </NavDropdown.Item>
      <NavDropdown.Item className="domain-control" onClick={() => navigate("/orders/create-series")}>
        Zlecenie cykliczne
      </NavDropdown.Item>
    </NavDropdown>
  );

  const orderDropdown = (
    <NavDropdown
      title="Zlecenia"
      data-bs-theme="light"
    >
      <NavDropdown.Item className="domain-control" onClick={() => navigate("/orders/list-view")}>
        Lista zleceń
      </NavDropdown.Item>
      <NavDropdown.Item className="domain-control" onClick={() => navigate("/orders/calendar-view")}>
        Kalendarz zleceń
      </NavDropdown.Item>
      <NavDropdown.Item className="domain-control" onClick={() => navigate("/orders/vehicle-view")}>
        Widok samochodowy
      </NavDropdown.Item>
      <NavDropdown.Item className="domain-control" onClick={() => navigate("/orders/driver-view")}>
        Widok kierowców
      </NavDropdown.Item>
      <NavDropdown.Item className="domain-control" onClick={() => navigate("/orders/shipping-calendar-view")}>
        Kalendarz TIR
      </NavDropdown.Item>
      <NavDropdown.Item className="domain-control" onClick={() => navigate("/orders/series-view")}>
        Lista zleceń cyklicznych
      </NavDropdown.Item>
      <NavDropdown.Item className="domain-control" onClick={() => navigate("/orders/deleted")}>
        Lista zleceń usuniętych
      </NavDropdown.Item>
    </NavDropdown>
  );

  return (
    <Navbar bg="primary" data-bs-theme="dark" fixed="top">
      <div className="d-flex w-100 px-3">
        <Navbar.Brand onClick={() => navigate("/")}>
          {/*<link ref="%PUBLIC_URL%/logo.jpg"/>*/}
          <img className="border-black border-1 rounded-3 hover-pointer" height="30px" src={process.env.PUBLIC_URL + "/logo.jpg"} alt="logo"/>
        </Navbar.Brand>
        <Navbar.Collapse>
          <Nav className="me-auto">
            {createOrderDropdown}
            {orderDropdown}
            <NavLink to="/configuration" className="nav-item nav-link">
              Administracja
            </NavLink>
            <NavLink to="/reports" className="nav-item nav-link">
              Raporty
            </NavLink>
            <NavLink to="/keo-reports" className="nav-item nav-link">
              Raporty KEO
            </NavLink>
            <NavLink to="/containers/used-state" className="nav-item nav-link">
              Stan kontenerów
            </NavLink>
            {/*<NavLink to="/kpo-searcher" className="nav-item nav-link">*/}
            {/*  Wyszukiwarka KPO*/}
            {/*</NavLink>*/}
          </Nav>
        </Navbar.Collapse>

        <Navbar.Toggle/>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="">
            <VersionControl/>
          </Navbar.Text>
          <NotificationIcon/>
          <Navbar.Text className="me-2">
            Status BDO: <StatusBadge isActive={details?.isBdoLinked}/>
          </Navbar.Text>
          <Navbar.Text>
            Zalogowany: <a className="me-1" href="">{details?.login}</a>
            <ChangePassword userId={details?.id} />
            <ButtonOverlay variant="success" placement="bottom" tooltip={details?.companyName}>ID</ButtonOverlay>
          </Navbar.Text>
          <Col xs="auto">
            <DomainButton variant="secondary" onClick={logout} className="ms-2">
              Wyloguj
            </DomainButton>
          </Col>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}
