import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";
import { downloadReport } from "../_helpers/fileDownloader";
// import { defaultPageNumber, defaultPageSize } from "_store";

// create slice

const name = "bdo";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const bdoActions = { ...slice.actions, ...extraActions };
export const bdoReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    // companies: {},
  };
}


function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/bdo`;
  
    return {
        searchCompany: searchCompany(),
        searchEup: searchEup(),
        generateComparingKeoReport: generateComparingKeoReport(),
        generateMonthlyKeoReport: generateMonthlyKeoReport(),
        searchKeoCards: searchKeoCards(),
    };
  
    function searchCompany() {
      return createAsyncThunk(
        `${name}/searchCompany`,
        async ({ searchTerm }) =>
          await fetchWrapper.get(
            `${baseUrl}/company?searchQuery=${searchTerm}`
          )
      );
    }

    function searchEup() {
        return createAsyncThunk(
          `${name}/searchEup`,
          async ({ companyId, searchTerm }) =>
            await fetchWrapper.get(
              `${baseUrl}/company/${companyId}/eup?searchQuery=${searchTerm}`
            )
        );
      }

  function generateComparingKeoReport() {
    return createAsyncThunk(
      `${name}/generateComparingKeoReport`,
      async ({ payload, formData }) => {
        const {keoId, month, year, sheetName, keoReportType, outputType } = payload;
        const params = `month=${month}&year=${year}&sheetName=${sheetName}&keoReportType=${keoReportType}&outputType=${outputType}`;
        return await fetchWrapper.post(`${baseUrl}/keo/${keoId}/comparing-report?${params}`, formData);
      }
    );
  }

  function generateMonthlyKeoReport() {
    return createAsyncThunk(
      `${name}/generateMonthlyKeoReport`,
      async ({ payload }) => {
        const {keoId} = payload;
        return await fetchWrapper.post(`${baseUrl}/keo/${keoId}/monthly-report`, payload);
      }
    );
  }

  function searchKeoCards() {
    return createAsyncThunk(
      `${name}/search-keo-cards`,
      async ({ payload }) => await fetchWrapper.post(`${baseUrl}/keo/search`, payload)
    );
  }
}



function createExtraReducers() {
    return {
      ...generateMonthlyKeoReport(),
      ...generateComparingKeoReport(),
    };
  function generateMonthlyKeoReport() {
    var { fulfilled } = extraActions.generateMonthlyKeoReport;
    return {
      [fulfilled]: (state, action) => {
        const { payload } = action;
        downloadReport(payload);
      },
    };
  }

  function generateComparingKeoReport() {
    var { fulfilled } = extraActions.generateComparingKeoReport;
    return {
      [fulfilled]: (state, action) => {
        const { payload } = action;
        downloadReport(payload);
      },
    };
  }
}
