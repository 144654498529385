import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";
import { defaultPageNumber, defaultPageSize, getDictionaryQuery } from "_store";

// create slice

const name = "containers";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const containersActions = { ...slice.actions, ...extraActions };
export const containersReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    containers: {},
    containersPaging: null,
    containersSummary: null,
    usedContainers: []
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/containers`;

  return {
    getAll: getAll(),
    search: search(),
    create: create(),
    update: update(),
    refreshUsed: refreshUsed(),
    getState: getState(),
    getUsedState: getUsedState(),
    updateUsedContainers: updateUsedContainers()
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async(payload) =>
        await fetchWrapper.get(getDictionaryQuery(baseUrl, payload)));
  }

  function search() {
    return createAsyncThunk(
      `${name}/search`,
      async ({ pageNumber, pageSize, groupContainerId, searchTerm }) =>
        await fetchWrapper.get(
          `${baseUrl}?pageNumber=${pageNumber ?? defaultPageNumber}&pageSize=${pageSize ?? defaultPageSize}${groupContainerId ? `&groupContainerId=${groupContainerId}` : ""}&searchQuery=${searchTerm ?? ""}`
        )
    );
  }


  function create() {
    return createAsyncThunk(`${name}/create`, async (payload) => {
      return await fetchWrapper.post(`${baseUrl}`, payload);
    });
  }

  function update() {
    return createAsyncThunk(
      `${name}/update`,
      async ({ containerId, payload }) => {
        return await fetchWrapper.patch(`${baseUrl}/${containerId}`, payload);
      }
    );
  }

  function refreshUsed() {
    return createAsyncThunk(
      `${name}/refreshUsed`,
      async ({ payload }) => {
        return await fetchWrapper.post(`${baseUrl}/refresh-containers`, payload );
      }
    );
  }

  function getState() {
    return createAsyncThunk(
      `${name}/getState`,
      async ({ id }) => await fetchWrapper.get(`${baseUrl}/${id}/state`));}

  function getUsedState() {
    return createAsyncThunk(
      `${name}/getUsedState`,
      async () => await fetchWrapper.get(`${baseUrl}/used-state`));}

  function updateUsedContainers() {
    return createAsyncThunk(`${name}/updateUsedContainers`, async (payload) => {
      return await fetchWrapper.post(`${baseUrl}/used-batch`, payload);
    });
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...update(),
    ...getUsedState()
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;

    return {
      [pending]: (state) => {
        state.containers = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.containers = action.payload.items;
        state.containersPaging = action.payload.paging;
        state.containersSummary = action.payload.summary;
      },
      [rejected]: (state, action) => {
        state.containers = { error: action.error };
      },
    };
  }

  function update() {
    var { fulfilled } = extraActions.update;
    return {
      [fulfilled]: (state, action) => {
        const { containerId, payload } = action.meta.arg;
        const container = state.containers.find((x) => x.id === containerId);
        Object.assign(container, payload);
        container.remainingCount = container.initializeCount - container.usedCount;
      },
    };
  }

  function getUsedState() {
    var { fulfilled } = extraActions.getUsedState;
    return {
      [fulfilled]: (state, action) => {
        const { payload } = action;

        state.usedContainers = payload;
      },
    };
  }
}
