import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { dateToString } from "../../../domain/Configuration";
import { SquarePlaceType } from "../../../domain/SquarePlaceType";
import { LoadingSpinner } from "../../Abstractions/LoadingSpinner";
import { ReadonlyControl } from "../../Forms/ReadonlyControl";
import { DomainButton } from "../../Abstractions/DomainButton";
import { useSearchParams } from "react-router-dom";
import { CalendarShippingItem } from "./CalendarShippingItem";
import { Card } from "react-bootstrap";
import { ArrowLeftCircleFill, ArrowRightCircleFill } from "react-bootstrap-icons";
import { SemiTrailerType } from "../../../domain/VehicleType";
import { DateSearcher } from "../../Forms/DateSearcher";

export const OrderShippingCalendarView = ({ date, selectable, onSubmit, defaultValues, selectedId, includedVehicle }) => {
  const localizer = momentLocalizer(moment);
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultValue = dateToString(date ?? searchParams.get("date") ?? new Date());
  const [currentDate, setCurrentDate] = useState(defaultValue);
  const dispatch = useDispatch();
  const { shippingCalendarView } = useSelector(x => x.orderViews);
  const resources = shippingCalendarView?.availableSquarePlaceTypes?.map(x =>
    ({ squarePlaceType: x, squarePlaceTitle: SquarePlaceType[x] })) ?? [];

  const [selected, setSelected] = useState(defaultValues ?? {});
  const onSelect = (e) => setSelected(e);
  const handleDateChange = (e) => {
    setCurrentDate(e)
  };
  const handleDayChange = (offset) => {
    const date = new Date(currentDate);
    date.setDate(date.getDate() + offset);
    setCurrentDate(dateToString(date));
  };
  useEffect(() => {
    const refreshCalendar = async() => await dispatch(orderViewsActions.getShippingCalendarView({ date: currentDate, includedVehicleType: includedVehicle?.type }));
    setSearchParams({ "date": currentDate });
    refreshCalendar();
  }, [currentDate]);

  const selectPanel = <>
    {selectable && includedVehicle && <>
      {includedVehicle.name && <ReadonlyControl label="Naczepa">{includedVehicle.name}</ReadonlyControl>}
      {includedVehicle.type && <ReadonlyControl label="Typ naczepy">{SemiTrailerType[includedVehicle.type]}</ReadonlyControl>}
    </>}
    <ReadonlyControl label="Data" onSearchChange={handleDateChange} inputType="date">
      {defaultValue}
    </ReadonlyControl>
    {selectable &&
      <ReadonlyControl label="Wybrany czas">
        {selected.selectedStartTime && selected.selectedEndTime ? `${selected.selectedStartTime} - ${selected.selectedEndTime}` : "-"}
      </ReadonlyControl>}
    {selectable && <ReadonlyControl label="Miejsce na placu">
      {selected.squarePlaceType ? SquarePlaceType[selected.squarePlaceType] : "-"}
    </ReadonlyControl>}
    <div className="mb-3 h-100">
      <LoadingSpinner isLoading={shippingCalendarView?.loading} as="div"/>
      <DomainButton btnSize="lg" onClick={() => onSubmit(selected)}>Zapisz</DomainButton>
    </div>
  </>;

  return (
    <div>
      {!selectable &&
        <>
          <h2>Kalendarz TIR</h2>
          <div className="d-flex align-items-center gap-2">
            <DomainButton onClick={() => handleDayChange(-1)} className="mb-2"><ArrowLeftCircleFill/> Poprzedni</DomainButton>
            <DateSearcher label="" onDateChange={handleDateChange} inputType="date" currentValue={currentDate} readonly={selectable}/>
            <DomainButton onClick={() => handleDayChange(1)} className="mb-2">Następny <ArrowRightCircleFill/></DomainButton>
            <LoadingSpinner isLoading={shippingCalendarView?.loading} as="div"/>
          </div>
        </>}
      {selectable && <Card className="position-sticky top-0 z-3">
        <Card.Body className="d-flex my-0 py-0 pt-3 align-items-center justify-content-center gap-2">
          {selectPanel}
        </Card.Body>
      </Card>}
      <div style={{ height: "550px" }}>
        <CalendarShippingItem
          date={currentDate}
          onSelect={onSelect}
          selectable={selectable}
          selected={selected}
          localizer={localizer}
          selectedId={selectedId}
          items={shippingCalendarView.items}
          resources={resources}
          includedVehicleId={includedVehicle?.id}
        />
      </div>
    </div>
  );
};

