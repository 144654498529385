import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { orderViewsActions } from "../../_store/orderView.slice";

export function useGetOrderDetails({ id }) {
  const dispatch = useDispatch();
  const { orderDetails: item } = useSelector(x => x.orderViews);
  const getOrderById = useCallback(async ({ id, token }) => {
      dispatch(orderViewsActions.getById({ id, token }));
    }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const token = abortController.signal;

    getOrderById({ id, token });
    return () => {
      if(process.env.REACT_APP_ENVIROMENT === "Development") { return; }
      abortController.abort(); };
  }, [id, getOrderById]);

  return { item, getOrderById };
}