import { useDispatch } from "react-redux";
import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { mapToTransportDetails } from "../../../_services/Orders/orderMapper";
import { UpdateOrderTransportForm } from "./UpdateOrderTransportDetailsForm";

export function UpdateOrderSeriesTransport({ seriesId, transactionType, transportDetails }) {
  const dispatch = useDispatch();

  const onSubmit = async(payload) => {
    const requestPayload = { id: seriesId, transportDetails: mapToTransportDetails(payload) };
    const response = await dispatch(ordersActions.updateSeriesTransportDetails({ seriesId, payload: requestPayload }));
    if (response?.error) {
      return response;
    }

    await dispatch(orderViewsActions.getSeriesById({ id: seriesId }));

    return response;
  };

  return <UpdateOrderTransportForm
    orderId={seriesId}
    transportDetails={transportDetails}
    onSubmit={onSubmit}
    transactionType={transactionType}
    isMainDetailsUpdateAvailable
    isSeries/>;
}