import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { driversActions } from "../../_store/drivers.slice";

export function useDriverSearch({ driverType, allowSearchWithoutType = false, refreshOnInitialize = true }) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const isFirstRender = useRef(true);

  const onSearchChange = async(searchTerm, id) => {
    if ((!driverType && !allowSearchWithoutType) || (!allowSearchWithoutType && searchTerm === "" && items.length === 0)) {
      setItems([]);
      return;
    }

    const query = {
      pageNumber: 1,
      pageSize: 10,
      filter: { id, searchQuery: searchTerm, driverType: driverType ? driverType : null }
    };

    const { payload } = await dispatch(driversActions.search(query));
    const newItems = payload.items;
    setItems(newItems);
    return newItems;
  };

  useEffect(() => {
    if (!isFirstRender.current || refreshOnInitialize) {
      onSearchChange('');
    } else {
      isFirstRender.current = false;
    }
  }, [driverType]);

  return { items, onSearchChange };
}

