import { FormModal } from "../../../_components/Forms/FormModal";
import { ContractorOrderDetails } from "../../../_components/Orders/ContractorOrderDetails";
import { TRANSACTION_TYPE, TransactionTypeToClientType } from "../../../domain/TransactionType";
import { ORDER_STATUS } from "../../../domain/Orders/OrderStatus";
import { OrderProvider } from "../../../_components/Orders/MainForms/OrderContext";
import { OrderValidator } from "../../../domain/Orders/CreatePurchaseOrderValidator";

export function UpdateOrderMainDetailsForm({ transactionType, status, mainDetails, onSubmit, isSeries, wastesDetails }) {
  const isTransport = transactionType === TRANSACTION_TYPE.TRANSPORT;
  const contractorRole = !isTransport ? TransactionTypeToClientType[transactionType] : "Sender";
  const contractorId = mainDetails?.contractorId;
  const canUpdateMainContractorDetails = status === ORDER_STATUS.CREATED || isSeries;
  const formControls =
    <OrderProvider transactionType={transactionType} isUpdate>
      <ContractorOrderDetails contractorRole={contractorRole} overrideAddressLabel="Adres" isUpdate canUpdateMainContractorDetails={canUpdateMainContractorDetails} />
      {isTransport && <ContractorOrderDetails overrideContractorRole="Receiver" overrideAddressLabel="Adres rozładunku" isUpdate canUpdateMainContractorDetails={canUpdateMainContractorDetails}/>}
    </OrderProvider>;

  const defaultValues = {
    transactionType: transactionType,
    remarks: mainDetails?.remarks,
    externalOrderNumber: mainDetails?.externalOrderNumber,
    externalOrderNumberInit: mainDetails?.externalOrderNumber,
    intermediaryId: mainDetails?.intermediaryId,
    contractor: mainDetails ? {
      id: contractorId,
      name: mainDetails?.contractorName,
      centralAddressRaw: mainDetails?.centralAddressRaw,
      remarks: mainDetails?.contractorRemarks,
      salesRepresentativeInfo: mainDetails?.salesRepresentativeInfo,
      isSalesRepresentativeLinked: !!mainDetails?.salesRepresentativeId,
    } : null,
    orderAddress: mainDetails ? {
      id: mainDetails.addressId,
      name: mainDetails.addressName,
      rawAddress: mainDetails.addressRaw,
      remarks: mainDetails.addressRemarks,
      defaultContactPersonId: mainDetails.contactPersonId
    } : null,
    receiverContractor: mainDetails && isTransport ? {
      id: mainDetails?.additionalContractorId,
      name: mainDetails?.additionalContractorName,
      rawAddress: mainDetails.additionalContractorAddressRaw,
    } : null,
    receiverOrderAddress: mainDetails && isTransport ? {
      id: mainDetails?.additionalAddressId,
      name: mainDetails?.additionalAddressName,
      rawAddress: mainDetails?.additionalAddressRaw,
      remarks: mainDetails?.additionalAddressRemarks
    } : null,
    wastesDetails: wastesDetails
  };

  return <>
    <FormModal
      formControls={formControls}
      validationSchema={OrderValidator.validationSchema}
      sizeModal="lg"
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      buttonText="Edytuj główne dane"
      successMessage={isSeries ? "Zaktualizowano dane zlecenia cyklicznego i powiązanych z nim zleceń zaplanowanych" : "Zaktualizowano dane zlecenia"}
      title="Zmień główne dane"
      btnSize="x-small"
    />
  </>;
}