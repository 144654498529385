import { Popover } from "react-bootstrap";
import { ButtonOverlay } from "../../Abstractions/ButtonOverlay";
import { InfoCircle } from "react-bootstrap-icons";
import { CalendarDay } from "./CalendarDay";

export const CalendarDayOverlay = ({ item }) => {
  const InfoTooltip = () => <Popover>
    <Popover.Body className="p-0">
      <CalendarDay orderItem={item} />
    </Popover.Body>
  </Popover>;

  return <ButtonOverlay variant="secondary" size="x-small" placement="top" overrideTooltip tooltip={InfoTooltip({ item })}>
    <InfoCircle/>
  </ButtonOverlay>;
};

