import { DictionaryPage } from "_components/Abstractions/DictionaryPage";
import { ContractorsTable } from "_components/Contractors/ContractorsTable";
import { contractorsActions } from "_store/contractors.slice";
import { useSelector } from "react-redux";
import { DomainButton } from "_components/Abstractions/DomainButton";
import { useNavigate } from "react-router-dom";
import { SalesRepresentativeFilterSelect } from "./Extensions/CreateContractor/SalesRepresentativeFilterSelect";
import { HorizontalView } from "../../_components/Abstractions/HorizontalView";
import { MultiSelectFilter } from "../../_components/Abstractions/MultiSelect/MultiSelectFilter";
import { OrderStatus } from "../../domain/Orders/OrderStatus";
import { ContractorTypes } from "../../domain/ContractorType";
import { FilterControl } from "../../_components/Forms/FilterControl";

export function GetContractors({ activeEventKey, pageEventKey }) {
  const { contractors, contractorsPaging, contractorsSorting } = useSelector((x) => x.contractors);
  // const [sorting, setSorting] = useState({ value: "name", type: "asc" });
  const navigate = useNavigate();

  const table = <ContractorsTable contractors={contractors} sorting={contractorsSorting} itemActions={contractorsActions}/>;
  const createPage = (
    <DomainButton className="mb-2" onClick={() => navigate("/contractors/create")}>
      Dodaj klienta
    </DomainButton>
  );

  const customFiltersControls = <HorizontalView columnsSize={[3, 3, 3]}>
    <FilterControl name="address" label="Adres"/>
    <SalesRepresentativeFilterSelect name="salesRepresentativeId" />
    <MultiSelectFilter name="contractorTypes" options={ContractorTypes} title="Typ klienta"/>
  </HorizontalView>

  return (
    <DictionaryPage
      items={contractors}
      paging={contractorsPaging}
      itemsTable={table}
      itemActions={contractorsActions}
      createItem={createPage}
      activeEventKey={activeEventKey}
      pageEventKey={pageEventKey}
      includeSearcher
      searcherPlaceholder="Szukaj po nazwie, NIP, BDO..."
      sorting={contractorsSorting}
      customFiltersControls={customFiltersControls}
    />
  );
}
