export const KPO_STATUS = {
  PLANNED: "Planned",
  APPROVED: "Approved",
  CONFIRMATION_GENERATED: "ConfirmationGenerated",
  RECEIVE_CONFIRMATION: "ReceiveConfirmation",
  TRANSPORT_CONFIRMATION: "TransportConfirmation",
  WITHDRAWN: "Withdrawn",
  REJECTED: "Rejected",
  DELETED: "Deleted",
};

export const KpoCardStatus = {
  Planned: "Planowana",
  Approved: "Zatwierdzona",
  ConfirmationGenerated: "Potwierdzenie wygenerowane",
  TransportConfirmation: "Potwierdzenie transportu",
  ReceiveConfirmation: "Potwierdzenie przejęcia",
  Withdrawn: "Wycofana",
  Rejected: "Odrzucona",
  Deleted: "Usunięta",
};

export const KpoCardStatusColor = {
  Planned: "indigo",
  Approved: "teal",
  ConfirmationGenerated: "success",
  TransportConfirmation: "primary",
  ReceiveConfirmation: "orange",
  Withdrawn: "secondary",
  Rejected: "danger",
  Deleted: "dark",
};