import { useFormContext } from "react-hook-form";
import { UsedSubContainerForm } from "./UsedSubContainerForm";

export function UsedSubContainersForm({ name }) {
  const { watch } = useFormContext();
  const containers = watch(name);

  const controls = (
    <>
      {containers?.map((container, index) => (
        <div key={container.containerId}>
            <UsedSubContainerForm name={`${name}[${index}]`} />
        </div>
      ))}
    </>
  );

  return <>{controls}</>;
}