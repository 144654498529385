import { TableDomain } from "_components/Abstractions/TableDomain";
import { stringToDate } from "../../../domain/Configuration";
import { KpoBdoStatusBadge } from "./KpoBdoStatusBadge";
import { KpoBdoCardDetailsOverlay } from "./KpoBdoCardDetailsOverlay";
import { KpoBdoCardDetailsOffcanvas } from "./KpoBdoCardDetailsOffcanvas";

export function KpoBdoTable({ items, onSelect, onSelectedItemsChanged, selectedItems }) {
  const keyColumn = "kpoId";
  const columns = [
    {
      name: "cardNumber",
      headerName: "Numer karty"
    },
    {
      name: "realTransportTime",
      headerName: "Rzeczywista data transportu",
      map: (x) => stringToDate(x.realTransportTime)
    },
    {
      name: "wasteCode",
      headerName: "Kod odpadu"
    },
    {
      name: "vehicleRegNumber",
      headerName: "Numer rejestracyjny"
    },
    {
      name: "senderName",
      headerName: "Przekazujący"
    },
    {
      name: "receiverName",
      headerName: "Przejmujący"
    },
    {
      name: "status",
      headerName: "Status",
      map: x => <KpoBdoStatusBadge status={x.status}/>
    },
    {
      name: "additionalInfo",
      headerName: "Info",
      map: x => <div className="d-flex gap-1">
        <KpoBdoCardDetailsOverlay item={x}/>
        <KpoBdoCardDetailsOffcanvas item={x}/>
      </div>
    },

  ];

  const props = { keyColumn, columns, items, onSelect, multiSelect: true, onSelectedItemsChanged, selectedItems };

  return (
    <>
      <TableDomain {...props}></TableDomain>
    </>
  );
}
