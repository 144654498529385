import { useDispatch } from "react-redux";
import { useToast } from "../notifications/useToasts";

const useFetch = () => {
  const { showErrorMessage } = useToast();
  const dispatch = useDispatch();

  const fetchAction = async(action, setItems, isPaged = true) => {
    setItems({ loading: true });
    const response = await dispatch(action);
    const { payload: responsePayload, error } = response;
    setItems(isPaged ? responsePayload?.items : responsePayload ?? []);
    if (error) {
      setItems({ loading: false });
      showErrorMessage(error.message);
    }
  };

  return fetchAction;
};

export default useFetch;
