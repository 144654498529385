export const SquarePlaceType = {
  Scales: "Nr. 1 - Waga",
  Wall: "Nr. 2 - Ściana",
  Ramp: "Nr. 3 - Rampa",
  Pet: "Nr. 4 - Pety",
  Shed: "Nr. 5 - Wiata"
};

export const SQUARE_PLACE_TYPE = {
  SCALES: "Scales",
  WALL: "Wall",
  RAMP: "Ramp",
  PET: "Pet",
  SHED: "Shed"
};