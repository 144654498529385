import { authActions, store } from "_store";

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  patch: request("PATCH"),
  delete: request("DELETE")
};

function request(method) {
  return (url, body, token) => {
    const requestOptions = {
      method,
      headers: authHeader(url)
    };

    if (body instanceof FormData) {
      requestOptions.body = body;
    } else if (body) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }

    if(token) {
      requestOptions.signal = token;
    }

    return fetch(url, requestOptions).then(handleResponse);
  };
}

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = authToken();
  const isLoggedIn = !!token;
  const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

function authToken() {
  return store.getState().auth.user;
}

const isResponseOk = (response) => response.ok;

function isResponseJson(response) {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.includes("application/json");
}

function isResponseFile(response) {
  const contentType = response.headers.get("content-type");
  return contentType && (contentType.includes("pdf") || contentType.includes("vnd.openxmlformats-officedocument.spreadsheetml.sheet"));
}

function isTextPlain(response) {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.includes("text/plain");
}

function handleFileResponse(response) {
  const headers = response.headers;
  const contentDisposition = headers.get("Content-Disposition");
  const contentType = headers.get("Content-Type");
  let fileName = "plik";
  const matches = contentDisposition?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
  if (matches && matches.length > 1) {
    fileName = matches[1].replace(/['"]/g, "");
  }

  return response.blob().then((blob) => URL.createObjectURL(blob)).then((filePath) => {
    return { filePath, fileName, contentType };
  });
}

function handleJsonResponse(response) {
  return response.text().then((text) => {
    return text && JSON.parse(text);
  });
}

function handleErrorResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    let error = response.statusText;
    const errorCodeMessages = {
      "0": "Wystąpił problem z połączeniem się z serwerem",
      "401": "Proszę się zalogować",
      "403": "Brak uprawnień do wykonania akcji",
      "503": "Nastąpiło zbyt wiele połączeń z serwerem. Spróbuj ponownie lub skontaktuj się z Administratorem"
    }
    if (!response.ok) {
      if (errorCodeMessages[response.status] && authToken()) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        error = errorCodeMessages[response.status];
        if(response.status === 401) {
          const logout = () => store.dispatch(authActions.logout());
          logout();
        }

        return Promise.reject(error);
      }

      error = data.message;
      if (data.descriptions) {
        const defaultLanguage = "pl"; // TODO load from config
        error = data.descriptions[defaultLanguage];
      }

      if (data.errors) {
        error += Object.keys(data.errors).map(
          (key, idx) => `\n${idx + 1}) ${data.errors[key]}`
        );
      }

      return Promise.reject(error);
    }

    return data;
  });
}

function handleResponse(response) {
  if (!isResponseOk(response)) {
    return handleErrorResponse(response);
  }

  if (isResponseFile(response)) {
    return handleFileResponse(response);
  }
  if (isResponseJson(response)) {
    return handleJsonResponse(response);
  }

  if (isTextPlain(response)) {
    return response.text();
  }
}
