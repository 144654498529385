import { Dropdown } from "_components/Abstractions/Dropdown";
import { FormControl } from "_components/Forms/FormControl";
import { TextArea } from "_components/Forms/TextArea";
import { TransactionTypeToClientType, TransactionTypeToMainType } from "domain/TransactionType";
import { useFormContext } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { ContractorSelect } from "_components/Contractors/ContractorSelect";
import { AddressSelect } from "_components/Addresses/AddressSelect";
import { useContractorExtendedData } from "_components/Contractors/ContractorExtentedData";
import { HorizontalView } from "_components/Abstractions/HorizontalView";
import { ContractorTypeLargeBadge } from "./Extensions/ContractorTypeLargeBadge";
import { CardView } from "../Abstractions/CardView";
import { DomainSwitch } from "../Abstractions/DomainSwitch";
import { OrderContext } from "./MainForms/OrderContext";
import { Alert } from "react-bootstrap";

export function ContractorOrderDetails({ overrideContractorRole, overrideAddressLabel, canUpdateMainContractorDetails = false, isUpdate = false }) {
  const { watch, setValue, getValues } = useFormContext();
  const transactionType = watch("transactionType");
  const isSale = transactionType === "Sale";
  const contractorRole = overrideContractorRole ?? TransactionTypeToMainType[transactionType];
  const isTransport = transactionType === "Transport";
  const isTransportSender = isTransport && contractorRole === "Sender";
  const isTransportReceiver = isTransport && contractorRole === "Receiver";
  const contractorType = TransactionTypeToClientType[transactionType];
  const transportContractorType = isTransportSender ? "Sender" : isTransportReceiver ? "Receiver" : null;
  const contractorField = isTransportReceiver ? "receiverContractor" : "contractor";
  const addressField = isTransportReceiver ? "receiverOrderAddress" : "orderAddress";
  const address = watch(addressField);
  const contractor = watch(contractorField);
  const externalOrderNumber = watch("externalOrderNumber");
  const externalOrderNumberInit = watch("externalOrderNumberInit");
  const contractorId = contractor?.id;
  const { isSalesRepresentativeDefault } = contractor ?? {};
  const { isBdoIntegrated } = useContext(OrderContext);
  const isExternalOrderNumberApplicableForKpoItemsUpdate = externalOrderNumber !== externalOrderNumberInit && isBdoIntegrated;
  const { getExtendedData } = useContractorExtendedData({ contractorId, contractorField, isUpdate, isSalesRepresentativeDefault, isBdoIntegrated });
  const { intermediaries, addresses } = getExtendedData();
  const contactPersons = address?.contactPersons ?? addresses?.find(x => x.id === address?.id)?.contactPersons;
  const getFieldName = (prefix, field) => `${prefix}.${field}`;
  const [previousContractorId, setPreviousContractorId] = useState(contractorId);

  useEffect(() => {
    setValue(contractorField, null);
  }, [transactionType])

  useEffect(() => {
    if (isUpdate && previousContractorId === contractorId) {
      return;
    }
    const clearContractorData = () => {
      setPreviousContractorId(contractorId);
      setValue(addressField, null);
      setValue("intermediaryId", null);
    };

    clearContractorData();

    if (!contractor) {
      return;
    }

    const { pickupType } = contractor;
    if (pickupType?.length === 1 && !isTransportReceiver) {
      setValue("transportDetails.pickupType", pickupType[0]);
    }

  }, [contractor]);

  useEffect(() => {
    if (isTransportReceiver) {
      return;
    }

    if (isUpdate) {
      setValue("intermediaryId", getValues("intermediaryId"));
      return;
    }

    const defaultIntermediaryEntry = intermediaries ? Object.entries(intermediaries).find(([_, value]) => value.isDefault) : null;
    const [defaultIntermediaryKey, _] = defaultIntermediaryEntry || [];

    setValue("intermediaryId", defaultIntermediaryKey);
  }, [intermediaries]);

  useEffect(() => {
    if (isUpdate) {
      return;
    }

    const defaultAddress = addresses?.find((x) => x.isDefault);
    if (defaultAddress) {
      setValue(addressField, defaultAddress);
    }
  }, [addresses]);

  useEffect(() => {
    if (!address) {
      return;
    }

    let { contactPersonId, defaultContactPersonId } = address;
    if (defaultContactPersonId && contactPersons && isUpdate) {
      setValue("orderAddress.contactPersonId", defaultContactPersonId);
      return;
    }
    if (isUpdate) {
      return;
    }

    let defaultContactEntry = null;
    if (contactPersons) {
      const entries = Object.entries(contactPersons);
      defaultContactEntry = entries.find(([_, value]) => value.isDefault) ?? entries.find(([_]) => true);
    }

    const [defaultContactKey, _] = defaultContactEntry || [];
    if (contactPersonId === defaultContactKey) {
      return;
    }

    setValue("orderAddress.contactPersonId", defaultContactKey);
  }, [address, contactPersons]);

  return (<>
    <CardView headerText="Dane klienta" headerTypo="h4" headerContent={<ContractorTypeLargeBadge role={contractorRole}/>}>
      <HorizontalView>
        <ContractorSelect name={contractorField} contractorType={isTransport ? transportContractorType : contractorType} readonly={isUpdate && (!canUpdateMainContractorDetails && isBdoIntegrated)}/>
        <AddressSelect name={addressField}
                       label={`${overrideAddressLabel ?? "Adres odbioru"} (nazwa)`}
                       addressesField={getFieldName(contractorField, "addresses")} readonly={!contractor || isUpdate && (!canUpdateMainContractorDetails && isBdoIntegrated)}/>
      </HorizontalView>

      <HorizontalView>
        {contractor?.centralAddressRaw && <FormControl label="Adres siedziby" name={getFieldName(contractorField, "centralAddressRaw")} readonly/>}
        {address && (<FormControl label={`${overrideAddressLabel ?? "Adres odbioru"}`} name={getFieldName(addressField, "rawAddress")} readonly/>)}
      </HorizontalView>
      <HorizontalView>
        {contractor?.remarks && (<TextArea label="Informacje o kliencie" name={getFieldName(contractorField, "remarks")} readonly/>)}
        {address?.remarks && (<TextArea label="Informacje o adresie" name={getFieldName(addressField, "remarks")} readonly/>)}
      </HorizontalView>
      {!isTransportReceiver &&
        <>
          <HorizontalView>
            <Dropdown
              items={intermediaries} name="intermediaryId"
              label="Pośrednik"
              displayProperty="name"
              readonly={!contractor}
              required={false}/>
            {/*(<FormControl label="Kontakt" name={getFieldName(addressField, "contactPersonInfo")} required={false}/>)*/}
            {address && <Dropdown
              items={contactPersons}
              name={getFieldName(addressField, "contactPersonId")}
              label="Osoba kontaktowa"
              displayProperty="name"
              readonly={!contractor}
              required={false}/>}
            <div>
              {contractor?.salesRepresentativeInfo && <FormControl label="Przedstawiciel handlowy" name={getFieldName(contractorField, "salesRepresentativeInfo")} readonly required={false}/>}
              {contractor?.salesRepresentativeInfo && <DomainSwitch className="mb-2" name={getFieldName(contractorField, "isSalesRepresentativeLinked")} label="Podpięcie PH do zlecenia"/>}
            </div>
          </HorizontalView>
          {isSale && (<FormControl name="externalOrderNumber" label="Numer zamówienia przejmującego" required={false}/>)}
          {isSale && isUpdate && isExternalOrderNumberApplicableForKpoItemsUpdate && <Alert variant="success">Zmiana numeru zamówienia przejmującego spowoduje zmianę dodatkowych informacji w kartach KPO planowanych i zatwierdzonych</Alert>}
          <TextArea label="Uwagi do zlecenia" name="remarks"/>
        </>}
    </CardView>
  </>);
}
