import { BadgeDomain } from "../../Abstractions/BadgeDomain";
import { Card } from "react-bootstrap";

export function OrderDriverActionsDetailsView({ orderStatus, driverActionsDetails }) {
  const { isConfirmedByDriver, isCourseCompletedByDriver, isKpoConfirmationGeneratedByDriver } = driverActionsDetails || {};
  if(!isConfirmedByDriver && !isCourseCompletedByDriver && !isKpoConfirmationGeneratedByDriver) {
    return;
  }

  return <Card.Title>
    Kurs:{" "}
    {isKpoConfirmationGeneratedByDriver && <BadgeDomain bg="primary">KPO</BadgeDomain>}
    {isConfirmedByDriver && !isCourseCompletedByDriver && <BadgeDomain bg="indigo">Rozpoczęty przez kierowcę</BadgeDomain>}
    {isCourseCompletedByDriver && <BadgeDomain bg="success">Zrealizowany przez kierowcę</BadgeDomain>}
  </Card.Title>;
}