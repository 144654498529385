import { FloatingLabel, Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

export function TextArea({ label, name, inputType, value, readonly, height }) {
  const { register, formState } = useFormContext();
  const { errors } = formState;

  return (
    <>
      <Form.Group className="mb-3">
        <FloatingLabel label={label}>
          <Form.Control
            as="textarea"
            type={inputType ?? "text"}
            placeholder={label}
            isInvalid={!!errors[name]}
            rows={3}
            disabled={readonly}
            style={{ height: height ?? "50px" }}
            className="form-input form"
            {...register(name, { value: value })}
          />
          <Form.Control.Feedback type="invalid">
            {errors[name]?.message}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    </>
  );
}
