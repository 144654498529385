import { useDispatch, useSelector } from "react-redux";
import { groupContainersActions } from "../../_store/groupContainers.slice";
import { GroupContainerForm } from "./GroupContainerForm";

export function CreateGroupContainer() {
  const dispatch = useDispatch();
  const { groupContainersPaging } = useSelector((x) => x.groupContainers);

  async function onSubmit(payload) {
    const response = await dispatch(groupContainersActions.createGroup(payload));
    if (!response?.error) {
      const pageNumber = groupContainersPaging?.pageNumber ?? 1;
      dispatch(groupContainersActions.getAll({ pageNumber }));
    }

    return response;
  }

  const buttonText = "Dodaj grupę kontenerów";
  const successMessage = "Dodano grupę kontenerów";
  const defaultValues = { containers: [] };

  return <GroupContainerForm onSubmit={onSubmit} defaultValues={defaultValues} successMessage={successMessage} buttonText={buttonText}/>;
}
