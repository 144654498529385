import React from "react";
import { Card } from "react-bootstrap";
import AddressDetails from "./AddressDetails";
import s from "./style.module.css";

const AddressesDetails = ({ addresses }) => {
  return (
    <Card className={s.sectionCard}>
      <Card.Header as="h4">Adresy</Card.Header>
      <Card.Body className="upper">
        <div className={s.group}>
          {addresses?.map((address) => (
            <AddressDetails key={address.id} address={address} />
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default AddressesDetails;
