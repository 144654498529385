import { Card } from "react-bootstrap";
import { stringToDate, stringToDateExact } from "../../../domain/Configuration";
import { VehicleTypeBadge } from "../../Vehicles/VehicleTypeBadge";
import { TransportTypeBadge } from "../Components/TransportTypeBadge";
import { YesNoBadge } from "../../Abstractions/YesNoBadge";
import { OrderLineItem } from "./OrderDetailsView";
import { UpdateOrderTransport } from "../../../useCases/orders/updateTransportDetails/UpdateOrderTransport";
import { ORDER_STATUS } from "../../../domain/Orders/OrderStatus";
import { LoadingSpinner } from "../../Abstractions/LoadingSpinner";
import { Link } from "react-router-dom";
import { SquarePlaceType } from "../../../domain/SquarePlaceType";
import { InvoiceTransportType } from "../../../domain/InvoiceTransportType";
import { SemiTrailerTypeBadge } from "../Components/SemiTrailerTypeBadge";
import { Fragment } from "react";
import { UpdateOrderSeriesTransport } from "../../../useCases/orders/updateTransportDetails/UpdateOrderSeriesTransport";
import { TRANSPORT_TYPES } from "../../../domain/TransportType";

export function OrderTransportDetailsView({ item, isOrderSeries = false }) {
  const { id, transportDetails, status, transactionType, orderBdoInfo } = item || {};
  const hasKpoCards = orderBdoInfo?.kpoCards?.length > 0;
  const isUpdateVisible = !isOrderSeries && status !== ORDER_STATUS.CLOSED && status !== ORDER_STATUS.CANCELLED;
  const isMainDetailsUpdateAvailable = status === ORDER_STATUS.CREATED;
  const isRealTransportDateVisible = !isOrderSeries && status !== ORDER_STATUS.CREATED;

  return <Card className="h-100">
    <Card.Header className="d-flex align-items-center justify-content-between">
      Dane transportu
      {isUpdateVisible &&
        <UpdateOrderTransport
          isMainDetailsUpdateAvailable={isMainDetailsUpdateAvailable}
          transactionType={transactionType}
          transportDetails={transportDetails}
          orderId={id}
          hasKpoCards={hasKpoCards}
        />}
      {isOrderSeries && <UpdateOrderSeriesTransport seriesId={id} transportDetails={transportDetails} transactionType={transactionType} />}
    </Card.Header>
    <LoadingSpinner isLoading={transportDetails?.loading} as="div" center/>
    {!transportDetails?.loading && <Card.Body>
      <OrderLineItem name="Planowana data załadunku">{stringToDate(transportDetails?.plannedLoadingDate)}</OrderLineItem>
      {isRealTransportDateVisible && <OrderLineItem name="Rzeczywista data załadunku">{stringToDate(transportDetails?.realLoadingDate)}</OrderLineItem>}
      {transportDetails?.plannedReloadingDate &&
        <OrderLineItem name="Planowana data rozładunku">
          {transportDetails.plannedReloadingDate ? stringToDate(transportDetails.plannedReloadingDate) : "-"}
        </OrderLineItem>}
      <div className="d-flex gap-2">
        {transportDetails?.pickupType && <OrderLineItem name="Typ pojazdu">{<VehicleTypeBadge type={transportDetails?.pickupType}/>}</OrderLineItem>}
        {transportDetails?.transportType && <OrderLineItem name="Typ transportu">{<TransportTypeBadge transportType={transportDetails?.transportType}/>}</OrderLineItem>}
      </div>
      <div className="d-flex gap-2">
        {transportDetails?.shippingDate && <OrderLineItem
          name="Data awizacji">{stringToDateExact(transportDetails?.shippingDate, "dd-MM-yyyy")} {transportDetails?.plannedShippingTimeStart} - {transportDetails?.plannedShippingTimeEnd}</OrderLineItem>}
        {transportDetails?.squarePlaceType && <OrderLineItem name="Miejsce plac">{SquarePlaceType[transportDetails?.squarePlaceType]}</OrderLineItem>}
      </div>

      <div className="d-flex gap-2">
        <OrderLineItem name="Kierowca">{transportDetails?.driverName ?? "-"}</OrderLineItem>

        <OrderLineItem name="Telefon">{transportDetails?.driverPhone ?? "-"}</OrderLineItem>
      </div>
      {transportDetails?.transportType === TRANSPORT_TYPES.INTERNAL &&
        <OrderLineItem name="Uwagi dla kierowcy">{transportDetails?.driverRemarks ?? "-"}</OrderLineItem>}

      {transportDetails?.transportType === "External" && <>
        <OrderLineItem name="Przewoźnik"> {transportDetails?.transportContractorName}</OrderLineItem>
        <OrderLineItem name="Nr. rejestracyjny pojazdu">{transportDetails?.vehicleRegNumber ? `${transportDetails.vehicleRegNumber}` : "-"}</OrderLineItem>
        <OrderLineItem name="Nr. rejestracyjny naczepy">
          {transportDetails?.includedVehicleRegNumber ? `${transportDetails.includedVehicleRegNumber} ` : "-"}
          {transportDetails.includedVehicleType && <SemiTrailerTypeBadge type={transportDetails.includedVehicleType}/>}
        </OrderLineItem>
      </>}
      {transportDetails?.transportType === "Internal" && <>
        <OrderLineItem name="Pojazd">{transportDetails?.vehicleName ? `${transportDetails.vehicleName}` : "-"}</OrderLineItem>
        <OrderLineItem name={transportDetails.pickupType === "Tir" ? "Naczepa" : "Przyczepa"}>
          {transportDetails?.includedVehicleName ? `${transportDetails.includedVehicleName} ` : "-"}
          {transportDetails.includedVehicleType && (<SemiTrailerTypeBadge type={transportDetails.includedVehicleType}/>)}
        </OrderLineItem>
      </>}

      <OrderLineItem name="Przerzut"><YesNoBadge value={transportDetails?.isTransfer}/></OrderLineItem>
      {transportDetails?.isTransfer && transactionType === "Purchase" && <>
        <OrderLineItem name="Nr. zlecenia sprzedaży przerzutu">
          <Link to={`/orders/${transportDetails?.transferSaleId}`}>{transportDetails?.transferSaleNumber}</Link>
        </OrderLineItem></>}
      {transportDetails?.isTransfer && transactionType === "Sale" && <>
        <OrderLineItem name="Pełny przerzut"><YesNoBadge value={transportDetails?.isFullyTransfer}/></OrderLineItem>
        <OrderLineItem name="Nr. zleceń zakupów przerzutu">
          {transportDetails.transferPurchases?.map((item, index, array) =>
            <Fragment key={item.id}>
            <Link to={`/orders/${item.id}`}>{item.displayName}</Link>
              {index < array.length - 1 && ", "}
            </Fragment>
          )}
        </OrderLineItem>
      </>}
      <div className="d-flex gap-2 mb-2">
        <OrderLineItem name="Faktura za transport"><YesNoBadge value={transportDetails?.isTransportInvoiceNeeded}/></OrderLineItem>
        {transportDetails?.isTransportInvoiceNeeded && <>
          {transportDetails?.transportType === "External" && <OrderLineItem name="Faktura na">{InvoiceTransportType[transportDetails?.invoiceTransportType]}</OrderLineItem>}
        </>}
      </div>
      {transportDetails?.isTransportInvoiceNeeded &&
        <OrderLineItem name="Dane faktury">{transportDetails?.transportInvoiceNumber ?? "-"} | {transportDetails?.transportInvoiceAmount?.toFixed(2) ?? "-"} zł</OrderLineItem>}
      <div className="d-flex gap-2">
        {transportDetails?.transportType === "External" && <OrderLineItem name="Spedycja"> <YesNoBadge value={transportDetails?.isFreightForwarder}/></OrderLineItem>}
        {transportDetails?.isFreightForwarder && <>
          <OrderLineItem name="Firma spedycyjna">{transportDetails?.freightForwarderName}</OrderLineItem>
        </>}
      </div>
    </Card.Body>}
  </Card>;
}