import { SelectSearcher } from "_components/Abstractions/MultiSearcher/SelectSearcher";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { groupContainersActions } from "../../_store/groupContainers.slice";

export function GroupContainersForAddressSearchSelect({ name, addressId, saveWholeObject = true }) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  const onSearchChange = async(searchTerm) => {
    if(!addressId){
      setItems([]);
      return;
    }

    const params = { searchTerm, pageNumber: 1, pageSize: 10, addressId };
    const { payload } = await dispatch(groupContainersActions.searchForAddress(params));
    const items = payload.items.map(x => { return { usedContainerId: x.usedContainerId, groupContainerType: x.type, wasteIds: x.wastesIds };});
    setItems(items);
  };

  useEffect(() => {
    onSearchChange(null);
  }, [addressId]); // eslint-disable-next-line/react-hooks/exhaustive-deps

  return (
    <>
      <SelectSearcher
        name={name}
        label="Grupa kontenera"
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "usedContainerId", value: "groupContainerType" }}
        placeholder="Wyszukaj grupę kontenera..."
        readonly={!addressId}
        saveWholeObject={saveWholeObject}
      />
    </>
  );
}
