import { FormControl } from "../../../../_components/Forms/FormControl";
import { HorizontalView } from "../../../../_components/Abstractions/HorizontalView";
import { IntegerControl } from "../../../../_components/Forms/IntegerControl";

export function UsedSubContainerForm({ name }) {
  const GetFullField = (field) => `${name}.${field}`;

  return <>
    <HorizontalView>
      <FormControl name={GetFullField("containerName")} label="Podtyp kontenera" readonly/>
      <IntegerControl name={GetFullField("usedCount")} label="Liczba używanego podtypu"/>
    </HorizontalView>
  </>;
}