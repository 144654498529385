import { Table } from "react-bootstrap";
import { ContractorType } from "./ContractorType";
import { StatusBadge } from "_components/Abstractions/StatusBadge";
import { ButtonOverlay } from "_components/Abstractions/ButtonOverlay";
import { LoadingSpinner } from "../Abstractions/LoadingSpinner";
import { RowLink } from "../Abstractions/RowLink";
import { SortedTh } from "../Abstractions/SortedTh";

export function ContractorsTable({ contractors, sorting, itemActions }) {
  const columnsNames = {
    "name": "Nazwa",
    "taxId": "NIP",
    "address": "Adres centrali",
    "contactPerson": "Osoba kontaktowa",
    "salesRepresentative": "Przedstawiciel handlowy",
  }

  return (
    <>
      <Table hover>
        <thead>
        <tr>
          {Object.entries(columnsNames).map(([value, name]) =>
            <SortedTh key={value} sorting={sorting} itemActions={itemActions} sortingValue={value}>{name}</SortedTh>)}
          <th>Typ klienta</th>
          <th>Pośrednicy</th>
          <th>Status</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        <LoadingSpinner isLoading={contractors.loading}/>
        {contractors.length > 0 && contractors.map((x, index) =>
          (
            <tr key={index} className="position-relative">
              <td style={{ minWidth: "250px", maxWidth: "250px" }} className="td-ellipsis">{x.name}</td>
              <td style={{ width: "80px" }}>{x.taxId}</td>
              <td style={{ minWidth: "250px", maxWidth: "250px" }} className="td-ellipsis">{x.centralAddressRaw}</td>
              <td style={{ width: "300px" }}>{x.defaultContact?.name} {x.defaultContact?.phone}</td>
              <td style={{ width: "300px" }}>{x.salesRepresentative?.name}</td>
              <td style={{ width: "300px" }}><ContractorType types={x.contractorTypes}/></td>
              <td onClick={(e) => e.preventDefault()}>
                <IntermediaryOverlay intermediaries={x.intermediaries} />
              </td>
              <td style={{ zIndex: 1000 }}>
                <StatusBadge isActive={x.isActive}/>
              </td>
              <RowLink to={`/contractors/${x.id}`}/>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

const IntermediaryOverlay = ({ intermediaries }) => {
  return intermediaries?.length > 0 ? (
      <ButtonOverlay
        tooltip={
          <div>
            {intermediaries.map((x, idx) => (
              <li key={idx}>{x.name}</li>
            ))}
          </div>
        }
      >
        TAK
      </ButtonOverlay>
    ) : (
      "-"
    )
}