import { stringToDate } from "../../../domain/Configuration";
import { ListGroup, Popover } from "react-bootstrap";
import { ButtonOverlay } from "../../Abstractions/ButtonOverlay";
import { InfoCircle } from "react-bootstrap-icons";

export const KpoBdoCardDetailsOverlay = ({ item }) => {
  const getDateDisplay = (date) => date ? stringToDate(date) : "-";
  const InfoTooltip = () => <Popover>
    <Popover.Header>{<b className="small">{item.cardNumber}</b>}</Popover.Header>
    <Popover.Body>
      <ListGroup>
        <ListGroup.Item>Osoba przekazująca:<br/> {item.senderUser ?? "-"}</ListGroup.Item>
        <ListGroup.Item>Osoba przejmująca:<br/> {item.receiverUser ?? "-"}</ListGroup.Item>
        <ListGroup.Item>Planowana data transportu:<br/> {getDateDisplay(item.plannedTransportTime)}</ListGroup.Item>
        {item.revisionNumber > 0 &&
          <>
            <ListGroup.Item>Numer korekty: {item.revisionNumber}</ListGroup.Item>
            <ListGroup.Item>Data odrzucenia:<br/> {getDateDisplay(item.cardRejectionTime)}</ListGroup.Item>
            <ListGroup.Item>Osoba odrzucająca kartę: <br/>{item.rejectedByUser ?? "-"}</ListGroup.Item>
          </>}
        <ListGroup.Item>Data potwierdzenia przejęcia:<br/> {getDateDisplay(item.receiveConfirmationTime)}</ListGroup.Item>
        <ListGroup.Item>Data ostatniej zmiany:<br/> {getDateDisplay(item.kpoLastModifiedAt)}</ListGroup.Item>
      </ListGroup>
    </Popover.Body>
  </Popover>;

  return <ButtonOverlay placement="left" overrideTooltip tooltip={InfoTooltip({ item })}>
    <InfoCircle/>
  </ButtonOverlay>;
};

