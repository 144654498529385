import { FormModal } from "../../../_components/Forms/FormModal";
import { useDispatch } from "react-redux";
import { GenerateOrderKpoCardsForm } from "./GenerateOrderKpoCardsForm";
import * as Yup from "yup";
import { WasteMassValidator } from "../../../domain/Orders/CreatePurchaseOrderValidator";
import { kpoActions } from "../../../_store/kpo.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { stringToDateTimeForm } from "../../../domain/Configuration";
import { ordersActions } from "../../../_store/order.slice";
import { TRANSPORT_TYPES } from "../../../domain/TransportType";

export function GenerateOrderKpoCards({ order }) {
  const dispatch = useDispatch();
  const { id: orderId, contractorDetails, transportDetails } = order;
  const { addressBdoRegistrationNumber, bdoCompanyName } = contractorDetails;
  const today = new Date();
  today.setMinutes(today.getMinutes() + 5);
  const minimumDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
  const kpoCardToGenerateValidationSchema = Yup.object().shape({
    wasteBdo: Yup.object().nullable().required("Towar BDO jest wymagany").typeError("Towar BDO jest wymagany"),
    wasteMass: WasteMassValidator.validator,
  });
  const validationSchema = Yup.object().shape({
    kpoCards: Yup.array().of(kpoCardToGenerateValidationSchema).min(1, "Przynajmniej jeden towar powinien być zdefiniowany do wygenerowania karty"),
    bdoCompanyName: Yup.string().required("Podmiot BDO zlecenia jest wymagany"),
    addressBdoRegistrationNumber: Yup.string().required("Adres BDO zlecenia jest wymagany"),
    plannedTransportTime: Yup.date().typeError("Planowana data rozpoczęcia transportu jest wymagana").min(minimumDate, "Planowana data rozpoczęcia transportu nie może być wcześniejsza niż dzisiejsza"),
    transportType: Yup.string().required("Rodzaj transportu jest wymagany").typeError("Rodzaj transportu jest wymagany"),
    carrierCompanyName: Yup.string().nullable().when("transportType", {
      is: TRANSPORT_TYPES.EXTERNAL, then: Yup.string().nullable().required("Przewoźnik jest wymagany dla zlecenia BDO, aby wygenerować karty")
    }),
  });

  const defaultValues = {
    kpoCards: [{}],
    bdoCompanyName,
    addressBdoRegistrationNumber,
    plannedTransportTime: stringToDateTimeForm(today),
    transportType: transportDetails?.transportType,
    carrierCompanyName: transportDetails?.transportContractorName,
    additionalInfo: order?.contractorDetails?.externalOrderNumber
  };

  const handleDownloadWastesBdo = async() => {
    const { payload } = await dispatch(ordersActions.getOrderWastesBdo({ orderId }));
    return payload?.map(x => ({ wasteBdo: x.wasteBdo, wasteMass: x.wasteMass }));
  }

  const formControls = <GenerateOrderKpoCardsForm handleDownloadWastesBdo={handleDownloadWastesBdo} transportType={defaultValues?.transportType}/>;

  const onSubmit = async(payload) => {
    const kpoCardsToGenerate = payload.kpoCards.map((x) => ({ wasteCodeBdoId: x.wasteBdo.id, wasteMass: x.wasteMass }));
    const requestPayload = { orderId, kpoCardsToGenerate, plannedTransportTime: payload.plannedTransportTime }
    const response = await dispatch(kpoActions.createPlannedKpoCards({ orderId, payload: requestPayload }));
    if (!response?.error) {
      dispatch(orderViewsActions.getBdoInfoById({ id: orderId }));
      // dispatch(orderViewsActions.refreshHistory({ id: orderId }));
    }

    return response;
  };

  return <>
    <FormModal
      formControls={formControls}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      sizeModal="lg"
      buttonText="Wygeneruj planowane karty KPO"
      successMessage="Wygenerowano planowane karty KPO"
      title="Generowanie kart KPO do zlecenia"
      submitBtnText="Wygeneruj karty"
    />
  </>;
}