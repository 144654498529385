import * as Yup from "yup";
import { FormControl } from "_components/Forms/FormControl";
import { FormModal } from "_components/Forms/FormModal";
import { WasteBdoSelect } from "./WasteBdoSelect";

export function WasteForm({ onSubmit, defaultValues, isUpdate }) {
  const validationSchema = Yup.object().shape({
    internalName: Yup.string()
      .required("Nazwa własna jest wymagana")
      .max(50, "Nazwa formalna nie może mieć więcej niż 50 znaków"),
    wasteBdo: Yup.object()
      .required()
      .typeError("Towar BDO jest wymagany")
      .shape({
        description: Yup.string()
          .required("Nazwa formalna jest wymagana")
          .max(400, "Kod odpadu nie może mieć więcej niż 50 znaków"),
      }),
  });

  const formControls = (
    <>
      <FormControl label="Nazwa wewnętrzna" name="internalName" autoFocus />
      <WasteBdoSelect name="wasteBdo" />
      <FormControl
        label="Nazwa formalna"
        name="wasteBdo.description"
        readonly
      />
      <FormControl label="Kod odpadu" name="wasteBdo.code" readonly />
      <FormControl label="Ref. BDO" name="wasteBdo.id" readonly />
    </>
  );

  const buttonText = isUpdate ? "Edytuj towar" : "Dodaj towar";
  const successMessage = isUpdate ? "Zaktualizowano towar" : "Dodano towar";
  const title = buttonText;

  const onSubmitForm = (data) => {
    const payload = {
      id: data.wasteId,
      wasteCodeBdoId: data.wasteBdo?.id,
      internalName: data.internalName,
      externalName: data.wasteBdo?.description,
      wasteCode: data.wasteBdo?.code,
    };
    return onSubmit(payload);
  };

  return (
    <>
      <FormModal
        formControls={formControls}
        validationSchema={validationSchema}
        onSubmit={onSubmitForm}
        buttonText={buttonText}
        successMessage={successMessage}
        title={title}
        defaultValues={defaultValues}
        sizeModal="lg"
        btnSize="small"
      />
    </>
  );
}

