import { Card } from "react-bootstrap";
import { ContactPersonTypeBadgeList } from "_components/ContactPersons/ContactPersonTypeBadgeList";

const ContactPersonDetails = ({ contactPerson }) =>
  (<Card style={{ height: "180px"}}>
    <Card.Body>
      <ContactPersonTypeBadgeList contactPerson={contactPerson}/>
      <Card.Title>{contactPerson.name}</Card.Title>
      <Card.Subtitle className="mb-2 text-muted">Telefon: {contactPerson.phone ?? "-"}</Card.Subtitle>
      <Card.Subtitle className="mb-2 text-muted">E-mail: {contactPerson.email ?? "-"}</Card.Subtitle>
      <Card.Text>{contactPerson.remarks}</Card.Text>
    </Card.Body>
  </Card>);

export default ContactPersonDetails;
