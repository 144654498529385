import { useDispatch } from "react-redux";
import { useToast } from "../../../../_helpers/notifications/useToasts";
import { orderViewsActions } from "../../../../_store/orderView.slice";
import { PromptModal } from "../../../Abstractions/PromptModal";
import { BiLink, BiUnlink } from "react-icons/bi";
import { ordersActions } from "../../../../_store/order.slice";

export function KpoUnlinkFromOrder({ orderId, kpoItem, orderNumber }) {
  const { kpoId, cardNumber, isLinked } = kpoItem;
  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useToast();

  if (isLinked === false) {
    return;
  }

  async function onSubmit(promptResult) {
    if (promptResult !== "Yes") {
      return;
    }

    var response = await dispatch(ordersActions.unlinkKpoFromOrder({ orderId, kpoId }));
    if (!response.error) {
      await dispatch(orderViewsActions.getBdoInfoById({ id: orderId }));
      await dispatch(orderViewsActions.refreshHistory({ id: orderId }));
      showSuccessMessage("Odłączono kartę KPO");
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText={<BiUnlink/>}
        btnVariant="danger"
        title={`Czy odłączyć kartę KPO ${cardNumber ?? ""} dla zlecenia ${orderNumber}?`}
        type="YesNo"
        onSubmit={onSubmit}
        btnSize="sm"
      />
    </>
  );
}

export function KpoLinkFromOrder({ orderId, kpoItem, orderNumber }) {
  const { kpoId, cardNumber, isLinked } = kpoItem;
  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useToast();

  if (isLinked !== false) {
    return;
  }

  async function onSubmit(promptResult) {
    if (promptResult !== "Yes") {
      return;
    }

    var response = await dispatch(ordersActions.linkKpoToOrder({ orderId, kpoId, status: kpoItem.status }));
    if (!response.error) {
      showSuccessMessage("Podpięto kartę KPO");
      dispatch(orderViewsActions.linkKpoCard({ kpoId }));
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText={<BiLink/>}
        btnVariant="teal"
        title={`Czy podpiąć kartę KPO ${cardNumber} dla zlecenia ${orderNumber}?`}
        type="YesNo"
        onSubmit={onSubmit}
        btnSize="sm"
      />
    </>
  );
}

