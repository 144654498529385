import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { vehiclesActions } from "../../_store/vehicles.slice";

export function useVehicleSearch({ vehicleType, driverId, allowSearchWithoutType = false, refreshOnInitialize = true, allowEmptyQuery = true }) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const isFirstRender = useRef(true);
  const [isEmptyQuery, setIsEmptyQuery] = useState(false);

  const onSearchChange = async(searchTerm, id, token) => {
    if (!vehicleType && !allowSearchWithoutType) {
      return;
    }

    const isEmptyQuery = (!searchTerm || searchTerm === "") && items.length === 0;
    setIsEmptyQuery(isEmptyQuery);

    if(!driverId && isEmptyQuery) {
      return;
    }

    const query = {
      pageNumber: 1,
      pageSize: 10,
      filter: { id, searchQuery: searchTerm?.trim(), driverId: driverId, vehicleType: vehicleType },
      token
    };
    const { payload } = await dispatch(
      vehiclesActions.search(query)
    );
    const newItems = payload?.items;
    setItems(newItems);

    return newItems;
  };

  useEffect(() => {
    if (!isFirstRender.current || refreshOnInitialize) {
      onSearchChange("", null);
    } else {
      isFirstRender.current = false;
    }

  }, [vehicleType, driverId]);

  return { items, onSearchChange, isEmptyQuery };
}
