import { KpoBdoSelect } from "../Bdo/Kpo/KpoBdoSelect";
import { useContext } from "react";
import { OrderContext } from "./MainForms/OrderContext";
import { KpoBdoSelectedCards } from "../Bdo/Kpo/KpoBdoSelectedCards";
import { CardView } from "../Abstractions/CardView";

export function OrderBdoDetails({ isUpdate = false, isOrderSeries = false }) {
  const { taxId, registrationNumber, isBdoIntegrated, addressRegistrationNumber,
    originatorRole, isSale, kpoCards, unselectKpoCard, plannedLoadingDate, transportType } = useContext(OrderContext);
  const bdoRole = originatorRole === "Sender" ? "Sender" : transportType === "Internal" ? "Carrier" : "Receiver";
  const kpoSelectData = { originatorRole: bdoRole, taxId, registrationNumber, addressRegistrationNumber, isSale, defaultSelectedCards: kpoCards };
  const showBdoDetailsSection = !isOrderSeries && isBdoIntegrated && (!isSale || isUpdate);
  const Wrapper = isUpdate ? `div` : CardView;

  return (
    <>
      {showBdoDetailsSection && (
        <>
          <Wrapper headerText="Dane BDO" >
              <div className="mb-2">
                {/*{isSale && <DomainSwitch label="Generuj karty KPO" name="shouldGenerateKpo"/>}*/}
                <KpoBdoSelect {...kpoSelectData}/>
              </div>
              <KpoBdoSelectedCards
                originatorRole={bdoRole}
                selectedCards={kpoCards}
                onUnselect={unselectKpoCard}
                plannedLoadingDate={plannedLoadingDate}
                hideSave={true}/>
          </Wrapper>
        </>
      )}
    </>
  );
}