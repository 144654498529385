import { PromptModal } from "_components/Abstractions/PromptModal";
import { useToast } from "_helpers/notifications/useToasts";
import { wastesActions } from "_store/wastes.slice";
import { useDispatch, useSelector } from "react-redux";

export function RemoveWaste({ waste }) {
  const dispatch = useDispatch();
  const { wastesPaging } = useSelector((x) => x.wastes);
  const { internalName, id: wasteId } = waste;
  const { showSuccessMessage, showErrorMessage } = useToast();

  async function onSubmit(promptResult) {
    if (promptResult !== "Yes") {
      return;
    }

    const response = await dispatch(wastesActions.remove({ wasteId }));

    if (!response.error) {
      const pageNumber = wastesPaging?.pageNumber ?? 1;
      dispatch(wastesActions.getAll({ pageNumber }));
      showSuccessMessage("Usunięto towar");
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText="Usuń"
        title={`Czy chcesz usunąć towar (${internalName})?`}
        type="YesNo"
        onSubmit={onSubmit}
      />
    </>
  );
}
