import { BadgeDomain } from "_components/Abstractions/BadgeDomain";
import { Card } from "react-bootstrap";

export function IntermediaryListDetails({ intermediaries }) {
  return (
    <>
      <Card>
        <Card.Header as="h4">Pośrednicy</Card.Header>
        <Card.Body>
          {(intermediaries == null || intermediaries?.length === 0) && (
            <>Brak pośredników</>
          )}
          {intermediaries?.map((intermediary) => (
            <BadgeDomain bg="success" size="small" key={intermediary.intermediaryId}>
              {intermediary.name} {intermediary.isDefault ? " (domyślny)" : ""}
            </BadgeDomain>
          ))}
        </Card.Body>
      </Card>
    </>
  );
}
