import { useState } from "react";
import { Form } from "react-bootstrap";

export function Searcher({ name, label, onSearchChange, inputType, className, placeholder, defaultValue, currentValue, additionalAttributes, readonly = false,  autoFocus = false }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const timeout = !inputType || inputType === "text" ? 300 : 0;

  const handleSearch = (event) => {
    if (event.target.value === searchTerm) {
      return;
    }
    setSearchTerm(event.target.value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const newSearchTimeout = setTimeout(() => {
      if (name) {
        onSearchChange({ name, value: event.target.value });
      } else {
        onSearchChange?.(event.target.value);
      }

    }, timeout);

    setSearchTimeout(newSearchTimeout);
  };

  return (
    <>
      <Form.Group className={className ?? "mb-3"}>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Control
          type={inputType}
          onChange={handleSearch}
          autoFocus={autoFocus}
          placeholder={placeholder ?? label}
          defaultValue={defaultValue}
          value={currentValue}
          disabled={readonly}
          {...additionalAttributes}
        />
      </Form.Group>
    </>
  );
}
