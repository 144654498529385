import { useDispatch } from "react-redux";
import { useState } from "react";
import { containersActions } from "../../_store/containers.slice";

export function useContainersSearch({ groupContainerId } ) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  const onSearchChange = async (searchTerm) => {
    const params = { searchTerm, pageNumber: 1, pageSize: 10, groupContainerId };
    const { payload } = await dispatch(containersActions.search(params));
    const items = payload.items?.map((x) => ({
      containerId: x.id,
      containerName: x.name,
    }));

    setItems(items);
  };

  return { onSearchChange, items }
}