import { Card, Popover } from "react-bootstrap";
import { Hr } from "../../../../_components/Abstractions/Hr/Hr";
import { HorizontalView } from "../../../../_components/Abstractions/HorizontalView";
import { FormControl } from "../../../../_components/Forms/FormControl";
import { ButtonOverlay } from "../../../../_components/Abstractions/ButtonOverlay";
import { ReadonlyControl } from "../../../../_components/Forms/ReadonlyControl";
import { dateToString } from "../../../../domain/Configuration";
import React from "react";

export function ContractorReceiverDetailsView({ addressField, allowedWasteCodesBdo, allowedWasteCodesBdoCount, formActions, decisionDate, decisionNumber, isReadonly = false, forceAccordionOpen, errors }) {
  const getFieldName = (fieldName) => `${addressField}.wasteProcessingDecisionData.${fieldName}`;

  const GetFormControl = (name, label, inputType, value) => isReadonly ?
    <ReadonlyControl label={label} inputType={inputType}>{value}</ReadonlyControl> :
    <FormControl name={getFieldName(name)} label={label} inputType={inputType}/>;

  return <div className="my-2">
    <Hr>Dane decyzji przetwarzania odpadów</Hr>
    <HorizontalView>
      {GetFormControl("decisionDate", "Data ważności decyzji", "date", dateToString(decisionDate))}
      {GetFormControl("decisionNumber", "Numer decyzji", null, decisionNumber)}
    </HorizontalView>
    {formActions}
    <AllowedCodesBdo allowedWasteCodesBdo={allowedWasteCodesBdo} allowedWasteCodesBdoCount={allowedWasteCodesBdoCount} errors={errors}/>
  </div>;
}

export const AllowedCodesBdo = ({ allowedWasteCodesBdo, allowedWasteCodesBdoCount, errors }) => {
  const InfoTooltip = ({ item }) => {
    return <Popover>
      <Popover.Header>Liczba wybranych kodów - {item.selectedItems.length}</Popover.Header>
      <Popover.Body>
        {item.selectedItems.map(x => x.code).join(", ")}
      </Popover.Body>
    </Popover>;
  };

  return <>
    <div className="d-flex flex-column gap-2">
      <Card.Header>Dozwolone kody towarów BDO - wybranych kodów: {allowedWasteCodesBdoCount ?? 0}</Card.Header>
      <Card.Body>
        {Object.entries(allowedWasteCodesBdo ?? {}).map(([parentId, item]) =>
          <div key={parentId}>
            <ButtonOverlay placement="right" size="small" overrideTooltip tooltip={InfoTooltip({ item })}>
              {item.parentCodeDescription} - wybranych kodów: {item.selectedItems.length}
            </ButtonOverlay>
          </div>)}
        {errors && <div className="error mt-3 mb-0 small">{errors}</div>}
      </Card.Body>
    </div>
  </>;
}