import { CheckListBoxItem } from "_components/CheckListBox/CheckListBoxItem";
import { FormModal } from "_components/Forms/FormModal";
import { VEHICLES_TYPES } from "domain/VehicleType";
import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";

export function AssignVehicles({
  entityId,
  activeEntityId,
  entityVehicles,
  getVehiclesForEntity,
  assignVehicles,
  displayInfo,
  isFormActive,
  onShow,
}) {
  const { assignedVehicles, restVehicles } = entityVehicles;
  const [selectedVehiclesIds, setSelectedVehiclesIds] = useState([]);

  const onSelect = (id) => setSelectedVehiclesIds((array) => [...array, id]);
  const onUnselect = (id) => {
    const newArray = selectedVehiclesIds.filter((x) => x !== id);
    setSelectedVehiclesIds(newArray);
  };

  useEffect(() => {
    if (!isFormActive) {
      return;
    }

    if (entityId !== activeEntityId) {
      return;
    }
    getVehiclesForEntity();
  }, [activeEntityId, entityId, isFormActive]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getAssignedVehiclesIds = () => {
      if (assignedVehicles) {
        const ids = Object.values(assignedVehicles)
          .flat()
          .map((item) => item.id);
        setSelectedVehiclesIds(ids);
      }
    };

    getAssignedVehiclesIds();
  }, [assignedVehicles, activeEntityId, entityId]);

  const validate = (selectedIds) => {
    const initialIds = Object.values(assignedVehicles)
      .flat()
      .map((item) => item.id);

    if (
      initialIds.length === selectedIds.length &&
      initialIds.every((x) => selectedIds.includes(x))
    ) {
      const response = {
        error: {
          message: "Nie wprowadzono żadnych zmian w przypisywanych pojazdach",
        },
      };
      return response;
    }
  };

  async function onSubmit(payload) {
    payload = { vehiclesIds: selectedVehiclesIds };

    const validationResult = validate(selectedVehiclesIds);
    if (validationResult) {
      return validationResult;
    }

    const response = await assignVehicles(payload);

    if (!response?.error) {
      getVehiclesForEntity();
    }
    return response;
  }

  const form = (
    <>
      {restVehicles && (
        <Accordion defaultActiveKey={Object.keys(assignedVehicles ?? [])} alwaysOpen>
          {Object.entries(VEHICLES_TYPES)
            .filter(([key]) => !!assignedVehicles[key] || !!restVehicles[key])
            .map(([key, value]) => {
              return (
                <div key={key}>
                  <Accordion.Item eventKey={key}>
                    <Accordion.Header>{value}</Accordion.Header>
                    <Accordion.Body className="upper">
                      {assignedVehicles?.[key] &&
                        assignedVehicles[key].map((x) => (
                          <CheckListBoxItem
                            key={x.id}
                            id={x.id}
                            checked
                            onSelect={onSelect}
                            onUnselect={onUnselect}
                          >
                            {`${x.displayName}`}
                          </CheckListBoxItem>
                        ))}
                      {restVehicles[key] &&
                        restVehicles[key].map((x) => (
                          <CheckListBoxItem
                            key={x.id}
                            id={x.id}
                            onSelect={onSelect}
                            onUnselect={onUnselect}
                            checked={false}
                          >
                            {`${x.displayName}`}
                          </CheckListBoxItem>
                        ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              );
            })}
        </Accordion>
      )}
    </>
  );

  return (
    <>
      <FormModal
        formControls={form}
        onSubmit={onSubmit}
        buttonText={displayInfo.buttonText}
        successMessage={displayInfo.successMessage}
        title={displayInfo.title}
        onCloseModal={() => setSelectedVehiclesIds([])}
        loading={entityVehicles.loading}
        onShowModal={onShow}
        btnSize="small"
      />
    </>
  );
}
