import { Form, FloatingLabel } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useFilterFormContext } from "./FilterContext";

export function FilterControl({
  name,
  label,
  inputType = "text",
  readonly = false,
  additionalAttributes = null

}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const { currentFilters, onFilterChange, reset } = useFilterFormContext();
  const [selectedValue, setSelectedValue] = useState(currentFilters[name] ?? "");

  const handleSearch = (event) => {
    const value = event.target.value;
    setSelectedValue(event.target.value);
    if (value === searchTerm) {
      return;
    }

    setSearchTerm(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const newSearchTimeout = setTimeout(() => {
      onFilterChange({ name, value });
    }, 300);

    setSearchTimeout(newSearchTimeout);
  };

  useEffect(() => {
    if(reset > 0) {
      setSelectedValue("");
    }
  }, [reset]);

  return (
    <>
      <Form.Group className="mb-3">
        <FloatingLabel label={label}>
          <Form.Control
            className="form-input"
            type={inputType}
            placeholder={label}
            onChange={handleSearch}
            value={selectedValue}
            disabled={readonly}
            {...additionalAttributes}
          />
        </FloatingLabel>
      </Form.Group>
    </>
  );
}
