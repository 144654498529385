import { contractorsActions } from "_store/contractors.slice";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";

export function useContractorExtendedData({ contractorId, contractorField, isUpdate, isSalesRepresentativeDefault, isBdoIntegrated }) {
  const dispatch = useDispatch();
  const [extendedData, setExtendedData] = useState({});
  const { setValue } = useFormContext();

  const getExtendedData = () => extendedData;

  const onContractorChange = async() => {
    if (!contractorId) {
      return;
    }

    const { payload } = await dispatch(contractorsActions.getExtendedData({ contractorId }));

    setFormData(payload);
    setExtendedData(payload);

    return payload;
  };

  const setFormData = (payload) => {
    const { addresses, intermediaries, salesRepresentative, additionalCompanyBdoDataItems } = payload;
    const salesRepresentativeInfo = salesRepresentative && `${salesRepresentative.name} ${salesRepresentative.phone}`;
    setValue(`${contractorField}.addresses`, addresses);
    setValue(`${contractorField}.intermediaries`, intermediaries);
    setValue(`${contractorField}.additionalCompanyBdoDataItems`, additionalCompanyBdoDataItems);
    if (salesRepresentativeInfo) {
      setValue(`${contractorField}.salesRepresentativeInfo`, salesRepresentativeInfo);
      setValue(`${contractorField}.salesRepresentativeId`, salesRepresentative.id);
      if (isUpdate && isBdoIntegrated) {
        return;
      }

      if (isUpdate) {
        return;
      }

      if (isSalesRepresentativeDefault) {
        setValue(`${contractorField}.isSalesRepresentativeLinked`, true);
      } else {
        setValue(`${contractorField}.isSalesRepresentativeLinked`, false);
      }
    }

    const defaultAddress = addresses.find((x) => x.isDefault);
    if (defaultAddress) {
      setValue("orderAddress", defaultAddress);
    }
  };

  useEffect(() => {
    const extendedData = onContractorChange();
    // setFormData(extendedData);
  }, [contractorId]);

  return { getExtendedData };
}
