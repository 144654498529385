import { BadgeDomain } from "./BadgeDomain";

export function StatusBadge({ isActive }) {
  return (
    <>
      <BadgeDomain bg={`${isActive ? "success" : "secondary"}`}>
        {isActive ? "Aktywny" : "Nieaktywny"}
      </BadgeDomain>
    </>
  );
}
