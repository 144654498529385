import { MultiSelectSearcher } from "../../Abstractions/MultiSearcher/MultiSearcher";

export function KpoBdoDropdown({ name, wasteCodeId, items }) {

  const kpoItems = items?.filter(x => x.wasteCodeId === wasteCodeId && x.isSelected).map(x => GetKpoDropdownItem({ kpoItem: x }))

  const onSearchChange = () => {
    // TODO
  };

  return <MultiSelectSearcher
    name={name}
    items={kpoItems}
    onSearchChange={onSearchChange}
    optionsObject={{ key: "kpoId", value: "description" }}
    // optionSave={(x) => x.kpoId}
    placeholder="Karty KPO"
    bg="fiolet"
    // selectedHorizontalView={true}
  />;
}

export const GetKpoDropdownItem = ({ kpoItem }) => {
  const { kpoId, cardNumber, wasteCode, wasteMass } = kpoItem;
  const getKpoDescription = () => {

    const shortNumber = cardNumber.substring(0, cardNumber.indexOf('/'));
    return `${shortNumber} / ${wasteCode} / ${wasteMass.toFixed(4)} MG`;
  }

  return ({ kpoId: kpoId, description: getKpoDescription() });
}