import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { OrderWorkFlowAction } from "./OrderWorkFlowAction";

export function ConfirmOrderReceive({ orderId, orderNumber }) {
  const successMessage = "Zrealizowano przejęcie zlecenia";
  const buttonText = "Zrealizuj przejęcie";
  const title = `Czy potwierdzić przejęcie zlecenia ${orderNumber}?`;

  const props = {
    orderId: orderId,
    orderAction: ordersActions.confirmOrderReceive,
    orderViewAction: orderViewsActions.confirmReceive,
    successMessage,
    buttonText,
    title
  };

  return <OrderWorkFlowAction {...props} />
}
