import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";
import { defaultPageNumber, defaultPageSize, getDictionaryQuery } from "_store";

// create slice

const name = "services";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const servicesActions = { ...slice.actions, ...extraActions };
export const servicesReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    services: {},
    servicesSorting: { value: "name", type: "asc" },
    servicesPaging: null,
  };
}

function createReducers() {
  return { setSorting };

  function setSorting(state, action) {
    state.servicesSorting = action.payload;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/contractor-services`;

  return {
    getAll: getAll(),
    search: search(),
    create: create(),
    update: update(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async(payload) =>
        await fetchWrapper.get(getDictionaryQuery(baseUrl, payload)));
  }

  function search() {
    return createAsyncThunk(
      `${name}/getAll`,
      async ({ pageNumber, pageSize, searchTerm }) =>
        await fetchWrapper.get(
          `${baseUrl}?pageNumber=${pageNumber ?? defaultPageNumber}&pageSize=${
            pageSize ?? defaultPageSize
          }&searchQuery=${searchTerm ?? ""}`
        )
    );
  }

  function create() {
    return createAsyncThunk(`${name}/create`, async (payload) => {
      return await fetchWrapper.post(`${baseUrl}`, payload);
    });
  }

  function update() {
    return createAsyncThunk(
      `${name}/update`,
      async ({ id, payload }) => {
        return await fetchWrapper.patch(`${baseUrl}/${id}`, payload);
      }
    );
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...update(),
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;

    return {
      [pending]: (state) => {
        state.services = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.services = action.payload.items;
        state.servicesPaging = action.payload.paging;
      },
      [rejected]: (state, action) => {
        state.services = { error: action.error };
      },
    };
  }

  function update() {
    var { fulfilled } = extraActions.update;
    return {
      [fulfilled]: (state, action) => {
        const { containerId, payload } = action.meta.arg;
        const item = state.services.find((x) => x.id === containerId);
        Object.assign(item, payload);
      },
    };
  }
}
