import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";
import { defaultPageNumber, defaultPageSize, getDictionaryQuery } from "_store";

// create slice

const name = "groupContainers";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const groupContainersActions = { ...slice.actions, ...extraActions };
export const groupContainersReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    groupContainers: {},
    groupContainersPaging: null,
    groupContainersSummary: null,
    groupContainerVehicles: {},
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/containers/group`;

  return {
    getAll: getAll(),
    createGroup: createGroup(),
    updateGroup: updateGroup(),
    search: search(),
    searchForAddress: searchForAddress(),
    getVehiclesForGroupContainer: getVehiclesForGroupContainer(),
    assignVehicles: assignVehicles(),
    getState: getState(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getGroupAll`,
      async(payload) =>
        await fetchWrapper.get(`${getDictionaryQuery(baseUrl, payload)}&includeSubContainers=true`));
  }

  function createGroup() {
    return createAsyncThunk(`${name}/create/group`, async(payload) => {
      return await fetchWrapper.post(`${baseUrl}`, payload);
    });
  }
  function updateGroup() {
    return createAsyncThunk(
      `${name}/updateGroup`,
      async ({ id, payload }) => {
        return await fetchWrapper.patch(`${baseUrl}/${id}`, payload);
      }
    );
  }


  function search() {
    return createAsyncThunk(
      `${name}/search`,
      async ({ pageNumber, pageSize, searchTerm }) =>
        await fetchWrapper.get(`${baseUrl}?pageNumber=${pageNumber ?? defaultPageNumber}&pageSize=${pageSize ?? defaultPageSize}
        &searchQuery=${searchTerm ?? ""}`
        )
    );
  }

  function searchForAddress() {
    return createAsyncThunk(
      `${name}/searchForAddress`,
      async ({ pageNumber, pageSize, searchTerm, addressId }) =>
        await fetchWrapper.get(`${baseUrl}/address/${addressId}?
        pageNumber=${pageNumber ?? defaultPageNumber}&pageSize=${pageSize ?? defaultPageSize}
        &searchQuery=${searchTerm ?? ""}`
        )
    );
  }

  function getVehiclesForGroupContainer() {
    return createAsyncThunk(
      `${name}/getVehiclesForContainer`,
      async ({ groupContainerId }) => {
        return await fetchWrapper.get(`${baseUrl}/${groupContainerId}/get-vehicles`);
      }
    );
  }

  function assignVehicles() {
    return createAsyncThunk(
      `${name}/assignVehicles`,
      async ({ groupContainerId, payload }) => {
        return await fetchWrapper.put(
          `${baseUrl}/${groupContainerId}/assign-vehicles`,
          payload
        );
      }
    );
  }

  function getState() {
    return createAsyncThunk(
      `${name}/getState`,
      async ({ id }) => await fetchWrapper.get(`${baseUrl}/${id}/state`));}
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...updateGroup(),
    ...getVehiclesForGroupContainer(),
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;

    return {
      [pending]: (state) => {
        state.groupContainers = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.groupContainers = action.payload.items;
        state.groupContainersPaging = action.payload.paging;
        state.groupContainersSummary = action.payload.summary;

      },
      [rejected]: (state, action) => {
        state.groupContainers = { error: action.error };
      },
    };
  }

  function updateGroup() {
    var { fulfilled } = extraActions.updateGroup;
    return {
      [fulfilled]: (state, action) => {
        const { id, payload } = action.meta.arg;
        const groupContainer = state.groupContainers.find((x) => x.id === id);
        Object.assign(groupContainer, payload);
      },
    };
  }

  function getVehiclesForGroupContainer() {
    var { pending, fulfilled } = extraActions.getVehiclesForGroupContainer;
    return {
      [pending]: (state) => {
        state.groupContainerVehicles = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.groupContainerVehicles = action.payload;
      },
    };
  }
}