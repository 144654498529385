import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";
import { defaultPageNumber, defaultPageSize } from "_store";

// create slice

const name = "holidays";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const holidaysActions = { ...slice.actions, ...extraActions };
export const holidaysReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    holidays: {
      items: [],
      paging: {}
    },
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/holidays`;

  return {
    getAll: getAll(),
    create: create(),
    update: update(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async ({ pageNumber, pageSize }) =>
        await fetchWrapper.get(
          `${baseUrl}?pageNumber=${pageNumber ?? defaultPageNumber}&pageSize=${
            pageSize ?? defaultPageSize
          }`
        )
    );
  }

  function create() {
    return createAsyncThunk(`${name}/create`, async (payload) => {
      return await fetchWrapper.post(`${baseUrl}`, payload);
    });
  }

  function update() {
    return createAsyncThunk(
      `${name}/update`,
      async ({ id, payload }) => {
        return await fetchWrapper.patch(`${baseUrl}/${id}`, payload);
      }
    );
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...update(),
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.holidays = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.holidays = action.payload;
      },
      [rejected]: (state, action) => {
        state.holidays = { error: action.error };
      },
    };
  }

  function update() {
    var { fulfilled } = extraActions.update;
    return {
      [fulfilled]: (state, action) => {
        const { id, payload } = action.meta.arg;
        const item = state.holidays?.items?.find((x) => x.id === id);
        Object.assign(item, payload);
      },
    };
  }
}
