import { BadgeDomain } from "../../Abstractions/BadgeDomain";
import { TransactionType, TransactionTypeToClientType } from "../../../domain/TransactionType";
import { ContractorBadges } from "../../../domain/ContractorType";

export function TransactionTypeBadge({ transactionType, className, size }) {
  const contractorType = TransactionTypeToClientType[transactionType];
  const color = ContractorBadges[contractorType];
  const transactionTypeValue = TransactionType[transactionType];

  return  <BadgeDomain className={className} bg={color} size={size}>{transactionTypeValue}</BadgeDomain>;
}