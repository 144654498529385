import React from "react";
import { Container, Row, Card } from "react-bootstrap";
import s from "./style.module.css";
import { MakeOrdersDashboardItems } from "./MakeOrdersDashboardItems";
import { BrowseOrdersDashboardItems } from "./BrowseOrdersDashboardItems";
import { ConfigurationDashboardItems } from "./ConfigurationDashboardItems";
import { CardLinkBody } from "../Abstractions/CardLinkBody";

export const MainDashboard = () => {
  const actionGroups = {
    "Dodaj zlecenia": MakeOrdersDashboardItems,
    "Przeglądaj zlecenia": BrowseOrdersDashboardItems,
    "Administracja": ConfigurationDashboardItems
  };

  return (
    <Container fluid className="d-flex justify-content-center">
      <Row>
        {Object.keys(actionGroups).map((x, idx) => <div key={idx} className="my-2">
          <h3>{x}</h3>
          <div className="d-flex gap-4">
            {actionGroups[x].map((x, idx) => {
              const Icon = x.icon;
              return <Card key={idx} className={s.item}>
                  <CardLinkBody
                    link={x.link}
                    className="d-flex gap-2 p-4 flex-column justify-content-center align-items-center align-content-center">
                    <Icon color={x.color} width={64} height={64}/>
                    <h5>{x.name}</h5>
                  </CardLinkBody>
              </Card>;
            })}
          </div>
        </div>)}
      </Row>
    </Container>
  );
};