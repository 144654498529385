import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react";
import { useFilterFormContext } from "../../Forms/FilterContext";
import { BadgeDomain } from "../BadgeDomain";

export function MultiSelectFilter({ name, title, options,  onSubmit, defaultOptions, className }) {
  const { onFilterChange, currentFilters, reset } = useFilterFormContext();
  const currentFilter = currentFilters?.[name];
  const defaultValues = currentFilter && currentFilter !== "" ? currentFilter?.split("|") ?? defaultOptions : [];
  const [selectedOptions, setSelectedOptions] = useState(defaultValues);

  const handleOnToggle = (isOpened) => {
    if (!isOpened) {
      const filteredOptions = selectedOptions?.join("|") ?? "";
      onSubmit?.(selectedOptions);
      onFilterChange({ name, value: filteredOptions });
    }
  };

  const onSelect = (selectedValue) => {
    setSelectedOptions([ ...selectedOptions, selectedValue ]);
  }

  const onUnselect = (selectedValue) => {
    const newSelectedOptions = selectedOptions.filter((x) => x !== selectedValue);
    setSelectedOptions(newSelectedOptions);
  }

  useEffect(() => {
    if(reset > 0) {
      setSelectedOptions(currentFilter?.split("|") ?? []);
    }
  }, [reset])
  
  return <Dropdown onToggle={handleOnToggle} className="d-inline mx-2" autoClose="outside">
    <Dropdown.Toggle className={`mb-3 ${className}`} variant="outline-primary">
      {title}{" "}
      {selectedOptions?.length > 0 && <BadgeDomain bg="success">{selectedOptions?.length}</BadgeDomain>}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {Object.entries(options).map(([key, value]) => <DropdownItem
        value={key}
        onSelect={onSelect}
        onUnselect={onUnselect}
        isSelected={selectedOptions?.includes(key)}
        className={className}
        key={key}>
        {value}
      </DropdownItem>)}
    </Dropdown.Menu>
  </Dropdown>;
}

const DropdownItem = ({ children, value, onSelect, onUnselect, isSelected, className }) => {
  const [active, setActive] = useState(isSelected ?? false);
  const handleOnSelect = (e) => {
    e.stopPropagation();
    const isActive = !active;
    setActive(isActive);

    if (isActive) {
      onSelect?.(value);
    } else {
      onUnselect?.(value);
    }
  };

  useEffect(() => {
    setActive(isSelected);
  }, [isSelected]);

  return <Dropdown.Item className={className} active={active} onClick={handleOnSelect}>{children}</Dropdown.Item>;
};