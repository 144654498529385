import { FloatingLabel, Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { ControlLabel } from "./ControlLabel";

export function Dropdown({
  label,
  name,
  items,
  defaultValue,
  readonly = false,
  displayProperty = null,
  isSimpleDropdown = false,
  selectedValue = null,
  required = true
}) {
  const { register, getFieldState, setValue, watch } = useFormContext();
  let fieldState = getFieldState(name);
  const formattedLabel = <ControlLabel label={label} required={required}/>
  const value = watch(name);
  useEffect(() => {
    if(value === ""){
      setValue(name, null);
    }
  }, [value]);

  const additionalProps = {
    disabled: readonly
  };

  const GetItems = (
    <>
      {!items ? [] : Object.entries(items).map(([key, value]) =>
        (
          <option className="hover-pointer" key={key} value={isSimpleDropdown ? value : key}>
            {displayProperty ? value[displayProperty] : value}
          </option>
        ))}
    </>
  );
  return (
    <>
      <Form.Group className="mb-3">
        <FloatingLabel label={formattedLabel}>
          <Form.Select
            className="hover-pointer form-input form"
            isInvalid={fieldState.error}
            defaultValue={selectedValue ?? null}
            {...register(name)}
            {...additionalProps}
          >
            <option key={-1} value={""}>{defaultValue ?? ""}</option>
            {GetItems}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {fieldState?.error?.message}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    </>
  );
}
