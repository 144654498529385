import * as Yup from "yup";
import { FormControl } from "../../../Forms/FormControl";
import { FormModal } from "../../../Forms/FormModal";
import { KPO_STATUS } from "../../../../domain/KpoCardStatus";
import { ListGroup } from "react-bootstrap";
import { WasteMassValidator } from "../../../../domain/Orders/CreatePurchaseOrderValidator";
import { BaseKpoForm } from "./KpoReceiveConfirmation";
import { ValidateBdoAction } from "./BdoActionValidator";
import { NumericControl } from "../../../Forms/NumericControl";
import { useState } from "react";
import { BasicSwitch } from "../../../Abstractions/BasicSwitch";

export function KpoReviseForm({ kpoCards, onSubmit, name, promptTitle, performAll }) {
  const { validate } = ValidateBdoAction();
  const { formControls, defaultValues, validationSchema } = KpoReviseFormProvider({ kpoCards: kpoCards });
  const handleSubmit = async(payload) => {
    const requestItems = payload.kpoItems.filter(x => x.isSelected).map(x => {
      return { kpoId: x.kpoId, wasteCodeBdoId: x.waste.wasteCodeBdoId, wasteMass: x.wasteMass };
    });
    validate(requestItems);

    await onSubmit(requestItems);
  };

  return <FormModal
    title={promptTitle}
    formControls={formControls}
    defaultValues={defaultValues}
    onSubmit={handleSubmit}
    buttonText={name}
    sizeModal="md"
    validationSchema={validationSchema}
    isButtonTrigger={performAll}
    skipToast
    submitBtnText={name}/>;
}

export function KpoReviseFormProvider({ kpoCards }) {
  const [skipWasteMassUpdateValidation, setSkipWasteMassUpdateValidation] = useState(false);
  const kpoItemSchema = Yup.object().shape({
    cardNumber: Yup.string().required("Numer karty jest wymagany").typeError("Numer karty jest wymagany"),
    waste: Yup.object().typeError("Towar jest wymagany"),
    wasteMass: WasteMassValidator.validator
    .when([], {
      is: _ => Boolean(!skipWasteMassUpdateValidation),
      then: Yup.string().notOneOf([Yup.ref("currentWasteMass")], "Masa musi się różnić od masy początkowej")
    })
  });

  const validationSchema = Yup.object().shape({
    kpoItems: Yup.array().of(kpoItemSchema)
  });

  const filteredKpoCards = kpoCards.filter(x => x.status === KPO_STATUS.REJECTED);
  const values = filteredKpoCards.map(x => {
    return {
      kpoId: x.kpoId,
      cardNumber: x.cardNumber,
      wasteMass: x.wasteMass.toFixed(4),
      currentWasteMass: x.wasteMass.toFixed(4),
      remarks: x.remarks,
      waste: {
        id: x.wasteCodeId,
        wasteCodeBdoId: x.wasteCodeId,
        displayDescription: x.codeDescription
      },
      isSelected: true
    };
  });

  const defaultValues = { kpoItems: values };
  const formControls =
    <div>
      {values.length === 0 && <ListGroup.Item>Brak kart KPO do korekty</ListGroup.Item>}
      <BasicSwitch onChange={setSkipWasteMassUpdateValidation} value={skipWasteMassUpdateValidation} label="Pomiń walidację masy w kartach KPO" />
      {filteredKpoCards.map((x, idx) => <div key={idx}>
        <BaseKpoForm
          kpoItem={x}
          selectable={kpoCards?.length > 1}
          formControls={
            <>
              <FormControl name={`kpoItems[${idx}].remarks`} label="Uwagi" readonly />
              <NumericControl name={`kpoItems[${idx}].wasteMass`} label="Masa odpadów [Mg]" decimalPlaces={4}/>
            </>}
          parentName={`kpoItems[${idx}]`}/>
      </div>)}
    </div>;

  return { validationSchema, formControls, defaultValues };
}
