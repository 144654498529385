import { useDispatch } from "react-redux";
import { salesRepresentativesActions } from "../../_store/salesRepresentatives.slice";
import { PromptModal } from "../../_components/Abstractions/PromptModal";

export function ChangeSalesRepresentativeState({ item }) {
  const { id, isActive } = item;
  const buttonText = isActive ? "Deaktywuj" : "Aktywuj";
  const action = isActive ? "deaktywować" : "aktywować";
  const dispatch = useDispatch();

  async function onSubmit() {
    const payload = {
      ...item,
      isActive: !item.isActive
    };

    return dispatch(salesRepresentativesActions.update({ id, payload }));
  }

  return (
      <PromptModal
        buttonText={buttonText}
        title={`Czy ${action} PH?`}
        type="YesNo"
        onSubmit={onSubmit}
      />
  );
}