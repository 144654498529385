export const ContractorTypes = {
  Sender: "Przekazujący",
  Carrier: "Transportujący",
  Receiver: "Przejmujący",
  Intermediary: "Pośrednik",
};

export const ContractorBadges = {
  None: "dark",
  Sender: "indigo",
  Receiver: "teal",
  Carrier: "orange",
  Intermediary: "danger",
};

export const OriginatorTypes = {
  Own: "Własny",
  SalesRepresentative: "Przedstawiciel handlowy",
};
