import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

export function ButtonOverlay({
  children,
  tooltip,
  variant,
  onClick,
  placement = "top",
  overrideTooltip = false,
  size = "smaller"
}) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      delay={{ show: 150, hide: 300 }}
      placement={placement}
      overlay={overrideTooltip ? tooltip : renderTooltip}
    >
      <Button style={{ fontSize: size }} size="sm" variant={variant} onClick={onClick}>
        {children}
      </Button>
    </OverlayTrigger>
  );
}
