import { DomainSwitch } from "_components/Abstractions/DomainSwitch";
import { TextArea } from "_components/Forms/TextArea";
import { Card, Col, Container, Row } from "react-bootstrap";
import { ContactPersonsForm } from "./ContactPersonsForm";
import { FormControl } from "_components/Forms/FormControl";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { UsedContainersForm } from "./UsedContainersForm";
import { BdoAddressForm } from "./BdoAddressForm";
import { WastesMultiSelect } from "./WastesMultiSelect";

export function AddressForm({ addressField, isCentral }) {
  const GetFullField = (field) => `${addressField}.${field}`;
  const { setValue, watch } = useFormContext();
  const isPickup = watch(GetFullField("isPickup"));
  const isDefault = watch(GetFullField("isDefault"));
  const defaultFieldAddressName = watch("defaultFieldAddressName");

  useEffect(() => {
    if (isCentral) {
      setValue(`${addressField}.isCentral`, true);
      if (!isPickup) {
        setValue(`${addressField}.isPickup`, false);
      }
      if (isDefault === true) {
        setValue("defaultFieldAddressName", addressField);
      }
      if (!isDefault) {
        setValue(`${addressField}.isDefault`, false);
      }
    }
  }, []); // eslint-disable-next-line/react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDefault === true) {
      setValue("defaultFieldAddressName", addressField);
    }
  }, [isDefault]);

  useEffect(() => {
    if (isDefault === false) {
      return;
    }

    if (isDefault === true && defaultFieldAddressName !== addressField && defaultFieldAddressName) {
      setValue(`${addressField}.isDefault`, false);
    }
  }, [defaultFieldAddressName, isPickup]);

  useEffect(() => {
    if (isPickup === false) {
      setValue(`${addressField}.isDefault`, false);
    }
  }, [isPickup]);

  return (
    <Container className={`${!isCentral ? "form-control" : ""}`}>
      <Row>
        <Col>
          {isCentral && (
            <DomainSwitch
              label="Adres centrali jest adresem dostawy"
              name={GetFullField("isPickup")}
            />
          )}
          {isPickup && (
            <>
              <Card.Header as="h6">
                Dane BDO (Miejsce prowadzenia działalności gospodarczej)
              </Card.Header>
              <BdoAddressForm addressField={addressField}/>
            </>
          )}
          <Card.Header as="h6">Dane adresowe</Card.Header>
          <FormControl label="Nazwa adresu" name={GetFullField("name")}/>
          <Row>
            <Col>
              <FormControl
                label="Kod pocztowy"
                name={GetFullField("postalCode")}
              />
            </Col>
            <Col>
              <FormControl label="Miasto" name={GetFullField("city")}/>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormControl label="Ulica" name={GetFullField("streetName")}/>
            </Col>
            <Col>
              <FormControl label="Numer" name={GetFullField("streetNumber")}/>
            </Col>
            <Col>
              <FormControl label="Lokal" name={GetFullField("apartment")} required={false}/>
            </Col>
          </Row>
          <TextArea label="Uwagi do adresu" name={GetFullField("remarks")}/>

          {isPickup && (
            <DomainSwitch
              label="Domyślny adres dostawy"
              name={GetFullField("isDefault")}
            />
          )}
        </Col>
        <Col>
          <Card.Header as="h6">Osoby kontakowe do adresu</Card.Header>
          <ContactPersonsForm prefix={`${addressField}`} isAddressContact/>
          {isPickup && (
            <>
              <Card.Header className="mt-3" as="h6">Załadunek</Card.Header>
              <Card.Body>
                <WastesMultiSelect name={`${addressField}.loadingWastesIds`} skipInitLoading required={false}/>
              </Card.Body>
              <Card.Header as="h6">Kontenery dla adresu</Card.Header>
              <UsedContainersForm prefix={`${addressField}`}/>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
