import { FormDomain } from "../../_components/Forms/FormDomain";
import { FormControl } from "../../_components/Forms/FormControl";
import { Container } from "react-bootstrap";
import { Dropdown } from "../../_components/Abstractions/Dropdown";
import { useDispatch } from "react-redux";
import { bdoActions } from "../../_store/bdo.slice";
import { HorizontalView } from "../../_components/Abstractions/HorizontalView";
import { KeoBdoSelect } from "../../_components/Bdo/Keo/KeoBdoSelect";
import * as Yup from "yup";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { maintenanceActions } from "../../_store/maintenance.slice.js";
import { RadioButtonGroup } from "../../_components/Abstractions/RadioButtonGroup";
import { Months } from "../../domain/Months";

export function KeoGenerateReport() {
  const dispatch = useDispatch();
  const keoReportType = {
    "Collected": "Przejęte",
    "Forwarded": "Przekazane"
  };

  const validationSchema = Yup.object().shape({
    keoCard: Yup.object().shape({
      keoId: Yup.string().required("Identyfikator karty Keo ID jest wymagany (wybierz kartę)"),
      cardNumber: Yup.string().required("Numer karty Keo jest wymagany (wybierz kartę)"),
      year: Yup.string().required("Rok jest wymagany")
    }),
    month: Yup.string().required("Miesiąc jest wymagany").typeError("Miesiąc jest wymagany"),
    keoReportType: Yup.string().required("Typ raportu KEO jest wymagany").typeError("Typ raportu KEO jest wymagany"),
    sheetName: Yup.string().nullable().when("reportType", {
      is: "comparing-report",
      then: Yup.string()
      .required("Nazwa arkusza jest wymagana")
      .min(1, "Nazwa arkusza jest wymagana")
      .typeError("Nazwa arkusza jest wymagana")
    }),
    sheetFile: Yup.mixed().nullable().when("reportType", {
      is: "comparing-report",
      then: Yup.mixed().required("Plik jest wymagany")
      .test("length", "Plik jest wymagany", value => value?.length === 1)
      .test(
        "fileType",
        "Plik z programu magazynowego jest wymagany w formacie Excel (xlsx).",
        (value) => {
          if (!value) return true; // Do not perform the test if the value is not provided
          return value?.[0] && (
            value[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            value[0].type === "application/vnd.oasis.opendocument.spreadsheet");
        })
    })
  });

  const outputTypes = [
    { name: "OpenLibrary (.ods)", value: "ods" },
    { name: "Excel (.xlsx)", value: "excel" }
  ];

  const formControls = <>
    <RadioButtonGroup
      label="Typ raportu"
      name="reportType"
      items={[
        { name: "Zestawienie danych z programu magazynowego", value: "comparing-report" },
        { name: "Pobranie kart KEO z miesiąca ", value: "monthly-report" }
      ]}
      defaultChecked="comparing-report"
    />
    <div className="mb-2">
      <KeoBdoSelect name="keoCard"/>
    </div>
    <FormControl name="keoCard.cardNumber" label="Numer karty KEO" readonly/>
    <FormControl name="keoCard.wasteCode" label="Kod odpadu" readonly/>
    <HorizontalView>
      <FormControl name="keoCard.year" label="Rok" readonly/>
      <Dropdown name="month" items={Months} label="Miesiąc"/>
    </HorizontalView>
    <Dropdown name="keoReportType" items={keoReportType} label="Typ raportu KEO"/>
    <UploadKeoFileInput/>
    <RadioButtonGroup label="Plik wynikowy" name="outputType" items={outputTypes} defaultChecked="ods"/>
  </>;

  async function onSubmit(payload) {
    const { sheetFile, reportType, keoCard, ...data } = payload;
    const body = { ...data, ...keoCard };

    if (reportType === "comparing-report") {
      const formData = new FormData();
      formData.append("file", sheetFile[0]);
      return await dispatch(bdoActions.generateComparingKeoReport({ payload: body, formData }));
    } else if (reportType === "monthly-report") {
      return await dispatch(bdoActions.generateMonthlyKeoReport({ payload: body }));
    }
  }

  const formData = {
    validationSchema,
    formControls,
    isModal: false,
    successMessage: "Wygenerowano raport",
    addSubmitFooter: false,
    submitBtnName: "Generuj raport",
    onSubmit: onSubmit
  };

  return <Container>
    <FormDomain {...formData}/>
  </Container>;
}

export function UploadKeoFileInput() {
  const dispatch = useDispatch();
  const { watch } = useFormContext();
  const [sheetNames, setSheetNames] = useState([]);
  const sheetFile = watch("sheetFile");
  const reportType = watch("reportType");

  const onFileUpload = async() => {
    setSheetNames([]);
    if (!sheetFile || sheetFile?.length === 0) {
      return;
    }

    const file = sheetFile[0];
    const fileExtension = file.name.split(".").pop();
    const action = fileExtension === "ods" ? maintenanceActions.getOdsWorksheets : maintenanceActions.getExcelWorksheets;
    const formData = new FormData();
    formData.append("file", file);

    const response = await dispatch(action(formData));
    const { payload } = response;

    setSheetNames(payload || []);
  };

  useEffect(() => {
    onFileUpload();
  }, [sheetFile]);

  if (reportType === "monthly-report") {
    return <></>;
  }

  return <>
    <HorizontalView>
      <FormControl name="sheetFile" label="Arkusz z programu magazynowego (.ods / .xlsx)" inputType="file"
                   additionalAttributes={{ accept: ".ods, .xlsx" }}/>
      <Dropdown name="sheetName" label="Nazwa arkusza" items={sheetNames} readonly={!sheetFile} isSimpleDropdown/>
    </HorizontalView>
  </>;
}