import { TRANSACTION_TYPE } from "../../domain/TransactionType";

export const mapToUpdateTransportDetails = ({ orderId, payload }) => {
  let vehicleData = { id: orderId };
  if (payload.transportType === "Internal") {
    vehicleData = {
      ...vehicleData,
      driverId: payload.driverId?.id,
      vehicleId: payload.vehicleId?.id,
      vehicleRegNumber: payload.vehicleId?.registrationNumber,
      includedVehicleId: payload.includedVehicleId?.id,
      includedVehicleRegNumber: payload.includedVehicleId?.registrationNumber
    };
  } else {
    vehicleData = {
      ...vehicleData,
      externalDriver: payload.externalDriver,
      vehicleRegNumber: payload.vehicleRegNumber,
      includedVehicleRegNumber: payload.includedVehicleRegNumber,
    };
  }

  return vehicleData;
}

export function mapToWastesDetails(wastesDetailsData, transactionType, includeExtendedData = false){
  const wasteExchanges = [];
  if (wastesDetailsData.isLoading && wastesDetailsData.loading?.wastes?.length > 0) {
    const loadExchangeItem = {
      isContainer: false,
      wastes: mapToRequestWastes(wastesDetailsData.loading.wastes),
      usedContainerId: null,
      wasteExchangeId: wastesDetailsData.loading.wasteExchangeId
    };

    wasteExchanges.push(loadExchangeItem);
  }

  if (wastesDetailsData.isContainerExchange && wastesDetailsData.containers?.length > 0) {
    const containerExchangeItems = wastesDetailsData.containers?.map((x) => ({
      isContainer: true,
      wastes: mapToRequestWastes(x.wastes),
      usedContainerId: x.usedContainer?.usedContainerId,
      wasteExchangeId: x.wasteExchangeId
    })) ?? [];

    wasteExchanges.push(...containerExchangeItems);
  }

  if (wastesDetailsData.saleWastes && wastesDetailsData.saleWastes?.wastes?.length > 0) {
    const saleWastes = mapToRequestWastes(wastesDetailsData.saleWastes?.wastes, includeExtendedData) ?? [];
    const saleExchangeItem = {
      isContainer: false,
      wastes: saleWastes,
      usedContainerId: null,
      wasteExchangeId: wastesDetailsData.saleWastes?.wasteExchangeId
    };

    wasteExchanges.push(saleExchangeItem);
  }

  if (wastesDetailsData.transportWastes && wastesDetailsData.transportWastes?.wastes?.length > 0) {
    const transportWastes = mapToRequestWastes(wastesDetailsData.transportWastes?.wastes, includeExtendedData) ?? [];
    const transportExchangeItem = {
      isContainer: false,
      wastes: transportWastes,
      usedContainerId: null,
      wasteExchangeId: wastesDetailsData.transportWastes?.wasteExchangeId,
    };

    wasteExchanges.push(transportExchangeItem);
  }

  return {
    receivedNoteNumber: wastesDetailsData.receivedNoteNumber,
    deliveryNoteNumber: wastesDetailsData.deliveryNoteNumber,
    saleDocumentNumber: wastesDetailsData.saleDocumentNumber,
    isBdoIntegrated: wastesDetailsData.isBdoIntegrated,
    wasteExchanges: wasteExchanges,
    isContainerExchange: wastesDetailsData.isContainerExchange,
    isLoading: wastesDetailsData.isLoading,
    isWasteTransport: transactionType === TRANSACTION_TYPE.TRANSPORT ? wastesDetailsData.isWasteTransport : null
  };
}

export const mapToRequestWastes = (wastes, includeExtendedData = false) => wastes?.map((x) => ({
  wasteId: x.wasteId,
  wasteMass: x.wasteMass,
  code: x.wasteCode,
  bdoId: x.wasteCodeBdoId,
  internalName: x.internalName,
  extendedData: includeExtendedData ? mapToExtendedData(x) : null
}));

const mapToExtendedData = (waste) => ({
  deductions: waste.deductions,
  initWasteMass: waste.initWasteMass,
  revenueDocumentNumber: getValueIfDeductionsNonZeroOrNull(waste.revenueDocumentNumber, waste.deductions),
  revenueWasteMass: waste.revenueWasteMass,
  wasteDifferenceReasons: getValueIfDeductionsNonZeroOrNull(waste.wasteDifferenceReasons, waste.deductions),
  wasteDifferenceOtherReason: getValueIfDeductionsNonZeroOrNull(waste.wasteDifferenceOtherReason, waste.deductions)
});

const getValueIfDeductionsNonZeroOrNull = (value, deductions) => Number(deductions) !== 0 ? value : null;