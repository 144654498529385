import { FormControl } from "../../Forms/FormControl";
import { Dropdown } from "../../Abstractions/Dropdown";
import { WASTE_DIFFERENCE_REASON, WasteDifferenceReason } from "../../../domain/WasteDifferenceReason";
import { HorizontalView } from "../../Abstractions/HorizontalView";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { NumericControl } from "../../Forms/NumericControl";
import { CheckListBox } from "../../CheckListBox/CheckListBox";

export function SaleWastesAdditionalForm({ parentName }) {
  const GetFieldName = (name) => `${parentName}.${name}`;
  const { watch, setValue, getValues } = useFormContext();
  const wasteDifferenceReasons = watch(GetFieldName("wasteDifferenceReasons"));
  const wasteMass = watch(GetFieldName("wasteMass"));
  const initWasteMass = getValues(GetFieldName("initWasteMass"));
  const isOtherReasonRequired = wasteDifferenceReasons?.includes(WASTE_DIFFERENCE_REASON.OTHER);
  const [incomeTypeLabel, setIncomeTypeLabel] = useState("PW / RW");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const deductions = (Number(wasteMass ?? 0) - Number(initWasteMass ?? 0));
    const isRevenue = deductions >= 0;
    const revenueWasteMass = isRevenue ? deductions : -deductions;

    setIncomeTypeLabel(isRevenue ? "PW" : "RW");
    setValue(GetFieldName("deductions"), deductions.toFixed(4));
    setValue(GetFieldName("revenueWasteMass"), revenueWasteMass.toFixed(4));
    setVisible(deductions != 0);
  }, [wasteMass, initWasteMass]);

  return <>
    <HorizontalView>
      <NumericControl decimalPlaces={4} name={GetFieldName("initWasteMass")} label="Masa początkowa [Mg]"/>
      <FormControl name={GetFieldName("deductions")} label="Potrącenia [Mg]" readonly/>
      <FormControl name={GetFieldName("revenueWasteMass")} label={`Masa ${incomeTypeLabel} [Mg]`} readonly/>
    </HorizontalView>
    {visible && <>
    <HorizontalView>
      <FormControl name={GetFieldName("revenueDocumentNumber")} label={`Numer ${incomeTypeLabel}`}/>
    </HorizontalView>
    <HorizontalView>
      <CheckListBox name={GetFieldName("wasteDifferenceReasons")} label="Przyczyny" items={WasteDifferenceReason}/>
    </HorizontalView>
    <HorizontalView>
      {isOtherReasonRequired && <FormControl name={GetFieldName("wasteDifferenceOtherReason")} label="Inne przyczyny"/>}
    </HorizontalView>
    </>}
  </>;
}