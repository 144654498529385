import { YesNoBadge } from "../../_components/Abstractions/YesNoBadge";
import { OrderReportGroup } from "./OrderReportGroup";
import { OrderStatusBadge } from "../../_components/OrderViews/Components/OrderStatusBadge";

export const OrderReportEntry = ({ entry, skipStatus }) => (
  <tr>
    <td>{entry.orderNumber}</td>
    <td>{entry.contractorName}</td>
    <td>{entry.addressName}</td>
    {!skipStatus && <th><OrderStatusBadge status={entry.status}/></th>}
    <td>{entry.createdAt}</td>
    <td>{entry.plannedLoadingDate}</td>
    <td><YesNoBadge value={entry.isLoading}/></td>
    <td><YesNoBadge value={entry.isContainersExchange}/></td>
  </tr>
);

export const OrderReportData = ({ data, skipStatus }) => {
  if (!data) {
    return;
  }

  return (
    <div>
      {data.statusGroups?.map((group, index) => (
        <OrderReportGroup key={index} group={group} skipStatus={skipStatus}/>
      ))}
    </div>
  );
};