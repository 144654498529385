import { Card } from "react-bootstrap";
import { AddressTypeBadgeList } from "_components/Addresses/AddressTypeBadgeList";
import ContactPersonListDetails from "./ContactPersonsDetails";
import { ContainerListDetails } from "./ContainerListDetails";
import { LoadingWastesCard } from "./LoadingWastesCard";
import { dateToString } from "../../../../domain/Configuration";
import { AllowedCodesBdoModal } from "./AllowedCodesBdoModal";

const AddressDetails = ({ address }) => {
  const { bdoDetails, rawAddress, remarks, wasteProcessingDecisionData } = address;

  return (
    <Card>
      <Card.Body>
        <AddressTypeBadgeList address={address} />
        <Card.Title>{address.name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{rawAddress}</Card.Subtitle>
        {bdoDetails?.registrationNumber && <Card.Text><b>EUP: {bdoDetails?.registrationNumber}</b></Card.Text>}
        {wasteProcessingDecisionData && <Card.Text>
          Decyzja: {wasteProcessingDecisionData?.decisionNumber} ({dateToString(wasteProcessingDecisionData?.decisionDate)})
          {" "}<AllowedCodesBdoModal
          allowedWasteCodesBdo={wasteProcessingDecisionData?.allowedWasteCodesBdo}
          allowedWasteCodesBdoCount={wasteProcessingDecisionData?.allowedWasteCodesBdoCount}/>
        </Card.Text>}
        <Card.Subtitle>{remarks}</Card.Subtitle>

        <ContactPersonListDetails contactPersons={address.contactPersons} useLightBorder/>
        <LoadingWastesCard loadingWastes={address.loadingWastes} />
        <ContainerListDetails containers={address.usedContainers} />
      </Card.Body>
    </Card>
  );
};

export default AddressDetails;

