import { ListGroup } from "react-bootstrap";
import { KpoBdoStatusBadge } from "../../../Bdo/Kpo/KpoBdoStatusBadge";
import { BadgeDomain } from "../../../Abstractions/BadgeDomain";
import { DomainSwitch } from "../../../Abstractions/DomainSwitch";
import { useFormContext } from "react-hook-form";

export function KpoCardNumberList({ kpoCards, selectable = false }) {
  const isSelectable = selectable && kpoCards.length > 1;
  const itemStyle = { borderColor: "black" };
  return <ListGroup className="mb-2 w-auto">
    <ListGroup.Item key={0} style={itemStyle}><b>Karty KPO</b></ListGroup.Item>
    {kpoCards.length === 0 && <ListGroup.Item>Brak kart KPO do wykonania wybranej akcji</ListGroup.Item>}
    {kpoCards.map((x, idx) => <KpoCardNumberItem key={x.kpoId} parentName={`kpoItems.${idx}`} kpoItem={x} selectable={isSelectable}/>)}
  </ListGroup>;
}

export function KpoCardNumberItem({ kpoItem, parentName, selectable = false }) {
  const { watch } = useFormContext();
  const isSelected = watch(`${parentName}.isSelected`);
  const itemStyle = {
    borderColor: "black",
    backgroundColor: isSelected ? "" : "lightGray"
  };
  const { cardNumber, wasteCode, wasteMass, status } = kpoItem;

  return <ListGroup.Item className="d-flex gap-2 align-items-center justify-content-between small" style={itemStyle}>
    {cardNumber ?? "Brak numeru karty"}
    <BadgeDomain>{wasteCode}</BadgeDomain>
    {wasteMass && <>{wasteMass.toFixed(4)} Mg </>}
    <KpoBdoStatusBadge status={status}/>
    {selectable && <DomainSwitch name={`${parentName}.isSelected`} className="domain-control"/>}
  </ListGroup.Item>;
}