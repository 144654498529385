import { KpoBdoSelect } from "../../_components/Bdo/Kpo/KpoBdoSelect";
import { CompanyBdoSelect } from "../../_components/Bdo/Company/CompanyBdoSelect";
import React, { useState } from "react";
import { EupBdoSelect } from "../../_components/Bdo/Eup/EupBdoSelect";
import { OriginatorType } from "../../domain/TransactionType";
import { DropdownInline } from "../../_components/Abstractions/MultiSearcher/DropdownInline";
import { ReadonlyControl } from "../../_components/Forms/ReadonlyControl";
import { HorizontalView } from "../../_components/Abstractions/HorizontalView";
import { Container } from "react-bootstrap";

export function KpoBdoSearcherPage() {
  const [company, setCompany] = useState({});
  const [eupAddress, setEupAddress] = useState({});
  const [originatorType, setOriginatorType] = useState({});

  return <>
    <Container className="ms-2">
      <DropdownInline label="Wyszukiwanie jako:" items={OriginatorType} onSelectedChanged={x => setOriginatorType(x)}/>
      <HorizontalView>
        <div className="my-2">
          <CompanyBdoSelect overrideLabel="Wybierz podmiot BDO" onSelect={x => setCompany(x)}/>
          <ReadonlyControl label="Podmiot BDO">{company.name}</ReadonlyControl>
        </div>
        <>
          <EupBdoSelect companyId={company.companyId} overrideLabel="Wybierz adres MPD" onSelect={x => setEupAddress(x)}/>
          <ReadonlyControl label="Adres MPD">{eupAddress.addressRaw}</ReadonlyControl>
        </>
      </HorizontalView>
    </Container>

    <KpoBdoSelect
      registrationNumber={company.registrationNumber}
      addressRegistrationNumber={eupAddress?.registrationNumber}
      taxId={company.nip}
      originatorRole={originatorType}
      isModal={false}/>
  </>;
}