import { useState } from "react";
import { Form } from "react-bootstrap";
import dayjs from "dayjs";

export function DateSearcher({
  name,
  label,
  onDateChange,
  className,
  placeholder,
  defaultValue,
  currentValue,
  additionalAttributes,
  readonly = false,
  autoFocus = false,
  dateFormat = "dd/MM/yyyy",
}) {
  const [date, setDate] = useState(currentValue || "");
  const [dateError, setDateError] = useState(null);

  const handleDateChange = (event) => {
    const newDate = event.target.value;

    const isValid = dayjs(newDate, dateFormat, true).isValid();

    if (isValid) {
      setDateError(null);
      setDate(newDate);

      if (name) {
        onDateChange({ name, value: newDate });
      } else {
        onDateChange?.(newDate);
      }
    } else {
      setDateError(`Invalid date format. Expected format: ${dateFormat}`);
    }
  };

  return (
    <Form.Group className={className ?? "mb-3"}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        type="date"
        onChange={handleDateChange}
        autoFocus={autoFocus}
        placeholder={placeholder ?? label}
        defaultValue={defaultValue}
        value={date}
        isInvalid={!!dateError}
        disabled={readonly}
        {...additionalAttributes}
      />
      <Form.Control.Feedback type="invalid">
        {dateError}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
