import { Card, Row } from "react-bootstrap";
import { BadgeDomain } from "../../../../_components/Abstractions/BadgeDomain";

export const LoadingWastesCard = ({ loadingWastes }) => {
  if (!loadingWastes || loadingWastes?.length === 0) {
    return;
  }
  return (
    <>
      <Card border="light">
        <Card.Header as="h5">Towary do załadunku</Card.Header>
        <Card.Body>
          {loadingWastes.map(x => <BadgeDomain key={x.wasteId} size="smaller">{x.wasteCode} - {x.internalName}</BadgeDomain>)}
        </Card.Body>
      </Card>
    </>
  );
};