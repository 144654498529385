import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { containersActions } from "../../_store/containers.slice";
import { FormDomain } from "../../_components/Forms/FormDomain";
import { useFieldArray, useFormContext } from "react-hook-form";
import { HorizontalView } from "../../_components/Abstractions/HorizontalView";
import { FormControl } from "../../_components/Forms/FormControl";
import { IntegerControl } from "../../_components/Forms/IntegerControl";
import * as Yup from "yup";
import ContractorValidator from "../../domain/Contractors/ContractorValidator";
import { Dropdown } from "../../_components/Abstractions/Dropdown";
import { DomainButton } from "../../_components/Abstractions/DomainButton";
import { BiMinus } from "react-icons/bi";
import { PlusCircleFill } from "react-bootstrap-icons";
import { Container } from "react-bootstrap";
import { Hr } from "../../_components/Abstractions/Hr/Hr";

export function UpdateUsedContainersState() {
  const { usedContainers } = useSelector(x => x.containers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(containersActions.getUsedState());
  }, []);

  if (usedContainers.length === 0) {
    return;
  }

  const formControls = <>
    <UpdateUsedContainersForm/>
  </>;
  const groupSubContainers = {};
  Object.entries(usedContainers?.groupSubContainers)?.forEach(([groupContainerId, items]) => {
    const subContainers = {};
    items.forEach(container => subContainers[container.id] = container);
    groupSubContainers[groupContainerId] = subContainers;
  });

  const defaultValues = {
    usedContainers: usedContainers?.stateItems,
    groupSubContainers: groupSubContainers,
    removedUsedContainersIds: []
  };

  const validationSchema = Yup.object().shape({
    usedContainers: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().typeError("Grupa kontenera jest wymagana").required("Grupa kontenera jest wymagany"),
        usedCount: Yup.number().typeError("Liczba używanych w grupie kontenera musi zostać podana").integer("Liczba używanych w grupie kontenera musi zostać podana").min(1, "Liczba używanych w grupie musi być większa od zera").
          test("is-total-count-valid", "Suma używanych podtypów kontenerów musi być równa liczbie używanej grupy", function(value) {
            const { items } = this.parent;
            const usedCountsSum = items.reduce((sum, subContainer) => sum + subContainer.usedCount, 0);
            return value === usedCountsSum;
          }).test("areSubtypesUnique", "Podtypy kontenerów nie mogą się powtarzać w grupie", function(value) {
            const { items } = this.parent;
            const ids = items?.map(x => x.containerId);
            if (!ids || ids?.length === 0) {
              return true;
            }

          return new Set(ids).size === items?.length;
        }),
        items: Yup.array().of(ContractorValidator.subContainerSchema)
        .typeError("Podtypy kontenerów są wymagane")
        .min(1, "Podtypy kontenerów są wymagane")
      }))
  });

  const onSubmit = async(data) => {
    const { usedContainers, removedUsedContainersIds } = data;
    const items = usedContainers?.map(x => ({
      id: x.id,
      groupContainerId: x.groupContainerId,
      usedCount: x.usedCount,
      subUsedContainers: x.items.map(s => ({
        id: s.id,
        containerId: s.containerId,
        usedCount: s.usedCount
      }))
    }));

    const payload = { items, removedUsedContainersIds };
    return dispatch(containersActions.updateUsedContainers(payload));
  };

  return <FormDomain
    formControls={formControls}
    onSubmit={onSubmit}
    defaultValues={defaultValues}
    validationSchema={validationSchema}
    successMessage="Zaktualizowano stan kontenerów"
  />;
}

export const UpdateUsedContainersForm = () => {
  const { getValues, setValue } = useFormContext();
  const groupSubContainers = getValues("groupSubContainers");
  // console.log(groupSubContainers);
  const { fields } = useFieldArray({ name: "usedContainers" });
  const handleUsedContainerRemove = (groupIndex, containerIndex) => {
    const usedContainers = getValues("usedContainers");
    const removedUsedContainersIds = getValues("removedUsedContainersIds");
    const groupItems = usedContainers[groupIndex].items;
    const itemToRemove = groupItems[containerIndex];
    if(itemToRemove.id) {
      removedUsedContainersIds.push(itemToRemove.id);
    }

    setValue("removedUsedContainersIds", removedUsedContainersIds);

    // Remove the specific sub-container
    groupItems.splice(containerIndex, 1);

    setValue("usedContainers", usedContainers);
  };

  const handleUsedContainerAppend = (groupIndex, group) => {
    const usedContainers = getValues("usedContainers");
    const groupItems = usedContainers[groupIndex].items;
    const newUsedContainer = {
      contractorName: group.contractorName,
      addressName: group.addressName,
      groupContainerName: group.groupContainerName,
      containerId: null,
      usedCount: 0
    };

    groupItems.push(newUsedContainer);
    setValue("usedContainers", usedContainers);
  };

  return <Container>
    {fields?.map((groupItem, idx) => (
      <div key={groupItem.id}>
        <h6>{groupItem.contractorName} | Adres - {groupItem.addressName}</h6>
        <HorizontalView columnsSize={[ 3, 2]}>
          <FormControl name={`usedContainers[${idx}].groupContainerName`} label="Grupa" readonly/>
          <IntegerControl name={`usedContainers[${idx}].usedCount`} label="Ilość" readonly/>
        </HorizontalView>
        <Hr>Podtypy</Hr>
        <div className="d-flex flex-column">
        {groupItem.items.map((subItem, index) => (
          <div key={subItem.id ?? index} className="d-flex gap-4 justify-content-center">
            <HorizontalView columnsSize={[3, 3, 2, 2, 1, 1]} className="w-100">
              {/*<FormControl name={`usedContainers[${idx}].items[${index}].contractorName`} label="Klient" readonly/>*/}
              {/*<FormControl name={`usedContainers[${idx}].items[${index}].addressName`} label="Adres" readonly/>*/}
              <FormControl name={`usedContainers[${idx}].items[${index}].groupContainerName`} label="Grupa" readonly/>
              {/*<FormControl name={`usedContainers[${idx}].items[${index}].subContainerName`} label="Podtyp" readonly/>*/}
              <Dropdown
                displayProperty="name"
                name={`usedContainers[${idx}].items[${index}].containerId`}
                label="Podtyp"
                items={groupSubContainers[groupItem.groupContainerId]}/>
              <IntegerControl name={`usedContainers[${idx}].items[${index}].usedCount`} label="Liczba"/>
              <DomainButton className="mt-3" onClick={() => handleUsedContainerRemove(idx, index, groupItem)}><BiMinus/></DomainButton>
            </HorizontalView>
          </div>))}
          <div className="align-self-end">
            <DomainButton onClick={() => handleUsedContainerAppend(idx, groupItem)}>Nowy podtyp <PlusCircleFill/></DomainButton>
          </div>
        </div>
        <hr/>
      </div>
    ))}
  </Container>;
};