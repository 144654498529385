import { Card } from "react-bootstrap";
import { BaseWastesForm } from "./BaseWastesForm";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CardView } from "../../Abstractions/CardView";
import { useContext, useEffect } from "react";
import { OrderContext } from "../MainForms/OrderContext";
import { GroupContainersForAddressSearchSelect } from "../../Containers/GroupContainersForAddressSearchSelect";
import { DomainSwitch } from "../../Abstractions/DomainSwitch";

const ContainerExchangeItem = ({ idx }) => {
  const { addressId, isUpdate, requireApplicableKpoItems, isSeries } = useContext(OrderContext);
  const fillWasteMass = isUpdate && !isSeries;
  const wasteFieldName = `wastesDetails.containers[${idx}]`;
  const getFieldName = (field) => `${wasteFieldName}.${field}`;

  return <>
    <Card.Header className="mb-2">Kontener {idx + 1}</Card.Header>
    <BaseWastesForm
      wasteFieldName={wasteFieldName}
      isUsedContainerRequired
      fillWasteMass={fillWasteMass}
      requireApplicableKpoItems={requireApplicableKpoItems}
      additionalFormControls={<GroupContainersForAddressSearchSelect addressId={addressId} saveWholeObject={true} name={getFieldName("usedContainer")}/>}/>
  </>;
};

export function ContainersExchangeForm() {
  const { watch } = useFormContext();
  const isContainersExchange = watch("wastesDetails.isContainerExchange");
  const showSecondContainer = watch("wastesDetails.showSecondContainer");
  const { remove } = useFieldArray({ name: "wastesDetails.containers" });

  useEffect(() => {
    if (showSecondContainer === false) {
      remove(1);
    }
  }, [showSecondContainer]);

  return (
    <>
      {isContainersExchange && (
        <CardView headerText="Dane kontenerów">
          <DomainSwitch name="wastesDetails.showSecondContainer" label="Drugi kontener"/>
          <div>
            <ContainerExchangeItem idx={0}/>
            {showSecondContainer && <ContainerExchangeItem idx={1}/>}
          </div>
        </CardView>
      )}
    </>
  );
}
