import { contractorsActions } from "_store/contractors.slice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export function useContractorSearch({ contractorType, refreshOnInitialize = true }) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  const onSearchChange = async(searchTerm, id) => {
    const query = {
      pageNumber: 1,
      pageSize: 10,
      filter: { id, searchQuery: searchTerm?.trim(), contractorType: contractorType }
    };
    const { payload } = await dispatch(
      contractorsActions.search({ query })
    );
    const items = payload.items;
    setItems(items);
    return items;
  };

  useEffect(() => {
    if (refreshOnInitialize) {
      onSearchChange("");
    }
  }, [contractorType]);


  return { items, onSearchChange };
}
