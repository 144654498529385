import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";

// create slice

const name = "maintenance";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const maintenanceActions = { ...slice.actions, ...extraActions };
export const maintenanceReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    environmentInfo: {
      version: "0.0.0",
      environmentType: "-"
    },
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/maintenance`;

  return {
    getExcelWorksheets: getExcelWorksheets(),
    getOdsWorksheets: getOdsWorksheets(),
    getEnvironmentInfo: getEnvironmentInfo()
  };

  function getExcelWorksheets() {
    return createAsyncThunk(
      `${name}/getExcelWorksheets`,
      async(payload) =>
        await fetchWrapper.post(`${baseUrl}/excel-worksheets`, payload)
    );
  }

  function getOdsWorksheets() {
    return createAsyncThunk(
      `${name}/getOdsWorksheets`,
      async(payload) =>
        await fetchWrapper.post(`${baseUrl}/ods-worksheets`, payload)
    );
  }

  function getEnvironmentInfo() {
    return createAsyncThunk(`${name}/getEnvironmentInfo`, async() => await fetchWrapper.get(`${baseUrl}/version`));
  }
}

function createExtraReducers() {
  return {
    ...getEnvironmentInfo(),
  };

  function getEnvironmentInfo() {
    var { fulfilled } = extraActions.getEnvironmentInfo;
    return {
      [fulfilled]: (state, action) => {
        state.environmentInfo = action.payload;
      },
    };
  }
}