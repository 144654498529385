import { PromptModal } from "_components/Abstractions/PromptModal";
import { useToast } from "_helpers/notifications/useToasts";
import { vehiclesActions } from "_store/vehicles.slice";
import { useDispatch } from "react-redux";

export function ChangeVehicleState({ vehicle, onShow }) {
  const { id: vehicleId, isActive, name } = vehicle;
  const buttonText = isActive ? "Deaktywuj" : "Aktywuj";
  const action = isActive ? "deaktywować" : "aktywować";

  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useToast();

  async function onSubmit(promptResult) {
    if (promptResult !== "Yes") {
      return;
    }

    const response = await dispatch(
      vehiclesActions.changeStatus({ vehicleId, isActive: !isActive })
    );

    if (!response.error) {
      const keyWord = isActive ? "Zdezaktywowano" : "Aktywowano";
      showSuccessMessage(`${keyWord} pojazd`);
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText={buttonText}
        title={`Czy chcesz ${action} pojazd (${name})?`}
        type="YesNo"
        onSubmit={onSubmit}
        onShowModal={onShow}
      />
    </>
  );
}
