import { Form } from "react-bootstrap";
import { useFilterFormContext } from "../Forms/FilterContext";
import { Fragment } from "react";

export function FlatFilterDropdown({
  name,
  label,
  items,
  defaultValue,
  displayProperty,
  onChange,
}) {
  const { onFilterChange } = useFilterFormContext();

  const GetItems = (
    <>
      {!items ? [] : Object.entries(items).map(([key, value]) =>
        (
          <option className="hover-pointer" key={key} value={key}>
            {displayProperty ? value[displayProperty] : value}
          </option>
        ))}
    </>
  );

  const handleOnChange = (e) => {
    onFilterChange({ name, value: e.target.value });
    onChange?.(e.target.value);
  };
  return (
    <>
      <Form.Group className="d-flex align-items-end gap-2 mb-3">
          <Form.Label>{label}</Form.Label>
          <Form.Select
            className="hover-pointer form-input"
            style={{ minWidth: "100px" }}
            onChange={handleOnChange}
          >
            <option key={-1} value={""}>{defaultValue ?? ""}</option>
            {GetItems}
          </Form.Select>
      </Form.Group>
    </>
  );
}
