import Form from "react-bootstrap/Form";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "_helpers/notifications/useToasts";
import { Button, Modal } from "react-bootstrap";
import { DomainButton } from "_components/Abstractions/DomainButton";

export function FormDomain({
  formControls,
  validationSchema,
  onSubmit,
  successMessage,
  isModal = false,
  handleModal,
  defaultValues = null,
  submitBtnName = "Zapisz zmiany",
  addSubmitFooter = true,
  skipToast = false,
  customSubmitBtn = false
}) {
  const formOptions = {
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    defaultValues: defaultValues
  };

  const methods = useForm(formOptions);
  const { handleSubmit, formState, reset } = methods;
  const { isSubmitting } = formState;
  const { showSuccessMessage, showErrorMessage } = useToast();

  const onModalChange = (state) => {
    reset();
    handleModal(state);
  };

  async function submit(payload) {
    const response = await onSubmit(payload);
    if (!response) {
      if (!skipToast) {
        console.warn("Nullable response");
      }
      return;
    }

    if (!response?.error) {
      showSuccessMessage(successMessage);
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  const onError = (errors) => {
    console.error(errors);
    showErrorMessage("Proszę poprawić dane w formularzu...");
  };

  return (
    <>
      <FormProvider {...methods}>
        <Form
          className="mb-5"
          noValidate
          onSubmit={handleSubmit(submit, onError)}
        >
          {formControls}
          <Modal.Footer>
            {isModal && (
              <Button
                variant="secondary"
                disabled={isSubmitting}
                onClick={() => onModalChange(false)}
              >
                Zamknij
              </Button>
            )}
            <div
              className={
                !isModal && addSubmitFooter
                  ? "d-flex justify-content-end align-items-center fixed-bottom border "
                  : ""
              }
              style={
                !isModal && addSubmitFooter
                  ? {
                    backgroundColor: "lightGrey",
                    paddingRight: "150px",
                    marginTop: "20px"
                  }
                  : {}
              }
            >
              {!customSubmitBtn && <DomainButton
                disabled={isSubmitting}
                className="mt-2 mb-2"
                type="submit"
                size="medium"
              >
                {(isSubmitting) && (
                  <span className="spinner-border spinner-border-sm "></span>
                )}
                {submitBtnName}
              </DomainButton>}
            </div>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </>
  );
}
