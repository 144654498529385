import { configureStore } from "@reduxjs/toolkit";

import { authReducer } from "./auth.slice";
import { usersReducer } from "./users.slice";
import { containersReducer } from "./containers.slice";
import { driversReducer } from "./drivers.slice";
import { vehiclesReducer } from "./vehicles.slice";
import { contractorsReducer } from "./contractors.slice";
import { wastesReducer } from "./wastes.slice";
import { bdoReducer } from "./bdo.slice";
import {ordersReducer} from "./order.slice";
import { kpoReducer } from "./kpo.slice";
import { maintenanceReducer } from "./maintenance.slice.js";
import { orderViewsReducer } from "./orderView.slice";
import { groupContainersReducer } from "./groupContainers.slice";
import { salesRepresentativesReducer } from "./salesRepresentatives.slice";
import { servicesReducer } from "./services.slice";
import { orderFileDocumentsReducer } from "./orderFileDocuments.slice";
import { holidaysReducer } from "./holidays.slice";
import { reportsReducer } from "./reports.slice";
import { notificationsReducer } from "./notifications.slice";

export * from "./auth.slice";
export * from "./users.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    containers: containersReducer,
    drivers: driversReducer,
    vehicles: vehiclesReducer,
    contractors: contractorsReducer,
    wastes: wastesReducer,
    bdo: bdoReducer,
    orders: ordersReducer,
    kpo: kpoReducer,
    maintenance: maintenanceReducer,
    orderViews: orderViewsReducer,
    groupContainers: groupContainersReducer,
    salesRepresentatives: salesRepresentativesReducer,
    services: servicesReducer,
    orderDocuments: orderFileDocumentsReducer,
    holidays: holidaysReducer,
    reports: reportsReducer,
    notifications: notificationsReducer
  },
});

export const defaultPageNumber = 1;
export const defaultPageSize = 50;

export const getDictionaryQuery = (baseUrl, payload) => {
  const { pageNumber, pageSize, searchQuery, isActive, sorting, customFilters } = payload;

  let baseQuery = `${baseUrl}?pageNumber=${pageNumber ?? defaultPageNumber}&pageSize=${pageSize ?? defaultPageSize
  }&searchQuery=${searchQuery ?? ""}&isActive=${isActive ?? ""}`;

  if(customFilters) {
    const fieldKeys = Object.keys(customFilters);
    fieldKeys.forEach((fieldKey) => {
      baseQuery+=`&${fieldKey}=${customFilters[fieldKey]}`;
    })
  }

  if (sorting) {
    const { value, type } = sorting;
    baseQuery += `&sortValue=${value ?? ""}&sortType=${type ?? ""}`;
  }

  return baseQuery;
}