import { useEffect, useRef, useState } from "react";
import { CloseButton, ListGroup } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";

import s from "./style.module.css";
import { useFilterFormContext } from "../../Forms/FilterContext";

export function FilterSelectSearcher({
  name,
  label,
  items,
  optionsObject,
  onSearchChange,
  placeholder,
  saveWholeObject,
  readonly,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [display, setDisplay] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const { onFilterChange, currentFilters, reset } = useFilterFormContext();
  const [isDefaultValueLoaded, setIsDefaultValueLoaded] = useState(false);
  const { key, value } = optionsObject;

  useEffect(() => {
    if (reset > 0) {
      handleDismiss();
    }
  }, [reset]);

  useEffect(() => {
    const setDefaultValueIfNeeded = async() => {
      const selectedKey = currentFilters?.[name];
      if (selectedKey) {
        const selectedObjectList = await onSearchChange(null, selectedKey);
        handleSelectOption(selectedObjectList?.[0]);
      }

      setIsDefaultValueLoaded(true);
    };

    setDefaultValueIfNeeded();
  }, []);

  useEffect(() => {
    const newValue = saveWholeObject ? selectedOption : selectedOption?.[key];
    if (selectedOption) {
      setDisplay(selectedOption?.[value] ?? "");
    } else {
      setDisplay("");
    }

    if (isDefaultValueLoaded) {
      onFilterChange({ name: name, value: newValue });
    }
  }, [selectedOption]);

  const handleSearch = (event) => {
    if (event.target.value === searchTerm) {
      return;
    }

    setSearchTerm(event.target.value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const newSearchTimeout = setTimeout(() => {
      onSearchChange?.(event.target.value);
    }, 300);

    setSearchTimeout(newSearchTimeout);
  };

  const handleSelectOption = (option) => {
    setShowOptions(false);
    setSelectedOption(option);
  };

  const handleDismiss = (e) => {
    e?.stopPropagation();
    setShowOptions(false);
    setSelectedOption(null);
  };

  const handleClick = () => {
    if (showOptions) {
      ref.current?.focus();
    }
  };
  const ref = useRef(null);

  const searcher = (
    <div className={s.searcher}>
      <input
        className="form-control"
        ref={ref}
        value={searchTerm}
        onChange={handleSearch}
        placeholder={placeholder}
      ></input>
      <BiSearch className={s.searcherIcon}/>
    </div>
  );

  const handleOptionsShow = () => {
    setShowOptions(true);
  };

  const selectedSection = (
    <>
      {
        <div className="form-floating">
          <input
            className={"form-control form-select hover-pointer upper x-small"}
            placeholder={placeholder}
            readOnly
            value={display}
            onClick={() => {
              handleClick();
            }}
            onFocus={handleOptionsShow}
            disabled={readonly}
          />
          <div className={s.actions}>
            {display?.length > 1 && (
              <CloseButton tabIndex={2} onClick={handleDismiss}/>
            )}
            <div className="vr" style={{ height: "24px" }}></div>
          </div>
          <label>{label}</label>
        </div>
      }
    </>
  );

  const optionsSection = (
    <div className={s.options}>
      <ListGroup>
        {items?.length > 0 &&
          items.map((option) => (
            <ListGroup.Item key={option[optionsObject.key]} onMouseDown={() => handleSelectOption(option)} className={s.option}>
              {option[optionsObject.value]}
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  );

  return (
    <>
      <div
        className={`${s.mainContainer} mb-3`}
        onClick={handleOptionsShow}
        onBlur={() => setShowOptions(false)}
      >
        {selectedSection}
        {showOptions && searcher}
        {showOptions && optionsSection}
      </div>
    </>
  );
}
