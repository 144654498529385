import { useDispatch } from "react-redux";
import { servicesActions } from "../../_store/services.slice";
import { PromptModal } from "../../_components/Abstractions/PromptModal";

export function ChangeServiceState({ item }) {
  const { serviceId: id, isActive } = item;
  const buttonText = isActive ? "Deaktywuj" : "Aktywuj";
  const action = isActive ? "deaktywować" : "aktywować";
  const dispatch = useDispatch();

  async function onSubmit() {
    const payload = {
      ...item,
      id,
      isActive: !item.isActive
    };

    return dispatch(servicesActions.update({ id, payload }));
  }

  return (
    <PromptModal
      buttonText={buttonText}
      title={`Czy ${action} usługę?`}
      type="YesNo"
      onSubmit={onSubmit}
    />
  );
}