import { Card, Col, Row } from "react-bootstrap";
import ContactPersonDetails from "./ContactPersonDetails";

const ContactPersonListDetails = ({ contactPersons, useLightBorder }) => {
  if (contactPersons == null || contactPersons?.length === 0) {
    return <></>;
  }

  const Contacts = () =>
    contactPersons?.map((contactPerson) => (
      <Col key={contactPerson.name}>
        <ContactPersonDetails contactPerson={contactPerson} />
      </Col>
    ));

  const ContactsCard = () => (
    <>
      <Card border={useLightBorder ? "light" : "grey"}>
        <Card.Header as="h5">Osoby kontaktowe</Card.Header>
        <Row md={3} className="g-2 p-3">
          {Contacts()}
        </Row>
      </Card>
    </>
  );

  return <>{ContactsCard()}</>;
};

export default ContactPersonListDetails;
