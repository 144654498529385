import { useDispatch } from "react-redux";
import { salesRepresentativesActions } from "../../_store/salesRepresentatives.slice";
import { SalesRepresentativesForm } from "../../_components/Wastes/SalesRepresentativesForm";

export function UpdateSalesRepresentative({ item }) {
  const dispatch = useDispatch();
  const { id } = item;

  async function onSubmit(payload) {
    return dispatch(salesRepresentativesActions.update({ id, payload }));
  }

  return (
    <SalesRepresentativesForm
      onSubmit={onSubmit}
      defaultValues={item}
      isUpdate
    />
  );
}