import { HorizontalView } from "_components/Abstractions/HorizontalView";
import { ReadonlyControl } from "_components/Forms/ReadonlyControl";
import { Card, ListGroup } from "react-bootstrap";
import { AccordionDomain } from "../../../../_components/Abstractions/AccordionDomain";
import { Hr } from "../../../../_components/Abstractions/Hr/Hr";

export function ContractorBdoDetails({ isBdoLinked, bdoDetails }) {
  const { companyId, companyName, taxId, address, additionalCompaniesBdo } = bdoDetails ?? {};

  return (
    <>
      {isBdoLinked && (
        <Card>
          <Card.Header as="h5">Dane BDO</Card.Header>
          <Card.Body className="g-2">
            <ReadonlyControl label="Nazwa podmiotu">
              {companyName}
            </ReadonlyControl>
            <AccordionDomain header="Szczegóły podmiotu BDO">
              <HorizontalView>
                <ReadonlyControl label="NIP">{taxId}</ReadonlyControl>
                <ReadonlyControl label="Numer rejestrowy">
                  {bdoDetails.registrationNumber}
                </ReadonlyControl>
                <ReadonlyControl label="Numer ref">
                  {companyId}
                </ReadonlyControl>
              </HorizontalView>
              <ReadonlyControl label="Adres centrali podmiotu">
                {address}
              </ReadonlyControl>
              {additionalCompaniesBdo?.length > 0 && <Hr>Dodatkowe podmioty BDO</Hr>}
              <ListGroup className="my-2">
                {additionalCompaniesBdo?.map((x, idx) => <ListGroup.Item key={x.companyId}>
                  {idx + 1}. <b>{x.companyName}</b> NIP: <b>{x.taxId}</b> BDO: <b>{x.registrationNumber}</b>
                  <br/>
                           Adres: {x.addressCentral}</ListGroup.Item>)}
              </ListGroup>
            </AccordionDomain>
          </Card.Body>
        </Card>
      )}
    </>
  );
}
