import { Form } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CheckListBoxItem } from "./CheckListBoxItem";
import { HorizontalView } from "_components/Abstractions/HorizontalView";
import { ControlLabel } from "../Abstractions/ControlLabel";
import { useFilterFormContext } from "../Forms/FilterContext";
import { useEffect, useState } from "react";

export function CheckListBoxFilter({ label, name, items, allowMultiple = false }) {
  const { onFilterChange, currentFilters, reset } = useFilterFormContext();
  const defaultValues = allowMultiple ? currentFilters?.[name]?.split("|") : [ currentFilters?.[name] ];
  const [selectedOptions, setSelectedOptions] = useState(defaultValues ?? []);

  const onSelect = (selectedValue) => {
    const newSelectedOptions = [ ...selectedOptions, selectedValue ];
    submitSelectedOptions(newSelectedOptions);
  }

  const onUnselect = (selectedValue) => {
    const newSelectedOptions = selectedOptions.filter((x) => x !== selectedValue);
    submitSelectedOptions(newSelectedOptions);
  }

  const submitSelectedOptions = (newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions);
    const queryString = newSelectedOptions?.join("|") ?? "";
    onFilterChange({ name, value: queryString });
  }

  useEffect(() => {
    if(reset > 0) {
      setSelectedOptions([]);
    }
  }, [reset])

  return (
    <>
      <Form.Group className="form-border px-2 domain-control mb-3  align-self-stretch">
        <Form.Label style={{ color: "gray", fontSize: "smaller"}}>{label}</Form.Label>
        <div className="d-flex gap-3">
          {Object.entries(items).map(([key, value]) => (
            <CheckListBoxItem
              key={key}
              id={key}
              onSelect={onSelect}
              onUnselect={onUnselect}
              checked={selectedOptions?.includes(key) || false}>
              {value}
            </CheckListBoxItem>
          ))}
        </div>
      </Form.Group>
    </>
  );
}
