import { useDispatch, useSelector } from "react-redux";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { useEffect } from "react";
import { LoadingSpinner } from "../../../_components/Abstractions/LoadingSpinner";
import { OrderSeriesListViewTable } from "../../../_components/OrderViews/ListViews/OrderSeriesListViewTable";
import { useToast } from "../../../_helpers/notifications/useToasts";

export function OrderSeriesListView() {
  const { orderSeriesListView } = useSelector((x) => x.orderViews);
  const dispatch = useDispatch();
  const { showErrorMessage } = useToast();

  const { items, paging, loading } = orderSeriesListView;
  const refreshList = async(payload) => {
    const response =
      await dispatch(orderViewsActions.getSeriesListView(payload));

    if (response && response.error) {
      showErrorMessage(response.error.message);
    }
  };

  const handlePageChange = async(pageNumber, pageSize) => {
    const newPaging = {
      pageNumber: pageNumber ?? paging?.pageNumber,
      pageSize: pageSize ?? paging?.pageSize
    };
    await refreshList(newPaging);
  };

  useEffect(() => {
    const defaultPaging = {
      pageNumber: paging?.pageNumber ?? 1,
      pageSize: paging?.pageSize ?? 50
    };
    refreshList(defaultPaging);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <div className="mb-4">
      <h2>Lista cyklicznych zleceń</h2>
      <LoadingSpinner isLoading={loading} as="div"/>
      <OrderSeriesListViewTable items={items} paging={paging} handlePageChange={handlePageChange}/>
    </div>
  </>;
}