import { PromptModal } from "_components/Abstractions/PromptModal";
import { useToast } from "_helpers/notifications/useToasts";
import { useDispatch } from "react-redux";

export function ChangeAccountState({ user, actions, onShow }) {
  const { id: userId, isActive, login } = user;
  const buttonText = isActive ? "Deaktywuj" : "Aktywuj";
  const action = isActive ? "deaktywować" : "aktywować";

  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useToast();

  async function onSubmit(promptResult) {
    if (promptResult !== "Yes") {
      return;
    }

    const deactivate = async() =>
      await dispatch(actions.deactivate({ userId }));
    const activate = async() => await dispatch(actions.activate({ userId }));

    const response = isActive ? await deactivate() : await activate();

    if (!response.error) {
      const keyWord = isActive ? "Zdezaktywowano" : "Aktywowano";
      showSuccessMessage(`${keyWord} użytkownika`);
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText={buttonText}
        title={`Czy chcesz ${action} konto (${login})?`}
        type="YesNo"
        onSubmit={onSubmit}
        onShowModal={onShow}
      />
    </>
  );
}

