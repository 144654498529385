import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { OrderWorkFlowAction } from "./OrderWorkFlowAction";

export function ConfirmOrderTransport({ orderId, orderNumber }) {
  const successMessage = "Transport zlecenia został zrealizowany";
  const buttonText = "Zrealizuj transport";
  const title = `Czy potwierdzić transport dla zlecenia ${orderNumber}?`;

  const props = {
    orderId: orderId,
    orderAction: ordersActions.confirmOrderTransport,
    orderViewAction: orderViewsActions.confirmTransport,
    successMessage,
    buttonText,
    title
  };

  return <OrderWorkFlowAction {...props} />;
}


