import { useDispatch } from "react-redux";
import { useToast } from "_helpers/notifications/useToasts";
import { DomainPagination } from "_components/Abstractions/DomainPagination";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Searcher } from "../Forms/Searcher";
import { YesNoItems } from "../../domain/YesNoItems";
import { FilterProvider } from "../Forms/FilterContext";
import { HorizontalView } from "./HorizontalView";
import { FlatFilterDropdown } from "./FlatFilterDropdown";
import { useSearchParams } from "react-router-dom";

export function DictionaryPage({
  items,
  paging,
  itemsTable,
  itemActions,
  createItem,
  tableUseCases,
  activeEventKey,
  pageEventKey,
  includeSearcher = false,
  hidePagination = false,
  excludeStatus = false,
  sorting = null,
  searcherPlaceholder = "Szukaj",
  customFiltersControls = null
}) {
  const dispatch = useDispatch();
  const { showErrorMessage } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState();
  const [isActive, setIsActive] = useState();
  const [customFilters, setCustomFilters] = useState();

  const handlePageChange = async(pageNumber, pageSize, searchQuery) => {
    const response = await dispatch(
      itemActions.getAll({ pageNumber, pageSize, searchQuery, isActive, sorting, customFilters })
    );

    if (paging && paging.error) {
      showErrorMessage(paging.error.message);
    }

    if (response && response.error) {
      showErrorMessage(response.error.message);
    }
  };

  useEffect(() => {
    if (activeEventKey !== pageEventKey) {
      return;
    }

    const oldSearchQuery = searchParams.get("search");
    const pageNumber = oldSearchQuery !== searchQuery ? 1 : paging?.pageNumber;
    setSearchParams({ type: pageEventKey, search: searchQuery ?? "" });

    handlePageChange(pageNumber, paging?.pageSize, searchQuery, isActive, customFilters);
  }, [dispatch, activeEventKey, searchQuery, isActive, sorting, customFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchChange = (e) => {
    setSearchQuery(e);
  };

  const handleFilterChange = (e) => {
    if(e.name === "isActive") {
      setIsActive(e.value);
      return;
    }

    if(customFilters?.[e.name] === e.value || (!customFilters?.[e.name] && !e.value)) {
      return;
    }

    setCustomFilters({ ...customFilters, [e.name]: e.value });
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <Col xs={10}>
          <FilterProvider onFilterChange={handleFilterChange}>
            <HorizontalView columnsSize={[6, 2]}>
              {includeSearcher && <Searcher className="mb-2" placeholder={searcherPlaceholder} onSearchChange={handleSearchChange} defaultValue={searchQuery} autoFocus/>}
              {includeSearcher && !excludeStatus && <FlatFilterDropdown name="isActive" defaultValue="-" items={YesNoItems} label="Aktywni:"/>}
            </HorizontalView>
            {customFiltersControls}
          </FilterProvider>
        </Col>
        <div className="d-flex gap-2 mb-2">
          {tableUseCases}
          {createItem}
        </div>
      </div>
      {itemsTable}
      {!items.loading && !hidePagination && (
        <DomainPagination
          onPageChange={handlePageChange}
          paging={paging}
          itemsCount={items.length}
        />
      )}
    </div>
  );
}
