import { PromptModal } from "_components/Abstractions/PromptModal";
import { useToast } from "_helpers/notifications/useToasts";
import { contractorsActions } from "_store/contractors.slice";
import { useDispatch } from "react-redux";

export function ChangeContractorState({
  contractor,
  detailsUpdate,
}) {
  const { id: contractorId, isActive, name } = contractor;
  const buttonText = isActive ? "Deaktywuj" : "Aktywuj";
  const action = isActive ? "deaktywować" : "aktywować";

  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useToast();

  async function onSubmit(promptResult) {
    if (promptResult !== "Yes") {
      return;
    }

    const response = await dispatch(
      contractorsActions.changeStatus({
        contractorId,
        isActive: !isActive,
        detailsUpdate,
      })
    );

    if (!response.error) {
      const keyWord = isActive ? "Zdezaktywowano" : "Aktywowano";
      showSuccessMessage(`${keyWord} klienta`);
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText={buttonText}
        title={`Czy chcesz ${action} klienta (${name})?`}
        type="YesNo"
        onSubmit={onSubmit}
      />
    </>
  );
}
