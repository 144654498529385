import { FloatingLabel, Form } from "react-bootstrap";

export function ReadonlyControl({
  label,
  children,
  inputType = "text",
  additionalAttributes = null,
  size = null
}) {
  return (
    <>
      <Form.Group className="mb-3">
        <FloatingLabel label={label}>
          <Form.Control
            className="form-input"
            type={inputType}
            placeholder={label}
            disabled
            value={children}
            size={size}
            {...additionalAttributes}
          />
        </FloatingLabel>
      </Form.Group>
    </>
  );
}
