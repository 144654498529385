import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { CalendarDayOverlay } from "../OrderCalendar/CalendarDayOverlay";
import { TransportTypeColor } from "../../../domain/TransportType";

export const useShippingCalendarExtras = ({ events, moment, onSelect, setEvents }) => {
  const navigate = useNavigate();
  const minDate = new Date(0, 0, 0, 6, 0);
  const maxDate = new Date(0, 0, 0, 18, 0);
  const navigateToDetails = (id) => navigate(`/orders/${id}`);

  const handleSelecting = ({ start, end, resourceId }) => {
    const isOverlapping = events.some(x => (
      !x.isSelected && x.squarePlaceType === resourceId && (
        (start >= x.start && start < x.end) ||
        (end > x.start && end <= x.end) ||
        (start <= x.start && end >= x.end))
    ));
    return !isOverlapping;
  };

  const DateTimeRangeFormat = ({ start, end }) => {
    const startTime = moment(start).format("HH:mm");
    const endTime = moment(end).format("HH:mm");
    return `${startTime} - ${endTime}`;
  };

  const formats = {
    timeGutterFormat: "HH:mm",
    selectRangeFormat: DateTimeRangeFormat,
    eventTimeRangeFormat: DateTimeRangeFormat
  };

  const handleSelectSlot = (slotInfo) => {
    const getTimeString = (time) => format(time, "HH:mm");
    const result = {
      selectedStartTime: getTimeString(slotInfo.start),
      selectedEndTime: getTimeString(slotInfo.end),
      isSelected: true,
      title: "",
      squarePlaceType: slotInfo.resourceId,
      ...slotInfo
    };

    setEvents((prev) => {
      const filtered = prev.filter((ev) => !ev.isSelected);
      filtered.push(result);
      return filtered;
    });

    onSelect?.(result);
  };

  const EventComponent = ({ event }) => (
    <div className="d-flex upper justify-content-between gap-2 small">
      {event.title}
      {!event.isSelected && <CalendarDayOverlay item={event}/>}
    </div>
  );

  const eventStyleGetter = (event, isSelected) => {
    let color = "";
    if (event.isSelected) {
      color = "gray";
    } else if (event.transportType) {
      color = TransportTypeColor[event.transportType];
    }

    return { style: { backgroundColor: color, borderColor: "black" } };
  };

  const handleDragAndDrop = (info) => {
    if (!handleSelecting(info)) {
      return;
    }

    handleSelectSlot(info);
  };

  const props = {
    formats: formats,
    min: minDate,
    max: maxDate,
    onSelecting: handleSelecting,
    // dayLayoutAlgorithm: "no-overlap",
    onSelectSlot: handleSelectSlot,
    startAccessor: "start",
    endAccessor: "end",
    views: ["day"],
    step: 15,
    timeslots: 1,
    defaultView: "day",
    draggableAccessor: "isSelected",
    resizableAccessor: "isSelected",
    resizable: true,
    onEventDrop: handleDragAndDrop,
    onEventResize: handleDragAndDrop,
    eventPropGetter: eventStyleGetter,
    onSelectEvent: e => e.id && navigateToDetails(e.id),
    showMultiDayTimes: true,
    components: {
      event: EventComponent,
      // resourceHeader: ({ resource }) => (
      //   <div className="position-sticky top-0 z-1">
      //     {resource.squarePlaceTitle}
      //   </div>),
      toolbar: () => (<div/>)
    }
  };

  return { props };
};