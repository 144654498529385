import { useSelector } from "react-redux";
import { OrderListViewTable } from "../../../_components/OrderViews/ListViews/OrderListViewTable";
import { useEffect } from "react";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { OrderCalendarFilterBox } from "../../../_components/OrderViews/OrderCalendar/OrderCalendarFilterBasic";
import { useCalendarView } from "../../../_components/OrderViews/OrderCalendar/useCalendarView";
import { LoadingSpinner } from "../../../_components/Abstractions/LoadingSpinner";

export function OrderListView() {
  const { ordersListView } = useSelector((x) => x.orderViews);
  const { items, paging, loading } = ordersListView;
  const { refreshCalendar, currentFilters, handleCalendarChange, refreshFilters, setCurrentFilters } = useCalendarView({ skipDates: true, orderViewsAction: orderViewsActions.getListView });

  const handlePageChange = async (pageNumber, pageSize) => {
    const newPaging = {
      pageNumber: pageNumber ?? paging?.pageNumber,
      pageSize: pageSize ?? paging?.pageSize
    };
    await refreshFilters(newPaging);
  };

  useEffect(() => {
    const defaultPaging = {
      pageNumber: paging?.pageNumber ?? 1,
      pageSize: paging?.pageSize ?? 50
    };
    refreshFilters(defaultPaging);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <div className="mb-4">
      <h2>Lista zleceń</h2>
      <OrderCalendarFilterBox
        currentDate={null}
        currentFilters={currentFilters}
        handleCalendarChange={handleCalendarChange}
        handleFilterChange={refreshFilters}
        refreshCalendar={refreshCalendar}
        setCurrentFilters={setCurrentFilters}
        isCalendarView={false}
      />
      <LoadingSpinner isLoading={loading} as="div"/>
      <OrderListViewTable items={items} paging={paging} handlePageChange={handlePageChange}/>
    </div>
  </>;
}

