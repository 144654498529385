import { Searcher } from "_components/Forms/Searcher";
import { Container, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
import { DomainButton } from "./DomainButton";

export function SelectTable({
  table,
  label,
  onSearchChange,
  show,
  setShow,
  selectContent,
  searcher,
  disabled,
  disabledReason,
  onSubmit,
  btnVariant = "primary",
  isModal = true,
  skipSearchBar = false,
  modalSize = "xl",
  modalTitle,
  onShowModal,
  btnSize,
  btnClassName
}) {
  const data = { label, show, setShow, disabled, disabledReason, onSubmit, btnVariant, modalSize, modalTitle, onShowModal, btnSize, btnClassName };

  if (isModal) {
    return (
      <ModalTableWrapper {...data}>
        {selectContent}
        {!skipSearchBar && (searcher ?? <Searcher placeholder="Szukaj" onSearchChange={onSearchChange} autoFocus/>)}
        {table}
      </ModalTableWrapper>
    );
  }

  return <BoxTable label={label}>
    {selectContent}
    {searcher ?? <Searcher placeholder="Szukaj" onSearchChange={onSearchChange} autoFocus/>}
    {table}
  </BoxTable>;
}

const BoxTable = ({ label, children }) => <>
  <Container>
    <h3>{label}</h3>
    {children}
  </Container>
</>;

const ModalTableWrapper = ({ label, children, show, setShow, disabled, disabledReason, btnVariant, modalSize, modalTitle, onShowModal,
btnSize, btnClassName }) => {
  const [internalShow, setInternalShow] = useState(false);

  const handleClose = () => {
    if (setShow) {
      setShow(false);
      return;
    }

    setInternalShow(false);
  };
  const handleShow = (e) => {
    e.stopPropagation();

    if (setShow) {
      setShow(true);
      return;
    }

    if (onShowModal) {
      onShowModal();
    }

    setInternalShow(true);
  };

  const overlay = disabled ? <Tooltip>{disabledReason}</Tooltip> : <></>;

  return <>
    <OverlayTrigger overlay={overlay}>
      <span className="d-inline-block">
        <DomainButton size={btnSize} className={btnClassName} variant={btnVariant} onClick={handleShow} disabled={disabled}>
          {label}
        </DomainButton>
      </span>
    </OverlayTrigger>
    <Modal show={show ?? internalShow} onHide={handleClose} size={modalSize} backdrop="static" enforceFocus>
      <Modal.Header closeButton>
        <Modal.Title as="h6">{modalTitle ?? label}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="form-input">{children}</Modal.Body>
    </Modal>
  </>;
};