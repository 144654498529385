import { MultiSelectSearcher } from "_components/Abstractions/MultiSearcher/MultiSearcher";
import { StringExtensions } from "_helpers/extensions";
import { wastesActions } from "_store/wastes.slice";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";

export function WastesMultiSelect({ name, optionSave, required, isUsedContainerRequired = false, isAddressIdRequired = false, usedContainerId, addressId, wasteIds, resetOnReadonlyChange, skipFieldsTrack, skipInitLoading = false }) {
  const dispatch = useDispatch();
  const { getValues } = useFormContext();
  const wastesEditHelperName = StringExtensions.extractObjectBeforeLastProperty(name);
  const wastesEditHelper = getValues(`${wastesEditHelperName}.wastes`);
  const isFirstRender = useRef(true);

  const [items, setItems] = useState([]);
  const onSearchChange = async(searchTerm) => {
    if (isUsedContainerRequired && !usedContainerId) {
      setItems([]);
      return;
    }

    const params = { searchQuery: searchTerm, pageNumber: 1, pageSize: 10, usedContainerId, addressId, wasteIds };
    const { payload } = await dispatch(wastesActions.search(params));
    setItems(payload?.items ?? []);
  };

  useEffect(() => {
    if (isFirstRender && skipInitLoading) {
      return;
    }
    onSearchChange(null);
  }, [usedContainerId]); // eslint-disable-next-line/react-hooks/exhaustive-deps

  return (
    <>
      <MultiSelectSearcher
        name={name}
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "wasteId", value: "displayDescription" }}
        optionSave={(x) => (optionSave ? optionSave(x) : x.wasteId)}
        placeholder="Wybierz towary"
        editHelper={wastesEditHelper}
        required={required}
        readonly={(isUsedContainerRequired && !usedContainerId) || (isAddressIdRequired && addressId)}
        resetOnReadonlyChange={resetOnReadonlyChange}
        // resetOnChange={usedContainerId}
        skipFieldsTrack={skipFieldsTrack}
      />
    </>
  );
}
