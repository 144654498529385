import { createContext } from "react";
import { useFormContext } from "react-hook-form";
import { TransactionTypeToOriginatorType } from "../../../domain/TransactionType";

export const OrderContext = createContext(null);

export const OrderProvider = ({ children, transactionType, isUpdate = false, requireApplicableKpoItems = false, isSeries = false }) => {
  const originatorRole = TransactionTypeToOriginatorType[transactionType];
  const { watch, setValue } = useFormContext();
  const contractor = watch("contractor");
  const orderAddress = watch("orderAddress");
  const wastesDetails = watch("wastesDetails");
  const transportDetails = watch("transportDetails");
  const { isBdoIntegrated, isWasteTransport } = wastesDetails || { };
  const { id: contractorId, taxId, registrationNumber, bdoCompanyName, hasAdditionalBdoCompanies, additionalCompanyBdoDataItems } = contractor || {};
  const { id: addressId, bdoDetails: addressBdoDetails } = orderAddress || {};
  const { registrationNumber: addressRegistrationNumber, bdoCompanyId } = addressBdoDetails || {};
  const { transportType } = transportDetails || {};
  const isSale = transactionType === "Sale";
  const kpoCards = watch("bdoDetails.kpoCards");
  const plannedLoadingDate = watch("transportDetails.plannedLoadingDate");
  const setKpoCards = (cards) => setValue("bdoDetails.kpoCards", cards);
  const unselectKpoCard = (kpoCard) => {
    const newKpoCards = kpoCards.filter(x => x.kpoId !== kpoCard.kpoId);
    setKpoCards(newKpoCards);
  };

  const orderForm = {
    isUpdate, requireApplicableKpoItems, contractorId, addressId, taxId,
    registrationNumber, isBdoIntegrated, addressRegistrationNumber, transactionType,
    originatorRole, isSale, setKpoCards, kpoCards, unselectKpoCard, wastesDetails, plannedLoadingDate,
    hasAdditionalBdoCompanies, additionalCompanyBdoDataItems,
    bdoCompanyName, bdoCompanyId, transportType, isWasteTransport, isSeries
  };

  return (
    <OrderContext.Provider value={orderForm}>
      {children}
    </OrderContext.Provider>
  );
};