import { ContactPersonTypeBadge } from "./ContactPersonTypeBadge";

export function ContactPersonTypeBadgeList({ contactPerson }) {
  let badges = [];
  if (contactPerson.isDefault) {
    badges.push(<ContactPersonTypeBadge key="isDefault" type="isDefault" />);
  }

  return <div className="mb-2">{badges}</div>;
}
