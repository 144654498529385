import { SelectTable } from "_components/Abstractions/SelectTable";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CompanyBdoTable } from "./CompanyBdoTable";
import { bdoActions } from "_store/bdo.slice";
import { DomainButton } from "../../Abstractions/DomainButton";

export function CompanyBdoSelect({ onSelect, overrideLabel, multiple = false, defaultValues }) {
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(defaultValues ?? []);
  const dispatch = useDispatch();

  const onSearchChange = async (searchTerm) => {
    const params = { searchTerm };
    setItems({ loading: true });
    const { payload } = await dispatch(bdoActions.searchCompany(params));
    setItems(payload);
  };

  const onSelectItem = (item) => {
    onSelect(item);
    setShow(false);
  };

  const selectedContent = <div className="d-flex justify-content-end mb-2">
    <DomainButton onClick={() => onSelectItem(selectedItems)}>Przypisz dodatkowe podmioty BDO</DomainButton>
  </div>

  const table = <CompanyBdoTable
    items={items}
    onSelect={onSelectItem}
    multiSelect={multiple}
    onSelectedItemsChanged={setSelectedItems}
    selectedItems={selectedItems}
    />

  return (
    <>
      <SelectTable
        table={table}
        label={overrideLabel ?? "Powiąż klienta z podmiotem BDO"}
        onSearchChange={onSearchChange}
        show={show}
        selectContent={multiple && selectedContent}
        setShow={setShow}
      />
    </>
  );
}
