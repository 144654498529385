import { useFieldArray, useFormContext } from "react-hook-form";
import { DomainButton } from "_components/Abstractions/DomainButton";
import { ContactPersonForm } from "./ContactPersonForm";
import { Accordion, Alert, Card } from "react-bootstrap";
import { useState } from "react";

export function ContactPersonsForm({ prefix = "", isAddressContact }) {
  const baseFieldName = "contactPersons";
  const fieldName = prefix ? `${prefix}.${baseFieldName}` : baseFieldName;
  const { getValues, getFieldState, watch } = useFormContext();
  const fieldState = getFieldState(fieldName);
  const { fields, append, remove } = useFieldArray({
    name: fieldName,
  });
  const [activeKey, setActiveKey] = useState([]);

  const addContactPerson = () => {
    let isDefault = false;
    let isSalesRepresentative = false;
    const originatorType = getValues("originatorType");
    const noContacts = fields.length === 0;
    if (noContacts) {
      isDefault = true;
    }
    if (
      originatorType === "SalesRepresentative" &&
      prefix === "" &&
      noContacts
    ) {
      isSalesRepresentative = true;
    }
    append({
      id: null,
      phone: "",
      email: "",
      name: "",
      remarks: "",
      isDefault,
      isSalesRepresentative,
    });

    onAccordionItemClick(fields.length);
  };

  const removeContactPerson = (index) => {
    setActiveKey((prevState) => prevState.filter(x => x !== index));
    remove(index);
  };

  const onAccordionItemClick = (index) => {
    if(activeKey.includes(index))
    {
      setActiveKey((prevState) => prevState.filter(x => x !== index));
      return;
    }

    setActiveKey((prevState) => [ ...prevState, index]);
  }

  return (
    <div>
      {fieldState?.error?.message && (
        <Alert variant="danger">{fieldState?.error?.message}</Alert>
      )}
      <Accordion activeKey={activeKey}>
        {fields.map((contactPerson, index) => (
          <div key={contactPerson.id}>
            <Accordion.Item
              eventKey={index}
              className={
                fieldState.error?.[index]
                  ? "mb-1 form-control is-invalid"
                  : "mb-1 "
              }
            >
              <Accordion.Header onClick={() => onAccordionItemClick(index)} className="">
                {index + 1}. Kontakt
              </Accordion.Header>
              <Accordion.Body>
                <DomainButton
                  className="mb-2"
                  onClick={() => removeContactPerson(index)}
                >
                  Usuń kontakt
                </DomainButton>

                <ContactPersonForm
                  contactField={`${fieldName}[${index}]`}
                  defaultContactFieldName={`${fieldName}.defaultContactIdx`}
                  idx={index}
                />
              </Accordion.Body>
            </Accordion.Item>
          </div>
        ))}
      </Accordion>

      <Card.Footer>
        <DomainButton className="mb-2" onClick={addContactPerson}>
          {isAddressContact
            ? "Dodaj kontakt do adresu"
            : "Dodaj kontakt do klienta"}
        </DomainButton>
      </Card.Footer>
    </div>
  );
}
