import { useDispatch, useSelector } from "react-redux";
import { wastesActions } from "_store/wastes.slice";
import { WasteForm } from "_components/Wastes/WasteForm";

export function CreateWaste() {
  const dispatch = useDispatch();
  const { wastesPaging } = useSelector((x) => x.wastes);

  async function onSubmit(payload) {
    const response = await dispatch(wastesActions.create(payload));
    if (!response?.error) {
      const pageNumber = wastesPaging?.pageNumber ?? 1;
      dispatch(wastesActions.getAll({ pageNumber }));
    }

    return response;
  }

  return <WasteForm onSubmit={onSubmit}></WasteForm>;
}

