import { useContractorSearch } from "./searchContractor";
import { FilterSelectSearcher } from "../Abstractions/MultiSearcher/FilterSelectSearcher";
import { useAddressSearch } from "../Addresses/AddressSearch";

export function ContractorFilterSelect({ name, contractorType, label = "Klient", saveWholeObject = true }) {
  const { items, onSearchChange, getValueById } = useContractorSearch({ contractorType, refreshOnInitialize: false });

  return (
    <>
      <FilterSelectSearcher
        name={name}
        label={label}
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "id", value: "name" }}
        placeholder="Wyszukaj..."
        getValueById={getValueById}
        saveWholeObject={saveWholeObject}
      />
    </>
  );
}

export function AddressFilterSelect({ name, saveWholeObject = true }) {
  const { items, onSearchChange } = useAddressSearch();

  return (
    <>
      <FilterSelectSearcher
        name={name}
        label="Adres"
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "id", value: "name" }}
        placeholder="Wyszukaj adres..."
        saveWholeObject={saveWholeObject}
      />
    </>
  );
}