import { DictionaryPage } from "_components/Abstractions/DictionaryPage";
import { useSelector } from "react-redux";
import { CreateGroupContainer } from "./CreateGroupContainer";
import { GroupContainerTable } from "../../_components/Containers/GroupContainerTable";
import { groupContainersActions } from "../../_store/groupContainers.slice";

export function GetGroupContainers({ activeEventKey, pageEventKey }) {
  const isActivePage = activeEventKey === pageEventKey;
  const { groupContainers, groupContainersPaging, groupContainersSummary } = useSelector((x) => x.groupContainers);
  const table = <GroupContainerTable groupContainers={groupContainers} isActivePage={isActivePage} summary={groupContainersSummary} />;
  const createPage = <CreateGroupContainer />;

  return (
    <DictionaryPage
      items={groupContainers}
      paging={groupContainersPaging}
      itemsTable={table}
      itemActions={groupContainersActions}
      createItem={createPage}
      activeEventKey={activeEventKey}
      pageEventKey={pageEventKey}
      includeSearcher
      excludeStatus
    />
  );
}
