import { BadgeDomain } from "_components/Abstractions/BadgeDomain";
import { VEHICLES_DISPLAY } from "domain/VehicleType";

export function VehicleTypeBadge({ type, size }) {
  return (
    <>
      <BadgeDomain size={size} bg={VEHICLES_DISPLAY[type].color}>
        {VEHICLES_DISPLAY[type].name}
      </BadgeDomain>
    </>
  );
}
