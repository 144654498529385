import { SelectSearcher } from "_components/Abstractions/MultiSearcher/SelectSearcher";
import { useDriverSearch } from "./DriverSearch";
import { FilterSelectSearcher } from "../Abstractions/MultiSearcher/FilterSelectSearcher";

export function DriverSelect({ name, driverType, readonly, allowSearchWithoutType, required = false }) {
  const { items, onSearchChange } = useDriverSearch({ driverType, allowSearchWithoutType, refreshOnInitialize: false });

  return (
      <SelectSearcher
        name={name}
        label="Kierowca Eko-Partner"
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "id", value: "displayName" }}
        placeholder="Szukaj"
        required={required}
        readonly={readonly}
        saveWholeObject
      />);
}

export function DriverFilterSelect({ name, driverType, allowSearchWithoutType, label }) {
  const { items, onSearchChange } = useDriverSearch({ driverType, allowSearchWithoutType, refreshOnInitialize: false });

  return (
    <>
      <FilterSelectSearcher
        name={name}
        label={label}
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "id", value: "displayName" }}
        placeholder="Szukaj"
        saveWholeObject={false}
      />
    </>
  );
}
