import { useFormContext } from "react-hook-form";
import { DomainSwitch } from "../../../../_components/Abstractions/DomainSwitch";
import { CompanyBdoSelect } from "../../../../_components/Bdo/Company/CompanyBdoSelect";
import { ListGroup } from "react-bootstrap";

export function BdoAdditionalCompaniesForm() {
  const { watch, setValue } = useFormContext();
  const hasAdditionalBdoCompanies = watch("bdoDetails.hasAdditionalBdoCompanies");
  const additionalBdoCompanies = watch("bdoDetails.additionalCompaniesBdo");
  const onAdditionalBdoCompaniesSelected = (selectedItems) => {
    setValue("bdoDetails.additionalCompaniesBdo", selectedItems);
    console.log(selectedItems);
  }

  return <>
    <DomainSwitch name="bdoDetails.hasAdditionalBdoCompanies" className="mt-4 mb-3" label="Podpięcie dodatkowych podmiotów BDO"/>
    {hasAdditionalBdoCompanies && <div>
      <CompanyBdoSelect
        onSelect={onAdditionalBdoCompaniesSelected}
        overrideLabel="Przypisz dodatkowe podmioty BDO"
        defaultValues={additionalBdoCompanies}
        multiple/>
      <ListGroup className="my-2">
        {additionalBdoCompanies?.map((x, idx) => <ListGroup.Item key={x.companyId}>
          {idx + 1}. <b>{x.name}</b> NIP: <b>{x.nip}</b> BDO: <b>{x.registrationNumber}</b>
          <br/>
          Adres: {x.addressRaw}</ListGroup.Item>)}
      </ListGroup>
    </div>}
  </>;
}