import { containersActions } from "_store/containers.slice";
import { useDispatch } from "react-redux";
import { ContainerForm } from "./ContainerForm";

export function UpdateContainer({ container }) {
  const dispatch = useDispatch();
  const { id: containerId } = container;

  async function onSubmit(payload) {
    const { groupContainer, ...data } = payload;
    const request = { ...data, ...groupContainer };

    return await dispatch(containersActions.update({ containerId, payload: request }));
  }

  const buttonText = "Edytuj";
  const successMessage = "Zaktualizowano kontener";
  const { groupContainerId, groupContainerType, ...data} = container;
  const defaultValues = { ...data, groupContainer: groupContainerId ? { groupContainerId, groupContainerType } : null };

  return <ContainerForm onSubmit={onSubmit} buttonText={buttonText} successMessage={successMessage} defaultValues={defaultValues} isUpdate/>;
}
