import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { OrderWorkFlowAction } from "./OrderWorkFlowAction";
import { DomainSwitch } from "../../../_components/Abstractions/DomainSwitch";
import { USER_ROLES } from "../../../domain/UserRoles";

export function CloseOrder({ orderId, orderNumber, userRole }) {
  const successMessage = "Zlecenie zostało zamknięte";
  const buttonText = "Zamknij zlecenie";
  const title = `Czy zamknąć zlecenie ${orderNumber}?`;
  const hasAdminPermissions = userRole === USER_ROLES.SUPER_USER || userRole === USER_ROLES.ADMIN;
  const formControls = <>
    <DomainSwitch name="skipWasteValidation" label="Pomiń walidację towarów" />
  </>

  const props = {
    orderId: orderId,
    orderAction: ordersActions.closeOrder,
    orderViewAction: orderViewsActions.close,
    successMessage,
    buttonText,
    title,
    formControls,
    useFormModal: hasAdminPermissions
  };

  return <OrderWorkFlowAction {...props} />;
}
