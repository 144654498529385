import { useDispatch } from "react-redux";
import { servicesActions } from "../../_store/services.slice";
import { ServicesForm } from "./ServicesForm";

export function UpdateService({ item }) {
  const dispatch = useDispatch();
  const { serviceId: id } = item;
  const defaultValues = { ...item, id }

  async function onSubmit(payload) {
    return dispatch(servicesActions.update({ id, payload }));
  }

  return (
    <ServicesForm
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      isUpdate
    />
  );
}

