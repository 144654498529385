import { ORDER_STATUS } from "../../../domain/Orders/OrderStatus";
import { OrderProvider } from "../../../_components/Orders/MainForms/OrderContext";
import { WastesOrderDetails } from "../../../_components/Orders/WastesOrderDetails";
import { WasteMassValidator } from "../../../domain/Orders/CreatePurchaseOrderValidator";
import * as Yup from "yup";

export function WastesDetailsFormProvider({ order, wastesDetails, transactionType, isSeries, requireApplicableKpoItems = false }) {
  const { contractorDetails, transportDetails } = order;
  const { addressId } = contractorDetails || {};
  const { isTransfer } = transportDetails || {};

  const { isBdoIntegrated, receivedNoteNumber, deliveryNoteNumber, wasteExchanges, saleDocumentNumber, isWasteTransport } = wastesDetails || {};
  const isPurchase = transactionType === "Purchase";
  const includeExtendedSaleWastesData = transactionType === "Sale"
    && !isTransfer
    && (order.status === ORDER_STATUS.RECEIVE_CONFIRMED || order.status === ORDER_STATUS.TRANSPORT_CONFIRMED || order.status === ORDER_STATUS.COURSE_COMPLETED);
  const formControls = (
    <div className="domain-control">
      <OrderProvider transactionType={transactionType} isUpdate requireApplicableKpoItems={requireApplicableKpoItems} isSeries={isSeries}>
        <WastesOrderDetails transactionType={transactionType} includeExtendedSaleWastesData={includeExtendedSaleWastesData} isUpdate isSeries={isSeries}/>
      </OrderProvider>
    </div>
  );

  const mapToWaste = (x) => {
    const extendedData = x.extendedData ? mapToExtendedData(x.extendedData) : null;
    return ({
      wasteId: x.wasteId,
      wasteCodeBdoId: x.bdoId,
      externalName: x.externalName,
      internalName: x.internalName,
      wasteCode: x.code,
      displayDescription: x.displayDescription,
      wasteMass: x.wasteMass?.toFixed(4),
      initWasteMass: x.wasteMass?.toFixed(4),
      ...extendedData
    });
  };

  const mapToExtendedData = (waste) => ({
    deductions: waste.deductions,
    revenueDocumentNumber: waste.revenueDocumentNumber,
    revenueWasteMass: waste.revenueWasteMass,
    wasteDifferenceReasons: waste.wasteDifferenceReasons,
    wasteDifferenceOtherReason: waste.wasteDifferenceOtherReason,
    initWasteMass: waste.initWasteMass?.toFixed(4)
  });

  let loading, sale, containers, transport;
  const isLoading = wasteExchanges?.some(x => !x.isContainer) && isPurchase;
  const isContainerExchange = wasteExchanges?.some(x => x.isContainer) && isPurchase;

  if (isLoading) {
    const loadingExchange = wasteExchanges?.find(x => !x.isContainer);
    const loadingWastes = loadingExchange?.wastes?.map(mapToWaste);
    loading = isLoading && { wasteExchangeId: loadingExchange?.wasteExchangeId, wastes: loadingWastes };
  }
  if (isContainerExchange) {
    containers = wasteExchanges?.filter(x => x.isContainer).map(x => ({
      usedContainer: {
        usedContainerId: x.usedContainerId,
        groupContainerType: x.containerName
      },
      wastes: x.wastes.map(mapToWaste),
      wasteExchangeId: x.wasteExchangeId
    }));
  }

  if (transactionType === "Sale") {
    const salesExchange = wasteExchanges?.[0];
    const saleWastes = salesExchange?.wastes?.map(mapToWaste);
    sale = { wastes: saleWastes, wasteExchangeId: salesExchange?.wasteExchangeId };
  }

  if (transactionType === "Transport") {
    const transportExchange = wasteExchanges?.[0];
    const transportWastes = transportExchange?.wastes?.map(mapToWaste);
    transport = { wastes: transportWastes, wasteExchangeId: transportExchange?.wasteExchangeId };
  }

  const defaultValues = {
    orderAddress: { id: addressId },
    wastesDetails: {
      isBdoIntegrated: isBdoIntegrated,
      isLoading: isLoading,
      receivedNoteNumber: receivedNoteNumber,
      deliveryNoteNumber: deliveryNoteNumber,
      saleDocumentNumber: saleDocumentNumber,
      isContainerExchange: isContainerExchange,
      containers: containers,
      loading: loading,
      saleWastes: sale,
      requireWasteMass: !isSeries,
      requireApplicableKpoItems: requireApplicableKpoItems,
      isWasteTransport: isWasteTransport,
      transportWastes: transport,
      showSecondContainer: containers?.length > 1
    }
  };
  const additionalValidationSchema = includeExtendedSaleWastesData ? WasteMassValidator.saleExtendedDetailsValidator : Yup.object();
  const validationSchema = Yup.object().shape({
    wastesDetails: WasteMassValidator.wasteDetailsValidator.concat(additionalValidationSchema)
  });

  return { formControls, defaultValues, validationSchema, includeExtendedSaleWastesData };
}