export const WasteDifferenceReason = {
  Humidity: "Wilgoć",
  Pollution: "Zanieczyszczenia",
  Calibration: "Kalibracja",
  Other: "Inne"
};

export const WASTE_DIFFERENCE_REASON = {
  HUMIDITY: "Humidity",
  POLLUTION: "Pollution",
  CALIBRATION: "Calibration",
  OTHER: "Other"
};