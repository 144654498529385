import * as Yup from "yup";
import { Dropdown } from "_components/Abstractions/Dropdown";
import { FormControl } from "_components/Forms/FormControl";
import { UserRoles } from "domain/UserRoles";
import { FormModal } from "_components/Forms/FormModal";
import { UserBdoDetails } from "./UserBdoDetails";
import { guidPattern } from "../../_services/extensions/yupProvider";

export function UserForm({ onSubmit, isUpdate = false, defaultValues }) {
  const passwordValidation = isUpdate
    ? {}
    : {
        password: Yup.string()
          .required("Hasło jest wymagane")
          .min(8, "Hasło musi mieć przynajmniej 8 znaków")
          .max(256, "Hasło może mieć maksymalnie 256 znaków"),
        confirmPassword: Yup.string()
          .required("Potwierdzenie hasła jest wymagane")
          .oneOf([Yup.ref("password"), null], "Hasła muszą się być takie same"),
      };

  const validationSchema = Yup.object().shape({
    login: Yup.string()
      .required("Login jest wymagany")
      .min(2, "Login musi mieć przynajmniej 2 znaki")
      .max(30, "Login może mieć maksymalnie 30 znaków"),
    name: Yup.string()
      .required("Imię jest wymagane")
      .min(3, "Imię musi mieć przynajmniej 3 znaki")
      .max(20, "Imię może mieć maksymalnie 20 znaków"),
    lastName: Yup.string()
      .required("Nazwisko jest wymagane")
      .min(3, "Nazwisko musi mieć przynajmniej 3 znaki")
      .max(20, "Nazwisko może mieć maksymalnie 20 znaków"),
    ...passwordValidation,
    bdoClientId: Yup.string().nullable().when("isBdoLinked", {
      is: (val) => val,
      then: Yup.string().nullable()
      .required("Pole ClientId z BDO jest wymagane")
      .length(36, "Pole ClientId z BDO jest niepoprawne, powinno składać się z 36 znaków)"),
    }),

    bdoClientSecret: Yup.string().nullable().when("isBdoLinked", {
      is: (val) => val,
      then: Yup.string().nullable()
      .required("Pole Client Secret z BDO jest wymagane")
      .length(64, "Client Secret powinien składać się z 64 znaków."
      ),
    }),
    userRole: Yup.string()
      .required("Rola użytkownika jest wymagana")
      .oneOf(Object.keys(UserRoles), "Rola użytkownika jest wymagana"),
  });

  const formControls = (
    <>
      <FormControl label="Login" name="login" className="login-input" autoFocus />
      <FormControl label="Imię" name="name" />
      <FormControl label="Nazwisko" name="lastName" />
      {!isUpdate && (
        <>
          <FormControl label="Hasło" name="password" inputType="password" className="login-input" />
          <FormControl
            label="Potwierdź hasło"
            name="confirmPassword"
            inputType="password"
            className="login-input"
          />
        </>
      )}
      <Dropdown label="Wybierz rolę" name="userRole" items={UserRoles} />
      <UserBdoDetails />
    </>
  );

  const buttonText = isUpdate ? "Edytuj" : "Dodaj użytkownika";
  const successMessage = isUpdate
    ? "Zaktualizowano użytkownika"
    : "Dodano użytkowika";
  const title = buttonText;

  return (
    <>
      <FormModal
        formControls={formControls}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        buttonText={buttonText}
        successMessage={successMessage}
        title={title}
        defaultValues={defaultValues}
      />
    </>
  );
}
