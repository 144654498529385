import { DomainSwitch } from "_components/Abstractions/DomainSwitch";
import { FormControl } from "_components/Forms/FormControl";
import { TextArea } from "_components/Forms/TextArea";
import { Card } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

export function UserBdoDetails() {
  const { watch } = useFormContext();
  const isBdoLinked = watch("isBdoLinked");

  return (
    <>
      <DomainSwitch label="Podpięcie pod konto BDO" name="isBdoLinked" />
      {isBdoLinked && (
        <Card>
          <Card.Header>Dane BDO</Card.Header>
          <Card.Body>
            <FormControl label="Client ID (BDO)" name="bdoClientId" />
            <TextArea label="Client Secret (BDO)" name="bdoClientSecret" />
          </Card.Body>
        </Card>
      )}
    </>
  );
}
