import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '_helpers';
import { getDictionaryQuery } from "_store";
import { StringExtensions } from "../_helpers/extensions";

// create slice

const name = 'vehicles';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, reducers, initialState, extraReducers });

export const vehiclesActions = { ...slice.actions, ...extraActions };
export const vehiclesReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    vehicles: {},
    vehiclesSorting: { value: "displayName", type: "asc" },
    vehiclesPaging: null,
    vehicleExtendedData: {},
  };
}

function createReducers() {
  return { setSorting };

  function setSorting(state, action) {
    state.vehiclesSorting = action.payload;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/vehicles`;

  return {
    getAll: getAll(),
    search: search(),
    create: create(),
    changeStatus: changeStatus(),
    update: update(),
    getExtendedData: getExtendedData(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async(payload) =>
        await fetchWrapper.get(getDictionaryQuery(baseUrl, payload)));
  }

  function search() {
    return createAsyncThunk(`${name}/search`, async(payload) => {
      const { pageNumber, pageSize, filter, token } = payload;
      const query = { pageNumber, pageSize, ...filter };
      const queryString = StringExtensions.createQueryStringFromObject(query);
      return await fetchWrapper.get(`${baseUrl}/search${queryString}`, null, token);
    });
  }

  function create() {
    return createAsyncThunk(`${name}/create`, async(payload) => {
      return await fetchWrapper.post(`${baseUrl}`, payload);
    });
  }

  function changeStatus() {
    return createAsyncThunk(
      `${name}/changeStatus`,
      async({ vehicleId, isActive }) => {
        const payload = {
          id: vehicleId,
          isActive,
        };
        return await fetchWrapper.patch(
          `${baseUrl}/${vehicleId}/change-status`,
          payload,
        );
      },
    );
  }

  function update() {
    return createAsyncThunk(`${name}/update`, async({ id, payload }) => {
      return await fetchWrapper.patch(`${baseUrl}/${id}`, {
        id,
        ...payload,
      });
    });
  }

  function getExtendedData() {
    return createAsyncThunk(`${name}/extended-data`, async({ vehicleId }) => {
      return await fetchWrapper.get(`${baseUrl}/${vehicleId}/extended-data`);
    });
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...changeStatus(),
    ...update(),
    ...getExtendedData(),
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.vehicles = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.vehicles = action.payload.items;
        state.vehiclesPaging = action.payload.paging;
      },
      [rejected]: (state, action) => {
        state.vehicles = { error: action.error };
      },
    };
  }

  function changeStatus() {
    var { fulfilled } = extraActions.changeStatus;
    return {
      [fulfilled]: (state, action) => {
        const { vehicleId, isActive } = action.meta.arg;

        const vehicle = state.vehicles.find((x) => x.id === vehicleId);
        vehicle.isActive = isActive;
      },
    };
  }

  function update() {
    var { fulfilled } = extraActions.update;
    return {
      [fulfilled]: (state, action) => {
        const { payload } = action.meta.arg;
        const { details } = payload;
        const { id } = details;
        let vehicle = state.vehicles.find((x) => x.id === id);
        Object.assign(vehicle, details);
        vehicle.displayName = `${vehicle.name} ${vehicle.registrationNumber}`;
      },
    };
  }

  function getExtendedData() {
    var { pending, fulfilled, rejected } = extraActions.getExtendedData;
    return {
      [pending]: (state) => {
        state.vehicleExtendedData = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.vehicleExtendedData = action.payload;
      },
      [rejected]: (state, action) => {
        state.vehicleExtendedData = { error: action.error };
      },
    };
  }
}
