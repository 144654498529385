import { reportsActions } from "../../_store/reports.slice";
import { GenerateOrderBaseReport } from "./GenerateOrderBaseReport";
import { OrderReportGroup } from "./OrderReportGroup";
import { OrderStatusBadge } from "../../_components/OrderViews/Components/OrderStatusBadge";
import { BadgeDomain } from "../../_components/Abstractions/BadgeDomain";

export function GenerateOrderStatusReport() {
  const renderData = (data) =>
    <div className="mt-2">
      {data?.handledOrdersCount >= 0 && <h6>Ilość zleceń w sumie: {data.handledOrdersCount}</h6>}
      {data?.statusGroups?.map((group, index) => {
        return (
          <>
            <hr/>
            <h5>{<OrderStatusBadge status={group.status}/>} - {<BadgeDomain>{group.count}</BadgeDomain>} </h5>
            <div className="mb-4"></div>
            <OrderReportGroup key={index} entries={group.entries} skipStatus/>
          </>
        );
      })}
    </div>;

  return <GenerateOrderBaseReport
    reportTitle="Raport Zlecenia - Status"
    reportType="orderStatus"
    skipStatus
    renderData={renderData}
    reportAction={reportsActions.generateOrderStatusReport}
  />;
}