import { OrderReportEntry } from "./OrderReportEntry";
import { TableReport } from "./TableReport";

export const OrderReportGroup = ({ entries, skipStatus }) => {
  if (!entries || entries.loading || entries.error) {
    return;
  }

  return (
    <>
      <TableReport>
        <thead>
        <tr>
          <th style={{ width: "8%" }}>Nr. zlecenia</th>
          <th style={{ width: "15%" }}>Klient</th>
          <th style={{ width: "20%" }}>Adres</th>
          {!skipStatus && <th>Status</th>}
          <th style={{ width: "10%" }}>Data przyjęcia</th>
          <th style={{ width: "10%" }}>Data załadunku</th>
          <th style={{ width: "10%" }}>Załadunek</th>
          <th style={{ width: "10%" }}>Wymiana kontenera</th>
        </tr>
        </thead>
        <tbody>
        {entries?.map((entry, index) => (
          <OrderReportEntry key={index} entry={entry} skipStatus={skipStatus}/>
        ))}
        </tbody>
      </TableReport>
    </>
  );
};

