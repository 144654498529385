import { FormControl } from "../../_components/Forms/FormControl";
import { GroupContainersSearchSelect } from "../../_components/Containers/GroupContainersSearchSelect";
import { FormModal } from "../../_components/Forms/FormModal";
import * as Yup from "yup";
import { IntegerControl } from "../../_components/Forms/IntegerControl";

export function ContainerForm({ onSubmit, buttonText, successMessage, defaultValues, isUpdate = false }) {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
    .required("Podtyp jest wymagany")
    .max(100, "Podtyp nie może mieć więcej niż 100 znaków"),
    groupContainer: Yup.object().shape({
      groupContainerId: Yup.string().typeError("Grupa kontenera jest wymagana")
    }).typeError("Grupa kontenera jest wymagana"),
    index: Yup.number()
    .typeError("Pozycja musi być liczbą")
    .integer("Pozycja musi być liczbą całkowitą")
    .min(0, "Pozycja musi być liczbą naturalną")
    .required("Pozycja musi być podana")
    .max(10_000, "Pozycja nie może być większa niż 10 000."),
    initializeCount: Yup.number()
    .typeError("Ilość bazowa musi być liczbą")
    .integer("Ilość bazowa musi być liczbą całkowitą")
    .min(1, "Ilość bazowa musi być większa od zera")
    .required("Ilość bazowa musi być podana")
    .test("isLowerThanUsedCount", "Ilość bazowa nie może być mniejsza niż używana ilość.", function(value) {
      const { usedCount } = this.parent;

      return !isUpdate || value >= usedCount;
    })
    .max(10_000, "Ilość bazowa nie może być większa niż 10 000."),
  });

  const formControls = (
    <>
      <FormControl label="Podtyp" name="name" autoFocus />
      <GroupContainersSearchSelect name="groupContainer"/>
      <IntegerControl
        value={0}
        label="Pozycja"
        name="index"
      />
      <IntegerControl
        value={0}
        label="Ilość bazowa"
        name="initializeCount"
      />
      {isUpdate && <>
        <FormControl
          label="Ilość zużytych"
          name="usedCount"
          readonly
        />
        <FormControl
          label="Pozostało"
          name="remainingCount"
          readonly
        />
      </>}
    </>
  );

  return (
    <>
      <FormModal
        formControls={formControls}
        validationSchema={validationSchema}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        buttonText={buttonText}
        successMessage={successMessage}
        title={buttonText}
        btnSize="small"
      />
    </>
  );
}