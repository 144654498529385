import { BuildingAdd, BuildingsFill } from "react-bootstrap-icons";

export const ConfigurationDashboardItems = [
  {
    name: "Dodaj klienta",
    link: "/contractors/create",
    icon: BuildingAdd,
    color: "lightGreen"
  },
  {
    name: "Przeglądaj klientów",
    link: "/configuration?type=contractors",
    icon: BuildingsFill,
    color: "lightBlue"
  }
];