import { useSelector } from "react-redux";
import { DictionaryPage } from "../../_components/Abstractions/DictionaryPage";
import { salesRepresentativesActions } from "../../_store/salesRepresentatives.slice";
import { CreateSalesRepresentative } from "./CreateSalesRepresentative";
import { SalesRepresentativesTable } from "../../_components/Wastes/SalesRepresentativesTable";

export function GetSalesRepresentatives({ activeEventKey, pageEventKey }) {
  const { salesRepresentatives } = useSelector((x) => x.salesRepresentatives);
  const { items, paging, sorting } = salesRepresentatives;
  const table = <SalesRepresentativesTable items={items} sorting={sorting} itemActions={salesRepresentativesActions} />;
  const createPage = <CreateSalesRepresentative/>;

  return (
    <DictionaryPage
      items={items}
      paging={paging}
      itemsTable={table}
      itemActions={salesRepresentativesActions}
      createItem={createPage}
      activeEventKey={activeEventKey}
      pageEventKey={pageEventKey}
      includeSearcher
      sorting={sorting}
    />
  );
}