import * as Yup from "yup";
import { FormControl } from "../Forms/FormControl";
import { FormModal } from "../Forms/FormModal";

export function SalesRepresentativesForm({ onSubmit, defaultValues, isUpdate }) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nazwa jest wymagana").max(100, "Nazwa nie może mieć więcej niż 100 znaków"),
    phone: Yup.string().required("Telefon jest wymagany").max(50, "Telefon nie może mieć więcej niż 50 znaków")
  });

  const formControls = (
    <>
      <FormControl label="Nazwa" name="name" autoFocus/>
      <FormControl label="Telefon" name="phone"/>
    </>
  );

  const buttonText = isUpdate ? "Edytuj PH" : "Dodaj PH";
  const successMessage = isUpdate ? "Zaktualizowano PH" : "Dodano PH";

  return (
    <>
      <FormModal
        formControls={formControls}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        buttonText={buttonText}
        successMessage={successMessage}
        title={buttonText}
        defaultValues={defaultValues}
        btnSize="small"
      />
    </>
  );
}