import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";

// create slice

const name = "orders";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const ordersActions = { ...slice.actions, ...extraActions };
export const ordersReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
    };
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/orders`;

    return {
        createPurchase: createPurchase(),
        createSale: createSale(),
        createTransportService: createTransportService(),
        createSeries: createSeries(),
        updateTransportDetails: updateTransportDetails(),
        updateSeriesTransportDetails: updateSeriesTransportDetails(),
        updateBdoDetails: updateBdoDetails(),
        updateWastesDetails: updateWastesDetails(),
        updateSeriesWastesDetails: updateSeriesWastesDetails(),
        updateMainDetails: updateMainDetails(),
        updateSeriesMainDetails: updateSeriesMainDetails(),
        confirmOrder: confirmOrder(),
        completeCourse: completeCourse(),
        confirmOrderReceive: confirmOrderReceive(),
        confirmOrderTransport: confirmOrderTransport(),
        closeOrder: closeOrder(),
        cancel: cancel(),
        reopen: reopen(),
        reject: reject(),
        deleteOrder: deleteOrder(),
        recoverOrder: recoverOrder(),
        deleteSeries: deleteSeries(),
        refreshKpoCards: refreshKpoCards(),
        refreshKpoCard: refreshKpoCard(),
        linkKpoToOrder: linkKpoToOrder(),
        unlinkKpoFromOrder: unlinkKpoFromOrder(),
        rejectKpoWithWastesDetails: rejectKpoWithWastesDetails(),
        getOrderWastesBdo: getOrderWastesBdo()
    };

    function createPurchase() {
        return createAsyncThunk(`${name}/create-purchase`, async (payload) => {
            return await fetchWrapper.post(`${baseUrl}/create-purchase`, payload);
        });
    }

    function createSale() {
        return createAsyncThunk(`${name}/sale`, async (payload) => {
            return await fetchWrapper.post(`${baseUrl}/sale`, payload);
        });
    }

    function createTransportService() {
        return createAsyncThunk(`${name}/transport-service`, async (payload) => {
            return await fetchWrapper.post(`${baseUrl}/transport-service`, payload);
        });
    }

    function createSeries() {
        return createAsyncThunk(`${name}/create-series`, async (payload) => {
            return await fetchWrapper.post(`${baseUrl}/series`, payload);
        });
    }

    function updateTransportDetails() {
        return createAsyncThunk(`${name}/updateTransportDetails`, async ({ orderId, payload }) => {
            return await fetchWrapper.put(`${baseUrl}/${orderId}/transport-details`, payload);
        });
    }

    function updateSeriesTransportDetails() {
        return createAsyncThunk(`${name}/updateTransportDetails`, async ({ seriesId, payload }) => {
            return await fetchWrapper.put(`${baseUrl}/series/${seriesId}/transport-details`, payload);
        });
    }

    function updateBdoDetails() {
        return createAsyncThunk(`${name}/updateBdoDetails`, async ({ orderId, payload }) => {
            return await fetchWrapper.put(`${baseUrl}/${orderId}/bdo-details`, payload);
        });
    }

    function updateWastesDetails() {
        return createAsyncThunk(`${name}/updateWastesDetails`, async ({ orderId, payload }) => {
            return await fetchWrapper.put(`${baseUrl}/${orderId}/wastes-details`, payload);
        });
    }

    function updateSeriesWastesDetails() {
        return createAsyncThunk(`${name}/updateSeriesWastesDetails`, async ({ seriesId, payload }) => {
            return await fetchWrapper.put(`${baseUrl}/series/${seriesId}/wastes-details`, payload);
        });
    }

    function updateMainDetails() {
        return createAsyncThunk(`${name}/updateMainDetails`, async ({ orderId, payload }) => {
            return await fetchWrapper.put(`${baseUrl}/${orderId}/main-details`, payload);
        });
    }
    function updateSeriesMainDetails() {
        return createAsyncThunk(`${name}/updateSeriesMainDetails`, async ({ seriesId, payload }) => {
            return await fetchWrapper.put(`${baseUrl}/series/${seriesId}/main-details`, payload);
        });
    }


    function rejectKpoWithWastesDetails() {
        return createAsyncThunk(`${name}/rejectKpoWithWastesDetails`, async ({ orderId, payload }) => {
            return await fetchWrapper.put(`${baseUrl}/${orderId}/wastes-details/reject-kpo`, payload);
        });
    }

    function confirmOrder() {
        return createAsyncThunk(`${name}/confirmOrder`, async ({ orderId }) => {
            return await fetchWrapper.patch(`${baseUrl}/${orderId}/confirm`);
        });
    }

    function completeCourse() {
        return createAsyncThunk(`${name}/completeCourse`, async ({ orderId }) => {
            return await fetchWrapper.patch(`${baseUrl}/${orderId}/complete-course`);
        });
    }

    function confirmOrderReceive() {
        return createAsyncThunk(`${name}/confirmOrderReceive`, async ({ orderId }) => {
            return await fetchWrapper.patch(`${baseUrl}/${orderId}/confirm-receive`);
        });
    }

    function confirmOrderTransport() {
        return createAsyncThunk(`${name}/confirmOrderTransport`, async ({ orderId }) => {
            return await fetchWrapper.patch(`${baseUrl}/${orderId}/confirm-transport`);
        });
    }

    function closeOrder() {
        return createAsyncThunk(`${name}/closeOrder`, async ({ orderId, payload }) => {
            const { skipWasteValidation } = payload;
            return await fetchWrapper.patch(`${baseUrl}/${orderId}/close?skipWasteValidation=${skipWasteValidation ?? false}`, payload);
        });
    }

    function cancel() {
        return createAsyncThunk(`${name}/cancel`, async ({ orderId, payload }) => {
            return await fetchWrapper.patch(`${baseUrl}/${orderId}/cancel`, { ...payload, id: orderId });
        });
    }

    function reopen() {
        return createAsyncThunk(`${name}/reopen`, async ({ orderId }) => {
            return await fetchWrapper.patch(`${baseUrl}/${orderId}/reopen`);
        });
    }

    function reject() {
        return createAsyncThunk(`${name}/reject`, async ({ orderId }) => {
            return await fetchWrapper.patch(`${baseUrl}/${orderId}/reject`);
        });
    }

    function deleteOrder() {
        return createAsyncThunk(`${name}/delete`, async ({ orderId }) => {
            return await fetchWrapper.delete(`${baseUrl}/${orderId}`);
        });
    }

    function recoverOrder() {
        return createAsyncThunk(`${name}/recover`, async ({ orderId }) => {
            return await fetchWrapper.patch(`${baseUrl}/${orderId}/recover`);
        });
    }

    function deleteSeries() {
        return createAsyncThunk(`${name}/deleteSeries`, async ({ orderSeriesId }) => {
            return await fetchWrapper.delete(`${baseUrl}/series/${orderSeriesId}`);
        });
    }

    function refreshKpoCards() {
        return createAsyncThunk(`${name}/refreshKpoCards`, async ({ orderId, transactionType }) => {
            return await fetchWrapper.patch(`${baseUrl}/${orderId}/kpo/refresh-all?transactionType=${transactionType}`);
        });
    }

    function refreshKpoCard() {
        return createAsyncThunk(`${name}/refreshKpoCard`, async ({ orderId, kpoId, transactionType }) => {
            return await fetchWrapper.patch(`${baseUrl}/${orderId}/kpo/${kpoId}/refresh?transactionType=${transactionType}`);
        });
    }

    function linkKpoToOrder() {
        return createAsyncThunk(
          `${name}/linkToOrder`,
          async({ kpoId, orderId, status }) =>
            await fetchWrapper.put(`${baseUrl}/${orderId}/kpo/${kpoId}/link?status=${status}`));
    }

    function unlinkKpoFromOrder() {
        return createAsyncThunk(
          `${name}/unlinkFromOrder`,
          async({ kpoId, orderId }) =>
            await fetchWrapper.put(`${baseUrl}/${orderId}/kpo/${kpoId}/unlink`));
    }

    function getOrderWastesBdo() {
        return createAsyncThunk(
          `${name}/getOrderWastesBdo`,
          async({ orderId }) =>
            await fetchWrapper.get(`${baseUrl}/${orderId}/wastes-bdo`));
    }
}

function createExtraReducers() {
    return {

    };
}
