import * as Yup from "yup";

class ContractorValidator {
  static nameMaxLength = 100;
  static addressInfoMaxLength = 30;
  static apartmentMaxLength = 10;
  static remarksMaxLength = 250;

  static subContainerSchema = Yup.object().shape({
    usedCount: Yup.number().nullable()
    .integer("Liczba używanego podtypu musi być liczbą całkowitą")
    .typeError("Liczba używanego podtypu musi być liczbą całkowitą")
    .min(1, "Liczba używanego podtypu musi być większa od zera"),
    containerId: Yup.string().typeError("Podtyp kontenera jest wymagany").required("Podtyp kontenera jest wymagany")
  });

  static usedContainerWasteSchema = Yup.object().shape({
    count: Yup.number().typeError("Liczba towaru musi zostać podana").integer("Liczba towaru musi zostać podana musi być liczbą całkowitą").min(1, "Liczba towaru musi być większa od zera")
  });

  static addressSchema = Yup.object().shape({
    name: Yup.string().required("Nazwa adresu jest wymagana").typeError("Nie udało się pobrać nazwy adresu").max(this.nameMaxLength, "Za długa nazwa (max 100 znaków)"),
    postalCode: Yup.string().required("Kod pocztowy jest wymagany").typeError("Nie udało się pobrać kod pocztowego").max(this.addressInfoMaxLength, "Za długi kod pocztowy (max 30 znaków)"),
    city: Yup.string().required("Miasto jest wymagane").typeError("Nie udało się pobrać miasta").max(this.addressInfoMaxLength, "Za długie miasto (max 30 znaków)"),
    streetName: Yup.string().required("Ulica jest wymagana").typeError("Nie udało się pobrać nazwy ulicy").max(this.addressInfoMaxLength, "Za długa ulica (max 30 znaków)"),
    streetNumber: Yup.string().required("Numer ulicy jest wymagany").typeError("Nie udało się pobrać numeru ulicy").max(this.apartmentMaxLength, "Za długi numer ulicy nazwa (max 10 znaków)"),
    contactPersons: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Nazwa kontaktu jest wymagana")
      })
    ),
    usedContainers: Yup.array().of(
      Yup.object().shape({
        groupContainerId: Yup.string().typeError("Grupa kontenera jest wymagany").required("Grupa kontenera jest wymagany"),
        totalCount: Yup.number().typeError("Liczba używanych w grupie kontenera musi zostać podana").integer("Liczba używanych w grupie kontenera musi zostać podana").min(1, "Liczba używanych w grupie musi być większa od zera").test("is-total-count-valid", "Suma używanych podtypów kontenerów musi być równa liczbie używanej grupy", function(value) {
          const { subContainers } = this.parent;
          const usedCountsSum = subContainers.reduce((sum, subContainer) => sum + subContainer.usedCount, 0);
          return value === usedCountsSum;
        }),
        wastes: Yup.array().required("Kody odpadów są wymagane").min(1, "Kody odpadów są wymagane").of(ContractorValidator.usedContainerWasteSchema),
        // wastesIds: Yup.array().required("Kody odpadów są wymagane").min(1, "Kody odpadów są wymagane"),
        subContainers: Yup.array().of(ContractorValidator.subContainerSchema).typeError("Podtypy kontenerów są wymagane").min(1, "Podtypy kontenerów są wymagane")
      })
    ).test("isGroupContainerUnique", "Grupy kontenerów nie mogą się powtarzać", function(value) {
      const ids = value?.map(x => x.groupContainerId);
      if (!ids || ids?.length === 0) {
        return true;
      }

      return new Set(ids).size === value?.length;
    }),
    wasteProcessingDecisionData: Yup.object().nullable().when("bdoDetails", {
      is: (bdoDetails) => !!bdoDetails?.companyId && bdoDetails?.contractorTypes?.includes("Receiver"),
      then: Yup.object().shape({
        decisionDate: Yup.string().nullable().required("Data ważności decyzji jest wymagana"),
        decisionNumber: Yup.string().nullable().required("Numer decyzji jest wymagana"),
        allowedWasteCodesBdoCount: Yup.number().nullable()
        .required("Dozwolone kody towarów są wymagane")
        .typeError("Dozwolone kody towarów są wymagane")
        .min(1, "Przynajmniej jeden kod towaru jest wymagany")
      })
    })
  });

  static validationSchema = Yup.object().shape({
    name: Yup.string().required("Nazwa jest wymagana").max(100, "Nazwa nie może mieć więcej niż 100 znaków"),
    taxId: Yup.string().nullable().when("isBdoLinked", {
      is: true,
      then: Yup.string().required("Numer NIP jest wymagany").matches(/^[0-9]+$/, "NIP jest niepoprawny (tylko cyfry)").length(10, "NIP jest niepoprawny (długość)")
    }),
    contractorTypes: Yup.array().min(
      1,
      "Przynajmniej jeden typ operacji musi zostać wybrany."
    ),
    originatorType: Yup.string().typeError("Rodzaj klienta jest wymagany"),
    salesRepresentative: Yup.object().nullable().when("originatorType", {
      is: "SalesRepresentative",
      then: Yup.object().required("PH jest wymagany").typeError("PH jest wymagany")
    }),
    pickupType: Yup.array().min(
      1,
      "Przynajmniej jeden sposób transportu musi zostać wybrany."
    ),
    remarks: Yup.string().nullable().max(250, "Maksymalna ilość znaków w uwagach to 250."),
    centralAddress: ContractorValidator.addressSchema,
    addresses: Yup.array().of(ContractorValidator.addressSchema)
  });
}

export default ContractorValidator;
