import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { SelectSearcher } from "../../../../_components/Abstractions/MultiSearcher/SelectSearcher";
import { salesRepresentativesActions } from "../../../../_store/salesRepresentatives.slice";
import { useSalesRepresentativeSearch } from "./SalesRepresentativeSearcher";

export function SalesRepresentativeSelect({ name }) {
  // const dispatch = useDispatch();
  // const isFirstRender = useRef(true);
  // const [items, setItems] = useState([]);
  // const onSearchChange = async (searchTerm) => {
  //   const params = { searchQuery: searchTerm, pageNumber: 1, pageSize: 30 };
  //   const { payload } = await dispatch(salesRepresentativesActions.search(params));
  //   setItems(payload.items);
  // };
  //
  // useEffect(() => {
  //   if (!isFirstRender.current) {
  //     onSearchChange(null);
  //   } else {
  //     isFirstRender.current = false;
  //   }
  //
  // }, []); // eslint-disable-next-line/react-hooks/exhaustive-deps

  const { items, onSearchChange } = useSalesRepresentativeSearch();

  return (
    <>
      <SelectSearcher
        name={name}
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "id", value: "name" }}
        label="Przedstawiciel handlowy"
        saveWholeObject
      />
    </>
  );
}

