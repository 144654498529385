import { format } from "date-fns";

export const DateTimeFormat = "dd-MM-yyyy HH:mm";
export const DateFormat = "dd-MM-yyyy";
export const TimeFormat = "HH:mm";

export const stringToDate = (dateString) => {
  if(!dateString){
    return "-";
  }
  return format(new Date(dateString), DateTimeFormat); };

export const stringToDateView = (dateString) => {
  if(!dateString){
    return "-";
  }
  return format(new Date(dateString), DateFormat); };


export const stringToDateTimeForm = (dateString) => `${format(new Date(dateString), "yyyy-MM-dd")}T${format(new Date(dateString), "HH:mm")}`;

export const stringToDateForm = (dateString) => `${format(new Date(dateString), "yyyy-MM-dd")}`;

export const stringToDateExact = (dateString, dateTimeFormat) => format(new Date(dateString), dateTimeFormat);

export const dateToString = (date) => date && format(date, "yyyy-MM-dd")