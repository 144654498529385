import { SelectSearcher } from "_components/Abstractions/MultiSearcher/SelectSearcher";
import { useVehicleSearch } from "./VehicleSearch";
import { FilterSelectSearcher } from "../Abstractions/MultiSearcher/FilterSelectSearcher";
import { Alert } from "react-bootstrap";

export function VehicleSelect(props) {
  const { name, vehicleType, driverId, label, required, readonly, readonlyWhenNoItems, allowEmptyQuery, selectedItem } = props;
  const { items, onSearchChange, isEmptyQuery } = useVehicleSearch({ vehicleType, driverId, allowEmptyQuery, refreshOnInitialize: false });
  const { warningMessage, hasExpiredDate } = selectedItem || {};

  return (
    <>
      <SelectSearcher
        name={name}
        label={label}
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "id", value: "displayName" }}
        placeholder="Wyszukaj..."
        required={required}
        readonly={readonly || (readonlyWhenNoItems && isEmptyQuery && items?.length === 0)}
        saveWholeObject
      />
      {warningMessage && <Alert variant={hasExpiredDate ? "danger" : "warning"}>{warningMessage}</Alert>}
    </>
  );
}

export function VehicleFilterSelect(props) {
  const { name, vehicleType, driverId, label, allowSearchWithoutType } = props;
  const { items, onSearchChange } = useVehicleSearch({ vehicleType, driverId, allowSearchWithoutType, refreshOnInitialize: false });

  return (
    <>
      <FilterSelectSearcher
        name={name}
        label={label}
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "id", value: "name" }}
        placeholder="Wyszukaj..."
        saveWholeObject={false}
      />
    </>
  );
}
