import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { OrderWorkFlowAction } from "./OrderWorkFlowAction";
import * as Yup from "yup";
import { FormControl } from "../../../_components/Forms/FormControl";

export function CancelOrder({ orderId, orderNumber }) {
  const successMessage = "Zlecenie zostało anulowane";
  const buttonText = "Anuluj zlecenie";
  const title = `Czy anulować zlecenie ${orderNumber}?`;
  const validationSchema = Yup.object().shape({
    reason: Yup.string().required("Powód jest wymagany"),
  });


  const formControls = <>
    <FormControl name="reason" label="Powód" />
  </>

  const props = {
    orderId: orderId,
    orderAction: ordersActions.cancel,
    orderViewAction: orderViewsActions.cancel,
    successMessage,
    buttonText,
    title,
    formControls,
    validationSchema,
    useFormModal: true
  };

  return <OrderWorkFlowAction {...props} />;
}

