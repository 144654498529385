import { useDispatch, useSelector } from "react-redux";
import { servicesActions } from "../../_store/services.slice";
import { ServicesForm } from "./ServicesForm";

export function CreateService() {
  const dispatch = useDispatch();
  const { servicesPaging: paging } = useSelector((x) => x.services);

  async function onSubmit(payload) {
    const response = await dispatch(servicesActions.create(payload));
    if (!response?.error) {
      const pageNumber = paging?.pageNumber ?? 1;
      dispatch(servicesActions.getAll({ pageNumber }));
    }

    return response;
  }

  return <ServicesForm onSubmit={onSubmit}/>;
}

