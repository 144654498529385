import { PromptModal } from "_components/Abstractions/PromptModal";
import { useToast } from "_helpers/notifications/useToasts";
import { useDispatch } from "react-redux";
import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";

export function RefreshKpoCards({ orderId, orderNumber, transactionType }) {
  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useToast();

  async function onSubmit(promptResult) {
    if (promptResult !== "Yes") {
      return;
    }

    const response = await dispatch(ordersActions.refreshKpoCards({ orderId, transactionType }));

    if (!response.error) {
      const { payload } = response;
      const { isSucceeded } = payload || {};

      await dispatch(orderViewsActions.getBdoInfoById({ id: orderId }));
      await dispatch(orderViewsActions.refreshHistory({ id: orderId }));

      if (isSucceeded) {
        await dispatch(orderViewsActions.refreshStatus({ id: orderId }));
      }

      let successMessage = "Odświeżono karty KPO";
      if (isSucceeded) {
        successMessage += " i zmieniono status zlecenia";
      }

      showSuccessMessage(successMessage);
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText="Odśwież karty KPO"
        title={`Czy odświeżyć karty KPO dla zlecenia ${orderNumber}?`}
        type="YesNo"
        onSubmit={onSubmit}
      />
    </>
  );
}
