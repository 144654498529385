import { UserForm } from "_components/Accounts/UserForm";
import { userActions } from "_store";
import { useDispatch } from "react-redux";

export function UpdateAccount({ user }) {
  const dispatch = useDispatch();

  const { id: userId } = user;

  async function onSubmit(payload) {
    return await dispatch(userActions.update({ userId, payload }));
  }

  const defaultValues = {
    ...user,
  };

  return (
    <>
      <UserForm onSubmit={onSubmit} isUpdate defaultValues={defaultValues} />
    </>
  );
}
