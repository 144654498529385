import { GenerateOrderBaseReport } from "./GenerateOrderBaseReport";
import { reportsActions } from "../../_store/reports.slice";
import { FormControl } from "../../_components/Forms/FormControl";
import * as Yup from "yup";
import { DomainSwitch } from "../../_components/Abstractions/DomainSwitch";
import { useFormContext } from "react-hook-form";
import { VehicleTypeBadge } from "../../_components/Vehicles/VehicleTypeBadge";
import { Dropdown } from "../../_components/Abstractions/Dropdown";
import { Months } from "../../domain/Months";
import { HorizontalView } from "../../_components/Abstractions/HorizontalView";
import { TableReport } from "./TableReport";

export function GenerateVehiclesInformationReport() {
  const validationSchema = Yup.object().shape({
    year: Yup.number().nullable().integer().when("isYearReport", {
      is: true,
      then: Yup.number().positive().integer()
      .typeError("Rok jest wymagany")
      .required("Rok jest wymagany")
    })
  });

  const renderData = (data) => <GenerateVehiclesInformationReportTable entries={data}/> ;

  return <GenerateOrderBaseReport
    reportTitle="Raport informacyjny pojazdów"
    reportType="vehiclesInformation"
    reportAction={reportsActions.generateVehiclesInformationReport}
    renderData={renderData}
    customControls={<GenerateVehiclesInformationReportForm/>}
    renderDefaultControls={false}
    additionalValidationSchema={validationSchema}
    overrideDefaultValues={{ year: new Date().getFullYear() }}
    isPrintable
  />;
}

const GenerateVehiclesInformationReportForm = () => {
  const { watch } = useFormContext();
  const isYearReport = watch("isYearReport");

  return <>
    <DomainSwitch name="isYearReport" label="Szczegółowy raport"/>
    {isYearReport && <>
    <b>Dokumenty wygasające w czasie:</b>
    <HorizontalView columnsSize={[2,2]}>
      <Dropdown name="month" items={Months} label="Miesiąc" required={false}/>
      <FormControl inputType="number" name="year" label="Rok"/>
    </HorizontalView>
    </>}
  </>;
};

export const GenerateVehiclesInformationReportTable = ({ entries }) => {
  if (!entries || entries.loading || entries.error) {
    return;
  }

  return (
    <>
      <TableReport>
        <thead>
        <tr>
          <th style={{ width: "20%" }}>Pojazd</th>
          <th style={{ width: "10%" }}>Typ pojazdu</th>
          <th style={{ width: "25%" }}>Nr. polisy OC</th>
          <th style={{ width: "12%" }}>Data polisy OC</th>
          <th style={{ width: "12%" }}>Data badania tech.</th>
          <th style={{ width: "12%" }}>Data UDT</th>
          <th style={{ width: "12%" }}>Data tachograf</th>
        </tr>
        </thead>
        <tbody>
        {entries?.map((entry, index) => (
          <GenerateVehiclesInformationReportEntry key={index} entry={entry}/>
        ))}
        </tbody>
      </TableReport>
    </>
  );
};

const GenerateVehiclesInformationReportEntry = ({ entry }) => (
  <tr>
    <td>{entry.registrationNumber}</td>
    <td><VehicleTypeBadge type={entry.vehicleType}/></td>
    <td>{entry.insuranceDisplayInfo}</td>
    <td>{entry.insuranceDate}</td>
    <td>{entry.inspectionDate}</td>
    <td>{entry.udtInspectionDate}</td>
    <td>{entry.tachographDate}</td>
  </tr>
);
