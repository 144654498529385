import { createContext, useContext, useState } from "react";

export const FilterContext = createContext(null);

export const FilterProvider = ({ children, onFilterChange, defaultValues, clearFilters }) => {
  const [currentFilters, setCurrentFilters] = useState(defaultValues ?? {});
  const [reset, setReset] = useState(0);
  const handleOnFilterChange = (filters) => {
    const { name, value } = filters;
    setCurrentFilters({ ...currentFilters, [name]: value });
    onFilterChange(filters);
  };

  const handlerClearFilters = () => {
    setReset(reset + 1);
    setCurrentFilters({});
    clearFilters();
  }

  const filterForm = { currentFilters, onFilterChange: handleOnFilterChange, clearFilters: handlerClearFilters, reset };

  return (
    <FilterContext.Provider value={filterForm}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterFormContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilterFormContext must be used within a FilterProvider");
  }
  return context;
};