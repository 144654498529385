// yupProvider.js

import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'isGuid', function (errorMessage) {
  return this.matches(
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
    errorMessage ?? "Pole zawiera jest niepoprawną wartość UUID"
  );
});

export const guidPattern = "/^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$/gi";

export default Yup;