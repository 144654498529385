import { Table } from "react-bootstrap";
import { RemoveWaste } from "useCases/wastes/RemoveWaste";
import { UpdateWaste } from "useCases/wastes/UpdateWaste";
import { SortedTh } from "../Abstractions/SortedTh";

export function WastesTable({ wastes, sorting, itemActions }) {
  const columnsNames = {
    "internalName": "Nazwa wewnętrzna",
    "wasteCode": "Kod odpadu",
    "externalName": "Nazwa formalna",
    "wasteCodeBdoId": "Ref. BDO",
  }

  const table = (
    <>
      <Table hover>
        <thead>
          <tr>
            {Object.entries(columnsNames).map(([value, name]) =>
              <SortedTh key={value} sorting={sorting} itemActions={itemActions} sortingValue={value}>{name}</SortedTh>)}
          </tr>
        </thead>
        <tbody>
          {wastes.loading && (
            <>
              <tr className="spinner-border"></tr>
            </>
          )}
          {wastes.length > 0 &&
            wastes.map((item, index) => (
              <tr key={index}>
                <td style={{ width: "15%" }}>{item.internalName}</td>
                <td style={{ width: "10%" }}>{item.wasteCode}</td>
                <td style={{ width: "50%" }}>{item.externalName}</td>
                <td>{item.wasteCodeBdoId}</td>
                <td>
                  <UpdateWaste waste={item} />
                  <RemoveWaste waste={item} />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );

  return <>{table}</>;
}

