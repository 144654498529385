import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { contractorsActions } from "../../_store/contractors.slice";
import { useDispatch } from "react-redux";

export function useContractorAddressSearch({ addressesField }) {
  const { watch } = useFormContext();
  const [items, setItems] = useState([]);
  const addresses = watch(addressesField);

  const onSearchChange = async (searchTerm) => {
    const items = searchTerm
      ? addresses?.filter((x) =>
          x.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : addresses;
    setItems(items);
  };

  useEffect(() => {
    onSearchChange(null);
  }, [addresses]);

  return { items, onSearchChange };
}


export function useAddressSearch() {
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();

  const onSearchChange = async (searchTerm, id) => {
    const query = {
      pageNumber: 1,
      pageSize: 10,
      searchQuery: searchTerm,
      id: id
    };
    const { payload } = await dispatch(contractorsActions.searchAddresses({ query }));
    const items = payload?.items;
    setItems(items ?? []);
    return items;
  };

  // useEffect(() => {
  //   onSearchChange(null);
  // }, []);

  return { items, onSearchChange };
}