import { useState } from "react";
import { FilterProvider } from "./FilterContext";
import Form from "react-bootstrap/Form";

export function FilterForm({ formControls, currentFilters, setCurrentFilters, onSubmit }) {
  const handleOnChange = ({ name, value }) => {
    if (currentFilters?.[name] === value || (!currentFilters?.[name] && !value)) {
      return;
    }
    const newFilters = { ...currentFilters, [name]: value };
    setCurrentFilters(newFilters);
    onSubmit({ [name]: value });
  };

  const clearFilters = () => {
    setCurrentFilters(null);
  }

  return (
    <Form>
      <FilterProvider onFilterChange={handleOnChange} defaultValues={currentFilters} clearFilters={clearFilters}>
        {formControls}
      </FilterProvider>
    </Form>
  );
}
