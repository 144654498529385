import { Calendar2, CalendarRangeFill, CarFront, Kanban, PersonWorkspace } from "react-bootstrap-icons";

export const BrowseOrdersDashboardItems = [
  {
    name: "Lista zleceń",
    link: "/orders/list-view",
    icon: Kanban
  },
  {
    name: "Kalendarz zleceń",
    link: "/orders/calendar-view",
    icon: Calendar2
  },
  {
    name: "Widok samochodowy",
    link: "/orders/vehicle-view",
    icon: CarFront
  },
  {
    name: "Widok kierowców",
    link: "/orders/driver-view",
    icon: PersonWorkspace
  },
  {
    name: "Kalendarz TIR",
    link: "/orders/shipping-calendar-view",
    icon: CalendarRangeFill
  }
];