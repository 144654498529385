import { useDispatch, useSelector } from "react-redux";
import { driversActions } from "_store/drivers.slice";
import { DriverForm } from "./DriverForm";
import * as Yup from "yup";
import { FormControl } from "../../_components/Forms/FormControl";

export function CreateDriver() {
  const dispatch = useDispatch();
  const { driversPaging } = useSelector((x) => x.drivers);

  async function onSubmit(payload) {
    const response = await dispatch(driversActions.create(payload));
    if (!response?.error) {
      const pageNumber = driversPaging?.pageNumber ?? 1;
      dispatch(driversActions.getAll({ pageNumber }));
    }

    return response;
  }

  const formControls = <>
    <FormControl label="Hasło" name="password" inputType="password" className="login-input"/>
    <FormControl label="Potwierdź hasło" name="confirmPassword" inputType="password" className="login-input"/>
  </>;

  const validationSchema = Yup.object().shape({
    password: Yup.string()
    .required("Hasło jest wymagane")
    .min(8, "Hasło musi mieć przynajmniej 8 znaków")
    .max(256, "Hasło może mieć maksymalnie 256 znaków"),
    confirmPassword: Yup.string()
    .required("Potwierdzenie hasła jest wymagane")
    .oneOf([Yup.ref("password"), null], "Hasła muszą się być takie same")
  });

  const buttonText = "Dodaj kierowcę";
  const successMessage = "Dodano kierowcę";

  return (
    <>
      <DriverForm
        onSubmit={onSubmit}
        buttonText={buttonText}
        successMessage={successMessage}
        title={buttonText}
        additionalFormControls={formControls}
        additionalValidationSchema={validationSchema}
      />
    </>
  );
}
