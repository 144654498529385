import { Accordion } from "react-bootstrap";
import { useEffect, useState } from "react";

export function AccordionDomain({ header, children, bodyClassName, openOnChange, isValid = true }) {
  const [isOpen, setIsOpen] = useState(false);
  const [externalTrigger, setExternalTrigger] = useState(false);

  useEffect(() => {
    if(!openOnChange){
      return;
    }

    if (openOnChange && !isOpen) {
      setExternalTrigger(true);
    } else{
      setExternalTrigger(false);
    }

  }, [openOnChange]);

  useEffect(() => {
    if(!isValid && !isOpen) {
      setIsOpen(true);
      setExternalTrigger(true);
    }

  }, [isValid]);

  return <Accordion activeKey={isOpen || externalTrigger}>
    <Accordion.Item eventKey={true}>
      <Accordion.Header onClick={() => { setIsOpen(!isOpen); setExternalTrigger(false) }}>
        {header}
      </Accordion.Header>
      <Accordion.Body className={bodyClassName}>
        {children}
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>;
}