import { useDispatch, useSelector } from "react-redux";
import { containersActions } from "_store/containers.slice";
import { ContainerForm } from "./ContainerForm";

export function CreateContainer() {
  const dispatch = useDispatch();
  const { containersPaging } = useSelector((x) => x.containers);

  async function onSubmit(payload) {
    const { groupContainer, ...data } = payload;
    const request = {
      ...data,
      groupContainerId: groupContainer.groupContainerId
    };

    const response = await dispatch(containersActions.create(request));
    if (!response?.error) {
      const pageNumber = containersPaging?.pageNumber ?? 1;
      dispatch(containersActions.getAll({ pageNumber }));
    }

    return response;
  }

  const buttonText = "Dodaj kontener";
  const successMessage = "Dodano kontener";

  return <ContainerForm onSubmit={onSubmit} buttonText={buttonText} successMessage={successMessage}/>;
}
