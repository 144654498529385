import { DomainButton } from "../../../Abstractions/DomainButton";
import { useFormContext } from "react-hook-form";
import { GetKpoDropdownItem } from "../../../Bdo/Kpo/KpoBdoDropdown";
import { useToast } from "../../../../_helpers/notifications/useToasts";

export function RecalculateKpoFromWastes() {
  const { getValues, setValue, trigger, getFieldState } = useFormContext();
  const { showErrorMessage } = useToast();

  const recalculateKpoItems = () => {
    const wastesDetails = getValues("wastesDetails");
    const kpoItems = getValues("kpoItems");
    const { loading, containers } = wastesDetails;

    kpoItems.forEach((kpoItem, idx) => {
      const { kpoId } = kpoItem;
      let wasteMassCalculated = 0;
      const containerWastes = containers?.map(x => x.wastes)?.flat() ?? [];
      const loadingWastes = loading?.wastes ?? [];
      const wastes = [...loadingWastes, ...containerWastes];

      wastes.forEach((waste) => {
        const { wasteMass, applicableKpoItems } = waste;
        const isKpoIncluded = applicableKpoItems?.some(x => x.kpoId === kpoId);
        if (!isKpoIncluded) {
          return;
        }
        if (applicableKpoItems.length === 1) {
          wasteMassCalculated += Number(wasteMass);
        }
        if (applicableKpoItems.length > 1) {
          const applicableKpoItem = applicableKpoItems.find(x => x.kpoId === kpoId);
          wasteMassCalculated += Number(applicableKpoItem?.wasteMass);
        }
      });

      wasteMassCalculated = wasteMassCalculated.toFixed(4);
      setValue(`kpoItems[${idx}].correctedWasteMass`, wasteMassCalculated);
    });

  };

  const handleRecalculateKpoItems = async() => {
    await trigger(["wastesDetails"]);
    const formState = getFieldState("wastesDetails");
    if (formState.error) {
      showErrorMessage("Przed przeliczeniem mas należy poprawić błędy w towarach");
      return;
    }
    recalculateKpoItems();
    await trigger(["kpoItems"]);
  };

  return <DomainButton className="mb-1" onClick={handleRecalculateKpoItems}>Przelicz masy KPO</DomainButton>;
}

export function useDefaultKpoItemsValuesForWastesCalculator() {

  const getDefaultValues = ({ wasteDefaultValues, kpoDefaultValues }) => {

    const { wastesDetails } = wasteDefaultValues;
    const { loading, containers } = wastesDetails;
    const { kpoItems } = kpoDefaultValues;
    const containerWastes = containers?.map(x => x.wastes).flat() ?? [];
    const loadingWastes = loading?.wastes ?? [];
    const wastes = [...loadingWastes, ...containerWastes];
    const kpoItemsDictionary = kpoItems.reduce((acc, kpoItem) => {
      const { wasteCodeId } = kpoItem;
      if (!acc[wasteCodeId]) {
        acc[wasteCodeId] = [];
      }
      acc[wasteCodeId].push(kpoItem);
      return acc;
    }, {});

    wastes?.forEach((waste) => {
      const { wasteCodeBdoId } = waste;
      waste.applicableKpoItems = kpoItemsDictionary[wasteCodeBdoId]?.map(x => GetKpoDropdownItem({ kpoItem: x }));
    });

    return { ...wasteDefaultValues, ...kpoDefaultValues };
  };

  return { getDefaultValues };
}