import { useEffect, useState } from "react";
import { kpoActions } from "../../../_store/kpo.slice";
import { useDispatch } from "react-redux";

export function useKpoCardPrintingDetails({ kpoId }) {
  const dispatch = useDispatch();
  const [kpoCardPrintingDetails, setKpoPrintingDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const onKpoIdChanged = async({ kpoId }) => {

      setIsLoading(true);
      const { payload } = await dispatch(kpoActions.getPrintingPage({ kpoId }));
      setKpoPrintingDetails(payload);
      setIsLoading(false);
    };

    onKpoIdChanged({ kpoId });
  }, [kpoId]);

  return { kpoCardPrintingDetails, isLoading };
}
