import { SelectSearcher } from "../Abstractions/MultiSearcher/SelectSearcher";
import { useUsersSearch } from "./UseUsersSearch";

export function UserSelect({ name }) {
  const { items, onSearchChange } = useUsersSearch({ refreshOnInitialize: true });

  return (
    <SelectSearcher
      name={name}
      label="Użytkownik"
      items={items}
      onSearchChange={onSearchChange}
      optionsObject={{ key: "id", value: "login" }}
      placeholder="Szukaj"
      required={false}
      saveWholeObject
    />);
}