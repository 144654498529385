import { WastesMultiSelect } from "./WastesMultiSelect";
import { GroupContainersSearchSelect } from "../../../../_components/Containers/GroupContainersSearchSelect";
import { useFormContext } from "react-hook-form";
import { ContainersSearchMultiSelect } from "../../../../_components/Containers/ContainersSearchMultiSelect";
import { UsedSubContainersForm } from "./UsedSubContainersForm";
import { UsedContainerWastesForm } from "./UsedContainerWastesForm";
import { IntegerControl } from "../../../../_components/Forms/IntegerControl";
import { DomainSwitch } from "../../../../_components/Abstractions/DomainSwitch";

export function UsedContainerForm({ name }) {
  const GetFullField = (field) => `${name}.${field}`;
  const { watch } = useFormContext();
  const groupContainerIdName = GetFullField("groupContainerId");
  const groupContainer = watch(groupContainerIdName);
  const fields = watch(GetFullField("wastes"));
  return (
    <>
      <DomainSwitch name={GetFullField("isInvalidContainersState")} label="Niepoprawny stan kontenerów"/>
      <div className="mb-2"/>
      <GroupContainersSearchSelect name={groupContainerIdName} saveWholeObject={false} skipInitLoading/>
      <div className="mb-2"/>
      <IntegerControl label="Liczba używanych grupy" name={GetFullField("totalCount")}/>
      <ContainersSearchMultiSelect name={GetFullField("subContainers")} readonly={!groupContainer} groupContainerId={groupContainer} skipInitLoading/>
      <div className="mb-2"/>
      <UsedSubContainersForm name={GetFullField("subContainers")} groupContainerId={groupContainer}/>
      <WastesMultiSelect name={GetFullField("wastes")} optionSave={x => x} skipFieldsTrack skipInitLoading/>
      <div className="mt-2">
        {fields?.map((field, idx) => <UsedContainerWastesForm key={field.wasteId} name={GetFullField(`wastes.${idx}`)}/>)}
      </div>
    </>
  );
}
