import { SelectTable } from "../../../../_components/Abstractions/SelectTable";
import { useEffect, useState } from "react";
import { wastesActions } from "../../../../_store/wastes.slice";
import { TableDomain } from "../../../../_components/Abstractions/TableDomain";
import { useDispatch } from "react-redux";
import { Accordion } from "react-bootstrap";
import { DomainButton } from "../../../../_components/Abstractions/DomainButton";
import { CheckAll } from "react-bootstrap-icons";
import { BadgeDomain } from "../../../../_components/Abstractions/BadgeDomain";

export function WastesBdoSelectGroupTable({ onSubmit, defaultValues }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(defaultValues ?? {});

  const handleOnSubmit = (e) => {
    const codesCount = Object.entries(selectedItems).map(([_, value]) => value.selectedItems.length).reduce((acc, x) => acc + Number(x), 0);
    onSubmit(selectedItems, codesCount);
    setShow(false);

    if(codesCount === 0) {
      e.stopPropagation();
    }
  }

  const selectContent = <div className="d-flex justify-content-end mb-2">
    <DomainButton onClick={handleOnSubmit}>Przypisz dozwolone kody</DomainButton>
    <hr/>
  </div>

  const fetchWastesBdo = async(searchTerm) => {
    setItems({ loading: true });
    const { payload } =
      await dispatch(wastesActions.searchGroupBdo({ searchTerm, isUsed: true }));
    setItems(payload);
  };

  const onGroupSelected = (parentId, isSelected, parentCodeDescription) => {
    const newItems = isSelected ? items.find(x => x.parentId === parentId).childItems : [];

    handleOnSelectedItemsChanged(parentId, newItems, parentCodeDescription);
  };

  const handleOnSelectedItemsChanged = (parentId, newSelectedItems, parentCodeDescription) => {
    let newItems = { ...selectedItems };
    if (newSelectedItems.length > 0) {
      newItems[parentId] = {
        selectedItems: newSelectedItems,
        parentId: parentId,
        parentCodeDescription: parentCodeDescription
      };
    } else {
      delete newItems[parentId];
    }

    setSelectedItems(newItems);
  };

  useEffect(() => {
    if(!show) {
      return;
    }

    fetchWastesBdo();
  }, [show]);

  return <SelectTable
    table={<WastesBdoGroupTable
      items={items}
      onGroupSelected={onGroupSelected}
      onSelectedItemsChanged={handleOnSelectedItemsChanged}
      selectedItems={selectedItems}/>}
    label="Wybierz kody odpadów BDO"
    onSearchChange={fetchWastesBdo}
    show={show}
    selectContent={selectContent}
    setShow={setShow}
    skipSearchBar
  />;
}

const WastesBdoGroupTable = (props) => {
  const keyColumn = "id";
  const { items, selectedItems, onGroupSelected, onSelectedItemsChanged } = props;
  const columns = [
    { name: "code", headerName: "Kod odpadu" },
    { name: "description", headerName: "Opis" }
  ];

  if (!items || items.loading) {
    return;
  }

  return (
    <>
      <Accordion alwaysOpen>
        {items.map(x => <AccordionWasteCodeBdoItem
          key={x.parentId}
          parentId={x.parentId}
          parentCodeDescription={x.parentCodeDescription}
          {...props}
          items={x.childItems}
          keyColumn={keyColumn}
          columns={columns}
          selectedItems={selectedItems?.[x.parentId]?.selectedItems ?? []}
          onGroupSelected={onGroupSelected}
          onSelectedItemsChanged={onSelectedItemsChanged}
        />)}
      </Accordion>
    </>
  );
};

export const AccordionWasteCodeBdoItem = ({ parentId, parentCodeDescription, onGroupSelected, onSelectedItemsChanged, ...props }) => {
  const { items, selectedItems } = props;
  const [isGroupSelected, setIsGroupSelected] = useState(items.length === selectedItems.length);
  const groupBtnVariant = isGroupSelected ? "primary" : "outline-primary";

  const handleOnGroupBtnClicked = (e) => {
    e.stopPropagation();
    const newIsSelected = !isGroupSelected;
    setIsGroupSelected(newIsSelected);
    onGroupSelected(parentId, newIsSelected, parentCodeDescription);
  };

  const handleOnSelectedItemsChanged = (selectedItems) => {
    if(selectedItems.length !== items.length ){
      setIsGroupSelected(false);
    }

    if(selectedItems.length === items.length ){
      setIsGroupSelected(true);
    }

    onSelectedItemsChanged(parentId, selectedItems, parentCodeDescription);
  }

  return <Accordion.Item eventKey={parentId}>
    <Accordion.Header>
      <div className="d-flex align-items-center gap-4 small w-100">
        <DomainButton size="xx-small" btnSize="sm" variant={groupBtnVariant} onClick={handleOnGroupBtnClicked}>
          <CheckAll size={15}/>
        </DomainButton>
        {parentCodeDescription}
      </div>
      {selectedItems.length > 0 && <BadgeDomain className="me-2">Wybrano: {selectedItems.length} z {items.length}</BadgeDomain>}
    </Accordion.Header>
    <Accordion.Body>
      <TableDomain {...props} multiSelect onSelectedItemsChanged={handleOnSelectedItemsChanged}/>
    </Accordion.Body>
  </Accordion.Item>;
};