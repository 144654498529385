import { ordersActions } from "../../../_store/order.slice";
import { mapToMainDetails } from "../../../_services/Orders/orderMapper";
import { UpdateOrderMainDetailsForm } from "./UpdateOrderMainDetailsForm";
import { useDispatch } from "react-redux";
import { orderViewsActions } from "../../../_store/orderView.slice";

export function UpdateOrderSeriesMainDetails({ series }) {
  const dispatch = useDispatch();
  const { id, transactionType, mainDetails, wastesDetails } = series || {};

  const onSubmit = async(payload) => {
    const requestPayload = mapToMainDetails(payload);
    const response = await dispatch(ordersActions.updateSeriesMainDetails({ id, payload: { id, details: requestPayload } }));
    if (response?.error) {
      return response;
    }

    await dispatch(orderViewsActions.getSeriesById({ id }));

    return response;
  };

  return <UpdateOrderMainDetailsForm
    mainDetails={mainDetails}
    transactionType={transactionType}
    wastesDetails={wastesDetails}
    onSubmit={onSubmit}
    isSeries
  />;
}