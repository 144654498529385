import { ModalDomain } from "../../../../_components/Abstractions/ModalDomain";
import { AllowedCodesBdo } from "../CreateContractor/ContractorReceiverDetailsView";
import { BadgeDomain } from "../../../../_components/Abstractions/BadgeDomain";

export function AllowedCodesBdoModal({ allowedWasteCodesBdo, allowedWasteCodesBdoCount }) {
  const modalData = {
    title: "" ,
    buttonText: <>Towary <BadgeDomain>{allowedWasteCodesBdoCount}</BadgeDomain></>,
    body: <AllowedCodesBdo allowedWasteCodesBdo={allowedWasteCodesBdo} allowedWasteCodesBdoCount={allowedWasteCodesBdoCount}/>,
    isReadonly: true,
    handleShow: () => {}
  };
  return (
    <>
      <ModalDomain btnSize="x-small" {...modalData} />
    </>
  );
}