import React, { useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import { HorizontalView } from "../../Abstractions/HorizontalView";
import { ReadonlyControl } from "../../Forms/ReadonlyControl";
import { BdoNumberControl } from "../../Forms/BdoNumberControl";
import { KpoCardStatus } from "../../../domain/KpoCardStatus";
import { MultiDropdown } from "../../Abstractions/MultiSearcher/MultiDropdown";
import { BasicSwitch } from "../../Abstractions/BasicSwitch";
import { Searcher } from "../../Forms/Searcher";

export function KpoBdoSearcher({ isSale, taxId, registrationNumber, addressRegistrationNumber, onSearchChange }) {
  const [showTransportDateRange, setShowTransportDateRange] = useState(false);
  const defaultKpoStatuses = ["Planned", "Approved", "ConfirmationGenerated"];
  const [filters, setFilters] = useState({
    taxId,
    transportDateRange: false,
    statusesCodes: defaultKpoStatuses,
    // cardNumber: !isSale ? addressRegistrationNumber : null,
    allowMultipleEupIds: false
  });

  const onTransportDateRangeChanged = (e) => {
    setShowTransportDateRange(e);
    onFilterChanged({ name: "transportDateRange", value: e });
  };

  const onStatusesChanged = (statuses) => {
    onFilterChanged({ name: "statusesCodes", value: Object.keys(statuses) });
  };

  const onFilterChanged = ({ name, value }) => {
    let newFilters;

    if (value === "") {
      newFilters = { ...filters, [name]: null };
    } else {
      newFilters = { ...filters, [name]: value };
    }

    if(name === "allowMultipleEupIds") {
      newFilters.cardNumber = null;
    }

    setFilters(newFilters);
    onSearchChange(newFilters);
  };

  const onAllowMultipleEupIdsSwitchChanged = (value) => {
    onFilterChanged({ name: "allowMultipleEupIds", value });
  }

  useEffect(() => {
    onFilterChanged({ name: "taxId", value: taxId });
    // onSearchChange(filters);
  }, [taxId]);  //originatorRole, registrationNumber, addressRegistrationNumber]);

  return (
    <Container>
      <HorizontalView>
        <ReadonlyControl name="taxId" label="NIP Klienta">{taxId}</ReadonlyControl>
        <ReadonlyControl name="registrationNumber" label="Numer rejestrowy podmiotu">{registrationNumber}</ReadonlyControl>
        {!isSale && !filters?.allowMultipleEupIds && <ReadonlyControl name="addressRegistrationNumber" label="Numer rejestrowy adresu" inputType="text">{addressRegistrationNumber}</ReadonlyControl>}
      </HorizontalView>
      <HorizontalView>
        <Searcher name="wasteCode" label="Kod odpadu" inputType="text" onSearchChange={onFilterChanged}/>
        <Searcher name="transportStartDate" label={`Data rozpoczęcia transportu ${showTransportDateRange ? "od" : ""}`} inputType="date" onSearchChange={onFilterChanged}/>
        {showTransportDateRange && <Searcher name="transportStartDateTo" label="Data rozpoczęcia transportu do" inputType="date" onSearchChange={onFilterChanged}/>}
      </HorizontalView>
      <div className="d-flex gap-4">
      <BasicSwitch name="transportDateRange" label="Zakres dat transportu" onChange={onTransportDateRangeChanged}/>
      <BasicSwitch
        name="allowMultipleEupIds"
        label="Karty z  wszystkich adresów MPD klienta"
        onChange={onAllowMultipleEupIdsSwitchChanged} />
      </div>
      <HorizontalView columnsSize={[5]}>
        {!isSale && addressRegistrationNumber && !filters.allowMultipleEupIds ?
          <BdoNumberControl name="cardNumber" label="Karta KPO" inputType="text" onSearchChange={onFilterChanged} addressRegistrationNumber={addressRegistrationNumber}/>
          : <Searcher name="cardNumber" label="Karta KPO" inputType="text" onSearchChange={onFilterChanged}/>}
      </HorizontalView>
      <MultiDropdown label="Statusy kart" name="cards-statuses" items={KpoCardStatus} onSelectedItemsChanged={onStatusesChanged} defaultKeys={defaultKpoStatuses}/>
      <Card>
      </Card>
    </Container>
  );
}
