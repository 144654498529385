import { DomainButton } from "_components/Abstractions/DomainButton";
import { HorizontalView } from "_components/Abstractions/HorizontalView";
import { EupBdoSelect } from "_components/Bdo/Eup/EupBdoSelect";
import { FormControl } from "_components/Forms/FormControl";
import { TextArea } from "_components/Forms/TextArea";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { AccordionDomain } from "../../../../_components/Abstractions/AccordionDomain";
import { ContractorReceiverDetailsForm } from "./ContractorReceiverDetailsForm";

export function BdoAddressForm({ addressField }) {
  const GetFullField = (field) => `${addressField}.${field}`;
  const { setValue, getFieldState, watch, getValues } = useFormContext();
  const contractorCompanyId = watch("bdoDetails.companyId");
  const contractorTypes = watch("contractorTypes");
  const addressCompanyId = watch(GetFullField("bdoDetails.companyId"));
  const isBdoLinked = watch(GetFullField("isBdoLinked"));
  const fieldState = getFieldState("wasteProcessingDecisionData");
  const isValid = !fieldState.invalid;
  const setValueOptions = {
    shouldValidate: true,
    shouldDirty: true,
    shouldTouch: true
  };
  const onEupSelect = (address) => {
    const {
      name,
      registrationNumber,
      eupId,
      companyId,
      addressRaw,
      addressDetails
    } = address;
    const currentAddress = getValues(addressField);
    const bdoDetails = {
      registrationNumber: registrationNumber,
      eupId: eupId,
      companyId: companyId,
      address: addressRaw,
      addressName: name,
      contractorTypes: contractorTypes
    };
    setValue(
      addressField,
      {
        ...currentAddress,
        ...addressDetails,
        name: name,
        isBdoLinked: true,
        bdoDetails
      },
      setValueOptions
    );
  };

  const RemoveEupLink = () => {
    setValue(GetFullField("bdoDetails"), null);
    setValue(GetFullField("isBdoLinked"), false);
  };

  useEffect(() => {
    if (addressCompanyId === contractorCompanyId) {
      return;
    }
    RemoveEupLink();
  }, [addressCompanyId, contractorCompanyId]);

  return (
    <>
      <div className="my-2">
        <div className="mb-2">
          <EupBdoSelect
            companyId={contractorCompanyId}
            onSelect={onEupSelect}
          />
          {isBdoLinked && (
            <DomainButton
              className="ms-2"
              variant="secondary"
              onClick={RemoveEupLink}
            >
              Usuń powiązanie z MPD
            </DomainButton>
          )}
        </div>
        {isBdoLinked && (
          <>
            <FormControl name={GetFullField("bdoDetails.addressName")} label="Nazwa BDO" readonly/>
            <AccordionDomain header="Szczegóły BDO" isValid={isValid}>
              <HorizontalView columnsSize={[4, null]}>
                <FormControl name={GetFullField("bdoDetails.registrationNumber")} label="Numer MPD" readonly/>
                <FormControl name={GetFullField("bdoDetails.eupId")} label="Numer ref. BDO" readonly/>
              </HorizontalView>
              <TextArea name={GetFullField("bdoDetails.address")} label="Adres" readonly height="100px"/>
              <ContractorReceiverDetailsForm contractorTypes={contractorTypes} addressField={addressField}/>
            </AccordionDomain>
          </>
        )}
      </div>
    </>
  );
}
