import { BadgeDomain } from "_components/Abstractions/BadgeDomain";
import { KpoCardStatus, KpoCardStatusColor } from "../../../domain/KpoCardStatus";

export function KpoBdoStatusBadge({ status, size }) {
  return (
    <>
      <BadgeDomain key={status} bg={KpoCardStatusColor[status]} size={size}>
        {KpoCardStatus[status]}
      </BadgeDomain>
    </>
  );
}
