import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { DomainButton } from "./DomainButton";
import { Fragment, useState } from "react";
import { LoadingSpinner } from "./LoadingSpinner";

export function ModalDomain({
  title,
  buttonText,
  body,
  show,
  handleShow,
  handleClose,
  isReadonly = false,
  size = null,
  btnSize = "small",
  isButtonTrigger = true,
  clearTriggerBtnDefaultStyles = false,
  triggerBtnVariant = null
}) {
  const [showModal, setShowModal] = useState(false);
  const onShow = () => {
    setShowModal(true);
    if (isReadonly && handleShow) {
      handleShow();
    }
  };
  const onClose = () => setShowModal(false);
  const triggerButton = <DomainButton
    className={clearTriggerBtnDefaultStyles ? "" : "me-2"}
    size={btnSize}
    variant={triggerBtnVariant}
    onClick={isReadonly && handleShow ? onShow : handleShow}
  >
    {buttonText}
  </DomainButton>;
  const triggerAction = <Dropdown.Item className="domain-control" onClick={isReadonly && handleShow ? onShow : handleShow}>{buttonText}</Dropdown.Item>;
  return (
    <>
      {isButtonTrigger ? triggerButton : triggerAction}
      <Modal
        size={size}
        show={show ?? showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose ?? onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>

        </Modal.Header>
          <Modal.Body>
            {body}
            {isReadonly && (
              <Modal.Footer>
                <DomainButton variant="secondary" onClick={onClose}>
                  Zamknij
                </DomainButton>
              </Modal.Footer>
            )}
          </Modal.Body>
      </Modal>
    </>
  );
}
