import { ButtonLink } from "../../_components/Abstractions/ButtonLink";
import { Container } from "react-bootstrap";

export function ReportsDashboard({ isDirect = true }) {
  const links = <div className="d-flex gap-2 mb-4">
    <ButtonLink to="/reports/order-status">Zlecenia - Status</ButtonLink>
    <ButtonLink to="/reports/order-driver">Zlecenia - Kierowca</ButtonLink>
    <ButtonLink to="/reports/order-contractor">Zlecenia - Klient</ButtonLink>
    <ButtonLink to="/reports/order-user">Zlecenia - Użytkownik</ButtonLink>
    <ButtonLink to="/reports/order-containers">Zlecenia - Kontenery</ButtonLink>
    <ButtonLink to="/reports/containers-usage">Zużycie kontenerów (podtypy)</ButtonLink>
    <ButtonLink to="/reports/group-containers-usage">Zużycie kontenerów (grupy)</ButtonLink>
    <ButtonLink to="/reports/vehicles-information">Raport pojazdów</ButtonLink>
  </div>

  return isDirect ? <Container><h3>Raporty</h3>{links}</Container> : links;
}