export const AddressTypes = {
    isDefault: "Domyślny",
    isPickup: "Dostawa",
    isCentral: "Centrala",
    isBdoLinked: "BDO"
  };
  

  export const AddressTypesBadges = {
    isDefault: "info",
    isPickup: "fiolet",
    isCentral: "indigo",
    isBdoLinked: "primary"
  };