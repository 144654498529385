import { DictionaryPage } from "_components/Abstractions/DictionaryPage";
import { WastesTable } from "_components/Wastes/WastesTable";
import { useSelector } from "react-redux";
import { CreateWaste } from "./CreateWaste";
import { wastesActions } from "_store/wastes.slice";

export function GetWastes({ activeEventKey, pageEventKey }) {
  const { wastes, wastesPaging, wastesSorting } = useSelector((x) => x.wastes);
  const table = <WastesTable wastes={wastes} sorting={wastesSorting} itemActions={wastesActions} />;
  const createPage = <CreateWaste />;

  return (
    <DictionaryPage
      items={wastes}
      paging={wastesPaging}
      itemsTable={table}
      itemActions={wastesActions}
      createItem={createPage}
      activeEventKey={activeEventKey}
      pageEventKey={pageEventKey}
      includeSearcher
      searcherPlaceholder="Szukaj po nazwie, kodzie odpadu..."
      excludeStatus
      sorting={wastesSorting}
    />
  );
}

