  import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useRef } from "react";

export function DomainSwitch({ label, name, className, readonly = false }) {
  const { register, formState } = useFormContext();
  const { errors } = formState;

  return (
    <>
      <Form.Group className={className ?? "mb-3 domain-control"}>
        <Form.Check
          type="switch"
          label={label}
          id={name}
          isInvalid={!!errors[name]}
          disabled={readonly}
          className="hover-pointer switch"
          {...register(name)}
        ></Form.Check>
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
}
