import { BaseOrderForm } from "./BaseOrderForm";
import { ordersActions } from "../../../_store/order.slice";
import { mapToCreatePurchaseRequest } from "../../../_services/Orders/orderMapper";
import * as Yup from "yup";
import { WasteMassValidator } from "../../../domain/Orders/CreatePurchaseOrderValidator";
import { useNavigate } from "react-router-dom";

export function PurchaseOrderForm() {
  const navigate = useNavigate();
  const extendedClientData = <></>;
  const defaultValues = {
    transportDetails: {
      transportType: "Internal"
    }
  };

  const purchaseValidationSchema = Yup.object().shape({ wastesDetails: WasteMassValidator.wasteDetailsValidator });

  const redirectToCreatingSaleTransfer = ({ orderId, request }) => {
    const { transportDetails: { isTransfer } } = request;
    if (isTransfer) {
      navigate(`/orders/create-sale?transferPurchaseId=${orderId}`);
    } else {
      navigate(`/orders/${orderId}`);
    }
  };

  return (
    <BaseOrderForm
      transactionTypeParam="Purchase"
      validationSchema={purchaseValidationSchema}
      submitAction={ordersActions.createPurchase}
      requestMapper={mapToCreatePurchaseRequest}
      extendedClientData={extendedClientData}
      setInternalTransportType={true}
      defaultValues={defaultValues}
      redirect={redirectToCreatingSaleTransfer}
    />
  );
}