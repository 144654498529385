import * as Yup from "yup";
import { WasteMassValidator } from "../../../../domain/Orders/CreatePurchaseOrderValidator";
import { GetDefaultRemarks, KpoRemarksControl } from "./KpoRemarksControl";
import { ListGroup } from "react-bootstrap";
import { FormModal } from "../../../Forms/FormModal";
import { BaseKpoForm } from "./KpoReceiveConfirmation";
import { ValidateBdoAction } from "./BdoActionValidator";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { RecalculateKpoFromWastes } from "./RecalculateKpoFromWastes";
import { NumericControl } from "../../../Forms/NumericControl";
import { BasicSwitch } from "../../../Abstractions/BasicSwitch";

export function KpoRejectForm({ kpoCards, onSubmit, name, promptTitle, performAll }) {
  const { validate } = ValidateBdoAction();
  const { formControls, defaultValues, validationSchema } = KpoRejectFormProvider({ kpoCards: kpoCards });


  const handleSubmit = async(payload) => {
    const kpoItems = payload.kpoItems.filter(x => x.isSelected).map((x) => ({
      kpoId: x.kpoId,
      correctedWasteMass: x.correctedWasteMass,
      remarks: x.remarks
    }));

    validate(kpoItems);
    await onSubmit(kpoItems);
  };

  return <FormModal
    title={promptTitle}
    formControls={formControls}
    defaultValues={defaultValues}
    onSubmit={handleSubmit}
    buttonText={name}
    validationSchema={validationSchema}
    isButtonTrigger={performAll}
    skipToast
    sizeModal="md"
    submitBtnText={name}/>;
}

export function KpoRejectControl({ kpoItem, selectable, parentName }) {
  const { watch, setValue } = useFormContext();
  const correctedWasteMass = watch(`${parentName}.correctedWasteMass`);

  useEffect(() => {
    const remarks = GetDefaultRemarks(correctedWasteMass, null, false, true);
    setValue(`${parentName}.remarks`, remarks);
  }, [correctedWasteMass]);


  const formControls =  <div className="domain-control">
    <NumericControl decimalPlaces={4} name={`${parentName}.correctedWasteMass`} label="Prawidłowa masa odpadów [Mg]"/>
    <KpoRemarksControl
      parentName={parentName}
      includeIntermediary={false}/>
  </div>;

  return <BaseKpoForm kpoItem={kpoItem} selectable={selectable} formControls={formControls} parentName={parentName}/>;
}

export function KpoRejectFormProvider({ kpoCards, allowRecalculateWasteMass = false }) {
  const [skipWasteMassUpdateValidation, setSkipWasteMassUpdateValidation] = useState(false);
  const kpoItemSchema = Yup.object().shape({
    correctedWasteMass: Yup.string().when("isSelected", {
      is: true,
      then: WasteMassValidator.validator
      .when([], {
        is: _ => Boolean(!skipWasteMassUpdateValidation),
        then: Yup.string().notOneOf([Yup.ref("initWasteMass")], "Masa musi się różnić od masy początkowej")
      })
    }),
    cardNumber: Yup.string().required("Numer karty jest wymagany")
  });

  const validationSchema = Yup.object().shape({
    kpoItems: Yup.array().of(kpoItemSchema)
  });

  const filteredKpoCards = kpoCards; //.filter(x => x.status === KPO_STATUS.CONFIRMATION_GENERATED);
  const values = filteredKpoCards.map(x => {
    return (
      {
        kpoId: x.kpoId,
        cardNumber: x.cardNumber,
        wasteMass: x.wasteMass,
        initWasteMass: x.wasteMass?.toFixed(4),
        wasteCode: x.wasteCode,
        wasteCodeId: x.wasteCodeId,
        // correctedWasteMass: x.wasteMass.toFixed(4),
        remarks: GetDefaultRemarks(x.wasteMass, null, false),
        isSelected: true
      });
  });


  const defaultValues = { kpoItems: values };
  const formControls =
    <div>
      {values.length === 0 && <ListGroup.Item>Brak kart KPO do wygenerowania potwierdzenia</ListGroup.Item>}
      <BasicSwitch onChange={setSkipWasteMassUpdateValidation} value={skipWasteMassUpdateValidation} label="Pomiń walidację masy w kartach KPO" />
      {allowRecalculateWasteMass && <RecalculateKpoFromWastes/>}
      {filteredKpoCards.map((x, idx) =>
        <div key={idx}>
          <KpoRejectControl kpoItem={x} selectable={filteredKpoCards.length > 1} parentName={`kpoItems[${idx}]`} />
        </div>)}
    </div>;

  return { validationSchema, formControls, defaultValues, allowRecalculateWasteMass };
}