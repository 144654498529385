import { DomainButton } from "./Abstractions/DomainButton";
import { useFormContext } from "react-hook-form";

export function SubmitButton({ children }) {
  const { formState: { isSubmitting } } = useFormContext();

  return <DomainButton type="submit" disabled={isSubmitting}>
    {isSubmitting && (
      <span className="spinner-border spinner-border-sm mr-1"></span>
    )}
    {children}
  </DomainButton>
}