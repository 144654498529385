import { Row } from "react-bootstrap";
import { FormControl } from "../../Forms/FormControl";
import { KpoBdoDropdown } from "../../Bdo/Kpo/KpoBdoDropdown";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { HorizontalView } from "../../Abstractions/HorizontalView";
import { SaleWastesAdditionalForm } from "./SaleWastesAdditionalForm";
import { NumericControl } from "../../Forms/NumericControl";

export function BaseWasteForm({ parentName, waste, kpoItems, requireApplicableKpoItems, additionalWasteControls, includeExtendedSaleWastesData }) {
  const GetFieldName = (name) => `${parentName}.${name}`;
  const { watch, setValue } = useFormContext();
  // const { fields: applicableKpoItems } = useFieldArray({ name: GetFieldName("applicableKpoItems") });
  const applicableKpoItems = watch(GetFieldName("applicableKpoItems"));

  useEffect(() => {
    if (applicableKpoItems?.length > 1) {
      setValue(GetFieldName("validateWasteMass"), true);
    } else {
      // replace([]);
      setValue(GetFieldName("validateWasteMass"), false);
    }
  }, [applicableKpoItems]);

  return <>
    <div className="my-2">
      <Row>
        <hr></hr>
        <HorizontalView className="d-flex gap-2 align-items-start">
          <FormControl
            name={GetFieldName("displayDescription")}
            label="Towar"
            readonly
          />
          <NumericControl decimalPlaces={4} name={GetFieldName("wasteMass")} label="Masa odpadów [Mg]"/>
        </HorizontalView>
        {requireApplicableKpoItems && <div className="domain-control">
          <KpoBdoDropdown wasteCodeId={waste.wasteCodeBdoId} items={kpoItems} name={GetFieldName("applicableKpoItems")}/>
          {waste.applicableKpoItems?.length > 1 && <div className="mt-2">
            {applicableKpoItems?.map((x, idx) =>
              <div key={x.kpoId}>
                <FormControl name={GetFieldName(`applicableKpoItems[${idx}].wasteMass`)} label={`Masa (${x.description})`} autoComplete="off"/>
              </div>)}</div>}
        </div>}
        {additionalWasteControls}
        {includeExtendedSaleWastesData && <SaleWastesAdditionalForm parentName={parentName} /> }
      </Row>
    </div>
  </>;
}