import { FloatingLabel, Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { ControlLabel } from "../Abstractions/ControlLabel";

export function FormControl({
  label,
  name,
  value,
  className,
  inputType = "text",
  autoFocus = false,
  readonly = false,
  additionalAttributes = null,
  required = true,
  autoComplete
}) {
  const { register, getFieldState } = useFormContext();
  let fieldState = getFieldState(name);
  const formattedLabel = <ControlLabel label={label} required={required}/>

  return (
    <>
      <Form.Group className="mb-3">
        <FloatingLabel label={formattedLabel}>
          <Form.Control
            type={inputType}
            autoFocus={autoFocus}
            placeholder={label}
            autoComplete={autoComplete}
            isInvalid={fieldState.error}
            disabled={readonly}
            className={`form-input form ${className}`}
            required={required}
            {...additionalAttributes}
            {...register(name, { defaultValue: value })}
          />
          <Form.Control.Feedback type="invalid">
            {fieldState?.error?.message}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    </>
  );
}
