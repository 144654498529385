import { DictionaryPage } from "_components/Abstractions/DictionaryPage";
import { useSelector } from "react-redux";
import { VehiclesTable } from "_components/Vehicles/VehiclesTable";
import { vehiclesActions } from "_store/vehicles.slice";
import { CreateVehicle } from "./CreateVehicle";

export function GetVehicles(props) {
  const { vehicles, vehiclesPaging, vehiclesSorting } = useSelector((x) => x.vehicles);
  const table = <VehiclesTable vehicles={vehicles} isActive={props.isActive} sorting={vehiclesSorting} itemActions={vehiclesActions} />;
  const createPage = <CreateVehicle />;

  return (
    <DictionaryPage
      items={vehicles}
      paging={vehiclesPaging}
      itemsTable={table}
      itemActions={vehiclesActions}
      createItem={createPage}
      activeEventKey={props.activeEventKey}
      pageEventKey={props.pageEventKey}
      isActive={props.isActive}
      includeSearcher
      searcherPlaceholder="Szukaj po nazwie, modelu..."
      sorting={vehiclesSorting}
    />
  );
}
