import { BaseWastesForm } from "./BaseWastesForm";

export function SaleWastesDetailsForm({ includeExtendedSaleWastesData }) {
  return (
    <BaseWastesForm
      wasteFieldName="wastesDetails.saleWastes"
      includeExtendedSaleWastesData={includeExtendedSaleWastesData}
    />
  );
}


export function TransportWastesDetailsForm({ fillWasteMass }) {
  return (<BaseWastesForm wasteFieldName="wastesDetails.transportWastes" fillWasteMass={fillWasteMass}/>);
}