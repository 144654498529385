import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '_helpers';
import { getDictionaryQuery } from "_store";
import { StringExtensions } from "../_helpers/extensions";

// create slice

const name = "drivers";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const driversActions = { ...slice.actions, ...extraActions };
export const driversReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    drivers: {},
    driversSorting: { value: "displayName", type: "asc" },
    driversPaging: null,
    driversVehicles: {},
  };
}

function createReducers() {
  return { setSorting };

  function setSorting(state, action) {
    state.usersSorting = action.payload;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/drivers`;
  const baseUsersUrl = `${process.env.REACT_APP_API_URL}/accounts`;

  return {
    getAll: getAll(),
    search: search(),
    create: create(),
    update: update(),
    deactivate: deactivate(),
    activate: activate(),
    getVehiclesForDriver: getVehiclesForDriver(),
    assignVehicles: assignVehicles(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async(payload) =>
          await fetchWrapper.get(getDictionaryQuery(baseUrl, payload)));
  }

  function search() {
    return createAsyncThunk(`${name}/search`, async(payload) => {
      const { pageNumber, pageSize, filter } = payload;
      const query = { pageNumber, pageSize, ...filter };
      const queryString = StringExtensions.createQueryStringFromObject(query);
      return await fetchWrapper.get(`${baseUrl}/search${queryString}`);
    });
  }

  function create() {
    return createAsyncThunk(`${name}/create`, async(payload) => {
      return await fetchWrapper.post(`${baseUrl}`, payload);
    });
  }

  function update() {
    return createAsyncThunk(`${name}/update`, async({ id, payload }) => {
      payload = { id, ...payload };
      return await fetchWrapper.patch(`${baseUrl}/${id}`, payload);
    });
  }

  function deactivate() {
    return createAsyncThunk(`${name}/deactivate`, async({ userId }) => {
      return await fetchWrapper.patch(`${baseUsersUrl}/${userId}/deactivate`);
    });
  }

  function activate() {
    return createAsyncThunk(`${name}/activate`, async({ userId }) => {
      return await fetchWrapper.patch(`${baseUsersUrl}/${userId}/activate`);
    });
  }

  function getVehiclesForDriver() {
    return createAsyncThunk(
      `${name}/getVehiclesForDriver`,
      async({ driverId }) => {
        return await fetchWrapper.get(`${baseUrl}/${driverId}/get-vehicles`);
      },
    );
  }

  function assignVehicles() {
    return createAsyncThunk(
      `${name}/assignVehicles`,
      async({ driverId, payload }) => {
        return await fetchWrapper.put(
          `${baseUrl}/${driverId}/assign-vehicles`,
          payload,
        );
      },
    );
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...deactivate(),
    ...activate(),
    ...getVehiclesForDriver(),
    ...update(),
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.drivers = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.drivers = action.payload.items;
        state.driversPaging = action.payload.paging;
      },
      [rejected]: (state, action) => {
        state.drivers = { error: action.error };
      },
    };
  }

  function deactivate() {
    var { fulfilled } = extraActions.deactivate;
    return {
      [fulfilled]: (state, action) => {
        const { userId } = action.meta.arg;
        const userToUpdate = state.drivers.find((x) => x.id === userId);
        userToUpdate.isActive = false;
      },
    };
  }

  function activate() {
    var { fulfilled } = extraActions.activate;
    return {
      [fulfilled]: (state, action) => {
        const { userId } = action.meta.arg;
        const userToUpdate = state.drivers.find((x) => x.id === userId);
        userToUpdate.isActive = true;
      },
    };
  }

  function getVehiclesForDriver() {
    var { pending, fulfilled } = extraActions.getVehiclesForDriver;
    return {
      [pending]: (state) => {
        state.driversVehicles = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.driversVehicles = action.payload;
      },
    };
  }

  function update() {
    var { fulfilled } = extraActions.update;
    return {
      [fulfilled]: (state, action) => {
        const { id, payload } = action.meta.arg;
        const updateDriver = state.drivers.find((x) => x.id === id);
        Object.assign(updateDriver, payload);
      },
    };
  }
}
