import { Card } from "react-bootstrap";
import { DomainButton } from "../../Abstractions/DomainButton";
import KpoBdoCard from "./KpoBdoCard";
import { Hr } from "../../Abstractions/Hr/Hr";
import { stringToDate } from "../../../domain/Configuration";
import { useToast } from "../../../_helpers/notifications/useToasts";
import { useState } from "react";
import { BasicSwitch } from "../../Abstractions/BasicSwitch";
import moment from "moment";

export function KpoBdoSelectedCards({ selectedCards, plannedLoadingDate, onUnselect, onSubmit, originatorRole, hideSave = false }) {
  const { showErrorMessage } = useToast();
  const [isDatesMismatchErrorShown, setIsDatesMismatchErrorShown] = useState(false);
  const [isDatesMismatchAllowed, setIsDatesMismatchAllowed] = useState(false);
  const loadingDateText = plannedLoadingDate ? `(data załadunku - ${stringToDate(plannedLoadingDate)})` : "";
  const handleOnSubmit = () => {
    const plannedDate = moment(plannedLoadingDate);
    const isDateMismatch = selectedCards.some(card => {
      const cardDate = moment(card.plannedTransportTime);
      return !cardDate.isBetween(plannedDate.clone().subtract(1, 'days'), plannedDate.clone().add(1, 'days'), 'day', '[]');
    });

    if(!isDatesMismatchAllowed && isDateMismatch) {
      showErrorMessage("Nie wszystkie karty KPO mają datę transportu zbieżną +/- 1 dzień z planowaną datą załadunku...")
      setIsDatesMismatchErrorShown(true);
      return;
    }
    onSubmit();
  }

  return <div className="my-2">
    <span style={{ textTransform: "none" }}>
      <Hr>Przypisane karty {loadingDateText}</Hr>
    </span>
    <div className="d-flex flex-column gap-1">
      {selectedCards?.map(x => <KpoBdoCard key={x.kpoId} kpoCard={x} onUnselect={onUnselect} originatorType={originatorRole} plannedLoadingDate={plannedLoadingDate}/>)}
    </div>
    {!hideSave && <Card className="mt-2">
      <Card.Footer className="d-flex flex-row align-items-start justify-content-between">
        {isDatesMismatchErrorShown ? <BasicSwitch name="allowOnInvalidKpoDates" label="Zezwól na niepoprawne daty KPO" onChange={setIsDatesMismatchAllowed} /> : <div></div>}
        <DomainButton onClick={handleOnSubmit}>Przypisz karty</DomainButton>
      </Card.Footer>
    </Card>}
  </div>;
}
