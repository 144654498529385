import { useEffect, useState } from "react";
import { Col, Form, FormCheck, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { useFormContext } from "react-hook-form";
import { ControlLabel } from "./ControlLabel";

export function RadioButtonGroup({
  label,
  name,
  items,
  conditionField,
  when,
  defaultChecked,
  colSize,
  required = true,
}) {
  const { register, formState, watch, setValue } = useFormContext();
  const { errors } = formState;
  const [show, setShow] = useState(true);
  const field = watch(conditionField);
  const formattedLabel = <ControlLabel label={label} required={required}/>

  useEffect(() => {
    if (!when) {
      return;
    }
    const updateShow = when(field);
    setShow(updateShow);
    if (!updateShow) {
      setValue(name, null);
    }
  }, [field, when, setValue, name]);

  return !show ? (
    <></>
  ) : (
    <>
      <Form.Group as={Row} className="mb-2">
        <FormCheck.Label className="mb-2 me-2">{formattedLabel}</FormCheck.Label>
        {items.map((x, index) => (
          <Col key={index} sm={colSize ?? "auto"}>
            <FormCheck id={`${name}-${index}`} key={index}>
              <div key={index}>
                <FormCheck.Input
                  className="hover-pointer"
                  type="radio"
                  isInvalid={!!errors[name]}
                  {...register(name)}
                  value={x.value}
                  defaultChecked={defaultChecked === x.value}
                />
                <FormCheck.Label className="ms-2 hover-pointer">
                  {x.name}
                </FormCheck.Label>
                {index === items.length - 1 && (
                  <Feedback type="invalid">{errors[name]?.message}</Feedback>
                )}
              </div>
            </FormCheck>
          </Col>
        ))}
      </Form.Group>
    </>
  );
}
