export function ContainersTableSummary({ summary, headerColSpan }) {
  if(!summary){
    return;
  }

  const { totalInitializeCount, totalUsedCount, totalRemainingCount} = summary;

  return <tr>
    <td colSpan={headerColSpan} className="dark-grey">Podsumowanie</td>
    <td className="dark-grey">{totalInitializeCount}</td>
    <td className="dark-grey">{totalUsedCount}</td>
    <td className="dark-grey">{totalRemainingCount}</td>
    <td className="dark-grey"/>
  </tr>
}