import { SelectSearcher } from "_components/Abstractions/MultiSearcher/SelectSearcher";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { groupContainersActions } from "../../_store/groupContainers.slice";

export function GroupContainersSearchSelect({ name, saveWholeObject = true, skipInitLoading = false }) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  const onSearchChange = async(searchTerm) => {
    const params = { searchTerm, pageNumber: 1, pageSize: 10 };
    const { payload } = await dispatch(groupContainersActions.search(params));
    const items = payload.items?.map(x => { return { groupContainerId: x.id, groupContainerType: x.type };}) ?? [];
    setItems(items);
  };

  useEffect(() => {
    if(skipInitLoading){
      return;
    }

    onSearchChange(null);
  }, []); // eslint-disable-next-line/react-hooks/exhaustive-deps

  return (
    <>
      <SelectSearcher
        name={name}
        label="Grupa kontenera"
        items={items}
        onSearchChange={onSearchChange}
        optionsObject={{ key: "groupContainerId", value: "groupContainerType" }}
        placeholder="Wyszukaj grupę kontenera..."
        saveWholeObject={saveWholeObject}
      />
    </>
  );
}
