import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";
import { GenerateOrderUserReport } from "../useCases/reports/GenerateOrderDriversReport";
import { downloadReport } from "../_helpers/fileDownloader";
import { GenerateVehiclesInformationReport } from "../useCases/reports/GenerateVehiclesInformationReport";
import { StringExtensions } from "../_helpers/extensions";

// create slice

const name = "reports";
const reducers = createReducers();
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const reportsActions = { ...slice.actions, ...extraActions };
export const reportsReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    reports: {},
  };
}

function createReducers() {
  return {
    clear,
  };

  function clear(state, action) {
    const { payload: { reportType } } = action;
    state.reports[reportType] = null;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/reports`;

  return {
    generateOrderStatusReport: generateOrderStatusReport(),
    generateOrderDriversReport: generateOrderDriversReport(),
    generateOrderContractorReport: generateOrderContractorReport(),
    generateOrderUserReport: generateOrderUserReport(),
    generateOrderContainerReport: generateOrderContainerReport(),
    generateSubContainersUsageReport: generateSubContainersUsageReport(),
    generateGroupContainersUsageReport: generateGroupContainersUsageReport(),
    generateVehiclesInformationReport: generateVehiclesInformationReport()
  };

  function generateOrderStatusReport() {
    return createAsyncThunk(`${name}/order-status`, async ({ payload }) => {
      const { dateFrom, dateTo } = payload;

      return await fetchWrapper.get(`${baseUrl}/order-status?dateFrom=${dateFrom}&dateTo=${dateTo}`);
    });
  }

  function generateOrderDriversReport() {
    return createAsyncThunk(`${name}/order-driver`, async ({ payload }) => {
      const { dateFrom, dateTo, driver } = payload;

      return await fetchWrapper.get(`${baseUrl}/order-driver?dateFrom=${dateFrom}&dateTo=${dateTo}&driverId=${driver?.id ?? ""}`);
    });
  }

  function generateOrderContractorReport() {
    return createAsyncThunk(`${name}/order-contractor`, async ({ payload }) => {
      const { dateFrom, dateTo, contractor, address } = payload;
      const queryStringItems = { dateFrom, dateTo, contractorId: contractor?.id, addressId: address?.id };
      const queryString = StringExtensions.createQueryStringFromObject(queryStringItems);

      return await fetchWrapper.get(`${baseUrl}/order-contractor${queryString}`);
    });
  }

  function generateOrderUserReport() {
    return createAsyncThunk(`${name}/order-user`, async ({ payload }) => {
      const { dateFrom, dateTo, user } = payload;

      return await fetchWrapper.get(`${baseUrl}/order-users?dateFrom=${dateFrom}&dateTo=${dateTo}&userId=${user?.id ?? ""}`);
    });
  }

  function generateOrderContainerReport() {
    return createAsyncThunk(`${name}/order-containers`, async ({ payload }) => {
      const { days } = payload;

      return await fetchWrapper.get(`${baseUrl}/order-containers?days=${days}`);
    });
  }

  function generateSubContainersUsageReport() {
    return createAsyncThunk(`${name}/sub-containers-usage`, async ({ payload }) => {
      const { isDetailed } = payload;

      return await fetchWrapper.post(`${baseUrl}/sub-containers/usage?isDetailed=${isDetailed}`);
    });
  }

  function generateGroupContainersUsageReport() {
    return createAsyncThunk(`${name}/group-containers-usage`, async ({ payload }) => {
      const { isDetailed } = payload;

      return await fetchWrapper.post(`${baseUrl}/group-containers/usage?isDetailed=${isDetailed}`);
    });
  }

  function generateVehiclesInformationReport() {
    return createAsyncThunk(`${name}/vehicles-information`, async ({ payload }) => {
      const { year, month, isYearReport } = payload;
      let query = "";
      if (isYearReport) {
        query = `?year=${year}&month=${month ?? ""}`;
      }

      return await fetchWrapper.get(`${baseUrl}/vehicles-information${query}`);
    });
  }
}

function createExtraReducers() {
  return {
    ...generateOrderStatusReport(),
    ...generateOrderDriversReport(),
    ...generateOrderContractorReport(),
    ...generateOrderUserReport(),
    ...generateOrderContainerReport(),
    ...generateSubContainersUsageReport(),
    ...generateGroupContainersUsageReport(),
    ...generateVehiclesInformationReport()
  };

  function generateOrderStatusReport() { return generateOrderReport("orderStatus", "generateOrderStatusReport") }
  function generateOrderDriversReport() { return generateOrderReport("orderDriver", "generateOrderDriversReport") }
  function generateOrderContractorReport() { return generateOrderReport("orderContractor", "generateOrderContractorReport") }
  function generateOrderUserReport() { return generateOrderReport("orderUser", "generateOrderUserReport") }
  function generateOrderContainerReport() { return generateOrderReport("orderContainer", "generateOrderContainerReport") }
  function generateVehiclesInformationReport() { return generateOrderReport("vehiclesInformation", "generateVehiclesInformationReport") }

  function generateOrderReport(reportType, reportAction) {
    var { pending, fulfilled, rejected } = extraActions[reportAction];
    return {
      [pending]: (state) => {
        state.reports[reportType] = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.reports[reportType] = action.payload;
      },
      [rejected]: (state, action) => {
        state.reports[reportType] = { error: action.error };
      },
    };
  }

  function generateSubContainersUsageReport() {
    var { fulfilled } = extraActions.generateSubContainersUsageReport;
    return {
      [fulfilled]: (state, action) => {
        const { payload } = action;
        downloadReport(payload);
      },
    };
  }

  function generateGroupContainersUsageReport() {
    var { fulfilled } = extraActions.generateGroupContainersUsageReport;
    return {
      [fulfilled]: (state, action) => {
        const { payload } = action;
        downloadReport(payload);
      },
    };
  }
}
