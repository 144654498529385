import { useDispatch } from "react-redux";
import { wastesActions } from "_store/wastes.slice";
import { WasteForm } from "_components/Wastes/WasteForm";

export function UpdateWaste({ waste }) {
  const dispatch = useDispatch();
  const { wasteId } = waste;

  async function onSubmit(payload) {
    return await dispatch(wastesActions.update({ wasteId, payload }));
  }

  const defaultValues = {
    wasteId,
    internalName: waste.internalName,
    wasteBdo: {
      id: waste.wasteCodeBdoId,
      code: waste.wasteCode,
      description: waste.externalName,
      codeDescription: `${waste.wasteCode} - ${waste.externalName}`,
    },
  };

  return (
    <WasteForm
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      isUpdate
    ></WasteForm>
  );
}


