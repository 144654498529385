export const VEHICLES_TYPES = {
  Tir: "TIR",
  Hook: "Hak",
  Trailer: "Przyczepa",
  SemiTrailer: "Naczepa",
};

export const PICKUP_TYPES = {
  TIR: "Tir",
  HOOK: "Hook",
};

export const PickupTypes = {
  Tir: "TIR",
  Hook: "Hak",
};

export const VEHICLES_DISPLAY = {
  Hook: {
    name: VEHICLES_TYPES["Hook"],
    color: "cyan",
  },
  Tir: {
    name: VEHICLES_TYPES["Tir"],
    color: "dark",
  },
  Trailer: {
    name: VEHICLES_TYPES["Trailer"],
    color: "warning",
  },
  SemiTrailer: {
    name: VEHICLES_TYPES["SemiTrailer"],
    color: "teal",
  },
};

export const HOOK_FEATURES = {
  AdjustableHook: {
    name: "Regulowany hak",
    color: "orange",
    text: "dark",
  },
  NonAdjustableHook: {
    name: "Nieregulowany hak",
    color: "success",
  }
};

export const HookTypes = {
  Large: "Duży hakowiec",
  Medium: "Średni hakowiec",
  Small: "Mały hakowiec",
}

export const HookTypesColors = {
  Large: "secondary",
  Medium:"teal",
  Small: "indigo"
}

export const SemiTrailerType = {
  Curtains: "Firanka",
  MovingFloor: "Ruchoma podłoga",
  MarineContainer: "Kontener morski",
}

export const SemiTrailerTypeColor = {
  Curtains: "indigo",
  MovingFloor: "fiolet",
  MarineContainer: "cyan",
}

export const SEMITRAILER_FEATURES = {
  Curtains: {
    name: "Firanka",
    color: "indigo",
    text: "dark",
  },
  MovingFloor: {
    name: "Ruchoma podłoga",
    color: "fiolet",
  },
};
