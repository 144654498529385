import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";
import { downloadReport } from "../_helpers/fileDownloader";
// import { defaultPageNumber, defaultPageSize } from "_store";

// create slice

const name = "kpo";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

export const kpoActions = { ...slice.actions, ...extraActions };
export const kpoReducer = slice.reducer;

// implementation

function createInitialState() {
  return {};
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/kpo`;

  return {
    searchCards: searchCards(),
    getPrintingPage: getPrintingPage(),
    getKpoCardsBydIds: getKpoCardsBydIds(),
    deleteKpoCards: deleteKpoCards(),
    createPlannedKpoCards: createPlannedKpoCards(),
    approveKpoCards: approveKpoCards(),
    generateKpoCardsConfirmationAsSender: generateKpoCardsConfirmationAsSender(),
    generateKpoCardsConfirmationAsCarrier: generateKpoCardsConfirmationAsCarrier(),
    assignKpoCardsReceiveConfirmation: assignKpoCardsReceiveConfirmation(),
    changeKpoCardsStatusToTransportConfirmation: changeKpoCardsStatusToTransportConfirmation(),
    withdrawnKpoCards: withdrawnKpoCards(),
    rejectKpoCards: rejectKpoCards(),
    reviseKpoCards: reviseKpoCards(),
    updateKpoCardsData: updateKpoCardsData(),
    downloadConfirmation: downloadConfirmation(),
    downloadPrintingPage: downloadPrintingPage(),
    updateKpoData: updateKpoData(),
  };

  function searchCards() {
    return createAsyncThunk(
      `${name}/search-cards`,
      async(payload) =>
        await fetchWrapper.post(
          `${baseUrl}/search-cards`, payload
        )
    );
  }

  function getPrintingPage() {
    return createAsyncThunk(
      `${name}/getPrintingPage`,
      async({ kpoId }) =>
        await fetchWrapper.get(
          `${baseUrl}/card-printing-page?kpoId=${kpoId}`)
    );
  }

  function getKpoCardsBydIds() {
    return createAsyncThunk(
      `${name}/getKpoCardsBydIds`,
      async({ kpoIds }) =>
        await fetchWrapper.post(
          `${baseUrl}/cards`, { kpoIds: kpoIds })
    );
  }

  function deleteKpoCards() {
    return createAsyncThunk(
      `${name}/deleteKpoCards`,
      async({ orderId, kpoIds, performAll }) =>
        await fetchWrapper.patch(
          `${baseUrl}/order/${orderId}/delete`, { orderId, kpoIds, performAll })
    );
  }

  function createPlannedKpoCards() {
    return createAsyncThunk(
      `${name}/createPlannedKpoCards`,
      async({ orderId, payload }) =>
        await fetchWrapper.post(
          `${baseUrl}/order/${orderId}/planned`, payload)
    );
  }

  function approveKpoCards() {
    return createAsyncThunk(
      `${name}/approveKpoCards`,
      async({ orderId, transactionType, kpoIds, performAll }) =>
        await fetchWrapper.patch(
          `${baseUrl}/order/${orderId}/approve`, { orderId, transactionType, kpoIds, performAll })
    );
  }

  function generateKpoCardsConfirmationAsSender() {
    return createAsyncThunk(
      `${name}/generateKpoCardsConfirmationAsSender`,
      async({ orderId, kpoItems, performAll }) =>
        await fetchWrapper.patch(
          `${baseUrl}/order/${orderId}/sender/generate-confirmation`, { orderId, kpoItems, performAll })
    );
  }

  function generateKpoCardsConfirmationAsCarrier() {
    return createAsyncThunk(
      `${name}/generateKpoCardsConfirmationAsCarrier`,
      async({ orderId, kpoItems, performAll }) =>
        await fetchWrapper.patch(
          `${baseUrl}/order/${orderId}/carrier/generate-confirmation`, { orderId, kpoItems, performAll })
    );
  }

  function assignKpoCardsReceiveConfirmation() {
    return createAsyncThunk(
      `${name}/assignReceiveConfirmation`,
      async({ orderId, transactionType, kpoItems, performAll }) =>
        await fetchWrapper.patch(
          `${baseUrl}/order/${orderId}/assign-receive-confirmation`, { orderId, transactionType, kpoItems, performAll })
    );
  }

  function changeKpoCardsStatusToTransportConfirmation() {
    return createAsyncThunk(
      `${name}/changeKpoCardsStatusToTransportConfirmation`,
      async({ orderId, kpoIds, transactionType, performAll }) =>
        await fetchWrapper.patch(
          `${baseUrl}/order/${orderId}/confirm-transport`, { orderId, kpoIds, transactionType, performAll })
    );
  }

  function withdrawnKpoCards() {
    return createAsyncThunk(
      `${name}/withdrawnKpoCards`,
      async({ orderId, kpoItems, performAll }) =>
        await fetchWrapper.patch(
          `${baseUrl}/order/${orderId}/withdrawn`, { orderId, kpoItems, performAll })
    );
  }

  function rejectKpoCards() {
    return createAsyncThunk(
      `${name}/rejectKpoCards`,
      async({ orderId, kpoItems, performAll }) =>
        await fetchWrapper.patch(
          `${baseUrl}/order/${orderId}/reject`, { orderId, kpoItems, performAll })
    );
  }

  function reviseKpoCards() {
    return createAsyncThunk(
      `${name}/reviseKpoCards`,
      async({ orderId, kpoItems, performAll }) =>
        await fetchWrapper.patch(
          `${baseUrl}/order/${orderId}/revise`, { orderId, kpoItems, performAll })
    );
  }

  function updateKpoCardsData() {
    return createAsyncThunk(
      `${name}/updateKpoCardsData`,
      async({ orderId, kpoItems, performAll }) =>
        await fetchWrapper.patch(
          `${baseUrl}/order/${orderId}/update`, { orderId, kpoItems, performAll })
    );
  }

  function downloadConfirmation() {
    return createAsyncThunk(
      `${name}/downloadConfirmation`,
      async({ kpoId, cardNumber }) => await fetchWrapper.get(`${baseUrl}/${kpoId}/download-confirmation?cardNumber=${cardNumber}`));
  }

  function downloadPrintingPage() {
    return createAsyncThunk(
      `${name}/downloadPrintingPage`,
      async({ kpoId, cardNumber }) => await fetchWrapper.get(`${baseUrl}/${kpoId}/download-printing-page?cardNumber=${cardNumber}`));
  }

  function updateKpoData() {
    return createAsyncThunk(`${name}/updateData`, async ({ orderId, payload }) => {
      return await fetchWrapper.patch(`${baseUrl}/order/${orderId}/data`, payload);
    });
  }
}

function createExtraReducers() {
  return {
    ...downloadConfirmation(),
    ...downloadPrintingPage()
  };

  function downloadConfirmation() {
    var { fulfilled } = extraActions.downloadConfirmation;
    return {
      [fulfilled]: (state, action) => {
        const { payload } = action;
        downloadReport(payload);
      },
    };
  }

  function downloadPrintingPage() {
    var { fulfilled } = extraActions.downloadPrintingPage;
    return {
      [fulfilled]: (state, action) => {
        const { payload } = action;
        downloadReport(payload);
      },
    };
  }
}