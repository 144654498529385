import { Form } from "react-bootstrap";

export function BasicSwitch({ name, label, readonly, onChange, value }) {
  const handleChange = (e) => {
    if(!onChange){
      return;
    }

    onChange(e.target.checked);
  };

  return (
    <>
      <Form.Group className="mb-3 domain-control">
        <Form.Check
          id={name}
          type="switch"
          className="hover-pointer switch"
          label={label}
          disabled={readonly}
          defaultChecked={value}
          onChange={handleChange}
        ></Form.Check>
      </Form.Group>
    </>
  );
}


