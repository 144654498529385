import * as Yup from "yup";
import { FormDomain } from "_components/Forms/FormDomain";
import { TransportOrderDetails } from "../TransportOrderDetails";
import { ContractorOrderDetails } from "../ContractorOrderDetails";
import { WastesOrderDetails } from "../WastesOrderDetails";
import { OrderBdoDetails } from "../OrderBdoDetails";
import { CardView } from "../../Abstractions/CardView";
import { OrderProvider } from "./OrderContext";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TransactionTypeBadge } from "../../OrderViews/Components/TransactionTypeBadge";
import { ContractorTypeLargeBadge } from "../Extensions/ContractorTypeLargeBadge";
import { OrderValidator, TransportDetailsValidator } from "../../../domain/Orders/CreatePurchaseOrderValidator";
import { Dropdown } from "../../Abstractions/Dropdown";
import { TRANSACTION_TYPE, TransactionType } from "../../../domain/TransactionType";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export function BaseOrderForm({
  transactionTypeParam,
  validationSchema,
  submitAction,
  requestMapper,
  overrideAddressLabel,
  defaultValues,
  redirect,
  additionalFormWrapper,
  isOrderSeries
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const additionalContractorDetails = <AdditionalContractorForm />

  const formControls = (
    <OrderProvider transactionType={transactionTypeParam}>
      <div className="mb-3">
        {additionalFormWrapper}
        <BaseOrderTransactionTypeForm />
        <ContractorOrderDetails overrideAddressLabel={overrideAddressLabel}/>
        {additionalContractorDetails}
        <CardView headerText="Dane transportu" headerTypo="h4" headerContent={<ContractorTypeLargeBadge role="Carrier"/>}>
          <TransportOrderDetails transactionType={transactionTypeParam}/>
        </CardView>
        <CardView headerText="Dane towarów" headerTypo="h4">
          <WastesOrderDetails transactionType={transactionTypeParam}/>
        </CardView>
        <OrderBdoDetails isOrderSeries={isOrderSeries} />
      </div>
    </OrderProvider>
  );

  const baseValidationSchema = Yup.object().shape({
    transportDetails: TransportDetailsValidator.getValidator(transactionTypeParam)
  })
  .concat(OrderValidator.validationSchema)
  .concat(validationSchema);

  async function onSubmit(payload) {
    const request = payload;
    const requestData = requestMapper(payload);
    const response = await dispatch(submitAction(requestData));
    if (!response?.error) {
      const { payload } = response;
      if (redirect) {
        redirect({ orderId: payload, request });
      } else {
        navigate(`/orders/${payload}`);
      }
    }

    return response;
  }

  const defaults = {
    transactionType: transactionTypeParam,
    wastesDetails: { isBdoIntegrated: true },
    transportDetails: { transportType: "Internal", isPlannedLoadingDateChange: true },
    ...defaultValues
  };

  // console.log(defaults);
  return (
    <div className="container">
      <h2>Nowe zlecenie {isOrderSeries ? "cykliczne" : <TransactionTypeBadge transactionType={transactionTypeParam}/>}</h2>
      <div>
        <FormDomain
          formControls={formControls}
          validationSchema={baseValidationSchema}
          onSubmit={onSubmit}
          successMessage="Dodano zlecenie"
          submitBtnName="Zapisz zlecenie"
          defaultValues={defaults}
        />
      </div>
    </div>
  );
};

function BaseOrderTransactionTypeForm() {
  const navigate = useNavigate();
  const { getValues, watch } = useFormContext();
  const isOrderSeriesDefinition = getValues("isOrderSeriesDefinition");
  const transactionType = watch("transactionType");

  useEffect(() => {
    const handleTransactionTypeChange = () => {
      if (!transactionType || isOrderSeriesDefinition) {
        return;
      }
      const transactionLink = transactionType?.toLowerCase();
      const link = `/orders/create-${transactionLink}`;
      if (window.location.pathname === link) {
        return;
      }
      navigate(link);
    };

    handleTransactionTypeChange();
  }, [transactionType]);

  return <Dropdown name="transactionType" label="Typ transakcji" items={TransactionType} />;
}

function AdditionalContractorForm() {
  const { watch } = useFormContext();
  const transactionType = watch("transactionType");
  if(transactionType !== TRANSACTION_TYPE.TRANSPORT){
    return;
  }

  return <ContractorOrderDetails overrideContractorRole="Receiver" overrideAddressLabel="Adres rozładunku"/>;
}