import { BadgeDomain } from "_components/Abstractions/BadgeDomain";
import {
  ContactPersonType,
  ContactPersonTypeColor,
} from "domain/ContactPersonType";

export function ContactPersonTypeBadge({ type }) {
  return (
    <>
      <BadgeDomain key={type} bg={ContactPersonTypeColor[type]}>
        {ContactPersonType[type]}
      </BadgeDomain>
    </>
  );
}
