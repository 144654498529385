export class StringExtensions {
  static extractObjectBeforeLastProperty(path) {
    const parts = path.split(".");
    if (parts.length < 2) {
      return undefined; // Ścieżka jest zbyt krótka, nie można uzyskać obiektu przed ostatnią właściwością
    }

    parts.pop(); // Usuń ostatnią właściwość
    // const pathToExtract = parts.join('.');

    // Oczyść obiekt przedostatniej właściwości
    // const extractedObject = pathToExtract[pathToExtract.length-1];

    return parts.join(".");
  }

    static createQueryStringFromObject(obj) {
        let queryString = "?";

        if (!obj || Object.keys(obj).length === 0) {
            return queryString;
        }

        queryString += Object.entries(obj)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value ?? "")}`)
        .join("&");

        return queryString;
    }
}