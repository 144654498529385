import { BadgeDomain } from "../../_components/Abstractions/BadgeDomain";
import { OrderStatusBadge } from "../../_components/OrderViews/Components/OrderStatusBadge";
import { TransactionTypeBadge } from "../../_components/OrderViews/Components/TransactionTypeBadge";

export const OrderReportUserTable = ({ data }) => (
    <div>
      <div className="d-flex flex-column mt-2 mb-4">
        {data.map((user, index) => (
          <UserCard key={index} user={user}/>
        ))}
      </div>
    </div>);

const UserCard = ({ user }) => {
  const { handledPurchaseOrders, handledSaleOrders, handledTransportOrders, handledPurchaseOrdersCount, handledSaleOrdersCount, handledTransportOrdersCount } = user;
  const handledOrders = {
    "Purchase": { orders: handledPurchaseOrders, count: handledPurchaseOrdersCount },
    "Sale": { orders: handledSaleOrders, count: handledSaleOrdersCount },
    "Transport": { orders: handledTransportOrders, count: handledTransportOrdersCount }
  };

  return (
    <div className="user-card">
      <h5>Użytkownik: {user.userName} <BadgeDomain bg="dark">{user.handledOrdersCount}</BadgeDomain></h5>
      {Object.entries(handledOrders).map(([transactionType, item]) =>
        <div className="mb-2">
          <div className="d-flex align-items-end gap-2">
          <><div>Obsłużone zlecenia - {<TransactionTypeBadge transactionType={transactionType}/>}{<BadgeDomain>{item.count}</BadgeDomain>} {item.count !== 0 && "|"}</div></>
            {Object.entries(item.orders).map(([status, count]) => (<div> <OrderStatusBadge status={status}/> - <BadgeDomain bg="success" key={status}>{count}</BadgeDomain></div>))}
          </div>
        </div>)}
      <hr/>
    </div>
  );
};