import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../_helpers/notifications/useToasts";
import { maintenanceActions } from "../../_store/maintenance.slice.js";
import { useEffect } from "react";
import { ButtonOverlay } from "../Abstractions/ButtonOverlay";
import { Card } from "react-bootstrap";

export function VersionControl() {
  const { environmentInfo } = useSelector((x) => x.maintenance);
  const { version: backendVersion, environmentType: backendEnvironmentType } = environmentInfo;
  const frontendVersion = process.env.REACT_APP_VERSION;
  const frontendEnvironmentType = process.env.REACT_APP_ENVIROMENT;
  const dispatch = useDispatch();
  const { showErrorMessage } = useToast();
  const toolTip = <>{`BE ${backendEnvironmentType} / FE ${frontendEnvironmentType}`}</>;

  useEffect(() => {
    const refresh = async() => {
      const response = await dispatch(maintenanceActions.getEnvironmentInfo());
      if (response?.error) {
        showErrorMessage(response.error.message);
      }
    };

    refresh();
  }, []);

  return <ButtonOverlay tooltip={toolTip} placement="bottom">Wersja BE: {backendVersion} FE: {frontendVersion}</ButtonOverlay>;
}