import { useDispatch } from "react-redux";
import { driversActions } from "_store/drivers.slice";
import { DriverForm } from "./DriverForm";

export function UpdateDriver({ driver, onShow }) {
  const dispatch = useDispatch();
  const { id, login } = driver;
  const defaultValues = { ...driver };

  function onSubmit(payload) {
    return dispatch(driversActions.update({ id, payload }));
  }

  const buttonText = "Edytuj";
  const successMessage = "Zaktualizowano kierowcę";
  const title = `Edytuj kierowcę '${login}'`;

  return (
    <>
      <DriverForm
        onSubmit={onSubmit}
        buttonText={buttonText}
        successMessage={successMessage}
        title={title}
        defaultValues={defaultValues}
        onShow={onShow}
      />
    </>
  );
}
