import { driversActions } from "_store/drivers.slice";
import { useDispatch, useSelector } from "react-redux";
import { AssignVehicles } from "useCases/vehicles/AssignVehicles";

export function AssignVehiclesToDriver({
  driver,
  activeDriverId,
  onShow,
  isFormActive,
}) {
  const dispatch = useDispatch();
  const { id: driverId, displayName } = driver;
  const { driversVehicles } = useSelector((x) => x.drivers);
  const getVehiclesForDriver = () => {
    return dispatch(driversActions.getVehiclesForDriver({ driverId }));
  };

  const assignVehicles = async (payload) =>
    await dispatch(
      driversActions.assignVehicles({
        driverId,
        payload,
      })
    );

  const buttonText = "Przypisz pojazdy";
  const successMessage = "Przypisano pojazdy do kierowcy";
  const title = `Przypisz pojazdy dla kierowcy '${displayName?.toUpperCase()}'`;
  const displayInfo = { buttonText, successMessage, title };

  return (
    <AssignVehicles
      entityId={driverId}
      activeEntityId={activeDriverId}
      entityVehicles={driversVehicles}
      getVehiclesForEntity={getVehiclesForDriver}
      assignVehicles={(payload) => assignVehicles(payload)}
      displayInfo={displayInfo}
      onShow={onShow}
      isFormActive={isFormActive}
    />
  );
}
