import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { history, fetchWrapper } from "_helpers";

// create slice

const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem("user")),
    details: JSON.parse(localStorage.getItem("details")),
    error: null,
  };
}

function createReducers() {
  return {
    logout,
    clearErrors,
  };

  function logout(state) {
    state.user = null;
    localStorage.removeItem("user");
    history.navigate("/login");
  }

  function clearErrors(state) {
    state.error = null;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/accounts`;

  return {
    login: login(),
    changePassword: changePassword(),
    resetPassword: resetPassword()
  };

  function login() {
    return createAsyncThunk(
      `${name}/login`,
      async ({ username, password }) => {
        return await fetchWrapper.get(
          `${baseUrl}/authenticate?login=${username}&password=${password}`
        );
      }
    );
  }

  function changePassword() {
    return createAsyncThunk(
      `${name}/changePassword`,
      async ({ userId, payload }) => {
        return await fetchWrapper.patch(
          `${baseUrl}/${userId}/change-password`,
          payload
        );
      }
    );
  }

  function resetPassword() {
    return createAsyncThunk(
      `${name}/resetPassword`,
      async ({ userId, payload }) => {
        return await fetchWrapper.patch(
          `${baseUrl}/${userId}/reset-password`,
          payload
        );
      }
    );
  }
}

function createExtraReducers() {
  return {
    ...login(),
  };

  function login() {
    var { pending, fulfilled, rejected } = extraActions.login;
    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state, action) => {
        const response = action.payload;
        const { token, ...userDetails} = response;

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(token));
        localStorage.setItem("details", JSON.stringify(userDetails));
        state.user = token;
        state.details = userDetails;

        // get return url from location state or default to home page
        const { from } = history.location.state || { from: { pathname: "/" } };
        history.navigate(from);
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }
}
