import { useDispatch } from "react-redux";
import { useToast } from "../../../../../_helpers/notifications/useToasts";
import { orderFileDocumentsActions } from "../../../../../_store/orderFileDocuments.slice";
import { DomainButton } from "../../../../Abstractions/DomainButton";
import { Download } from "react-bootstrap-icons";
import { useState } from "react";
import { Spinner } from "react-bootstrap";

export function DownloadOrderDocumentFile({ orderId, file }) {
  const { id: fileId } = file;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { showSuccessMessage, showErrorMessage } = useToast();

  async function onSubmit() {
    setLoading(true);
    const response = await dispatch(orderFileDocumentsActions.downloadFile({ orderId, fileId }));
    setLoading(false);

    if (!response.error) {
      showSuccessMessage("Pobrano dokument pomyślnie");
    } else {
      showErrorMessage(response.error?.message);
    }
    return response;
  }

  return (
    <DomainButton
      btnSize="sm"
      variant="primary"
      onClick={onSubmit}>
      {loading ? <Spinner className="small-spinner x-small mt-1" size="sm"/> : <Download/>}
    </DomainButton>
  );
}