import { Dropdown } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import { stringToDate, stringToDateView } from "../../domain/Configuration";
import { TransactionTypeBadge } from "../OrderViews/Components/TransactionTypeBadge";

export function NotificationItem({ notification, handleOnClicked }) {
  const handleOnClick = () => {
    handleOnClicked(notification);
  }

  if(notification.type === "VehicleOverdueDateReminder") {
    return <Dropdown.Item className={`small ${notification.isRead ? "light-grey" : notification.tags?.[0] === "Critical" ? "error-bg" : "warning-bg"}`} onClick={() => handleOnClick(notification)}>
      {stringToDate(notification.createdAt)}
      {" "}
      {notification.description}
    </Dropdown.Item>
  }

  return <Dropdown.Item as={Link} to={`/orders/${notification.subjectId}`} className={`small ${notification.isRead ? "light-grey" : ""}`} onClick={() => handleOnClick(notification)}>
    {stringToDate(notification.createdAt)}
    {" "}
    <TransactionTypeBadge transactionType={notification.transactionType}/>
    ({stringToDateView(notification.realLoadingDate)})
    {" "}
    {notification.description}
  </Dropdown.Item>
}