import { Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { WastesMultiSelect } from "useCases/contractors/Extensions/CreateContractor/WastesMultiSelect";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { useSearchParams } from "react-router-dom";
import { BaseWasteForm } from "./BaseWasteForm";

export function BaseWastesForm({
  wasteFieldName,
  additionalFormControls,
  additionalWasteControls,
  isUsedContainerRequired,
  isAddressIdRequired,
  addressId,
  requireApplicableKpoItems = false,
  fillWasteMass = true,
  includeExtendedSaleWastesData = false
}) {
  const GetFieldName = (name) => `${wasteFieldName}.${name}`;
  const GetWasteItemFieldName = (idx) => `${wasteFieldName}.wastes[${idx}]`;
  const { watch, getValues, setValue } = useFormContext();
  const kpoItems = watch("kpoItems");
  const usedContainer = watch(GetFieldName("usedContainer"));
  const { usedContainerId } = usedContainer || {};
  // const { fields, replace, remove } = useFieldArray({ name: GetFieldName("wastes") });
  const wastes = watch(GetFieldName("wastes"));
  // const transferPurchaseId = watch("transportDetails.transferPurchase.id");

  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (usedContainer) {
  //     replace([]);
  //   }
  // }, [usedContainer]);

  // useEffect(() => {
  //   const getOrderInfoForTransfer = async() => {
  //     if (!transferPurchaseId) {
  //       return;
  //     }
  //
  //     const response = await dispatch(orderViewsActions.getWastesInfoForTransfer({ id: transferPurchaseId }));
  //     if (!response.error) {
  //       const { payload } = response;
  //       const items = payload?.map(x => ({ ...x.waste, wasteMass: x.wasteMass }));
  //       setValue("wastesDetails.saleWastes.wastes", items);
  //     }
  //   };
  //
  //   getOrderInfoForTransfer();
  // }, [transferPurchaseId]);

  return (
    <>
      <Row>{additionalFormControls}</Row>
      <WastesMultiSelect
        name={GetFieldName("wastes")}
        optionSave={(x) => x}
        required={false}
        isUsedContainerRequired={isUsedContainerRequired}
        isAddressIdRequired={isAddressIdRequired}
        resetOnReadonlyChange
        skipFieldsTrack={false}
        addressId={addressId}
        usedContainerId={usedContainerId}
        skipInitLoading
      />
      {fillWasteMass && wastes?.map((waste, index) => (
        <BaseWasteForm
          key={index}
          parentName={GetWasteItemFieldName(index)}
          waste={waste}
          kpoItems={kpoItems}
          requireApplicableKpoItems={requireApplicableKpoItems}
          additionalWasteControls={additionalWasteControls}
          includeExtendedSaleWastesData={includeExtendedSaleWastesData}/>
      ))}
    </>
  );
}
