import { contractorsActions } from "_store/contractors.slice";
import { useDispatch, useSelector } from "react-redux";
import { ContractorForm } from "./Extensions/CreateContractor/ContractorForm";
import { useNavigate } from "react-router-dom";

export function CreateContractor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contractorsPaging } = useSelector((x) => x.contractors);

  async function onSubmit(payload) {
    const response = await dispatch(contractorsActions.create(payload));
    if (!response?.error) {
      const { payload } = response;
      navigate(`/contractors/${payload}`);
      const pageNumber = contractorsPaging?.pageNumber ?? 1;
      dispatch(contractorsActions.getAll({ pageNumber }));
    } else {
      // window.scrollTo(0, 0);
    }

    return response;
  }

  return (
    <div className="container">
      <ContractorForm onSubmit={onSubmit} />
    </div>
  );
}
