import { DictionaryPage } from "_components/Abstractions/DictionaryPage";
import { DriverTable } from "_components/Drivers/DriverTable";
import { driversActions } from "_store/drivers.slice";
import { useSelector } from "react-redux";
import { CreateDriver } from "./CreateDriver";

export function GetDrivers(props) {
  const { drivers, driversPaging, driversSorting, } = useSelector((x) => x.drivers);
  const table = <DriverTable drivers={drivers} sorting={driversSorting} itemActions={driversActions} />;
  const createPage = <CreateDriver />;

  return (
    <DictionaryPage
      items={drivers}
      paging={driversPaging}
      itemsTable={table}
      itemActions={driversActions}
      createItem={createPage}
      activeEventKey={props.activeEventKey}
      pageEventKey={props.pageEventKey}
      includeSearcher
      searcherPlaceholder="Szukaj po loginie, nazwie..."
      sorting={driversSorting}
    />
  );
}
