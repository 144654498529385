import { ModalDomain } from "_components/Abstractions/ModalDomain";
import { vehiclesActions } from "_store/vehicles.slice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stringToDateView } from "../../domain/Configuration";

export function GetExtendedData({
  vehicle,
  activeVehicleId,
  onShow,
  isFormActive
}) {
  const { id, displayName, vehicleType } = vehicle;
  const isHook = vehicleType === "Hook";
  const isPoweredVehicle = vehicleType === "Tir" || isHook;

  const dispatch = useDispatch();

  useEffect(() => {
    if (activeVehicleId !== id || !isFormActive) {
      return;
    }
    dispatch(vehiclesActions.getExtendedData({ vehicleId: id }));
  }, [activeVehicleId, id, isFormActive]); // eslint-disable-line react-hooks/exhaustive-deps

  const { vehicleExtendedData } = useSelector((x) => x.vehicles);
  const { compatibleContainers, allowedDrivers } = vehicleExtendedData;

  const content = (
    <>
      <h6>Data ważności badania technicznego: {stringToDateView(vehicle.inspectionDate)}</h6>
      <h6>Data ważności OC: {stringToDateView(vehicle.insuranceDate)}</h6>
      <h6>Nr. polisy OC: {vehicle.insuranceNumber}</h6>
      <h6>Towarzystwo ubezpieczeniowe: {vehicle.insuranceCompany}</h6>
      {isPoweredVehicle && <>
        <h6>Data Tachograf: {stringToDateView(vehicle.tachographDate)}</h6>
        {isHook && <h6>Data UDT: {stringToDateView(vehicle.udtInspectionDate)}</h6>}
      </>}

      <h6>Lista przypisanych kontenerów:</h6>
      <ul>
        {compatibleContainers?.map((x, index) => (
          <li className="upper" key={index}>{x.type}</li>
        ))}
      </ul>
      <h6>Lista przypisanych kierowców:</h6>
      <ul className="upper">
        {allowedDrivers?.map((x, index) => (
          <li key={index}>{x.displayName}</li>
        ))}
      </ul>
    </>
  );

  const modalData = {
    title: "Informacje o pojeździe " + displayName.toUpperCase(),
    buttonText: "Informacje",
    body: content,
    isReadonly: true,
    handleShow: onShow
  };
  return (
    <>
      <ModalDomain {...modalData} />
    </>
  );
}
