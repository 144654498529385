import { TableReport } from "./TableReport";

export const OrderReportContainerTable = ({ data }) => (
  <div>
    <div className="">
      <TableReport>
        <thead>
        <tr>
          <th style={{ width: "20%"}}>Grupa kontenera</th>
          <th style={{ width: "40%"}}>Klient</th>
          <th style={{ width: "35%"}}>Adres</th>
        </tr>
        </thead>
        <tbody>
        {Object.entries(data).map(([groupId, groupData]) => (
          <ContainerDataGroup key={groupId} groupId={groupId} groupData={groupData}/>
        ))}
        </tbody>
      </TableReport>
    </div>
  </div>);

const ContainerDataGroup = ({ groupData }) => (
  <>
    {groupData.map((row, index) => (
      <tr key={index}>
        <td>{row.groupContainerName}</td>
        <td>{row.contractorName}</td>
        <td>{row.addressName}</td>
      </tr>))}
  </>);