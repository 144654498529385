import React, { useState } from 'react';
import { Button, ButtonGroup, Form } from "react-bootstrap";

export const MultiDropdown = ({ name, label, items, onSelectedItemsChanged, defaultKeys }) => {
  const initializeState = () => {
    const initialState = {};
    defaultKeys.forEach((key) => {
        initialState[key] = items[key];
    });

    return initialState;
  };

  const [selectedItems, setSelectedItems] = useState(() => initializeState());
  const handleSelectOption = (optionKey) => {
    const selectedOption = items[optionKey];
    const newSelectedItems = { ...selectedItems };

    if (selectedItems[optionKey]) {
      delete newSelectedItems[optionKey];
    } else {
      newSelectedItems[optionKey] = selectedOption;
    }

    setSelectedItems(newSelectedItems);

    if (onSelectedItemsChanged) {
      onSelectedItemsChanged(newSelectedItems);
    }
  };

  return (
    <Form.Group controlId={name} >
      <Form.Label>{label}</Form.Label>
      <ButtonGroup className="d-flex flex-row">
        {Object.keys(items).map((optionKey) => (
          <Button
            variant={"outline-success"}
            key={optionKey}
            size="sm"
            active={selectedItems[optionKey]}
            onClick={() => handleSelectOption(optionKey)}
          >
            {items[optionKey]}
          </Button>
        ))}
      </ButtonGroup>
    </Form.Group>
  );
};

