import { SelectTable } from "_components/Abstractions/SelectTable";
import React, { useContext, useEffect, useState } from "react";
import { kpoActions } from "../../../_store/kpo.slice";
import { KpoBdoTable } from "./KpoBdoTable";
import { KpoBdoSearcher } from "./KpoBdoSearcher";
import { KpoBdoSelectedCards } from "./KpoBdoSelectedCards";
import { OrderContext } from "../../Orders/MainForms/OrderContext";
import useFetch from "../../../_helpers/hooks/useFetch";
import { useDispatch } from "react-redux";
import { useToast } from "../../../_helpers/notifications/useToasts";
import { Container } from "react-bootstrap";
import { FilterDropdown } from "../../Abstractions/FilterDropdown";
import { FilterProvider } from "../../Forms/FilterContext";

export function KpoBdoSelect({ originatorRole, taxId, registrationNumber, addressRegistrationNumber, isSale, defaultSelectedCards, isModal = true }) {
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const fetch = useFetch();
  const { setKpoCards, plannedLoadingDate, bdoCompanyName,
    hasAdditionalBdoCompanies, additionalCompanyBdoDataItems, bdoCompanyId } = useContext(OrderContext) || {};
  const [searcherData, setSearcherData] = useState();
  const shouldBlockLoading = (!taxId || (!addressRegistrationNumber && !hasAdditionalBdoCompanies) || !plannedLoadingDate);
  const { showErrorMessage } = useToast();
  const dispatch = useDispatch();
  const onSubmit = async() => {
    const kpoIds = selectedItems.map(x => x.kpoId);
    const response = await dispatch(kpoActions.getKpoCardsBydIds({ kpoIds }));
    if (response.error) {
      showErrorMessage("Wystąpił błąd związany z pobraniem kart. Spróbuj dodać mniejszą ilość. Błąd: " + response.error?.message);
      return;
    }

    const mergedCards = selectedItems.map(x => {
      const correspondingItem = response.payload.find(y => x.kpoId === y.kpoId);
      return { ...x, ...correspondingItem };
    });

    setKpoCards(mergedCards);

    setShow(false);
  };

  const onSetShow = (show) => {
    if (show && defaultSelectedCards !== selectedItems) {
      setSelectedItems(defaultSelectedCards);
    }
    setShow(show);
  };

  const onSearchChange = async(filters) => {
    if (shouldBlockLoading) {
      setItems([]);
      return;
    }

    const isAdditionalCompanyBdo = taxId !== filters.taxId;
    const { allowMultipleEupIds } = filters;
    let searchInReceivers, searchInSenders;
    if (originatorRole === "Carrier" && (isAdditionalCompanyBdo || allowMultipleEupIds)) {
      searchInReceivers = !isSale;
      searchInSenders = isSale;
    }

    const cardNumber = !isAdditionalCompanyBdo && !isSale && !allowMultipleEupIds ?
      filters.cardNumber ?? addressRegistrationNumber :
      filters.cardNumber;


    let params = {
      ...filters,
      allowMultipleEupIds: undefined,
      originatorRole,
      pageNumber: 1,
      pageSize: 50,
      cardNumber,
      searchInSenders,
      searchInReceivers
    };
    await fetch(kpoActions.searchCards(params), setItems);
  };

  const handleUnselect = (item) => {
    const newItems = selectedItems.filter(x => x !== item);
    setSelectedItems(newItems);
  };

  const disabledReason = "Brak wybranego klienta, adresu BDO lub daty załadunku";

  const table = (<KpoBdoTable items={items} onSelectedItemsChanged={(newSelectedItems) => setSelectedItems(newSelectedItems)} selectedItems={selectedItems}/>);
  const bdoCompaniesItems = { [bdoCompanyId]: {
      companyId: bdoCompanyId,
      taxId: taxId,
      bdoCompanyName: bdoCompanyName,
      registrationNumber: registrationNumber,
      addressRegistrationNumber
    } };
  additionalCompanyBdoDataItems?.forEach(item =>
    bdoCompaniesItems[item.companyId] = {
      ...item,
      bdoCompanyName: item.companyName,
      // addressRegistrationNumber: item.registrationNumber
    });

  const onSelectedCompanyChanged = (companyId) => {
    const selectedCompanyBdo = bdoCompaniesItems?.[companyId];

    if (!selectedCompanyBdo) {
      setSearcherData({ bdoCompanyName, taxId, registrationNumber, addressRegistrationNumber });
    }
    else {
      setSearcherData({ addressRegistrationNumber: "", ...selectedCompanyBdo });
    }
  }

  useEffect(() => {
    setSearcherData({ bdoCompanyName, taxId, registrationNumber, addressRegistrationNumber });

  }, [bdoCompanyName, taxId, registrationNumber, addressRegistrationNumber]);

  const selectContent = <>
    {hasAdditionalBdoCompanies && <Container className="w-100">
      <FilterProvider onFilterChange={() => {}}>
        <FilterDropdown
          displayProperty="bdoCompanyName"
          items={bdoCompaniesItems}
          name="bdoCompanyName"
          label="Podmiot BDO"
          useDefaultValue={false}
          onChange={onSelectedCompanyChanged}/>
      </FilterProvider>
    </Container>}
    <KpoBdoSearcher
      {...searcherData}
      onSearchChange={onSearchChange}
      isSale={isSale}
      originatorRole={originatorRole}
      onSubmit={onSubmit}
    />
    <KpoBdoSelectedCards onSubmit={onSubmit} selectedCards={selectedItems} plannedLoadingDate={plannedLoadingDate} onUnselect={(x) => handleUnselect(x)}/>
  </>;

  return (
    <>
      <SelectTable
        table={table}
        label={isModal ? "Wyszukiwarka kart KPO" : ""}
        onSearchChange={onSearchChange}
        show={show}
        setShow={onSetShow}
        selectContent={isModal && selectContent}
        disabled={shouldBlockLoading}
        skipSearchBar
        disabledReason={disabledReason}
        isModal={isModal}
      />
    </>
  );
}
