import { BusinessWeekdays } from "../../../domain/Weekdays";
import { stringToDateView } from "../../../domain/Configuration";
import { ORDER_SERIES_TYPES } from "./CreateOrderSeries";

export class frequencyDescriptionProvider {
  static getFrequencyDescription = (seriesData) => {
    const { frequencyType, frequencyInterval, weeklyData, dateStart, dateEnd } = seriesData;
    let description = "Zlecenie powtarza się ";

    if (!frequencyInterval) {
      return description;
    }

    if (frequencyType === ORDER_SERIES_TYPES.DAILY) {
      description += frequencyInterval === "1" ? "codziennie" : `co ${frequencyInterval} dzień`;
    }

    if (frequencyType === ORDER_SERIES_TYPES.WEEKLY) {
      const weekDaysDescription = weeklyData?.weekDays ? `w dniach: ${weeklyData?.weekDays?.map(x => BusinessWeekdays[x].toLowerCase()).join(", ")}` : "";
      description += frequencyInterval === "1" ? `każdego tygodnia ${weekDaysDescription}` : `co ${frequencyInterval} tydzień ${weekDaysDescription}`;
    }

    if (frequencyType === ORDER_SERIES_TYPES.MONTHLY) {
      description += frequencyInterval === "1" ? `każdego miesiąca` : `co ${frequencyInterval} miesiąc`;
    }

    const dateStartDescription = dateStart ? `od ${stringToDateView(dateStart)}` : "";
    const dateEndDescription = dateEnd ? `do ${stringToDateView(dateEnd)}` : "do końca roku";

    return `${description} ${dateStartDescription} ${dateEndDescription}`;
  };
}
