import { useDispatch } from "react-redux";
import { mapToWastesDetails } from "../../../_services/Orders/orderActionsMapper";
import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { ORDER_STATUS } from "../../../domain/Orders/OrderStatus";
import { UpdateOrderWastesDetailsForm } from "./UpdateOrderWastesDetailsForm";

export function UpdateOrderWastesDetails({ orderId, order, transactionType }) {
  const dispatch = useDispatch();
  const { status, wastesDetails } = order || {};

  if(status === ORDER_STATUS.CLOSED || status === ORDER_STATUS.CANCELLED) {
    return;
  }

  const onSubmit = async(payload, includeExtendedSaleWastesData) => {
    const requestPayload = {
      id: orderId,
      wastesDetails: mapToWastesDetails(payload.wastesDetails, transactionType, includeExtendedSaleWastesData)
    };
    const response = await dispatch(ordersActions.updateWastesDetails({ orderId, payload: requestPayload }));
    if (!response?.error) {
      dispatch(orderViewsActions.getWastesDetails({ id: orderId }));
      dispatch(orderViewsActions.refreshHistory({ id: orderId }));
    }

    return response;
  }

  return <UpdateOrderWastesDetailsForm
    order={order}
    transactionType={transactionType}
    wastesDetails={wastesDetails}
    onSubmit={onSubmit}
  />;
}

