import { FormControl } from "../../../Forms/FormControl";
import { DomainSwitch } from "../../../Abstractions/DomainSwitch";
import { useFormContext } from "react-hook-form";

export function RealTransportDateForm() {
  const { watch } = useFormContext();
  const shiftRealTransportTime = watch("shiftRealTransportTime");

  return <>
    <FormControl name="realTransportTime" label="Rzeczywisty czas rozpoczęcia transportu" inputType="datetime-local" readonly={!shiftRealTransportTime}/>
    {<DomainSwitch name="shiftRealTransportTime" label="Późniejsza data transportu" />}
  </>;
}