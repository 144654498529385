import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Searcher } from "../../Forms/Searcher";

export function KeoBdoSearcher({ onSearchChange }) {
  const [filters, setFilters] = useState({});

  const onFilterChanged = ({ name, value }) => {
    let newFilters;

    if (value === "") {
      newFilters = { ...filters, [name]: null };
    } else {
      newFilters = { ...filters, [name]: value };
    }

    setFilters(newFilters);

    onSearchChange(newFilters);
  };

  return (
    <Container>
      <Searcher name="year" placeholder="Rok" inputType="text" onSearchChange={onFilterChanged}/>
      <Searcher name="wasteCode" placeholder="Kod odpadu" inputType="text" onSearchChange={onFilterChanged}/>
      <Searcher name="cardNumber" placeholder="Numer karty KEO" inputType="text" onSearchChange={onFilterChanged}/>
    </Container>
  );
}
