import { DomainSwitch } from "_components/Abstractions/DomainSwitch";
import { FormControl } from "_components/Forms/FormControl";
import { TextArea } from "_components/Forms/TextArea";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

export function ContactPersonForm({ contactField, defaultContactFieldName }) {
  const GetFullField = (field) => `${contactField}.${field}`;
  const { watch, setValue } = useFormContext();
  const defaultFieldName = GetFullField("isDefault");
  const defaultContactField = watch(defaultContactFieldName);
  const isContactDefault = watch(defaultFieldName);

  useEffect(() => {
    if (isContactDefault === false) {
      return;
    }

    if (defaultContactField !== contactField) {
      setValue(defaultFieldName, false);
    }
  }, [defaultContactField]);

  useEffect(() => {
    if (isContactDefault === true) {
      setValue(defaultContactFieldName, contactField);
    }
  }, [isContactDefault]);

  return (
    <>
      <FormControl label="Nazwa kontaktu" name={GetFullField("name")} />
      <Row>
        <Col>
          <FormControl label="Telefon" name={GetFullField("phone")} required={false}/>
        </Col>
        <Col>
          <FormControl label="E-mail" name={GetFullField("email")} required={false}/>
        </Col>
      </Row>
      <TextArea label="Uwagi" name={GetFullField("remarks")} />
      <DomainSwitch label="Domyślny kontakt" name={defaultFieldName} />
    </>
  );
}
