import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { useState } from "react";

export function DropdownInline({label, items, onSelectedChanged}) {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (value) => {
    setSelectedValue(value);
    if(onSelectedChanged){
      onSelectedChanged(value);
    }
  };

  return (
    <>
        <p>{label}</p>
      <ButtonGroup>
        {Object.keys(items).map((optionKey) => (
          <ToggleButton
            key={optionKey}
            type="radio"
            variant="outline-primary"
            name="radio"
            value={optionKey}
            checked={selectedValue === optionKey}
            onClick={(e) => handleChange(optionKey)}
          >
            {items[optionKey]}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </>
  );
};
