import { FloatingLabel, Form } from "react-bootstrap";
import { useFilterFormContext } from "../Forms/FilterContext";
import { Fragment, useEffect, useState } from "react";

export function FilterDropdown({
  name,
  label,
  items,
  defaultValue,
  displayProperty,
  onChange,
  useDefaultValue = true
}) {
  const { onFilterChange, currentFilters, reset } = useFilterFormContext();
  const [selectedValue, setSelectedValue] = useState(currentFilters[name]);

  useEffect(() => {
    if(reset > 0) {
      setSelectedValue(defaultValue ?? "");
    }
  }, [reset]);

  const GetItems = (
    <>
      {!items ? [] : Object.entries(items).map(([key, value]) =>
        (
          <option className="hover-pointer" key={key} value={key}>
            {displayProperty ? value[displayProperty] : value}
          </option>
        ))}
    </>
  );

  const handleOnChange = (e) => {
    setSelectedValue(e.target.value);
    onFilterChange({ name, value: e.target.value });
    onChange?.(e.target.value);
  };

  return (
    <>
      <Form.Group className="d-flex align-items-end gap-2 mb-3">
        <FloatingLabel label={label}>
          <Form.Select
            className="hover-pointer form-input"
            style={{ minWidth: "120px" }}
            onChange={handleOnChange}
            value={selectedValue}
          >
            {useDefaultValue && <option key={-1} value={""}>{defaultValue ?? ""}</option>}
            {GetItems}
          </Form.Select>
        </FloatingLabel>
      </Form.Group>
    </>
  );
}
