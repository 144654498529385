import * as Yup from "yup";
import { FormModal } from "../../../_components/Forms/FormModal";
import { TransportOrderDetails } from "../../../_components/Orders/TransportOrderDetails";
import { dateToString, stringToDateTimeForm } from "../../../domain/Configuration";
import { TransportDetailsValidator } from "../../../domain/Orders/CreatePurchaseOrderValidator";
import { OrderProvider } from "../../../_components/Orders/MainForms/OrderContext";

export function UpdateOrderTransportForm({ orderId, transactionType, transportDetails, isMainDetailsUpdateAvailable, onSubmit, isSeries }) {
  const formControls =
    <OrderProvider transactionType={transactionType} isUpdate>
      <TransportOrderDetails orderId={orderId} transactionType={transactionType} isMainDetailsUpdateAvailable={isMainDetailsUpdateAvailable} isSeries={isSeries}/>
    </OrderProvider>;
  const validationSchema = Yup.object().shape({ transportDetails: TransportDetailsValidator.getValidator(transactionType, true) });
  const defaultDriver = transportDetails?.driverId ?
    {
      id: transportDetails?.driverId,
      displayName: transportDetails?.driverName,
      phone: transportDetails?.driverPhone,
      driverRemarks: transportDetails?.driverRemarks
    } : null;
  const transportContractor = transportDetails?.transportContractorId ? {
    id: transportDetails?.transportContractorId,
    name: transportDetails?.transportContractorName,
  } : null;
  const freightForwarder = transportDetails?.freightForwarderId ? {
    id: transportDetails?.freightForwarderId,
    name: transportDetails?.freightForwarderName
  } : null;
  const defaultPlannedLoadingDate = transportDetails?.plannedLoadingDate ? stringToDateTimeForm(transportDetails?.plannedLoadingDate) : null;

  const defaultValues = {
    transactionType,
    transportDetails: {
      ...transportDetails,
      transferPurchases: transportDetails?.transferPurchases,
      externalIncludedVehicleType: transportDetails?.includedVehicleType,
      externalDriver: transportDetails?.transportType === "External" ? transportDetails?.driverName : null,
      externalDriverPhone: transportDetails?.transportType === "External" ? transportDetails?.driverPhone : null,
      shippingDate: transportDetails?.shippingDate ? dateToString(transportDetails?.shippingDate) : null,
      plannedLoadingDate: defaultPlannedLoadingDate,
      realLoadingDate: transportDetails?.realLoadingDate ? stringToDateTimeForm(transportDetails?.realLoadingDate) : null,
      plannedReloadingDate: transportDetails?.plannedReloadingDate ? stringToDateTimeForm(transportDetails?.plannedReloadingDate) : null,
      driverId: defaultDriver,
      isPlannedLoadingDateChange: false,
      transportContractor: transportContractor,
      freightForwarder: freightForwarder,
      vehicleId: transportDetails?.vehicleId ? {
        id: transportDetails?.vehicleId,
        displayName: transportDetails?.vehicleName,
        registrationNumber: transportDetails?.vehicleRegNumber
      } : null,
      includedVehicleId: !!transportDetails?.includedVehicleId ? {
        id: transportDetails?.includedVehicleId,
        displayName: transportDetails?.includedVehicleName,
        registrationNumber: transportDetails?.includedVehicleRegNumber,
        semiTrailerType: transportDetails?.includedVehicleType
      } : null
    }
  };

  return <>
    <FormModal
      formControls={formControls}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      sizeModal="lg"
      buttonText="Edytuj dane transportu"
      successMessage="Zaktualizowano dane transportu"
      title="Zmień dane transportu"
      btnSize="x-small"
    />
  </>;
}