import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { userActions } from "../../_store";

export function useUsersSearch({ refreshOnInitialize = true }) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const isFirstRender = useRef(true);

  const onSearchChange = async(searchTerm) => {
    const query = {
      pageNumber: 1,
      pageSize: 10,
      searchQuery: searchTerm
    };

    const { payload } = await dispatch(userActions.getAll(query));
    const items = payload.items;
    setItems(items);
  };

  useEffect(() => {
    if (!isFirstRender.current || refreshOnInitialize) {
      onSearchChange("");
    } else {
      isFirstRender.current = false;
    }
  }, []);

  return { items, onSearchChange };
}